import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import AlarmCard from "src/components/Configuration/AlarmCard";
import Page from "src/components/Page";
import TopSnackbar from "src/components/TopSnackbar";
import { createAlarm, deleteAlarm, updateAlarm } from "src/repos/alarms";
import { getDeviceAlarms } from "src/repos/devices";
import { getDeviceMachineKeyToHumanKeys } from "src/repos/devices/views";
import { machineStates } from "src/repos/telemetryDefinitions";
import { getUsers } from "src/repos/users";
import { useTranslation } from "react-i18next";

import AlarmForm from "./AlarmForm";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  keywords: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(2),
  },
  chips: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  select: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: theme.spacing(1),
  },
  tagLabel: {
    marginLeft: "10px",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "15px",
  },
  formControl: {
    width: "100%",
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
  paper: {},
}));

function Configuration({ machine: device, setMachine: setDevice }) {
  const classes = useStyles();
  const [availableMetrics, setAvailableMetrics] = useState([]);
  const [availableMachineStates, setAvailableMachineStates] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [alarm, setAlarm] = useState({});
  const [alarms, setAlarms] = useState([]);
  const [toast, setToast] = useState({
    error: false,
    visible: false,
    message: "",
  });
  const { t } = useTranslation(["glossary", "common"]);

  const toastMessage = (message, error) => {
    setToast({
      error: error || false,
      visible: true,
      message: message,
    });
    setTimeout(
      () =>
        setToast((toast_) => ({
          ...toast_,
          visible: false,
        })),
      3000
    );
  };

  useEffect(() => {
    if (device?.id) {
      getDeviceAlarms(device.id)
        .then((r) => r.json())
        .then(({ data }) => {
          setAlarms(data);
          setAlarm(data[0] || {});
        });
      getDeviceMachineKeyToHumanKeys(device.id).then((data) => {
        setAvailableMetrics(Object.values(data));
      });

      machineStates()
        .then((r) => r.json())
        .then((data) => {
          setAvailableMachineStates(Object.keys(data));
        });
      getUsers()
        .then((r) => r.json())
        .then(({ data }) => setEmailList(_.map(data, "email")));
    }
  }, [device]);

  const onCreateAlarm = (alarm) => {
    createAlarm(alarm).then((r) => {
      r.json()
        .then((data) => {
          if (r.status < 300) {
            toastMessage(t("alarm_created_successfully"));
            setAlarms((alarms_) => {
              setAlarm(data.data);
              return [...alarms_, data.data];
            });
          } else {
            const field = Object.keys(data.errors)[0];
            toastMessage(
              t("alarm_creation_error") + ` ${field} ${data.errors[field]}`,
              true
            );
          }
        })
        .catch(() => toastMessage(t("alarm_creation_error"), true));
    });
  };

  const onUpdateAlarm = (alarm) => {
    updateAlarm(alarm).then((r) => {
      r.json()
        .then((data) => {
          if (r.status < 300) {
            const alarm = data.data;
            toastMessage(t("alarm_updated_successfully"));
            setAlarms((alarms_) => {
              setAlarm(alarm);
              return alarms_.map((s) => {
                if (s.id === alarm.id) return alarm;
                else return s;
              });
            });
          } else {
            const field = Object.keys(data.errors)[0];
            toastMessage(
              t("alarm_update_error") + ` ${field} ${data.errors[field]}`,
              true
            );
          }
        })
        .catch(() => toastMessage(t("alarm_update_error"), true));
    });
  };

  const onDeleteAlarm = (alarm) => {
    deleteAlarm(alarm.id).then((r) => {
      if (r.status < 300) {
        toastMessage(t("alarm_deleted_successfully"));
        setAlarms((alarms_) => {
          return alarms_.filter((s) => s.id !== alarm.id);
        });
        setAlarm({});
      } else {
        toastMessage(t("alarm_deletion_error"), true);
      }
    });
  };

  return (
    <Page className={classes.root} title="Configuration">
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item container xs={12} md={4} spacing={2} direction="column">
            <div
              style={{
                maxHeight: "800px",
                overflowY: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginBottom: "8px" }}>
                <AlarmCard
                  selected={alarm?.id === undefined}
                  onClick={() => setAlarm({})}
                />
              </div>
              {_.map(alarms, (s) => (
                <div key={s.id} style={{ marginBottom: "8px" }}>
                  <AlarmCard
                    alarm={s}
                    selected={s.id === alarm.id}
                    onClick={() => setAlarm(s)}
                  />
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <AlarmForm
              machine={device}
              alarm={alarm}
              availableMetrics={availableMetrics}
              machineStates={availableMachineStates}
              emailList={emailList}
              onCreateAlarm={onCreateAlarm}
              onUpdateAlarm={onUpdateAlarm}
              onDeleteAlarm={onDeleteAlarm}
            />
          </Grid>
        </Grid>
      </Container>
      <TopSnackbar
        open={toast.visible}
        message={toast.message}
        error={toast.error}
      />
    </Page>
  );
}

export default Configuration;
