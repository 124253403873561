import _ from "lodash";
import moment from "moment";

export const isEncodingBinary = (encoding) => {
  return (
    encoding.length === 2 &&
    (((encoding[0].from === true || encoding[0].from === false) &&
      (encoding[1].from === true || encoding[1].from === false)) ||
      ((encoding[0].from === 1 || encoding[0].from === 0) &&
        (encoding[1].from === 1 || encoding[1].from === 0)))
  );
};

const formatPassthrough = (telemDispCfg, value) => {
  return value;
};

const formatEncoded = (telemDispCfg, value) => {
  var value_new = value;
  _.some(telemDispCfg.encoding, (record) => {
    if (record.from === value) {
      value_new = record.to;
      return true;
    }
    return false;
  });
  return value_new;
};

const formatPercentage = (telemDispCfg, value) => {
  return _.isNumber(value) ? `${_.round(value * 100, 0)}%` : value;
};

const formatSeconds = (telemDispCfg, value) => {
  let seconds = parseInt(value);
  if (_.isNaN(seconds)) return value;
  let hours = Math.floor(moment.duration(seconds, "seconds").asHours());
  let minutes = moment.duration(seconds, "seconds").minutes();
  return `${hours} hr ${minutes} min`;
};

const formatFloat = (telemDispCfg, value) => {
  if (_.isNumber(value))
    return telemDispCfg.precision || telemDispCfg.precision === 0
      ? _.round(value + Number.EPSILON, telemDispCfg.precision)
      : numberPrecision(value);
  return value;
};

const formatFallback = (telemDispCfg, value) => value;

const formatFunctionMap = {
  passthrough: formatPassthrough,
  encoded: formatEncoded,
  percentage: formatPercentage,
  seconds: formatSeconds,
  float: formatFloat,
};

export const formatValue = (telemDispCfg, value) => {
  return (formatFunctionMap[telemDispCfg.type] || formatFallback)(
    telemDispCfg,
    value
  );
};

const numberPrecision = (value) => {
  if (!value) {
    return value;
  }
  value = Number(value);
  // for booleans
  if (value === 0 || value === 1) {
    return value;
  }
  // figure out the numerical precision; for example, take 6432.6
  let number_as_exponent = value.toExponential().toString(); // yields 6.4326e+3
  let decimal_index = number_as_exponent.indexOf(".");
  let e_index = number_as_exponent.indexOf("e");
  let precision = e_index - decimal_index - 1; // yields 4
  let exponent = Number(number_as_exponent.slice(e_index + 1)); // yields 3
  // examples:
  // 15.235236 -> 15.2352
  // 15.2 -> 15.2
  // 10 -> 10
  // 0.01346 -> 0.0134
  return value.toFixed(Math.min(4, Math.max(precision - exponent, 0)));
};
// formats tooltip value using telemDispCfg if there
// is one in the cfgs map.
export const formatLabelValue = (cfgs, key, value) => {
  const cfg = cfgs[key];
  if (cfg && cfg.type !== "encoded") {
    value = formatValue(cfg, value);
    if (cfg.units) value = `${value} ${cfg.units}`;
    key = cfg.title;
  } else if (cfg && isEncodingBinary(cfg.encoding)) {
    value = `${Math.round(value * 100)}%`;
  } else if (!cfg) {
    value = numberPrecision(value);
  }
  return [key, value];
};

export const categories = [
  ["Productivity", "productivity"],
  ["Printing", "printing"],
  ["Converting", "converting"],
  ["Machine Overview", "machine_overview"],
  ["Other", "other"],
];

export const types = [
  ["Percentage", "percentage"],
  ["Duration", "seconds"],
  ["Numeric", "float"],
  ["Raw", "passthrough"],
  ["Encoded", "encoded"],
];

export const computed = [
  "time_online",
  "machine_running_feeding",
  "uptime",
  "sheets_per_hour",
  "msf_hr",
  "sheet_size",
  "number_of_single_sheet_feeds",
  "counter_ejector_speed_diff_percentage",
  "wash_requested_counter",
  "print_1_status",
  "print_2_status",
  "print_3_status",
  "print_4_status",
];
