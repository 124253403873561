import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { Area, AreaChart, XAxis, YAxis, ResponsiveContainer } from "recharts";

const MetricsChart = ({
  data = {},
  height = 60,
  margin = { top: 5, right: 5, bottom: 5, left: 5 },
}) => {
  const dataAggregate = useCallback(() => {
    const timeData = {};
    _.map(data, (timeseries) => {
      _.each(timeseries, (timeserie) => {
        _.has(timeData, timeserie.time)
          ? (timeData[timeserie.time] =
              timeData[timeserie.time] + (timeserie.value || 0))
          : (timeData[timeserie.time] = timeserie.value || 0);
      });
    });

    return timeData;
  }, [data]);

  const timeseriesData = useMemo(() => {
    const numOfDevices = Object.keys(data).length;
    const devicesData = dataAggregate();
    const seriesData = [];

    _.forEach(devicesData, (value, time) => {
      seriesData.push({ time, value: value / numOfDevices });
    });

    return _.sortBy(seriesData, "time");
  }, [data, dataAggregate]);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart data={timeseriesData} margin={margin}>
        <defs>
          <linearGradient id="colorY" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3B7686" stopOpacity={0.4} />
            <stop offset="95%" stopColor="#3B7686" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="time" hide />
        <YAxis
          hide
          unit="%"
          tickFormatter={(tick) => tick * 100}
          domain={[0, 1]}
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#3B7686"
          fillOpacity={1}
          fill="url(#colorY)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default MetricsChart;
