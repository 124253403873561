import socket from "src/repos/socket";
import _ from "lodash";

import { demoChannel } from "src/utils/demoMode";

const channels = {};
// The backend supplies this as the state of telemetries it has no data for.
const UNDEFINED = "__UNDEFINED__";

let subscriberID = 0;

// Manages channel subscriptions based on whether there are subscribers
// to those channels. If not, the channel is flagged to be removed, creating
// a minimum of a 5 second delay before the unsubscribe. This serves as
// a debounce mechanism if the user is quickly navigating back and forth or
// a component's state that is setting up the subscription is in rapid
// flux in the first couple moments after mounting the first time.
setInterval(() => {
  _.each(channels, (value, key) => {
    const noSubscribers = _.isEmpty(value.subscribers);
    if (noSubscribers && value.remove) {
      delete channels[key];
      value.channel.leave();
      console.log(`Leaving channel: ${key}`);
    } else if (noSubscribers) {
      value.remove = true;
    } else if (value.remove) {
      value.remove = false;
    }
  });
}, 5000);

function getChannel(name, existingSocket) {
  if (name.indexOf("kiwiplan") === -1) return socket(name, existingSocket);
  else return demoChannel(name);
}

// This is a closure which multiplexes event messages from the websocket
// to the subscribers. In addition:
// 1. It maintains the computed key to the managed channel.
// 2. Keeps track of the last message value to prevent the onMessage callbacks
//    from being called without a state change.
// 3. Maintains the current value returned in the message which is later used to
//    provide an initial value to subscribers.
function _handle(deviceID, telemetry) {
  const key = `${deviceID}:${telemetry}`;
  let last;
  return (response, force = false) => {
    const data = response.data;
    channels[key].value = data;
    const { subscribers } = channels[key];
    if (last !== data || force) {
      // subscriber is ["<telemetry>", callback]
      _.each(subscribers, (subscriber) => {
        if (subscriber) subscriber[1](data === UNDEFINED ? undefined : data);
      });
      last = data;
    }
  };
}

// Sets up a single subscription to a telemetry:
// 1. Initializes the channel subscription if not present.
// 2. Sets up the subscriber callback and returns an unsubscribe callback.
// 3. Performs initial call to internal handler with join reply. Or ...
// 4. Call 'onMessage' with current channel value.
function _subscribe(deviceID, telemetry, onMessage, socket) {
  const id = ++subscriberID;
  const handle = _handle(deviceID, telemetry);
  if (channels[`${deviceID}:${telemetry}`] === undefined) {
    const channel = getChannel(
      `machine_telemetry:single:${deviceID}:${telemetry}`,
      socket
    );
    channels[`${deviceID}:${telemetry}`] = {
      channel,
      subscribers: {
        [id]: [telemetry, onMessage],
      },
      value: undefined,
      remove: false,
    };
    channel
      .join()
      .receive("ok", (payload) => {
        handle({ data: payload }, true);
        channel.on(`telemetry`, handle);
      })
      .receive("error", (reason) => {
        console.error(reason);
        channel.leave();
      });
  } else {
    let { subscribers, value } = channels[`${deviceID}:${telemetry}`];
    subscribers[id] = [telemetry, onMessage];
    value !== undefined && handle({ data: value });
  }
  return () => {
    if (channels[`${deviceID}:${telemetry}`]) {
      let { subscribers } = channels[`${deviceID}:${telemetry}`];
      delete subscribers[id];
    }
  };
}

// Receives list of devices, telemetries, and a callback with this signature:
//   onChange("<deviceID>", { "<telemetry1>": <value1>, "<telemetry2>": <value2> })
// Returns an unsubscribe callback which calls all the individual unsubscribe
// callbacks created internally.
function subscribe(deviceIDs, telemetries, onChange, socket) {
  const deviceTelemetries = _.reduce(
    deviceIDs,
    (a1, deviceID) => {
      a1[deviceID] = _.reduce(
        telemetries,
        (a2, t) => {
          a2[t] = null;
          return a2;
        },
        {}
      );
      return a1;
    },
    {}
  );
  const cancelCallbacks = _.reduce(
    deviceIDs,
    (a, deviceID) => {
      return _.concat(
        a,
        _.map(telemetries, (t) => {
          return _subscribe(
            deviceID,
            t,
            (value) => {
              deviceTelemetries[deviceID][t] = value;
              onChange(deviceID, deviceTelemetries[deviceID]);
            },
            socket
          );
        })
      );
    },
    []
  );
  return () => _.each(cancelCallbacks, (cancel) => cancel());
}

export default {
  subscribe,
};
