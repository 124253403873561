import { createMuiTheme } from "@material-ui/core";
import overrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

const baseTheme = {
  palette,
  typography,
  overrides,
};

const defaultTheme = createMuiTheme(baseTheme);
// had to add the tab override in the main file
// becuase we needed access to the theme's breakpoints
defaultTheme.overrides.MuiTab = {
  root: {
    fontSize: 14,
    textTransform: "none",
    "&$selected": {
      fontWeight: 700,
    },
    [defaultTheme.breakpoints.up("md")]: {
      fontSize: 14,
    },
  },
};

defaultTheme.overrides.MuiCard = {
  root: {
    boxShadow:
      "0 0.46875rem 2.1875rem rgba(4,9,20,0.03), 0 0.9375rem 1.40625rem rgba(4,9,20,0.03), 0 0.25rem 0.53125rem rgba(4,9,20,0.05), 0 0.125rem 0.1875rem rgba(4,9,20,0.03)",
  },
};

defaultTheme.overrides.MuiToggleButton = {
  root: {
    color: palette.icon,
    "&:hover": {
      backgroundColor: "rgba(208, 208, 208, 0.20)",
    },
    "&$selected": {
      backgroundColor: palette.primary.main,
      color: palette.gray.white,
      "&:hover": {
        backgroundColor: palette.primary.light,
      },
      "&>span>p": {
        color: `${palette.gray.white} !important`,
      },
    },
    [defaultTheme.breakpoints.down("xl")]: {
      borderRadius: "4px !important",
      margin: "0 3px 5px 0",
      borderLeft: "1px solid rgba(0, 0, 0, 0.12) !important",
    },
    "&:first-child": {
      [defaultTheme.breakpoints.up("xl")]: {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
    },
    "&:last-child": {
      [defaultTheme.breakpoints.up("xl")]: {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
    },
  },
};

const dataGridTheme = createMuiTheme({
  palette: {
    primary: {
      main: palette.primary.main,
    },
    secondary: {
      main: palette.secondary.main,
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        flexGrow: 1,
      },
      gutters: {
        paddingLeft: `16px !important`,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14)",
      },
      root: {
        textTransform: "none",
        fontWeight: 600,
      },
    },
    MuiTableSortLabel: {
      root: { fontWeight: 600, fontSize: "0.8rem", textTransform: "uppercase" },
    },
    MuiTableRow: {
      root: {
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.05)",
        },
      },
    },
    MuiTableCell: {
      paddingCheckbox: {
        textAlign: "left !important",
      },
    },
  },
});

export const appBarTheme = defaultTheme;
export const gridTheme = createMuiTheme(dataGridTheme);
export const theme = defaultTheme;
export const themeWithRtl = createMuiTheme({ ...baseTheme, direction: "rtl" });
