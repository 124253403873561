import { Chip } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import WarningIcon from "@material-ui/icons/Warning";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import telemetries from "src/repos/telemetries";
import palette from "src/theme/palette";
import { useSocketContext } from "src/context/SocketContext";
import { useTranslation } from "react-i18next";

const stateConfig = {
  off: {
    color: palette.gray.lighter,
    icon: <CancelIcon style={{ color: palette.gray.main }} />,
    text: "disconnected",
  },
  unknown: {
    color: palette.gray.lighter,
    icon: <HelpIcon style={{ color: palette.gray.main }} />,
    text: "unknown",
  },
  not_ready: {
    color: palette.status.warning.extraLight,
    icon: <WarningIcon style={{ color: palette.status.warning.main }} />,
    text: "not_ready",
  },
  ready: {
    color: palette.status.normal.extraLight,
    icon: <CheckCircleIcon style={{ color: palette.status.normal.main }} />,
    text: "ready_2",
  },
  ready_in_setup: {
    color: palette.status.normal.extraLight,
    icon: <CheckCircleIcon style={{ color: palette.status.normal.main }} />,
    text: "ready_in_setup_2",
  },
  ready_to_run: {
    color: palette.status.normal.extraLight,
    icon: <CheckCircleIcon style={{ color: palette.status.normal.main }} />,
    text: "ready_to_run_2",
  },
  running: {
    color: palette.status.normal.extraLight,
    icon: <CheckCircleIcon style={{ color: palette.status.normal.main }} />,
    text: "running_2",
  },
  running_and_feeding: {
    color: palette.status.success.extraLight,
    icon: <CheckCircleIcon style={{ color: palette.status.success.main }} />,
    text: "running_&_feeding",
  },
};

export const tagValueToHuman = _.reduce(
  stateConfig,
  (acc, { text: human }, tag) => {
    acc[tag] = human;
    return acc;
  },
  {}
);

function MachineConnectionStatus({ machineId, className }) {
  const [machineState, setMachineState] = useState("Unknown");
  const config = stateConfig[machineState] || stateConfig["unknown"];
  const { socket } = useSocketContext();
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    if (machineId)
      return telemetries.subscribe(
        [machineId],
        ["machine_state"],
        (_, state) => {
          setMachineState(state.machine_state);
        },
        socket
      );
  }, [machineId, socket]);

  return (
    <Chip
      size="small"
      style={{ backgroundColor: config["color"] }}
      avatar={config["icon"]}
      label={t(config["text"])}
      className={className}
    />
  );
}

export default MachineConnectionStatus;
