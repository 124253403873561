import { MenuItem, Select } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import DateRangePicker from "src/components/TimePicker/DateRangePicker";
import { useTranslation } from "react-i18next";

function TimeFramePicker({
  classes,
  disabled,
  handleRangeChange,
  excludeExtra,
  excludeExtraReason,
}) {
  excludeExtra = excludeExtra === false;
  const initialDatePast = moment().subtract(20, "minutes");
  const [selectedTimeframe, setSelectedTimeframe] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(initialDatePast);
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const { t } = useTranslation(["glossary", "common"]);

  const basicTimeRanges = [
    { key: "24-hours", label: t("last_24_hours") },
    { key: "7-days", label: t("last_7_days") },
    { key: "30-days", label: t("last_30_days") },
  ];

  const extraTimeRanges = [
    { key: "3-months", label: t("last_3_months") },
    { key: "1-Year", label: t("last_year") },
    { key: "custom", label: t("custom_range") },
  ];

  return (
    <>
      <Select
        variant="outlined"
        color="primary"
        value={selectedTimeframe}
        onChange={(e) => {
          const timeframe = e.target.value;
          setSelectedTimeframe(timeframe);
          if (_.isNil(timeframe) || timeframe === "custom") return;
          const [amount, unit] = timeframe.split("-");
          const startDate = moment().add(-1 * Number(amount), unit);
          const endDate = moment();
          setSelectedStartDate(startDate);
          setSelectedEndDate(endDate);
          handleRangeChange(startDate, endDate);
        }}
        className={classes.input}
      >
        {basicTimeRanges.map(({ key, label }) => (
          <MenuItem value={key}>{label}</MenuItem>
        ))}
        {extraTimeRanges.map(({ key, label }) => (
          <MenuItem value={key} disabled={excludeExtra}>
            {label}{" "}
            {excludeExtra &&
              (excludeExtraReason ? ` - ${excludeExtraReason}` : "")}
          </MenuItem>
        ))}
        unlimited
      </Select>

      {selectedTimeframe === "custom" && (
        <div className={classes.picker}>
          <DateRangePicker
            onApply={(_event, picker) => {
              setSelectedStartDate(picker.startDate);
              setSelectedEndDate(picker.endDate);
              handleRangeChange(picker.startDate, picker.endDate);
            }}
            open="left"
            drops="auto"
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            inputStartDate={selectedStartDate}
            inputEndDate={selectedEndDate}
            maxDate={new Date()}
          ></DateRangePicker>
        </div>
      )}
    </>
  );
}

export default TimeFramePicker;
