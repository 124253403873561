import { Chip } from "@material-ui/core";
import SignalCellular0BarRoundedIcon from "@material-ui/icons/SignalCellular0BarRounded";
import SignalCellular1BarRoundedIcon from "@material-ui/icons/SignalCellular1BarRounded";
import SignalCellular2BarRoundedIcon from "@material-ui/icons/SignalCellular2BarRounded";
import SignalCellular3BarRoundedIcon from "@material-ui/icons/SignalCellular3BarRounded";
import SignalCellular4BarRoundedIcon from "@material-ui/icons/SignalCellular4BarRounded";
import SignalCellularOffRoundedIcon from "@material-ui/icons/SignalCellularOffRounded";
import React, { useEffect, useState } from "react";
import telemetries from "src/repos/telemetries";
import palette from "src/theme/palette";
import { useSocketContext } from "src/context/SocketContext";

const stateConfig = {
  unknown: {
    color: palette.gray.lighter,
    icon: (
      <SignalCellularOffRoundedIcon
        style={{ color: palette.gray.main }}
        titleAccess="Unknown"
      />
    ),
  },
  0: {
    color: palette.gray.lighter,
    icon: (
      <SignalCellular0BarRoundedIcon
        style={{ color: palette.gray.main }}
        titleAccess="No Signal"
      />
    ),
  },
  1: {
    color: palette.status.warning.extraLight,
    icon: (
      <SignalCellular1BarRoundedIcon
        style={{ color: palette.status.warning.main }}
        titleAccess="Poor"
      />
    ),
  },
  2: {
    color: palette.status.warning.extraLight,
    icon: (
      <SignalCellular2BarRoundedIcon
        style={{ color: palette.status.warning.extraLight }}
        titleAccess="Fair"
      />
    ),
  },
  3: {
    color: palette.status.success.extraLight,
    icon: (
      <SignalCellular3BarRoundedIcon
        style={{ color: palette.status.success.main }}
        titleAccess="Good"
      />
    ),
  },
  4: {
    color: palette.status.warning.extraLight,
    icon: (
      <SignalCellular4BarRoundedIcon
        style={{ color: palette.status.success.extraLight }}
        titleAccess="Excellent"
      />
    ),
  },
};

function MachineSignalStatus({ machineId, className }) {
  const [machineSignal, setMachineSignal] = useState("Unknown");
  const config = stateConfig[machineSignal] || stateConfig["unknown"];
  const { socket } = useSocketContext();

  useEffect(() => {
    if (machineId)
      return telemetries.subscribe(
        [machineId],
        ["signal_4bar"],
        (_, state) => {
          setMachineSignal(state.signal_4bar);
        },
        socket
      );
  }, [machineId, socket]);

  return (
    <Chip
      size="small"
      style={{ backgroundColor: config["color"] }}
      avatar={config["icon"]}
      className={className}
    />
  );
}

export default MachineSignalStatus;
