import { Avatar, CardHeader, Grid, Paper } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 32px",
    background: theme.palette.gray.lightest,
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.gray.white,
    },
  },

  backgroundIcon: {
    backgroundColor: theme.palette.primary.extraLight,
    color: theme.palette.primary.main,
  },
}));

function AddMaintenanceCard(props) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(1);

  const onMouseOver = () => {
    setElevation(3);
  };
  const onMouseOut = () => {
    setElevation(1);
  };

  const { t } = useTranslation(["glossary", "common"]);

  return (
    <Grid item xs={12}>
      <Paper
        className={classes.root}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        elevation={elevation}
        onClick={() => props.onClick()}
      >
        <CardHeader
          avatar={
            <Avatar className={classes.backgroundIcon}>
              <AddCircleOutlineIcon fontSize="large" />
            </Avatar>
          }
          titleTypographyProps={{ variant: "h5" }}
          title={t("add_another_maintenance")}
        />
      </Paper>
    </Grid>
  );
}

AddMaintenanceCard.propTypes = {
  onClick: PropTypes.func,
};

export default AddMaintenanceCard;
