import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  default as ChevronRight,
  default as ChevronRightIcon,
} from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import MaterialTable, { MTableBody } from "material-table";
import moment from "moment";
import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import palette from "src/theme/palette";
import { gridTheme, theme } from "../../../theme";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <SortIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Complete: forwardRef((props, ref) => (
    <AssignmentTurnedInOutlinedIcon {...props} ref={ref} />
  )),
};

const useStyles = makeStyles((theme) => ({
  root: {},
  displayNone: {
    display: "none",
  },
}));

const checkDate = (date) => {
  var d = new Date().toISOString();
  if (date < d) {
    return true;
  } else {
    return false;
  }
};
function Results({
  className,
  empty_rows_when_paging,
  show_toolbar,
  maintenance_list,
  num_results,
  show_filters,
  show_grouping,
  show_header_actions,
  display_columns,
  title,
  page_size_options,
  show_actions,
  isLoading,
  referrer: ref,
  showDeviceNameColumn,
  ...rest
}) {
  const [completeDialogOpen, setCompleteDialogOpen] = React.useState(false);
  const [activeMaintenance, setActiveMaintenance] = useState();
  const classes = useStyles();
  let history = useHistory();
  const { id } = useParams();

  const dynamicColumns = React.useMemo(() => {
    const baseColumns = [...display_columns];
    if (showDeviceNameColumn) {
      baseColumns.unshift({
        title: i18next.t("glossary:device_name"),
        field: "device_name",
      });
    }
    return baseColumns;
  }, [showDeviceNameColumn, display_columns]);

  const onOpenCompleteDialog = (data) => {
    setActiveMaintenance(data);
    setCompleteDialogOpen(true);
  };
  const handleCompleteClose = (data) => {
    setCompleteDialogOpen(false);
  };

  const handleComplete = () => {
    setCompleteDialogOpen(false);
  };

  const onRowClicked = (event, rowData) => {
    const serialNumber = id || rowData.device_id;
    history.push(
      "/machines/" + serialNumber + `/maintenance?selected="${rowData.id}"`,
      {
        referrer: ref,
        status: rowData.status,
      }
    );
  };

  const detailPanel = [
    (rowData) => ({
      tooltip: t("show_maintenance_instructions"),
      icon: () => (
        <span>
          <ChevronRightIcon
            className={!rowData.description && classes.displayNone}
          />
        </span>
      ),
      openIcon: () => (
        <ExpandMoreIcon
          className={!rowData.description && classes.displayNone}
        />
      ),
      render: (rowData) => {
        return rowData.description ? (
          <MuiThemeProvider theme={theme}>
            <div
              style={{
                backgroundColor: palette.gray.lightest,
                minheight: 100,
                padding: theme.spacing(3),
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h3" gutterBottom>
                    {t("how_to_perform_maintenance", {
                      maintenance_type: rowData.maintenance_type,
                    })}
                  </Typography>
                  <Typography variant="body1">
                    {rowData.description
                      ? rowData.description
                      : t("no_instructions_available")}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </MuiThemeProvider>
        ) : null;
      },
    }),
  ];

  const { t } = useTranslation(["glossary", "common"]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={gridTheme}>
        <MaterialTable
          icons={tableIcons}
          localization={{
            body: {
              emptyDataSourceMessage: t("no_records_to_display"),
            },
            toolbar: {
              exportTitle: t("export"),
              exportAriaLabel: t("export"),
              exportName: t("export"),
              showColumnsTitle: t("show_columns"),
              searchPlaceholder: t("search"),
              addRemoveColumns: t("add_or_remove_columns"),
            },
            grouping: {
              placeholder: t("drag_headers_message"),
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
              labelRowsSelect: t("rows"),
              firstTooltip: t("first_page"),
              previousTooltip: t("previous_page"),
              nextTooltip: t("next_page"),
              lastTooltip: t("last_page"),
            },
          }}
          components={{
            Container: (props) => (
              <div style={{ background: palette.gray.white }}>
                {props.children}
              </div>
            ),
            Body: (props) =>
              isLoading ? (
                Array.apply(null, {
                  length: num_results >= 10 ? 10 : num_results,
                }).map((e, i) => (
                  <TableRow width="100%" key={i}>
                    <TableCell />
                    {display_columns.map((item, index) => (
                      <TableCell scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <MTableBody {...props} />
              ),
          }}
          onRowClick={onRowClicked}
          options={{
            selection: false,
            pageSize: num_results,
            filtering: show_filters,
            exportButton: show_header_actions,
            exportAllData: show_header_actions,
            filterCellStyle: { backgroundColor: palette.gray.lightest },
            grouping: show_grouping,
            actionsColumnIndex: -1,
            toolbar: show_toolbar,
            search: show_header_actions,
            pageSizeOptions: page_size_options,
            emptyRowsWhenPaging: empty_rows_when_paging,
            showTitle: false,
          }}
          columns={dynamicColumns}
          data={maintenance_list}
          detailPanel={detailPanel}
          actions={
            show_actions && [
              {
                icon: tableIcons["Complete"],
                tooltip: t("mark_as_complete"),
                onClick: (event, rowData) => {
                  onOpenCompleteDialog(rowData);
                },
              },
            ]
          }
        />
      </MuiThemeProvider>
      <Dialog
        open={completeDialogOpen}
        onClose={handleCompleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("verify_event")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("maintenance_confirmation_message")}{" "}
            <span style={{ fontWeight: 700 }}>
              {activeMaintenance ? activeMaintenance.id : ""}
            </span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCompleteClose} color="default">
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleComplete}
            color="secondary"
            autoFocus
          >
            {t("mark_complete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  maintenance_list: PropTypes.array,
  num_results: PropTypes.number,
  show_filters: PropTypes.bool,
  show_grouping: PropTypes.bool,
  show_header_actions: PropTypes.bool,
  show_toolbar: PropTypes.bool,
  title: PropTypes.string,
  display_columns: PropTypes.array,
  page_size_options: PropTypes.array,
  empty_rows_when_paging: PropTypes.bool,
  show_actions: PropTypes.bool,
  isLoading: PropTypes.bool,
  showDeviceNameColumn: PropTypes.bool,
};

Results.defaultProps = {
  num_results: 20,
  show_filters: false,
  show_grouping: false,
  show_header_actions: true,
  show_current_state: true,
  show_machine: false,
  show_actions: true,
  title: i18next.t("glossary:maintenance"),
  show_toolbar: true,
  showDeviceNameColumn: false,
  display_columns: [
    { title: i18next.t("glossary:machine"), field: "machine", width: 100 },
    {
      title: i18next.t("glossary:maintenance_type"),
      field: "maintenance_type",
    },
    { title: i18next.t("glossary:current_state"), field: "current_state" },
    {
      title: i18next.t("glossary:next_service_date"),
      field: "next_service_date",
      type: "date",
      defaultSort: "asc",
      render: (rowData) => (
        <div
          style={{
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            fontWeight: checkDate(rowData.next_service_date) ? 700 : 500,
            color: checkDate(rowData.next_service_date)
              ? palette.status.error.main
              : palette.gray.main,
          }}
        >
          {moment(rowData.next_service_date).fromNow()}
        </div>
      ),
    },
  ],
  maintenance_list: [],
  page_size_options: [10, 20, 50],
  empty_rows_when_paging: false,
  isLoading: true,
};
export default Results;
