import { Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useMemo, useState } from "react";
import telemetries from "src/repos/telemetries";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    height: 167,
    justifyContent: "space-around",
    borderRadius: "10px",
  },
  container: {
    padding: 0,
  },
  value: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "column",
    height: 42,
  },
  valueLabel: {
    marginRight: 0,
    color: theme.palette.gray.main,
  },
  textBold: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  valueWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  grid: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  gridItem: {
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 20%",
    },
    [theme.breakpoints.down("md")]: {
      flex: "0 0 50%",
    },
  },
  running: {
    marginRight: "5px",
  },
  divider: {
    border: `1px solid ${theme.palette.gray.primary}`,
    height: "29px",
  },
}));

const FleetState = ({ activeMachines, totalMachines, label }) => {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <div className={classes.root}>
      <div className={classes.running}>
        <Typography variant="h1">{activeMachines}</Typography>
      </div>
      <div className={classes.valueWrapper}>
        <div className={classes.value}>
          <Typography variant="body1" className={classes.valueLabel}>
            {t("of")} {totalMachines}
          </Typography>
          <Typography variant="body2" className={classes.textBold}>
            {label}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const MachineFleet = ({ machines, socket }) => {
  const classes = useStyles();
  const [fleetSize, setFleetSize] = useState(0);
  const [machinesStatus, setMachinesStatus] = useState({});
  const machinesFeeding = useMemo(() => {
    return _.sum(
      _.map(_.values(machinesStatus), (status) =>
        status?.machine_feeding ? 1 : 0
      )
    );
  }, [machinesStatus]);
  const machinesRunning = useMemo(() => {
    return _.sum(
      _.map(_.values(machinesStatus), (status) =>
        status?.machine_running ? 1 : 0
      )
    );
  }, [machinesStatus]);
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    setFleetSize(machines?.length || 0);
    setMachinesStatus((machineStatus) => {
      return _.pick(
        machineStatus,
        _.map(machines, (m) => m.id)
      );
    });
    if (machines?.length) {
      return telemetries.subscribe(
        _.map(machines, (m) => m.id),
        ["machine_running", "machine_feeding"],
        (deviceID, state) => {
          setMachinesStatus((machineStatus) => {
            return {
              ...machineStatus,
              [deviceID]: state,
            };
          });
        },
        socket
      );
    }
  }, [machines, socket]);

  return (
    <Card className={classes.root}>
      <FleetState
        activeMachines={machinesRunning}
        totalMachines={fleetSize}
        label={t("running_2")}
      />
      <div className={classes.divider} />
      <FleetState
        activeMachines={machinesFeeding}
        totalMachines={fleetSize}
        label={t("feeding")}
      />
    </Card>
  );
};

export default MachineFleet;
