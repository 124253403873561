/* eslint-disable no-unused-vars */
import { Button, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useSocketContext } from "src/context/SocketContext";
import { API_URL } from "src/repos/base";
import palette from "src/theme/palette";
import { getQueryParam } from "src/utils/query";
import validate from "validate.js";
import { useStyles } from "./LoginFormStyles";

require("dotenv").config();

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const errorToT = {
  authentication_failed: "auth.login_error_invalid_credentials_",
  account_locked_too_many_attempts: "auth.login_error_locked_",
};

function LoginForm({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { setToken } = useSocketContext();
  const { t } = useTranslation(["common", "glossary"]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSuccessfulResponse = (response) => {
    response.json().then((result) => {
      Cookies.set("sessionId", result.data.token);
      Cookies.set("currentUser", result.data.user);
      setToken(result.data.token);
      const redirect = getQueryParam("redirect");
      history.push(redirect || "/dashboard");
    });
  };

  const handleErrorResponse = (response) => {
    response.json().then(({ data: { error } }) => {
      setFormState((prevFormState) => ({
        ...prevFormState,
        errors: {
          form: t(errorToT[error] || errorToT["authentication_failed"]),
        },
      }));
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await fetch(`${API_URL}/v1/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formState.values),
    }).then((response) => {
      if (response.ok) handleSuccessfulResponse(response);
      else handleErrorResponse(response);
    });
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        <TextField
          color="primary"
          error={hasError("email")}
          fullWidth
          required
          helperText={hasError("email") ? formState.errors.email : null}
          label={t("glossary:email_address")}
          name="email"
          onChange={handleChange}
          value={formState.values.email || ""}
          variant="outlined"
        />
        <TextField
          color="primary"
          error={hasError("password")}
          fullWidth
          required
          helperText={hasError("password") ? formState.errors.password : null}
          label={t("auth.password")}
          name="password"
          onChange={handleChange}
          type="password"
          value={formState.values.password || ""}
          variant="outlined"
        />
      </div>
      <Typography variant="body2" className={classes.error}>
        {formState.errors.form ? formState.errors.form : null}
      </Typography>
      <Button
        className={classes.submitButton}
        color="secondary"
        disabled={!formState.isValid}
        size="large"
        type="submit"
        variant="contained"
      >
        {t("auth.sign_in")}
      </Button>
      <Typography variant="body2" className={classes.error}>
        <Link
          variant="link"
          style={{ color: palette.text.link, fontWeight: 600 }}
          to={{
            pathname: "/login/reset-password-request",
            state: { email: formState.values.email },
          }}
        >
          {t("auth.password_reset")}
        </Link>
      </Typography>
    </form>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
};

export default LoginForm;
