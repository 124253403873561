import React, { useCallback, useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { Gauge } from "src/components/Gauge";
import _ from "lodash";
import { graphPercentage } from "src/utils/gauge";
import { useTranslation } from "react-i18next";
import StatCard from "src/components/StatCard";
import { Grid } from "@material-ui/core";
import { getDeviceTimeseries } from "src/repos/devices";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  gaugeItem: {
    padding: "0 10px 0 10px",
  },
  divider: {
    border: `0.01em solid ${theme.palette.gray.lighter}`,
    height: "59px",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const gaugeAngle = 280;

const SlideshowMachineStats = ({ machineData, machine }) => {
  const classes = useStyles();

  const utc_now = useMemo(() => moment.utc().format(), []);
  const four_hours_ago = useMemo(
    () => moment.utc().subtract(4, "hours").format(),
    []
  );
  const [metricsAggregate, setMetricsAggregate] = useState({});

  useEffect(() => {
    if (machine?.id) {
      getDeviceTimeseries({
        devices: [machine.id],
        metrics: ["machine_speed", "msf_hr"],
        start_date: four_hours_ago,
        end_date: utc_now,
        datapoints: 4,
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            setMetricsAggregate(data.data);
          }
        })
        .catch(() => setMetricsAggregate({}));
    }
  }, [machine, four_hours_ago, utc_now]);

  const gaugeData = useCallback(
    (key) => {
      const uptime = machineData?.[key];
      const percentage = uptime ? Math.abs(_.round(uptime * 100, 0)) : null;
      const [gauge, total] = graphPercentage(percentage, gaugeAngle);
      const label = typeof percentage === "number" ? `${percentage}%` : "--";

      return {
        data: [{ value: gauge }, { value: total }],
        label: label,
        rawValue: percentage,
      };
    },
    [machineData]
  );

  const { t } = useTranslation(["glossary", "common"]);

  const uptimeData = useMemo(() => {
    return gaugeData("machine_running");
  }, [gaugeData]);

  return (
    <>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <StatCard
          telemetry="msf_hr"
          machineId={machine?.id}
          label={t("msf")}
          units={t("per/hr")}
          chartData={metricsAggregate?.msf_hr}
        />
      </Grid>
      <div className={classes.divider} />
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <StatCard
          telemetry="machine_speed"
          machineId={machine?.id}
          label={t("machine_speed")}
          units={t("RPM")}
          chartData={metricsAggregate?.machine_speed}
        />
      </Grid>
      <div className={classes.divider} />
      <div className={classes.gaugeItem}>
        <Gauge
          height={150}
          width={150}
          data={uptimeData.data}
          value={uptimeData.label}
          rawValue={uptimeData.rawValue}
          label={t("uptime")}
          colorRules="op-uptime"
          variant="small"
        />
      </div>
    </>
  );
};

export default SlideshowMachineStats;
