import { Card, Collapse, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ResultsList from "../../../Lists/AnomalySectionList";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 16,
  },
  row: {
    padding: "15px",
  },
  box: {
    textAlign: "center",
  },
  boxBorder: {
    "&, &:after": {
      borderRight: "1px solid #ECEFF1",
    },
  },
  stats: {
    borderTop: "1px solid #ECEFF1",
    borderBottom: "1px solid #ECEFF1",
  },
  statLabel: {
    fontSize: "12px",
    color: "#000000",
  },
  statValue: {
    fontSize: 24,
  },
  expand: {
    textAlign: "right",
  },
  expandLink: {
    cursor: "pointer",
  },
}));

const SectionResults = ({
  referrer,
  results,
  columns,
  handleRowClick,
  sectionName,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const sectionColumns = columns.filter((column) => column.field !== "section");

  return (
    <Card className={classes.root}>
      <Grid container className={classes.row}>
        <Grid item xs={11} md={11} xl={11}>
          <Typography variant="h5">
            {sectionName} ({results?.length})
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} xl={1} className={classes.expand}>
          {expanded ? (
            <ExpandLessIcon
              className={classes.expandLink}
              onClick={toggleExpand}
            />
          ) : (
            <ExpandMoreIcon
              onClick={toggleExpand}
              className={classes.expandLink}
            />
          )}
        </Grid>
      </Grid>
      <Collapse in={expanded} className={classes.collapse}>
        <Grid container>
          <ResultsList
            referrer={referrer}
            result={results}
            loading={false}
            remotePagination={false}
            enablePagination={false}
            display_columns={sectionColumns}
            handleRowClick={handleRowClick}
          />
        </Grid>
      </Collapse>
    </Card>
  );
};

export default SectionResults;
