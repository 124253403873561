import {
  Hidden,
  TableCell,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  ThemeProvider as MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SortIcon from "@material-ui/icons/Sort";
import { Pagination, Skeleton } from "@material-ui/lab";
import _ from "lodash";
import MaterialTable, { MTableBody } from "material-table";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import palette from "src/theme/palette";
import { gridTheme } from "../../../theme";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref) => <SortIcon {...props} ref={ref} />),
};

const StyledTablePagination = withStyles({
  root: {
    flexShrink: 1,
    display: "flex",
  },
  spacer: {
    flexBasis: 0,
  },
})(TablePagination);

function Results({
  show_toolbar,
  empty_rows_when_paging,
  referrer: ref,
  className,
  result,
  display_columns,
  page_size_options,
  totalCount,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  onOrderChange,
  loading,
  handleRowClick,
  remotePagination,
  enablePagination,
  ...rest
}) {
  const onRowClicked = (event, rowData) => {
    handleRowClick(rowData);
  };
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <div style={{ width: "100%" }}>
      <MuiThemeProvider theme={gridTheme}>
        <MaterialTable
          key={`${result.length}-${remotePagination}`}
          icons={tableIcons}
          components={{
            Container: (props) => (
              <div style={{ background: palette.gray.white }}>
                {props.children}
              </div>
            ),
            Pagination: (props) => (
              <>
                <Hidden smUp>
                  <StyledTablePagination
                    rowsPerPageOptions={[]}
                    count={totalCount}
                    page={page}
                    rowsPerPage={25}
                    onChangePage={onChangePage}
                  />
                </Hidden>
                <Hidden xsDown>
                  <StyledTablePagination
                    rowsPerPageOptions={page_size_options}
                    count={totalCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={props.onChangePage}
                    onChangeRowsPerPage={(event, args) => {
                      onChangeRowsPerPage(event, args);
                      !remotePagination &&
                        props.onChangeRowsPerPage(event, args);
                    }}
                    ActionsComponent={(props) => (
                      <Pagination
                        size="small"
                        count={Math.ceil(totalCount / rowsPerPage)}
                        page={page + 1}
                        onChange={(event, page) => {
                          onChangePage(event, page - 1);
                          !remotePagination &&
                            props.onChangePage(event, page - 1);
                        }}
                        siblingCount={1}
                      />
                    )}
                  />
                </Hidden>
              </>
            ),
            Body: (props) =>
              loading ? (
                <tbody>
                  {Array.apply(null, { length: 10 }).map((e, i) => (
                    <TableRow width="100%" key={i}>
                      {display_columns
                        .filter((c) => !c.hidden)
                        .map((item, index) => (
                          <TableCell scope="row" key={index}>
                            <Skeleton animation="wave" />
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
                </tbody>
              ) : (
                <MTableBody {...props} />
              ),
          }}
          onRowClick={onRowClicked}
          onOrderChange={onOrderChange}
          enableColumnActions={false}
          options={{
            selection: false,
            pageSize: remotePagination ? _.max(page_size_options) : rowsPerPage,
            filtering: false,
            columnsButton: false,
            filterCellStyle: { backgroundColor: palette.gray.lightest },
            grouping: false,
            actionsColumnIndex: -1,
            toolbar: show_toolbar,
            pageSizeOptions: page_size_options,
            emptyRowsWhenPaging: empty_rows_when_paging,
            showTitle: false,
            sorting: true,
            debounceInterval: 800,
            searchAutoFocus: true,
            draggable: false,
            paging: enablePagination,
            headerStyle: {
              fontSize: "0.85rem",
              fontWeight: 400,
              textTransform: "uppercase",
            },
            rowStyle: {
              height: 50,
            },
          }}
          columns={display_columns}
          data={result}
          localization={{
            body: {
              emptyDataSourceMessage: t("no_records_to_display"),
            },
            toolbar: {
              exportTitle: t("export"),
              exportAriaLabel: t("export"),
              exportName: t("export"),
              showColumnsTitle: t("show_columns"),
              searchPlaceholder: t("search"),
              addRemoveColumns: t("add_or_remove_columns"),
            },
            grouping: {
              placeholder: t("drag_headers_message"),
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
              labelRowsSelect: t("rows"),
              firstTooltip: t("first_page"),
              previousTooltip: t("previous_page"),
              nextTooltip: t("next_page"),
              lastTooltip: t("last_page"),
            },
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  result: PropTypes.array,
  display_columns: PropTypes.array,
  referrer: PropTypes.string,
  totalCount: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onOrderChange: PropTypes.func,
  loading: PropTypes.bool,
  page_size_options: PropTypes.array,
  handleRowClick: PropTypes.func,
  remotePagination: PropTypes.bool,
  enablePagination: PropTypes.bool,
};

Results.defaultProps = {
  result: [],
  display_columns: [
    { title: i18next.t("glossary:section_caps"), field: "section" },
    {
      title: i18next.t("glossary:measure_caps"),
      field: "measure",
    },
    {
      title: i18next.t("glossary:anomalous_time_caps"),
      field: "anomalous_time",
    },
  ],
  page_size_options: [10, 20, 50, 100],
  referrer: "/anomalies",
  totalCount: 0,
  page: 1,
  rowsPerPage: 10,
  remotePagination: true,
  enablePagination: true,
};

export default Results;
