const UPPER_CASE_CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  .split("")
  .reduce((a, c) => {
    a[c] = true;
    return a;
  }, {});

export const isUpperCaseChar = (c) => UPPER_CASE_CHARS[c] === true;

/*
  Usage:

  > const s = "someCamelCaseString"
  > console.log(camelCaseToWithPrefixChar(s, " "))
  some Camel Case String
  >
*/
export const camelCaseToWithPrefixChar = (s, prefix) => {
  return s
    .split("")
    .reduce((a, c) => {
      if (isUpperCaseChar(c)) a.push(prefix);
      a.push(c);
      return a;
    }, [])
    .join("");
};

export const camelCaseToSnakeCase = (s) => {
  return camelCaseToWithPrefixChar(s, "_").toLowerCase();
};
