import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, colors } from "@material-ui/core";
import PropTypes from "prop-types";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import Account from "./Account";
import { currentUser } from "src/utils/session";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    backgroundColor: colors.grey[300],
    marginBottom: "30px",
  },
  tabsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

function AccountDetails() {
  const classes = useStyles();
  const user = currentUser();
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <Page className={classes.root} title="Account Details">
      <Container maxWidth="md">
        <PageHeader pageTitle={t("account_details")} />
        <div className={classes.content}>
          <Account user={user} />
        </div>
      </Container>
    </Page>
  );
}

AccountDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default AccountDetails;
