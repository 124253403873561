import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { getPartsQuote } from "src/repos/maintenances";
import { getQueryParam } from "src/utils/query";
import { ConfirmationDialog } from "src/components/ConfirmationDialog";

const PartsQuoteRequest = (props) => {
  const history = useHistory();
  const token = getQueryParam("token");
  const { id } = useParams();
  const { t } = useTranslation(["common", "glossary"]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [requestResult, setRequestResult] = useState("");

  useEffect(() => {
    getPartsQuote(id, token).then((response) => {
      if (response.status === 200) {
        setConfirmationMessage(t("user.parts_quote_requested"));
        setRequestResult("Request Submitted");
      } else {
        setConfirmationMessage(t("auth.unauthorized"));
        setRequestResult("Unauthorized");
      }
      setShowConfirmation(true);
    });
  }, [history, id, token, t]);

  return (
    <ConfirmationDialog
      open={showConfirmation}
      onClose={() => history.push("/dashboard")}
      title={requestResult}
      onConfirm={() => history.push("/dashboard")}
      confirmText={t("go_to_dashboard")}
      body={<>{confirmationMessage}</>}
    />
  );
};

export default PartsQuoteRequest;
