import telemetries from "src/repos/telemetries";
import { convertDeviceDataToTypes } from "src/utils/machineTelemetryMapping";
import _ from "lodash";

const machineSubscribe = (
  machines,
  telemetry,
  socket,
  setValues,
  setLoading
) => {
  if (_.isEmpty(machines)) {
    setLoading(false);
    return;
  }
  return telemetries.subscribe(
    _.map(machines, (m) => m.id),
    [telemetry],
    (deviceID, state) => {
      state = convertDeviceDataToTypes(state);
      setValues((currentValues) => {
        return {
          ...currentValues,
          [deviceID]: state[telemetry],
        };
      });
      setLoading(false);
    },
    socket
  );
};

export default machineSubscribe;
