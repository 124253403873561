import _ from "lodash";

export const filterNumbers = (values: []) => {
  return values.filter((value) => typeof value === "number");
};

export const tileAvg = (group: Record<string, number>, count: number) => {
  const values = filterNumbers((Object as any).values(group));
  const val = _.sum(values || [0]) / count;
  return _.round(val, 0);
};
