import {
  Button,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import FeatureNotAvailable from "src/components/FeatureNotAvailable";
import TimeFramePicker from "src/components/TimePicker/TimeFramePicker";
import { getCustomers } from "src/repos/customers";
import { getCustomerDevices } from "src/repos/devices";
import { getDevices } from "src/repos/devices";
import { getEvents } from "src/repos/events";
import palette from "src/theme/palette";
import { demoValue, isDemoModeEnabled } from "src/utils/demoMode";
import { isOEMSuperUser, isSuperUser } from "src/utils/session";
import { isAllowed } from "src/utils/tier_permissions";
import moment from "moment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: { marginTop: theme.spacing(3), marginBottom: theme.spacing(2) },
  checkboxWrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "stretch",
  },
  checkbox: {
    width: "250px",
  },
  flexCol: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    paddingBottom: 0,
    paddingTop: 0,
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    minWidth: "300px",
    width: "50%",
  },
  picker: { marginTop: theme.spacing(2) },
  link: {
    color: palette.text.link,
    fontSize: "14px",
    textTransform: "none",
    fontWeight: 400,
    cursor: "pointer",
  },
  buttonContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  captionContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },
  timer: {
    color: palette.secondary.dark,
  },
}));

function Events() {
  const classes = useStyles();

  const [customer, setCustomer] = useState({});
  const [customers, setCustomers] = useState({});
  const [device, setDevice] = useState([]);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [noData, setNoData] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const { t } = useTranslation(["glossary", "common"]);

  const demoModeEnabled = isDemoModeEnabled();

  const resourceMapById = (resources) => {
    const m = _.reduce(
      resources,
      (map, resource) => {
        map[resource.id] = resource;
        return map;
      },
      {}
    );
    return m;
  };

  const resourceMapLength = (resourceMap) => {
    return _.keys(resourceMap).length;
  };

  const setDeviceByID = (deviceID) => {
    const device_ = devices[deviceID];
    const allowed_ =
      isAllowed("unlimitedReport", device_.service_tier) || isSuperUser();
    setDevice(device_);
    setAllowed(allowed_);
  };

  const getEventsCSVHeaders = () => [
    "Author",
    "Machine",
    "Event ID",
    "Event Name",
    "Event Start Time",
    "Event End Time",
    "Downtime Start",
    "Downtime End",
    "Downtime Duration",
    "Notes",
  ];

  const eventsCSV = (events) => {
    const headers = getEventsCSVHeaders();
    events = _.map(events, (event) => {
      const duration =
        moment(event.downtime_start_at).isValid() &&
        moment(event.downtime_end_at).isValid()
          ? moment
              .duration(
                moment(event.downtime_start_at).diff(
                  moment(event.downtime_end_at)
                )
              )
              .humanize()
          : "No Downtime";
      return [
        demoValue(event.author),
        event.device.name,
        String(event.id),
        event.name,
        event.start_at,
        event.end_at,
        event.downtime_start_at,
        event.downtime_end_at,
        duration,
        demoModeEnabled ? "-- DEMO --" : event.notes || "",
        ..._.map(
          event.interventions,
          (int) => `${int.type} | ${int.component} | ${int.reason}`
        ),
      ];
    });
    let longest = _.max(_.map(events, (event) => event.length));
    _.map(_.range(longest - headers.length), (i) => {
      headers.push(`Intervention ${i + 1}`);
    });
    events = _.map(events, (event) => {
      if (event.length < longest)
        _.map(_.range(longest - event.length), () => {
          event.push("");
        });
      return event;
    });
    return [
      headers.join(","),
      ..._.map(events, (event) => event.join(",")),
    ].join("\n");
  };

  useEffect(() => {
    if (isOEMSuperUser()) {
      getDevices()
        .then((r) => r.json())
        .then((d) => d.data)
        .then(resourceMapById)
        .then(setDevices);
    } else {
      if (_.isEmpty(customers))
        getCustomers()
          .then((r) => r.json())
          .then((d) => d.data)
          .then(resourceMapById)
          .then((customers_) => {
            setCustomers(customers_);
            if (resourceMapLength(customers_) === 1)
              setCustomer(_.values(customers_)[0]);
          });
      if (customer.id)
        getCustomerDevices(customer.id)
          .then((r) => r.json())
          .then((d) => d.data)
          .then(resourceMapById)
          .then(setDevices);
    }
  }, [customer, customers]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    getEvents(device.serial_number, selectedDateRange[0], selectedDateRange[1])
      .then((r) => r.json())
      .then((d) => d.data)
      .then((events) => {
        if (events.length) {
          const csv = eventsCSV(events);
          const a = document.createElement("a");
          a.href = `data:text/csv;base64,${btoa(csv)}`;
          a.type = "text/csv";
          a.download = "HeliosEventReport.csv";
          a.style.display = "none";
          a.style.visibility = "hidden";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          setNoData(true);
          setTimeout(() => setNoData(false), 3000);
        }
        setLoading(false);
      });
  };

  const isFormValid = () => {
    if (isOEMSuperUser()) {
      return (
        !_.some([device, selectedDateRange], _.isEmpty) && !loading && allowed
      );
    } else {
      return (
        !_.some([device, customer, selectedDateRange], _.isEmpty) &&
        !loading &&
        allowed
      );
    }
  };

  const upsellText = t("upsell_message");

  return (
    <Card>
      <CardContent>
        <form
          onSubmit={(e) => {
            onSubmitHandler(e);
          }}
          disabled={!allowed}
        >
          {device.id && !allowed && (
            <FeatureNotAvailable upsellText={upsellText} />
          )}
          <div className={classes.flexCol}>
            {resourceMapLength(customers) >= 2 && (
              <FormControl required className={classes.formControl}>
                <Typography gutterBottom variant="h5">
                  {t("customer")}
                </Typography>
                <Select
                  variant="outlined"
                  color="primary"
                  className={classes.input}
                  value={customer.id || ""}
                  onChange={(e) => {
                    setCustomer(customers[e.target.value]);
                    setDevice({});
                  }}
                >
                  {_.map(_.values(customers), (customer_, i) => {
                    return (
                      <MenuItem value={customer_.id} key={i}>
                        {demoValue(customer_.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <FormControl required className={classes.formControl}>
              <Typography gutterBottom variant="h5">
                {t("which_machine_message")}
              </Typography>
              <Select
                variant="outlined"
                color="primary"
                className={classes.input}
                value={device.id || ""}
                onChange={(e) => setDeviceByID(e.target.value)}
              >
                {_.map(_.values(devices), (device_, i) => {
                  return (
                    <MenuItem value={device_.id} key={i}>
                      {device_.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl required className={classes.formControl}>
              <Typography gutterBottom variant="h5">
                {t("choose_the_timeframe")}
              </Typography>
              <TimeFramePicker
                classes={classes}
                excludeExtra={true}
                excludeExtraReason={"hello"}
                handleRangeChange={(startDate, endDate) => {
                  setSelectedDateRange([startDate, endDate]);
                }}
              />
            </FormControl>

            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                className={classes.button}
                disabled={!isFormValid()}
              >
                {loading ? t("generating") : t("export")}
              </Button>
              {noData && (
                <div className={classes.captionContainer}>
                  <Typography className={classes.timer}>
                    {t("no_data_found")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

export default Events;
