// @ts-nocheck
import {
  Accordion,
  AccordionDetails,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import {
  Alert,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { ShowEventContents } from "src/components/ShowEvent";
import DateRangePicker from "src/components/TimePicker/DateRangePicker";
import { getComponents } from "src/repos/components";
import { getDevices } from "src/repos/devices";
import { createEvent } from "src/repos/events";
import palette from "src/theme/palette";
import { isAllowed } from "src/utils/tier_permissions";
import Intervention from "../types/Intervention";
import { useTranslation } from "react-i18next";

interface ReportEventProps {
  reportButtonColor?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | undefined;
  machine: object;
  startAt: moment.Moment;
  endAt: moment.Moment;
  downtime_start_at?: moment.Moment;
  downtime_end_at?: moment.Moment;
  onClose?: Function;
  onCreated?: Function;
}

const useStyles = makeStyles((theme: any) => ({
  drawer: {
    padding: theme.spacing(0),
    height: "100%",
    overflow: "auto",
    display: "flex",
  },
  header: {
    backgroundColor: palette.primary.main,
    display: "flex",
  },
  title: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
  },
  separator: {
    width: "10%",
    fontWeight: 800,
    fontSize: 20,
  },
  mainCard: {
    maxWidth: 550,
    overflow: "visible",
    margin: `${theme.spacing(0)}px auto`,
    padding: theme.spacing(0),
  },
  inputLabel: {
    fontWeight: 700,
    fontSize: 16,
    padding: "10px 0",
  },
  disabledInput: {
    backgroundColor: palette.primary.ultraLight,
  },
  timeRangeInput: {
    backgroundColor: palette.primary.ultraLight,
    height: "40px",
  },
  toggleButton: {
    height: "40px",
    width: "70px",
  },
  typeToggleButton: {
    height: "40px",
  },
  accordion: {
    border: "none",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
  toggleItem: {
    maxHeight: "30px",
    paddingLeft: "0px",
  },
  [theme.breakpoints.up("sm")]: {
    half: {
      width: "50%",
    },
  },
  eventInterval: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
}));

function ReportEventButton({
  reportButtonColor = "secondary",
  machine,
  startAt,
  endAt,
  downtime_start_at,
  downtime_end_at,
  onClose,
  onCreated,
  preloaded = true,
  ...rest
}: ReportEventProps) {
  const classes = useStyles();
  const [activeMachine, setActiveMachine] = useState(machine);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDowntime, setShowDowntime] = useState(false);
  const [showInterventions, setShowInterventions] = useState(false);
  const [components, setComponents] = useState([]);
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const [createdEvent, setCreatedEvent] = useState<any>(null);
  const [snackBarDetail, setSnackBarDetail] = useState({
    showMessage: false,
    isError: false,
    message: "",
  });
  const [eventDetails, setEventDetails] = useState<any>({});
  const [allDevices, setAllDevices] = useState([]);
  const loading = activeMachine?.id || machine?.id ? false : true;
  const { t } = useTranslation(["glossary", "common"]);

  const resetDetails = () => {
    setEventDetails({
      name: "",
      start_at: moment(startAt).isValid() ? moment(startAt) : moment(),
      end_at: moment(endAt).isValid() ? moment(endAt) : moment(),
      notes: "",
    });
    setShowDowntime(false);
    setShowInterventions(false);
    setInterventions([]);
    setCreatedEvent(null);
  };

  useEffect(() => {
    setActiveMachine(machine);
  }, [machine]);

  useEffect(() => {
    resetDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDrawer]);

  useEffect(() => {
    if (!preloaded) {
      getDevices()
        .then((r) => r.json())
        .then((d) => {
          setAllDevices(d.data);
        });
    }
  }, [preloaded]);

  useEffect(() => {
    if (onCreated && !_.isEmpty(createdEvent)) onCreated(createdEvent);
  }, [onCreated, createdEvent]);

  const updateDowntime = (
    downtime_start_at?: moment.Moment,
    downtime_end_at?: moment.Moment
  ) => {
    setEventDetails({ ...eventDetails, downtime_start_at, downtime_end_at });
  };

  const updateEventTime = (start_at: moment.Moment, end_at: moment.Moment) => {
    setEventDetails({ ...eventDetails, start_at, end_at });
  };

  const toggleDowntimeAccordion = (show: boolean) => {
    setShowDowntime(show);
    if (!show) {
      updateDowntime(undefined, undefined);
    }
  };

  const toggleInterventionsAccordion = (show: boolean) => {
    const intervention = show ? [{ type: "REPLACE" }] : [];
    if (_.isEmpty(components)) {
      getComponents()
        .then((r) => r.json())
        .then((data) => setComponents(data));
    }
    setInterventions(intervention);
    setShowInterventions(show);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!_.isEmpty(activeMachine))
      createEvent({
        ...eventDetails,
        device_id: activeMachine?.id,
        interventions,
      })
        .then((r) => r.json())
        .then(({ data }) => {
          setCreatedEvent(data);
          setSnackBarDetail({
            isError: false,
            showMessage: true,
            message: t("event_created_successfully"),
          });
          setTimeout(() => {
            setSnackBarDetail((snackbar) => {
              return {
                ...snackbar,
                showMessage: false,
              };
            });
          }, 3000);
        });
  };

  const updateInterventionsList = (
    intervention: Intervention,
    index: number
  ) => {
    const newArr = [...interventions];
    newArr[index] = intervention;
    setInterventions(newArr);
  };

  const timeInterval = useMemo(() => {
    const datesDiff = moment(eventDetails?.downtime_start_at).diff(
      moment(eventDetails?.downtime_end_at)
    );
    const duration = moment.duration(datesDiff);

    return datesDiff === 0 ? "" : duration.humanize();
  }, [eventDetails]);

  const report_menu = () => (
    <>
      <Card id="reportCard" square className={classes.mainCard}>
        <CardHeader
          className={classes.header}
          action={
            <IconButton aria-label="Close" onClick={() => setShowDrawer(false)}>
              <CloseIcon style={{ color: palette.gray.white }} />
            </IconButton>
          }
          title={
            <span
              className={classes.title}
              style={{ color: palette.gray.white }}
            >
              {t("report_event")}
            </span>
          }
        />
        <Divider />
        <CardContent>
          <Typography style={{ paddingBottom: "20px" }} variant="subtitle1">
            {t("report_event_message")}
          </Typography>
          <form id="event_form" onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel} required>
                  {t("machine")}
                </InputLabel>
                {preloaded && machine?.id && (
                  <TextField
                    color="primary"
                    fullWidth
                    disabled
                    name="Machine Name"
                    required
                    value={machine?.name || machine?.serial_number}
                    variant="outlined"
                    className={classes.disabledInput}
                  />
                )}
                {!preloaded && (
                  <Select
                    fullWidth
                    variant="outlined"
                    color="primary"
                    required
                    labelId="machineID-label"
                    id="machineID"
                    value={activeMachine?.id}
                    defaultValue=""
                    onChange={(event) => {
                      setActiveMachine(
                        _.find(
                          allDevices,
                          (machine) => machine.id === event.target.value
                        )
                      );
                    }}
                    name="machineID"
                  >
                    {allDevices.map((machine) => (
                      <MenuItem value={machine.id} key={machine.id}>
                        {machine.name ? machine.name : machine.serial_number}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel} required>
                  {t("event_name")}
                </InputLabel>
                <TextField
                  color="primary"
                  fullWidth
                  name="Event Name"
                  required
                  onChange={(event) => {
                    setEventDetails({
                      ...eventDetails,
                      name: event.target.value,
                    });
                  }}
                  value={eventDetails.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel} required>
                  {t("when_did_it_happen")}
                </InputLabel>
                <DateRangePicker
                  onApply={(event: any, picker: any) =>
                    updateEventTime(picker.startDate, picker.endDate)
                  }
                  open="left"
                  drops="down"
                  parentEl="#reportCard"
                  startDate={moment(eventDetails?.start_at).toDate()}
                  endDate={moment(eventDetails?.end_at).toDate()}
                  isRequired={true}
                  inputStartDate={eventDetails?.start_at}
                  inputEndDate={eventDetails?.end_at}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {t("unplanned_downtime_message")}
                </InputLabel>
                <Accordion
                  className={classes.accordion}
                  expanded={showDowntime}
                  square
                >
                  <ToggleButtonGroup
                    value={showDowntime}
                    exclusive
                    onChange={(event: any, value: boolean) => {
                      !_.isNil(value) && toggleDowntimeAccordion(value);
                    }}
                  >
                    <ToggleButton className={classes.toggleButton} value={true}>
                      {t("yes")}
                    </ToggleButton>
                    <ToggleButton
                      className={classes.toggleButton}
                      value={false}
                    >
                      {t("no")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className={classes.eventInterval}>
                        <InputLabel className={classes.inputLabel} required>
                          {t("for_how_long")}
                        </InputLabel>
                        <Typography
                          style={{ padding: "10px 10px" }}
                          variant="body2"
                        >
                          {timeInterval}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <DateRangePicker
                          onApply={(event: any, picker: any) =>
                            updateDowntime(picker.startDate, picker.endDate)
                          }
                          open="left"
                          drops="down"
                          parentEl="#reportCard"
                          isRequired={showDowntime}
                          inputStartDate={eventDetails?.downtime_start_at}
                          inputEndDate={eventDetails?.downtime_end_at}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {t("interventions_message")}
                </InputLabel>
                <Accordion
                  className={classes.accordion}
                  expanded={showInterventions}
                  square
                >
                  <ToggleButtonGroup
                    value={showInterventions}
                    exclusive
                    onChange={(event: any, value: boolean) => {
                      !_.isNil(value) && toggleInterventionsAccordion(value);
                    }}
                  >
                    <ToggleButton className={classes.toggleButton} value={true}>
                      {t("yes")}
                    </ToggleButton>
                    <ToggleButton
                      className={classes.toggleButton}
                      value={false}
                    >
                      {t("no")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <AccordionDetails>
                    <Grid container spacing={1} alignItems="center">
                      {interventions.map(
                        (intervention: Intervention, index: number) => (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="h5">
                                {t("intervention")} #{index + 1}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              {index !== 0 && (
                                <IconButton
                                  aria-label={t("close")}
                                  onClick={() => {
                                    var array = [...interventions];
                                    array.splice(index, 1);
                                    setInterventions(array);
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                {t("type")}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                options={[
                                  "REPLACE",
                                  "LUBRICATE",
                                  "CLEAN",
                                  "REPAIR",
                                  "REBOOT",
                                ]}
                                value={intervention.type}
                                onChange={(event: any, newValue: any) => {
                                  intervention.type = newValue;
                                  updateInterventionsList(intervention, index);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Name"
                                    variant="outlined"
                                    required
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                {t("component")}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                options={components}
                                value={intervention.component}
                                onChange={(event: any, newValue: any) => {
                                  intervention.component = newValue;
                                  intervention.component_id = newValue.id;
                                  updateInterventionsList(intervention, index);
                                }}
                                groupBy={(option: any) => option.parent_name}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("name")}
                                    variant="outlined"
                                    required
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                color="primary"
                                fullWidth
                                label={t("part_number")}
                                onChange={(event: any) => {
                                  updateInterventionsList(
                                    {
                                      ...intervention,
                                      component_part_number: event.target.value,
                                    },
                                    index
                                  );
                                }}
                                value={intervention.component_part_number}
                                variant="outlined"
                              />
                            </Grid>
                            {!_.isNil(intervention.component) && (
                              <Grid item xs={12}>
                                <InputLabel className={classes.inputLabel}>
                                  {t("reason")}
                                </InputLabel>
                                <RadioGroup
                                  row
                                  aria-label={t("reason")}
                                  name="reason"
                                  value={intervention.reason_id}
                                  onChange={(event) => {
                                    updateInterventionsList(
                                      {
                                        ...intervention,
                                        reason_id: event.target.value,
                                      },
                                      index
                                    );
                                  }}
                                >
                                  {intervention.component.reasons.map(
                                    (reason: any) => (
                                      <FormControlLabel
                                        value={reason.id.toString()}
                                        control={
                                          <Radio
                                            style={{
                                              paddingTop: "0px",
                                              paddingBottom: "0px",
                                            }}
                                          />
                                        }
                                        label={reason.name}
                                      />
                                    )
                                  )}
                                </RadioGroup>
                              </Grid>
                            )}
                          </>
                        )
                      )}
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={() => {
                            setInterventions([
                              ...interventions,
                              { type: "REPLACE" },
                            ]);
                          }}
                        >
                          <AddIcon fontSize="small" /> {t("add_intervention")}
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {t("notes")}
                </InputLabel>
                <TextField
                  color="primary"
                  fullWidth
                  multiline
                  rows={8}
                  name="notes"
                  onChange={(event) => {
                    setEventDetails({
                      ...eventDetails,
                      notes: event.target.value,
                    });
                  }}
                  value={eventDetails.notes}
                  variant="outlined"
                />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <Button
                  aria-label="submit event"
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                  type="submit"
                  form="event_form"
                  value="Submit"
                >
                  {t("submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );

  return (
    <div>
      <Tooltip
        title={
          preloaded &&
          !_.isNil(activeMachine) &&
          !isAllowed("accessEvents", activeMachine?.service_tier) && (
            <span>
              {t("report_event_not_supported_part_1")}
              <br />
              {t("report_event_not_supported_part_2")}
            </span>
          )
        }
      >
        <span>
          <Button
            aria-label={t("report_event")}
            disabled={
              (preloaded && _.isNil(activeMachine)) ||
              !isAllowed("accessEvents", activeMachine?.service_tier)
            }
            color={reportButtonColor}
            variant="contained"
            onClick={() => {
              setShowDrawer(true);
            }}
          >
            {t("report_event")}
          </Button>
        </span>
      </Tooltip>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        {((activeMachine?.id && !_.isNil(activeMachine)) || !preloaded) &&
          _.isNil(createdEvent) &&
          report_menu()}
        {!_.isNil(createdEvent) && (
          <ShowEventContents
            event={createdEvent}
            device={activeMachine}
            onClose={() => setShowDrawer(false)}
          />
        )}
        <Snackbar
          open={snackBarDetail.showMessage}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity={snackBarDetail.isError ? "error" : "success"}>
            {snackBarDetail.isError && "An error occured — "}
            <strong>{snackBarDetail.message}</strong>
          </Alert>
        </Snackbar>
      </Drawer>
    </div>
  );
}

export default ReportEventButton;
