import { Card, CardContent, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import { ShowEvent } from "src/components/ShowEvent";
import { getEvents } from "src/repos/events";
import Results from "src/views/SunScreens/Lists/EventList";
import EmptyResults from "src/views/SunScreens/Lists/EmptyEventList";
import { useQueryParam } from "src/utils/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  container: {
    padding: 0,
  },
}));

function Events({ createdEvent, device, ...props }) {
  const classes = useStyles();
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedEventId, setSelectedEventId] = useQueryParam("event_id");
  const [eventsIsLoading, setEventsIsLoading] = useState(true);
  const [eventPage, setEventPage] = useState(0);
  const INITIAL_PAGE_SIZE = 20;

  useEffect(() => {
    getEvents(id)
      .then((r) => r.json())
      .then(({ data }) => {
        setEvents(data);
        if (selectedEventId && selectedEventId !== "") {
          const ix = _.findIndex(data, (ev) => ev.id === selectedEventId);
          setSelectedEvent(data[ix]);
          setEventPage(Math.floor(ix / INITIAL_PAGE_SIZE));
        } else {
          setEventPage(0);
        }
      })
      .then(() => setEventsIsLoading(false));
  }, [id, selectedEventId]);

  useEffect(() => {
    if (!_.isEmpty(createdEvent))
      setEvents((events) => [...events, createdEvent]);
  }, [createdEvent]);

  useEffect(() => {
    const ix = _.findIndex(events, (ev) => ev.id === selectedEventId);
    setSelectedEvent(events[ix]);
    setEventPage(Math.floor(ix / INITIAL_PAGE_SIZE));
  }, [events, selectedEventId]);

  return (
    <Page className={classes.root} title="Events">
      <Container maxWidth={false} className={classes.container}>
        <Card>
          <CardContent className={classes.content}>
            {eventsIsLoading ? (
              <EmptyResults pageSize={10} pageSizeOptions={[5, 10]} />
            ) : (
              <Results
                isLoading={eventsIsLoading}
                className={classes.results}
                eventList={events}
                onRowClicked={(sytheticEvent, event) =>
                  setSelectedEventId(event.id)
                }
                pageSize={INITIAL_PAGE_SIZE}
                initialPage={eventPage}
              />
            )}
          </CardContent>
        </Card>
      </Container>
      {selectedEvent && (
        <ShowEvent
          event={selectedEvent}
          device={device}
          onClose={() => setSelectedEventId("")}
        />
      )}
    </Page>
  );
}

export default Events;
