import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import SamplerCard from "src/components/Configuration/SamplerCard";
import Page from "src/components/Page";
import TopSnackbar from "src/components/TopSnackbar";
import {
  createSampler,
  deleteSampler,
  getSamplerSpecs,
  updateSampler,
} from "src/repos/samplers";
import { getDeviceSamplers } from "src/repos/devices";
import SamplerForm from "./SamplerForm";
import { defaultMappings } from "src/repos/telemetryDefinitions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  keywords: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(2),
  },
  chips: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  select: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: theme.spacing(1),
  },
  tagLabel: {
    marginLeft: "10px",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "15px",
  },
  formControl: {
    width: "100%",
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
  paper: {},
}));

function Configuration({ machine: device, setMachine: setDevice }) {
  const classes = useStyles();
  const [samplerTypes, setSamplerTypes] = useState({});
  const [defaultHumanTags, setDefaultHumanTags] = useState([]);
  const [sampler, setSampler] = useState({
    plc_port: "",
    plc_address: "",
    spec: "",
    name: "",
    id: "",
    only_mapped_tags: "",
  });
  const [samplers, setSamplers] = useState([]);
  const [toast, setToast] = useState({
    error: false,
    visible: false,
    message: "",
  });
  const { t } = useTranslation(["glossary", "common"]);

  const toastMessage = (message, error) => {
    setToast({
      error: error || false,
      visible: true,
      message: message,
    });
    setTimeout(
      () =>
        setToast((toast_) => ({
          ...toast_,
          visible: false,
        })),
      3000
    );
  };

  useEffect(() => {
    getSamplerSpecs()
      .then((r) => r.json())
      .then(({ data }) => setSamplerTypes(data));
  }, []);

  useEffect(() => {
    if (device?.id) {
      getDeviceSamplers(device.id)
        .then((r) => r.json())
        .then(({ data }) => {
          setSamplers(data);
          setSampler(data[0] || {});
        });

      defaultMappings()
        .then((r) => r.json())
        .then(({ data }) => {
          setDefaultHumanTags(data);
        });
    }
  }, [device]);

  const onCreateSampler = (sampler) => {
    createSampler(sampler).then((r) => {
      if (r.status < 300) {
        r.json().then((data) => {
          toastMessage(t("sampler_updated_successfully"));
          setSamplers((samplers_) => {
            setSampler(data);
            return [...samplers_, data];
          });
        });
      } else {
        toastMessage(t("sampler_creation_error"), true);
      }
    });
  };

  const onUpdateSampler = (sampler) => {
    updateSampler(sampler).then((r) => {
      if (r.status < 300) {
        r.json().then((data) => {
          toastMessage(t("sampler_updated_successfully"));
          setSamplers((samplers_) => {
            setSampler(data);
            return samplers_.map((s) => {
              if (s.id === data.id) return data;
              else return s;
            });
          });
        });
      } else {
        toastMessage(t("sampler_creation_error"), true);
      }
    });
  };

  const onDeleteSampler = (sampler) => {
    deleteSampler(sampler.id).then((r) => {
      if (r.status < 300) {
        toastMessage(t("sampler_deleted_successfully"));
        setSamplers((samplers_) => {
          return samplers_.filter((s) => s.id !== sampler.id);
        });
      } else {
        toastMessage(t("sampler_deletion_error"), true);
      }
    });
  };

  return (
    <Page className={classes.root} title="Configuration">
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item container xs={12} md={4} spacing={2} direction="column">
            <SamplerCard
              selected={sampler?.id === undefined}
              onClick={() => setSampler({})}
            />
            {_.map(samplers, (s) => {
              return (
                <SamplerCard
                  key={s.id}
                  sampler={s}
                  selected={s.id === sampler.id}
                  onClick={() => setSampler(s)}
                ></SamplerCard>
              );
            })}
          </Grid>
          <Grid item xs={12} md={8}>
            <SamplerForm
              machine={device}
              sampler={sampler}
              samplerTypes={samplerTypes}
              onCreateSampler={onCreateSampler}
              onUpdateSampler={onUpdateSampler}
              onDeleteSampler={onDeleteSampler}
              defaultHumanTags={defaultHumanTags}
            />
          </Grid>
        </Grid>
      </Container>
      <TopSnackbar
        open={toast.visible}
        message={toast.message}
        error={toast.error}
      />
    </Page>
  );
}

export default Configuration;
