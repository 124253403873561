import { get, post, put, del } from "./base";

export function deleteMaintenance(maintenanceID, all = false) {
  return del(`maintenances/${maintenanceID}${all ? "?all" : ""}`);
}

export function getDeviceMaintenances(deviceID) {
  return get(`devices/${deviceID}/maintenances`);
}

export function getRecommendedMaintenances() {
  return get(`maintenances/recommended`);
}

export function createMaintenance(
  deviceID,
  kind,
  double_up,
  custom_name,
  custom_cutoff_value,
  custom_max_tag_threshold
) {
  return post(`maintenances`, {
    device_id: deviceID,
    maintenance: {
      kind,
      double_up,
      custom_name,
      custom_cutoff_value,
      custom_max_tag_threshold,
    },
  });
}

export function updateMaintenance(maintenance) {
  return put(`maintenances/${maintenance.id}`, {
    maintenance,
  });
}

export function getPartsQuote(maintenanceId, token) {
  return post(`maintenances/${maintenanceId}/parts_quote_request`, {
    token,
  });
}
