import { getSamplerTelemetryDefinitions } from "src/repos/samplers";

export const doesSamplerHaveMachineKey = (samplerID, machineKey) => {
  return new Promise((resolve) => {
    getSamplerTelemetryDefinitions(samplerID, {
      limit: 1,
      filters: [{ op: "eq", fd: "machine_key", va: machineKey }],
    })
      .then((r) => r.json())
      .then(({ paging }) => resolve(paging.count > 0));
  });
};
