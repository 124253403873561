import { get, put, post, del } from "./base";

export function getUsers() {
  return get(`users`);
}

export function resend_sms_code() {
  return get(`users/send_sms_code`);
}

export function confirm_sms_code(code) {
  return post(`users/confirm_sms_code`, { code: code });
}

export function getCustomerUsers(customerID) {
  return get(`customers/${customerID}/users`);
}

export function getUser(userID) {
  return get(`users/${userID}`);
}

export function deleteUser(userID) {
  return del(`users/${userID}`);
}

export function updateUser(user) {
  return put(`users/${user.id}`, user);
}

export function createUser(user) {
  return post(`users`, user);
}

export function createPasswordReset(email) {
  return post("passwords", {
    user: {
      email: email,
    },
  });
}

export function updateUserPassword(password, password_confirmation, token) {
  return put(token ? `passwords/${token}` : "users/password", {
    user: {
      password,
      password_confirmation,
    },
  });
}
