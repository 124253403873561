import mock from "src/utils/mock";

mock.onGet("/api/issues/list").reply(200, {
  issues: [],
});

mock
  .onGet("/api/issues/list/sample", { params: { status: "Pending" } })
  .reply(200, {
    issues: [
      {
        id: 472727,
        date: "2020-05-04T08:31:13Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 800,
        make: "Subaru",
        model: "SVX",
        size: "2XL",
        sun_number: 665923,
        machine_state: "Feeding",
        modified_by: "sslator0",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 981048,
        date: "2020-06-17T02:34:13Z",
        status: "Pending",
        component: "Feeder",
        downtime: 787,
        make: "Chevrolet",
        model: "Tahoe",
        size: "2XL",
        sun_number: 618696,
        machine_state: "Offline",
        modified_by: "kkillimister1",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 902271,
        date: "2020-05-16T14:35:27Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 390,
        make: "Chevrolet",
        model: "Tahoe",
        size: "L",
        sun_number: 350199,
        machine_state: "Offline",
        modified_by: "khuey2",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 490933,
        date: "2020-07-06T12:54:52Z",
        status: "Pending",
        component: "Printer",
        downtime: 255,
        make: "Ford",
        model: "E-Series",
        size: "XL",
        sun_number: 168054,
        machine_state: "Online",
        modified_by: "lperkins3",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 298221,
        date: "2020-06-28T18:37:58Z",
        status: "Pending",
        component: "Printer",
        downtime: 523,
        make: "Mazda",
        model: "MPV",
        size: "2XL",
        sun_number: 239599,
        machine_state: "Online",
        modified_by: "tgladman4",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 140265,
        date: "2020-05-24T08:32:19Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 188,
        make: "Volkswagen",
        model: "Golf",
        size: "L",
        sun_number: 241187,
        machine_state: "Feeding",
        modified_by: "fwrangle5",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 951383,
        date: "2020-04-01T03:07:46Z",
        status: "Pending",
        component: "Feeder",
        downtime: 189,
        make: "Saab",
        model: "9-5",
        size: "XS",
        sun_number: 200459,
        machine_state: "Feeding",
        modified_by: "dpowner6",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 994268,
        date: "2020-07-05T22:42:15Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 485,
        make: "Mazda",
        model: "MPV",
        size: "2XL",
        sun_number: 811362,
        machine_state: "Online",
        modified_by: "rcastane7",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 381089,
        date: "2020-04-03T21:37:20Z",
        status: "Pending",
        component: "Feeder",
        downtime: 463,
        make: "Subaru",
        model: "Impreza",
        size: "L",
        sun_number: 325117,
        machine_state: "Online",
        modified_by: "dlethcoe8",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 545302,
        date: "2020-05-03T05:50:21Z",
        status: "Pending",
        component: "Feeder",
        downtime: 395,
        make: "Mazda",
        model: "Mazda2",
        size: "2XL",
        sun_number: 987833,
        machine_state: "Offline",
        modified_by: "csedger9",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 114318,
        date: "2020-04-02T04:30:30Z",
        status: "Pending",
        component: "Printer",
        downtime: 145,
        make: "Audi",
        model: "S4",
        size: "L",
        sun_number: 878201,
        machine_state: "Feeding",
        modified_by: "kchatelaina",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 121407,
        date: "2020-07-12T10:44:16Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 350,
        make: "Lincoln",
        model: "Continental",
        size: "L",
        sun_number: 378917,
        machine_state: "Offline",
        modified_by: "ovalentimb",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 264945,
        date: "2020-04-16T04:50:13Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 508,
        make: "Hyundai",
        model: "Accent",
        size: "M",
        sun_number: 723968,
        machine_state: "Online",
        modified_by: "sperric",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 880637,
        date: "2020-06-04T08:05:30Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 223,
        make: "Mercedes-Benz",
        model: "300SD",
        size: "2XL",
        sun_number: 981126,
        machine_state: "Feeding",
        modified_by: "ggisbyed",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 832825,
        date: "2020-05-13T11:51:23Z",
        status: "Pending",
        component: "Printer",
        downtime: 940,
        make: "Dodge",
        model: "Ram 3500",
        size: "XS",
        sun_number: 765976,
        machine_state: "Feeding",
        modified_by: "bblasettie",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 923250,
        date: "2020-06-05T14:27:35Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 891,
        make: "Volvo",
        model: "XC90",
        size: "S",
        sun_number: 792928,
        machine_state: "Feeding",
        modified_by: "gwathanf",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 446198,
        date: "2020-06-11T16:56:07Z",
        status: "Pending",
        component: "Feeder",
        downtime: 193,
        make: "Volkswagen",
        model: "Touareg 2",
        size: "XL",
        sun_number: 443611,
        machine_state: "Feeding",
        modified_by: "gsoulsbyg",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 505732,
        date: "2020-06-24T09:41:11Z",
        status: "Pending",
        component: "Printer",
        downtime: 894,
        make: "Suzuki",
        model: "Forenza",
        size: "S",
        sun_number: 774239,
        machine_state: "Online",
        modified_by: "gcoathh",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 155986,
        date: "2020-05-29T06:36:40Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 349,
        make: "Dodge",
        model: "Ram 2500",
        size: "S",
        sun_number: 925130,
        machine_state: "Online",
        modified_by: "bbraseneri",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 377054,
        date: "2020-06-28T19:02:49Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 383,
        make: "Volvo",
        model: "S60",
        size: "M",
        sun_number: 878386,
        machine_state: "Online",
        modified_by: "llongmanj",
        machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
      },
      {
        id: 137447,
        date: "2020-05-17T05:36:09Z",
        status: "Pending",
        component: "Feeder",
        downtime: 551,
        make: "Ford",
        model: "E-Series",
        size: "XL",
        sun_number: 357279,
        machine_state: "Feeding",
        modified_by: "rrickeardk",
        machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
      },
      {
        id: 374857,
        date: "2020-07-03T15:07:33Z",
        status: "Pending",
        component: "Feeder",
        downtime: 788,
        make: "Lotus",
        model: "Exige",
        size: "XS",
        sun_number: 793161,
        machine_state: "Offline",
        modified_by: "mrigebyl",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 338316,
        date: "2020-04-30T02:03:20Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 898,
        make: "Buick",
        model: "Skylark",
        size: "XS",
        sun_number: 111777,
        machine_state: "Offline",
        modified_by: "mcasalettom",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 134475,
        date: "2020-04-09T07:33:49Z",
        status: "Pending",
        component: "Feeder",
        downtime: 86,
        make: "Audi",
        model: "Coupe Quattro",
        size: "M",
        sun_number: 834798,
        machine_state: "Feeding",
        modified_by: "rblaschken",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 270212,
        date: "2020-05-27T20:41:01Z",
        status: "Pending",
        component: "Printer",
        downtime: 126,
        make: "Pontiac",
        model: "LeMans",
        size: "M",
        sun_number: 870379,
        machine_state: "Feeding",
        modified_by: "bmereweathero",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 142130,
        date: "2020-07-02T08:47:36Z",
        status: "Pending",
        component: "Printer",
        downtime: 535,
        make: "Oldsmobile",
        model: "Toronado",
        size: "XS",
        sun_number: 200620,
        machine_state: "Feeding",
        modified_by: "rlynnittp",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 427043,
        date: "2020-07-08T22:14:51Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 172,
        make: "Chevrolet",
        model: "S10",
        size: "XS",
        sun_number: 294129,
        machine_state: "Feeding",
        modified_by: "wpykermanq",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 460714,
        date: "2020-06-05T07:31:05Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 489,
        make: "Mitsubishi",
        model: "L300",
        size: "S",
        sun_number: 800024,
        machine_state: "Feeding",
        modified_by: "dfruinr",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 768943,
        date: "2020-06-07T02:14:40Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 4,
        make: "Chevrolet",
        model: "Silverado 1500",
        size: "XS",
        sun_number: 129651,
        machine_state: "Feeding",
        modified_by: "fklemkes",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 255679,
        date: "2020-05-27T23:04:29Z",
        status: "Pending",
        component: "Feeder",
        downtime: 478,
        make: "Ford",
        model: "Club Wagon",
        size: "3XL",
        sun_number: 193146,
        machine_state: "Feeding",
        modified_by: "lcuddyt",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 441034,
        date: "2020-04-18T21:27:49Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 155,
        make: "Toyota",
        model: "Echo",
        size: "XS",
        sun_number: 885915,
        machine_state: "Online",
        modified_by: "rfeaversu",
        machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
      },
      {
        id: 246036,
        date: "2020-04-13T16:24:36Z",
        status: "Pending",
        component: "Feeder",
        downtime: 189,
        make: "Volkswagen",
        model: "Jetta",
        size: "3XL",
        sun_number: 260644,
        machine_state: "Feeding",
        modified_by: "keshelbyv",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
      {
        id: 333491,
        date: "2020-05-12T05:34:36Z",
        status: "Pending",
        component: "Die Cutter",
        downtime: 521,
        make: "Toyota",
        model: "Avalon",
        size: "XL",
        sun_number: 797861,
        machine_state: "Feeding",
        modified_by: "mnorewoodw",
        machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
      },
    ],
  });

mock.onGet("/api/issues/list/sample").reply(200, {
  issues: [
    {
      id: 699016,
      date: "2020-01-21T22:46:30Z",
      status: "Verified",
      component: "Feeder",
      downtime: 45,
      make: "Buick",
      model: "Regal",
      size: "2XL",
      sun_number: 166698,
      machine_state: "Feeding",
      modified_by: "tfrisch0",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 602246,
      date: "2020-01-30T08:57:50Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 393,
      make: "Ram",
      model: "C/V",
      size: "M",
      sun_number: 562888,
      machine_state: "Feeding",
      modified_by: "sbreznovic1",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 202203,
      date: "2019-12-22T15:01:44Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 680,
      make: "Land Rover",
      model: "LR3",
      size: "XL",
      sun_number: 282198,
      machine_state: "Online",
      modified_by: "egyrgorcewicx2",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 286354,
      date: "2019-07-27T10:29:02Z",
      status: "Verified",
      component: "Printer",
      downtime: 885,
      make: "Ford",
      model: "Tempo",
      size: "3XL",
      sun_number: 330179,
      machine_state: "Online",
      modified_by: "jmilmith3",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 266833,
      date: "2019-08-22T00:21:40Z",
      status: "Pending",
      component: "Printer",
      downtime: 112,
      make: "Audi",
      model: "200",
      size: "S",
      sun_number: 529577,
      machine_state: "Feeding",
      modified_by: "awinspire4",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 738600,
      date: "2019-11-28T15:21:06Z",
      status: "Verified",
      component: "Printer",
      downtime: 763,
      make: "Audi",
      model: "100",
      size: "L",
      sun_number: 825472,
      machine_state: "Feeding",
      modified_by: "darboin5",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 979681,
      date: "2019-12-24T07:10:50Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 51,
      make: "Buick",
      model: "Rainier",
      size: "L",
      sun_number: 497700,
      machine_state: "Online",
      modified_by: "lfish6",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 476674,
      date: "2020-03-12T14:32:48Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 94,
      make: "Honda",
      model: "CR-V",
      size: "XS",
      sun_number: 703094,
      machine_state: "Offline",
      modified_by: "ealesin7",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 825073,
      date: "2019-09-07T09:02:09Z",
      status: "Pending",
      component: "Die Cutter",
      downtime: 305,
      make: "Chevrolet",
      model: "Silverado 1500",
      size: "XL",
      sun_number: 732409,
      machine_state: "Offline",
      modified_by: "omanicom8",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 404222,
      date: "2020-03-23T13:52:07Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 281,
      make: "Dodge",
      model: "Caravan",
      size: "S",
      sun_number: 227100,
      machine_state: "Feeding",
      modified_by: "cwhodcoat9",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 209133,
      date: "2019-05-16T10:43:50Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 309,
      make: "Panoz",
      model: "Esperante",
      size: "2XL",
      sun_number: 852661,
      machine_state: "Feeding",
      modified_by: "vbosharda",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 217496,
      date: "2019-12-31T23:45:15Z",
      status: "Verified",
      component: "Feeder",
      downtime: 187,
      make: "Volkswagen",
      model: "GTI",
      size: "2XL",
      sun_number: 226596,
      machine_state: "Offline",
      modified_by: "cdinesenb",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 492286,
      date: "2019-11-02T13:39:22Z",
      status: "Pending",
      component: "Die Cutter",
      downtime: 953,
      make: "Pontiac",
      model: "6000",
      size: "XS",
      sun_number: 278084,
      machine_state: "Offline",
      modified_by: "pgrenec",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 780268,
      date: "2019-09-15T06:12:04Z",
      status: "Verified",
      component: "Printer",
      downtime: 654,
      make: "Dodge",
      model: "Ram 3500",
      size: "3XL",
      sun_number: 442690,
      machine_state: "Offline",
      modified_by: "bdelaunded",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 908162,
      date: "2019-06-21T19:30:01Z",
      status: "Pending",
      component: "Die Cutter",
      downtime: 75,
      make: "Lamborghini",
      model: "Murciélago",
      size: "XS",
      sun_number: 280625,
      machine_state: "Online",
      modified_by: "jbavagee",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 603585,
      date: "2020-02-26T07:35:30Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 397,
      make: "Chevrolet",
      model: "Corvette",
      size: "XS",
      sun_number: 340100,
      machine_state: "Feeding",
      modified_by: "rdietmarf",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 840205,
      date: "2019-05-07T21:09:17Z",
      status: "Pending",
      component: "Die Cutter",
      downtime: 813,
      make: "Chevrolet",
      model: "Express",
      size: "2XL",
      sun_number: 328358,
      machine_state: "Offline",
      modified_by: "cclooneyg",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 777546,
      date: "2019-06-28T04:36:01Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 816,
      make: "GMC",
      model: "1500 Club Coupe",
      size: "2XL",
      sun_number: 969056,
      machine_state: "Offline",
      modified_by: "mreeceh",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 662199,
      date: "2019-07-20T14:01:24Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 471,
      make: "GMC",
      model: "Canyon",
      size: "3XL",
      sun_number: 983901,
      machine_state: "Online",
      modified_by: "hdcostai",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 142963,
      date: "2019-05-27T19:10:29Z",
      status: "Pending",
      component: "Printer",
      downtime: 61,
      make: "GMC",
      model: "Savana 3500",
      size: "2XL",
      sun_number: 265964,
      machine_state: "Feeding",
      modified_by: "jtutingj",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 977613,
      date: "2019-09-07T07:11:33Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 932,
      make: "Mercedes-Benz",
      model: "E-Class",
      size: "M",
      sun_number: 215762,
      machine_state: "Feeding",
      modified_by: "jmcblaink",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 554199,
      date: "2019-04-05T09:25:30Z",
      status: "Pending",
      component: "Die Cutter",
      downtime: 179,
      make: "Toyota",
      model: "Camry",
      size: "3XL",
      sun_number: 872950,
      machine_state: "Offline",
      modified_by: "lmainel",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 381637,
      date: "2020-01-24T00:38:19Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 828,
      make: "Nissan",
      model: "Quest",
      size: "XL",
      sun_number: 858816,
      machine_state: "Offline",
      modified_by: "uladymanm",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 697054,
      date: "2019-06-14T19:38:06Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 52,
      make: "Mercury",
      model: "Cougar",
      size: "L",
      sun_number: 943427,
      machine_state: "Offline",
      modified_by: "gchoppingn",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 288005,
      date: "2019-07-30T00:48:36Z",
      status: "Pending",
      component: "Feeder",
      downtime: 896,
      make: "Ford",
      model: "E-Series",
      size: "XL",
      sun_number: 178959,
      machine_state: "Online",
      modified_by: "spavkovico",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 111935,
      date: "2019-09-25T05:30:07Z",
      status: "Pending",
      component: "Feeder",
      downtime: 523,
      make: "Toyota",
      model: "Camry Solara",
      size: "XL",
      sun_number: 855521,
      machine_state: "Online",
      modified_by: "wblumsonp",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 842408,
      date: "2019-08-13T21:38:27Z",
      status: "Verified",
      component: "Printer",
      downtime: 493,
      make: "Honda",
      model: "Pilot",
      size: "L",
      sun_number: 119120,
      machine_state: "Online",
      modified_by: "mmcilhoneq",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 711878,
      date: "2020-03-27T13:07:22Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 233,
      make: "Audi",
      model: "Q5",
      size: "XS",
      sun_number: 656582,
      machine_state: "Feeding",
      modified_by: "cbordoner",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 260171,
      date: "2019-07-19T16:30:01Z",
      status: "Pending",
      component: "Printer",
      downtime: 92,
      make: "Volkswagen",
      model: "Cabriolet",
      size: "S",
      sun_number: 433138,
      machine_state: "Offline",
      modified_by: "dmooress",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 746425,
      date: "2019-12-29T23:21:46Z",
      status: "Pending",
      component: "Feeder",
      downtime: 792,
      make: "Ford",
      model: "F250",
      size: "XL",
      sun_number: 763703,
      machine_state: "Feeding",
      modified_by: "tfarlambet",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 862282,
      date: "2019-05-09T16:55:16Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 909,
      make: "Jeep",
      model: "Grand Cherokee",
      size: "M",
      sun_number: 285694,
      machine_state: "Offline",
      modified_by: "shoulisonu",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 296793,
      date: "2019-06-25T15:35:28Z",
      status: "Verified",
      component: "Printer",
      downtime: 474,
      make: "BMW",
      model: "M",
      size: "L",
      sun_number: 566850,
      machine_state: "Feeding",
      modified_by: "panthesv",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 890400,
      date: "2019-12-07T08:49:31Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 724,
      make: "Ford",
      model: "Taurus",
      size: "L",
      sun_number: 481281,
      machine_state: "Offline",
      modified_by: "lfarnishw",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 172204,
      date: "2019-07-29T14:56:41Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 777,
      make: "Chevrolet",
      model: "Express 1500",
      size: "XS",
      sun_number: 295706,
      machine_state: "Online",
      modified_by: "gpowtonx",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 155774,
      date: "2019-03-24T22:58:00Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 892,
      make: "BMW",
      model: "6 Series",
      size: "L",
      sun_number: 775368,
      machine_state: "Offline",
      modified_by: "mmichuriny",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 918420,
      date: "2019-04-08T00:05:45Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 456,
      make: "Hyundai",
      model: "Accent",
      size: "2XL",
      sun_number: 848306,
      machine_state: "Online",
      modified_by: "pbuffinz",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 849711,
      date: "2019-03-06T11:28:05Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 535,
      make: "Audi",
      model: "A6",
      size: "3XL",
      sun_number: 367919,
      machine_state: "Offline",
      modified_by: "cporcas10",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 777762,
      date: "2019-06-20T22:40:41Z",
      status: "Verified",
      component: "Feeder",
      downtime: 459,
      make: "Audi",
      model: "100",
      size: "S",
      sun_number: 800958,
      machine_state: "Offline",
      modified_by: "cwarry11",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 248849,
      date: "2019-11-09T22:15:45Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 628,
      make: "Pontiac",
      model: "GTO",
      size: "L",
      sun_number: 991706,
      machine_state: "Feeding",
      modified_by: "amorillas12",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 758332,
      date: "2019-06-25T11:13:25Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 418,
      make: "Bentley",
      model: "Continental Super",
      size: "2XL",
      sun_number: 498933,
      machine_state: "Offline",
      modified_by: "edonahue13",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 118128,
      date: "2019-10-15T14:42:07Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 41,
      make: "Dodge",
      model: "Ram Van B150",
      size: "XS",
      sun_number: 382524,
      machine_state: "Feeding",
      modified_by: "imoiser14",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 751833,
      date: "2019-12-18T08:29:23Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 652,
      make: "Saturn",
      model: "Aura",
      size: "XL",
      sun_number: 932914,
      machine_state: "Offline",
      modified_by: "cbarkus15",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 666789,
      date: "2020-03-02T06:34:38Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 647,
      make: "Chevrolet",
      model: "Express 3500",
      size: "3XL",
      sun_number: 910370,
      machine_state: "Feeding",
      modified_by: "vpiggens16",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 468542,
      date: "2019-08-23T01:10:37Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 251,
      make: "Ford",
      model: "Explorer",
      size: "XS",
      sun_number: 551156,
      machine_state: "Offline",
      modified_by: "tdecourt17",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 421676,
      date: "2019-04-17T12:13:07Z",
      status: "Verified",
      component: "Feeder",
      downtime: 881,
      make: "Volkswagen",
      model: "Cabriolet",
      size: "2XL",
      sun_number: 822626,
      machine_state: "Online",
      modified_by: "kpaffot18",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 217899,
      date: "2019-09-05T03:01:10Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 112,
      make: "GMC",
      model: "Savana 2500",
      size: "L",
      sun_number: 546542,
      machine_state: "Offline",
      modified_by: "wmorgen19",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 910185,
      date: "2020-02-05T13:30:59Z",
      status: "Verified",
      component: "Feeder",
      downtime: 976,
      make: "Saab",
      model: "900",
      size: "M",
      sun_number: 911161,
      machine_state: "Offline",
      modified_by: "gstutter1a",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 794315,
      date: "2019-03-05T07:18:02Z",
      status: "Verified",
      component: "Feeder",
      downtime: 664,
      make: "Mazda",
      model: "Navajo",
      size: "3XL",
      sun_number: 299382,
      machine_state: "Offline",
      modified_by: "flockhart1b",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 846970,
      date: "2019-05-06T09:09:20Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 399,
      make: "Porsche",
      model: "911",
      size: "2XL",
      sun_number: 132035,
      machine_state: "Offline",
      modified_by: "lmougel1c",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 543213,
      date: "2019-08-20T05:43:51Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 169,
      make: "Nissan",
      model: "Murano",
      size: "M",
      sun_number: 107837,
      machine_state: "Feeding",
      modified_by: "dbuckoke1d",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 352448,
      date: "2020-03-18T15:02:35Z",
      status: "Pending",
      component: "Feeder",
      downtime: 342,
      make: "Buick",
      model: "Riviera",
      size: "S",
      sun_number: 315718,
      machine_state: "Offline",
      modified_by: "drenton1e",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 415956,
      date: "2019-03-11T00:04:40Z",
      status: "Verified",
      component: "Feeder",
      downtime: 133,
      make: "Mercedes-Benz",
      model: "SLR McLaren",
      size: "3XL",
      sun_number: 933767,
      machine_state: "Feeding",
      modified_by: "eheustace1f",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 294188,
      date: "2019-09-17T20:53:08Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 302,
      make: "Mazda",
      model: "Mazda6",
      size: "L",
      sun_number: 927419,
      machine_state: "Feeding",
      modified_by: "jelsy1g",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 871343,
      date: "2019-11-05T14:55:12Z",
      status: "Verified",
      component: "Feeder",
      downtime: 820,
      make: "Volvo",
      model: "S60",
      size: "L",
      sun_number: 192913,
      machine_state: "Offline",
      modified_by: "gsarfass1h",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 820392,
      date: "2019-08-07T23:53:47Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 359,
      make: "GMC",
      model: "1500",
      size: "3XL",
      sun_number: 641262,
      machine_state: "Feeding",
      modified_by: "pmurfin1i",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 956549,
      date: "2019-12-11T00:49:20Z",
      status: "Pending",
      component: "Feeder",
      downtime: 389,
      make: "Mercedes-Benz",
      model: "C-Class",
      size: "M",
      sun_number: 751247,
      machine_state: "Feeding",
      modified_by: "gelverstone1j",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 796191,
      date: "2019-10-03T04:01:18Z",
      status: "Pending",
      component: "Feeder",
      downtime: 778,
      make: "Ford",
      model: "Aerostar",
      size: "2XL",
      sun_number: 806036,
      machine_state: "Online",
      modified_by: "gswiffan1k",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 938970,
      date: "2019-10-30T11:34:53Z",
      status: "Verified",
      component: "Printer",
      downtime: 37,
      make: "Mercedes-Benz",
      model: "300D",
      size: "XL",
      sun_number: 467787,
      machine_state: "Offline",
      modified_by: "bpasso1l",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 331865,
      date: "2019-05-15T16:50:42Z",
      status: "Pending",
      component: "Die Cutter",
      downtime: 794,
      make: "Pontiac",
      model: "Vibe",
      size: "L",
      sun_number: 137475,
      machine_state: "Online",
      modified_by: "dlongstaff1m",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 706875,
      date: "2020-02-18T09:25:29Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 120,
      make: "Mazda",
      model: "MX-3",
      size: "3XL",
      sun_number: 256953,
      machine_state: "Online",
      modified_by: "ckeeltagh1n",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 751992,
      date: "2019-04-05T03:02:19Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 456,
      make: "Chevrolet",
      model: "Corvette",
      size: "XS",
      sun_number: 905669,
      machine_state: "Online",
      modified_by: "osterricks1o",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 724914,
      date: "2019-04-13T19:56:34Z",
      status: "Verified",
      component: "Feeder",
      downtime: 723,
      make: "Chrysler",
      model: "LHS",
      size: "M",
      sun_number: 776842,
      machine_state: "Offline",
      modified_by: "tloadsman1p",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 622820,
      date: "2019-09-03T13:39:37Z",
      status: "Verified",
      component: "Printer",
      downtime: 557,
      make: "Chevrolet",
      model: "3500",
      size: "S",
      sun_number: 197459,
      machine_state: "Feeding",
      modified_by: "cweddeburnscrimgeour1q",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 431217,
      date: "2020-02-06T08:25:14Z",
      status: "Pending",
      component: "Feeder",
      downtime: 32,
      make: "Subaru",
      model: "Impreza",
      size: "XS",
      sun_number: 773851,
      machine_state: "Online",
      modified_by: "mfilipponi1r",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 177043,
      date: "2019-02-28T13:18:39Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 163,
      make: "GMC",
      model: "Yukon",
      size: "S",
      sun_number: 141034,
      machine_state: "Online",
      modified_by: "tgrandham1s",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 474357,
      date: "2020-01-17T17:43:22Z",
      status: "Verified",
      component: "Printer",
      downtime: 572,
      make: "Chevrolet",
      model: "Monte Carlo",
      size: "XS",
      sun_number: 116311,
      machine_state: "Online",
      modified_by: "edaynter1t",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 448987,
      date: "2020-02-07T23:35:46Z",
      status: "Pending",
      component: "Printer",
      downtime: 267,
      make: "Volkswagen",
      model: "R32",
      size: "M",
      sun_number: 366573,
      machine_state: "Offline",
      modified_by: "rchatenier1u",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 184924,
      date: "2019-11-11T19:32:22Z",
      status: "Verified",
      component: "Printer",
      downtime: 803,
      make: "Chevrolet",
      model: "Venture",
      size: "S",
      sun_number: 672205,
      machine_state: "Feeding",
      modified_by: "hsinyard1v",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 654661,
      date: "2019-09-09T05:40:09Z",
      status: "Pending",
      component: "Feeder",
      downtime: 299,
      make: "Dodge",
      model: "Viper RT/10",
      size: "M",
      sun_number: 645113,
      machine_state: "Feeding",
      modified_by: "lwordesworth1w",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 688667,
      date: "2020-01-25T22:09:41Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 884,
      make: "Audi",
      model: "Q5",
      size: "M",
      sun_number: 577134,
      machine_state: "Online",
      modified_by: "hstronough1x",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 722390,
      date: "2019-07-14T02:42:04Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 827,
      make: "Mercury",
      model: "Grand Marquis",
      size: "L",
      sun_number: 585094,
      machine_state: "Offline",
      modified_by: "rcory1y",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 850846,
      date: "2019-07-26T20:32:23Z",
      status: "Verified",
      component: "Feeder",
      downtime: 478,
      make: "Pontiac",
      model: "Grand Am",
      size: "L",
      sun_number: 381663,
      machine_state: "Offline",
      modified_by: "pkemitt1z",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 851418,
      date: "2020-01-30T05:56:21Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 59,
      make: "Chrysler",
      model: "Town & Country",
      size: "2XL",
      sun_number: 886807,
      machine_state: "Feeding",
      modified_by: "soldcroft20",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 968858,
      date: "2019-12-23T04:40:02Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 49,
      make: "Acura",
      model: "NSX",
      size: "S",
      sun_number: 586289,
      machine_state: "Feeding",
      modified_by: "fpurchall21",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 213423,
      date: "2020-03-20T23:37:48Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 410,
      make: "Pontiac",
      model: "Grand Prix",
      size: "2XL",
      sun_number: 296493,
      machine_state: "Offline",
      modified_by: "mspurdle22",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 816258,
      date: "2019-09-25T06:49:29Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 242,
      make: "Toyota",
      model: "Tundra",
      size: "S",
      sun_number: 358953,
      machine_state: "Feeding",
      modified_by: "bcherrison23",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 505472,
      date: "2019-09-17T08:21:33Z",
      status: "Pending",
      component: "Printer",
      downtime: 405,
      make: "Ferrari",
      model: "FF",
      size: "L",
      sun_number: 927854,
      machine_state: "Feeding",
      modified_by: "tjorgensen24",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 220588,
      date: "2019-08-12T01:19:05Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 479,
      make: "BMW",
      model: "M6",
      size: "M",
      sun_number: 139036,
      machine_state: "Offline",
      modified_by: "emcgeady25",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 771538,
      date: "2019-12-18T05:12:01Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 245,
      make: "Honda",
      model: "Pilot",
      size: "3XL",
      sun_number: 891835,
      machine_state: "Online",
      modified_by: "cnesby26",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 819786,
      date: "2020-02-02T22:51:13Z",
      status: "Pending",
      component: "Feeder",
      downtime: 939,
      make: "Hyundai",
      model: "Genesis Coupe",
      size: "2XL",
      sun_number: 786862,
      machine_state: "Feeding",
      modified_by: "bmather27",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 665378,
      date: "2019-10-12T14:51:02Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 859,
      make: "Lexus",
      model: "LS",
      size: "S",
      sun_number: 425812,
      machine_state: "Feeding",
      modified_by: "dburges28",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 112374,
      date: "2019-07-14T05:25:45Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 303,
      make: "Lexus",
      model: "GX",
      size: "L",
      sun_number: 255281,
      machine_state: "Online",
      modified_by: "sgiven29",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 610231,
      date: "2019-05-05T06:38:48Z",
      status: "Pending",
      component: "Printer",
      downtime: 442,
      make: "Mercedes-Benz",
      model: "GL-Class",
      size: "2XL",
      sun_number: 943973,
      machine_state: "Offline",
      modified_by: "jitskovitz2a",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 186820,
      date: "2019-06-18T09:27:30Z",
      status: "Dismissed",
      component: "Feeder",
      downtime: 711,
      make: "Holden",
      model: "VS Commodore",
      size: "XS",
      sun_number: 172666,
      machine_state: "Offline",
      modified_by: "sdevonish2b",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 500072,
      date: "2020-01-29T22:55:30Z",
      status: "Pending",
      component: "Feeder",
      downtime: 797,
      make: "Mitsubishi",
      model: "GTO",
      size: "XL",
      sun_number: 551360,
      machine_state: "Online",
      modified_by: "hflury2c",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 767060,
      date: "2019-11-22T14:37:37Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 271,
      make: "Mercury",
      model: "Mountaineer",
      size: "XL",
      sun_number: 962379,
      machine_state: "Online",
      modified_by: "gvalentin2d",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 370374,
      date: "2019-10-19T06:54:17Z",
      status: "Pending",
      component: "Printer",
      downtime: 408,
      make: "Lincoln",
      model: "Town Car",
      size: "S",
      sun_number: 259179,
      machine_state: "Offline",
      modified_by: "ijellicorse2e",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 925129,
      date: "2020-01-17T02:37:51Z",
      status: "Pending",
      component: "Printer",
      downtime: 94,
      make: "Toyota",
      model: "Prius",
      size: "XS",
      sun_number: 511095,
      machine_state: "Online",
      modified_by: "cattewell2f",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 247797,
      date: "2020-01-17T02:43:10Z",
      status: "Dismissed",
      component: "Die Cutter",
      downtime: 307,
      make: "Mitsubishi",
      model: "Diamante",
      size: "3XL",
      sun_number: 692293,
      machine_state: "Online",
      modified_by: "wrollinshaw2g",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 746054,
      date: "2019-03-31T21:55:07Z",
      status: "Pending",
      component: "Feeder",
      downtime: 80,
      make: "Mazda",
      model: "RX-7",
      size: "L",
      sun_number: 551811,
      machine_state: "Online",
      modified_by: "smccoole2h",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 882749,
      date: "2020-03-07T18:37:24Z",
      status: "Verified",
      component: "Printer",
      downtime: 653,
      make: "Dodge",
      model: "Viper",
      size: "3XL",
      sun_number: 917771,
      machine_state: "Offline",
      modified_by: "lcull2i",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 574673,
      date: "2019-05-31T23:53:02Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 521,
      make: "Mazda",
      model: "Protege",
      size: "M",
      sun_number: 605904,
      machine_state: "Online",
      modified_by: "cbirbeck2j",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 531101,
      date: "2019-07-25T10:44:48Z",
      status: "Verified",
      component: "Printer",
      downtime: 830,
      make: "Chevrolet",
      model: "Avalanche 1500",
      size: "2XL",
      sun_number: 671872,
      machine_state: "Feeding",
      modified_by: "hors2k",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 153245,
      date: "2019-07-19T16:32:09Z",
      status: "Dismissed",
      component: "Printer",
      downtime: 205,
      make: "Dodge",
      model: "Stratus",
      size: "XL",
      sun_number: 591613,
      machine_state: "Feeding",
      modified_by: "sbullocke2l",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 829129,
      date: "2019-04-23T17:15:22Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 240,
      make: "Mercedes-Benz",
      model: "M-Class",
      size: "L",
      sun_number: 941141,
      machine_state: "Online",
      modified_by: "oclorley2m",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 614003,
      date: "2020-03-18T08:31:51Z",
      status: "Verified",
      component: "Feeder",
      downtime: 248,
      make: "Pontiac",
      model: "Firefly",
      size: "M",
      sun_number: 622843,
      machine_state: "Offline",
      modified_by: "jkhristyukhin2n",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 719982,
      date: "2019-10-16T09:51:07Z",
      status: "Verified",
      component: "Die Cutter",
      downtime: 923,
      make: "BMW",
      model: "5 Series",
      size: "S",
      sun_number: 892446,
      machine_state: "Offline",
      modified_by: "dscarsbrook2o",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 530308,
      date: "2019-06-26T08:28:24Z",
      status: "Verified",
      component: "Printer",
      downtime: 811,
      make: "Audi",
      model: "S5",
      size: "M",
      sun_number: 734515,
      machine_state: "Online",
      modified_by: "lkarp2p",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
    {
      id: 198499,
      date: "2019-12-02T14:56:09Z",
      status: "Verified",
      component: "Printer",
      downtime: 417,
      make: "Audi",
      model: "A4",
      size: "L",
      sun_number: 380918,
      machine_state: "Feeding",
      modified_by: "cbarthel2q",
      machine_id: "0595a2e3-eb66-4586-b9ee-8afbbc7ffd32",
    },
    {
      id: 301314,
      date: "2019-10-04T23:11:02Z",
      status: "Verified",
      component: "Feeder",
      downtime: 459,
      make: "Mazda",
      model: "B-Series Plus",
      size: "XL",
      sun_number: 504301,
      machine_state: "Online",
      modified_by: "jquest2r",
      machine_id: "25be0081-7d10-4544-9268-8ce64f88eb5c",
    },
  ],
});
