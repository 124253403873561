import { blueGrey, grey } from "@material-ui/core/colors";

const white = "#FFFFFF";
const dark = "#333";
const dataColorArray = [
  "#6929C4",
  "#1192E8",
  "#005D5D",
  "#9F1853",
  "#FA4D56",
  "#520408",
  "#198038",
  "#002D9C",
  "#EE5396",
  "#B28600",
  "#009D9A",
  "#012749",
  "#8A3800",
  "#A56EFF",
];
const machineStatusColorArray = [
  ["rgb(247, 222, 136)", "#FDDC69"],
  ["rgb(236, 239, 241)", "#ECEFF1"],
];

export default {
  gauge: {
    success: "#65D8A5",
    danger: "#EC5858",
    warning: "#FFB72B",
    background: "#E9EFF3",
  },
  gray: {
    contrastText: dark,
    black: "#000",
    dark: "#333",
    main: "#666",
    light: "#C2C2C2",
    lighter: "#ebebeb",
    lightest: "#f8f8f8",
    white: "#fff",
    primary: "#D3D3D3",
  },
  machine: {
    status: machineStatusColorArray,
  },
  primary: {
    contrastText: white,
    dark: "#29525D",
    main: "rgba(59,118,134,1)",
    light: "#62919E",
    extraLight: "#D8E4E7",
    ultraLight: "#f5f7fa",
    muted: "#546E7A",
  },
  secondary: {
    contrastText: white,
    dark: "#B34521",
    main: "rgba(224,87,42,1)",
    light: "#E67955",
    extraLight: "#FEF5F3",
    action: "#E0572A",
  },
  chartData: dataColorArray,
  status: {
    error: {
      contrastText: white,
      dark: "#861313",
      main: "#EC5858",
      light: "#F3A6A6",
      extraLight: "#F9D2D2",
    },
    success: {
      contrastText: white,
      dark: "#126C1B",
      main: "#157F1F",
      light: "#A1CCA5",
      extraLight: "#D0E5D2",
    },
    warning: {
      contrastText: dark,
      dark: "#996C00",
      main: "#FFB400",
      light: "#DDBE26",
      extraLight: "#FFE199",
    },
    normal: {
      main: "#1851E4",
      light: "#A5D1E4",
      extraLight: "#D1E5EE",
    },
    ready: {
      main: "#9219CE",
      light: "#C98DE7",
      extraLight: "#EACAF9",
    },
  },
  link: {
    blueGray: "#3B7686",
  },
  text: {
    primary: "#292929",
    secondary: "#4a4a4a",
    link: "#C5461C",
    caption: "#666666",
  },
  icon: blueGrey[600],
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  divider: grey[200],
};
