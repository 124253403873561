import React from "react";
import { Typography } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { makeStyles } from "@material-ui/styles";
import MachineConnectionStatus from "src/components/MachineConnectionStatus";
import palette from "src/theme/palette.js";
import history from "src/utils/history";
import { demoValue } from "src/utils/demoMode";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  textBold: {
    fontWeight: "600",
    color: theme.palette.text.primary,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "space-between",
    },
  },
  headerTitle: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap-reverse",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "space-between",
    },
  },
  caption: {
    color: "#999999",
    fontSize: "13px",
  },
  machineStatus: {
    marginLeft: "10px",
  },
  machineLink: {
    fontSize: "14px",
    color: palette.link.blueGray,
    fontWeight: 400,
  },
  viewMachine: {
    cursor: "pointer",
  },
  contentTitle: {
    maxWidth: "250px",
  },
}));

const SnapshotTitle = ({ name, facility, machine }) => {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  const viewMachine = () => {
    history.push("/machines/" + machine.id);
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerTitle}>
        <div className={classes.contentTitle}>
          <Typography variant="h5" className={classes.textBold}>
            {name}
          </Typography>
          <Typography variant="body1" className={classes.caption}>
            {demoValue(facility || "")}
          </Typography>
        </div>
        <div className={classes.machineStatus}>
          <MachineConnectionStatus machineId={machine.id} />
        </div>
      </div>
      <div className={classes.machineLink}>
        <span onClick={viewMachine} className={classes.viewMachine}>
          {t("view_machine")}
          <LaunchIcon
            htmlColor={palette.link.blueGray}
            style={{ marginLeft: "10px" }}
            fontSize="small"
          />
        </span>
      </div>
    </div>
  );
};

export default SnapshotTitle;
