/* eslint-disable no-undef */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

const {
  NODE_ENV,
  REACT_APP_GA_MEASUREMENT_ID: GA_MEASUREMENT_ID,
} = process.env;

function Page({ title, children, ...rest }) {
  const location = useLocation();

  useEffect(() => {
    if (NODE_ENV !== "production") {
      return;
    }

    if (window.gtag) {
      window.gtag("config", GA_MEASUREMENT_ID, {
        page_path: location.pathname,
        page_name: title,
      });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div {...rest}>
      <Helmet
        meta={[
          {
            name: "title",
            content: "Helios: Corrugated Machine Learning Platform",
          },
          {
            name: "description",
            content:
              "Helios is an AI/machine learning platform that monitors, learns, and predicts the important metrics driving the success of your corrugated equipment. Equipped with live monitoring, anomaly detection and predictive maintenance processes, Helios provides converters with the data and insight they need to maximize profitability. Download Helios today!",
          },
          {
            name: "keywords",
            content: ["Corrugated", "Machine Learning", "IIoT", "Helios"],
          },
        ]}
      >
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
