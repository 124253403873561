/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import ReportsOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import PersonIcon from "@material-ui/icons/Person";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { useTranslation } from "react-i18next";

const useMobileNavigationItems = () => {
  const { t } = useTranslation(["glossary", "common"]);

  return [
    {
      items: [
        {
          title: t("dashboard"),
          href: "/dashboard",
          icon: DashboardIcon,
        },
        {
          title: t("machines"),
          href: "/machines",
          icon: BusinessIcon,
        },
        {
          title: t("customers"),
          href: "/customers",
          icon: GroupOutlinedIcon,
        },
        {
          title: t("reports"),
          href: "/reports",
          icon: ReportsOutlinedIcon,
        },
      ],
    },
    {
      items: [
        {
          title: t("help_contact"),
          href:
            "https://sunautomation.atlassian.net/servicedesk/customer/portal/3",
          external: true,
          icon: FeedbackIcon,
        },
        {
          title: t("account"),
          href: "/account",
          icon: PersonIcon,
        },
      ],
    },
  ];
};
export default useMobileNavigationItems;
