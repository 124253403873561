import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip as MTooltip,
  Typography,
} from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useCallback, useState, useEffect } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceDot,
  Legend,
  Line,
  LineChart,
  Rectangle,
  ReferenceArea,
  ResponsiveContainer,
  Surface,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { pure } from "recompose";
import DateTimeHeader from "src/components/dateTimeHeader";
import palette from "src/theme/palette";
import { formatLabelValue } from "src/utils/telemDispCfgs";
import {
  buildRegressor,
  regressionNames,
  reverseTransform,
} from "src/utils/regressionLines";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  disabledText: {
    "& > span": {
      color: "#999 !important",
    },
  },
  formControl: {
    minWidth: 140,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const ad_flag_suffix = "ad_flag";

const axisName = (name) => {
  if (_.isEmpty(name)) return;
  if (name.includes("_regressor")) {
    return name.split("_regressor")[0];
  }
  return name;
};

const toColor = (metric, index) => {
  if (metric.includes("regressor")) {
    const regrs = Object.values(regressionNames);
    const regrType = metric.split("_regressor_")[1];
    return palette.chartData[regrs.indexOf(regrType)];
  }
  return palette.chartData[index];
};

const formatDateTime = (label, timezone, facilityTimezone) => {
  const ts =
    timezone === "local"
      ? moment(label)
      : moment.utc(label).tz(facilityTimezone);
  return `${ts.format("MM/DD/YYYY HH:mm:ss A")}`;
};

const CustomTooltip = ({
  keyToCfg,
  active,
  payload,
  label,
  displayAnomalies,
  timezone,
  facilityTimezone,
  chartType,
}) => {
  const { t } = useTranslation(["glossary", "common"]);
  if (active && payload && payload.length) {
    return (
      <Card
        key={`${chartType}-tooltip`}
        style={{
          border: "solid 1px #999",
          borderRadius: 3,
          padding: 10,
        }}
      >
        <p style={{ fontWeight: "bold", margin: 3 }}>
          {formatDateTime(label, timezone, facilityTimezone)}
        </p>
        {payload.map((item) => (
          <div
            key={`${item.dataKey}_tooltip_div`}
            style={{
              color: item.color,
              margin: 3,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {chartType === "linechart" &&
              formatLabelValue(keyToCfg, item.dataKey, item.value).map(
                (value, i) => (
                  <span
                    key={`${item.dataKey}_tooltip_span_${i}`}
                    style={i === 0 ? { paddingRight: 5 } : { marginLeft: 10 }}
                  >
                    {value}
                    {i === 0 ? ":" : ""}
                  </span>
                )
              )}
            {chartType === "barchart" && (
              <Fragment>
                <span
                  key={`${item.dataKey}_tooltip_span`}
                  style={{ paddingRight: 5 }}
                >
                  {keyToCfg[item.dataKey.slice(0, -8)]?.title ||
                    item.dataKey.slice(0, -8)}{" "}
                  {t("anomalous_pct")}
                </span>
                <span
                  key={`${item.dataKey}_tooltip_span_value`}
                  style={{ marginLeft: 10 }}
                >
                  {item.value !== null
                    ? `${Math.round(item.value * 100 * 100) / 100}%`
                    : "N/A"}
                </span>
              </Fragment>
            )}
          </div>
        ))}
      </Card>
    );
  }

  return null;
};

const JobTooltip = (props) => {
  const { job, timezone, facilityTimezone } = props;
  const { t } = useTranslation(["glossary", "common"]);
  const start_at = moment(job.start_at);
  const end_at = moment(job.end_at);

  const duration = moment.duration(end_at.diff(start_at));
  const minutes = duration.get("minutes");
  const minutesStr = `${minutes} minute${minutes === 1 ? "" : "s"}`;
  const hours = duration.get("hours");
  const hoursStr = `${hours} hour${hours === 1 ? "" : "s"}`;

  let durationStr;

  if (duration.asMinutes() < 60) {
    durationStr = `${minutesStr}`;
  } else {
    durationStr = `${hoursStr}, ${minutesStr}`;
  }

  return (
    <>
      <p>
        <b>{t("entry_length_colon")}</b> {job.entry_length}
      </p>
      <p>
        <b>{t("entry_width_colon")}</b> {job.entry_width}
      </p>
      <p>
        <b>{t("exit_length_colon")}</b> {job.exit_length}
      </p>
      <p>
        <b>{t("exit_width_colon")}</b> {job.exit_length}
      </p>
      <p>
        <b>{t("flute_code_colon")}</b> {job.flute_code}
      </p>
      <p>
        <b>{t("paper_density_colon")}</b> {job.paper_density}
      </p>
      <p>
        <b>{t("start_at_colon")}</b>{" "}
        {formatDateTime(job.start_at, timezone, facilityTimezone)}
      </p>
      <p>
        <b>{t("end_at_colon")}</b>{" "}
        {formatDateTime(job.end_at, timezone, facilityTimezone)}
      </p>
      <p>
        <b>{t("duration_colon")}</b> {durationStr}
      </p>
    </>
  );
};

const EventTooltip = (props) => {
  const { evt, timezone, facilityTimezone } = props;
  const { t } = useTranslation(["glossary", "common"]);
  const author = evt.sampler
    ? evt.sampler.name
    : evt.user
    ? evt.user.name
    : t("no_author");
  const start_at = moment(evt.start_at);
  const end_at = moment(evt.end_at);

  const duration = moment.duration(end_at.diff(start_at));
  const minutes = duration.get("minutes");
  const minutesStr = `${minutes} minute${minutes === 1 ? "" : "s"}`;
  const hours = duration.get("hours");
  const hoursStr = `${hours} hour${hours === 1 ? "" : "s"}`;

  let durationStr;

  if (duration.asMinutes() < 60) {
    durationStr = `${minutesStr}`;
  } else {
    durationStr = `${hoursStr}, ${minutesStr}`;
  }

  return (
    <>
      <p>
        <b>{t("name_colon")}</b> {evt.name}
      </p>
      <p>
        <b>{t("author_colon")}</b> {author}
      </p>
      <p>
        <b>{t("start_at_colon")}</b>{" "}
        {formatDateTime(evt.start_at, timezone, facilityTimezone)}
      </p>
      <p>
        <b>{t("end_at_colon")}</b>{" "}
        {formatDateTime(evt.end_at, timezone, facilityTimezone)}
      </p>
      <p>
        <b>{t("duration_colon")}</b> {durationStr}
      </p>
    </>
  );
};

const MultipleEventsTooltip = (props) => {
  const { evs } = props;
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <>
      <p>{t("multiple_events_message")}</p>
      <ol style={{ padding: "0px 10px" }}>
        {evs.map((evt) => (
          <li>{evt.name}</li>
        ))}
      </ol>
    </>
  );
};

const JobFlagDot = (props) => {
  const { cx, cy, job, r, timezone, facilityTimezone } = props;
  return (
    <MTooltip
      title={
        <JobTooltip
          job={job}
          timezone={timezone}
          facilityTimezone={facilityTimezone}
        />
      }
    >
      <svg x={cx - 9} y={cy - 9} width={2 * r + 2} height={2 * r + 2}>
        <circle cx="50%" cy="50%" r={r} fill="red" stroke="red"></circle>
        <svg x="12.5%" y="12.5%" width="75%" height="75%">
          <FlagIcon style={{ color: "white" }} />
        </svg>
      </svg>
    </MTooltip>
  );
};

const FlagDot = (props) => {
  const { cx, cy, evt, r, timezone, facilityTimezone } = props;
  return (
    <MTooltip
      title={
        <EventTooltip
          evt={evt}
          timezone={timezone}
          facilityTimezone={facilityTimezone}
        />
      }
    >
      <svg
        x={cx - 9}
        y={cy - 9}
        width={2 * r + 2}
        height={2 * r + 2}
        onClick={() =>
          window.open(
            `/machines/${evt.device.id}/events?event_id=${evt.id}`,
            "_blank"
          )
        }
      >
        <circle cx="50%" cy="50%" r={r} fill="black" stroke="black"></circle>
        <svg x="12.5%" y="12.5%" width="75%" height="75%">
          <FlagIcon style={{ color: "white" }} />
        </svg>
      </svg>
    </MTooltip>
  );
};

const MultipleEventsDot = (props) => {
  const { cx, cy, evs, r } = props;
  const xOffset = evs.length < 10 ? 5 : 3;
  const yOffset = evs.length < 10 ? 13 : 12;
  const fontSize = evs.length < 10 ? 12 : 10;
  return (
    <MTooltip title={<MultipleEventsTooltip evs={evs} />}>
      <svg x={cx - 9} y={cy - 9} width={2 * r + 2} height={2 * r + 2}>
        <circle cx="50%" cy="50%" r={r} fill="black" stroke="black"></circle>
        <text
          x={`${xOffset}px`}
          y={`${yOffset}px`}
          fill="white"
          fontSize={`${fontSize}px`}
          fontWeight="600"
        >
          {evs.length < 10 ? evs.length : "9+"}
        </text>
      </svg>
    </MTooltip>
  );
};

const flagJobShape = (jobs, timezone, facilityTimezone) => {
  return (
    <JobFlagDot
      job={jobs[0]}
      timezone={timezone}
      facilityTimezone={facilityTimezone}
    />
  );
};

const flagEventShape = (evs, timezone, facilityTimezone) => {
  if (evs.length > 1) {
    return <MultipleEventsDot evs={evs} />;
  } else {
    return (
      <FlagDot
        evt={evs[0]}
        timezone={timezone}
        facilityTimezone={facilityTimezone}
      />
    );
  }
};

const StateOverlayShape = (props) => {
  const { x, y, width, height, state } = props;
  const currentState = binaryState(state);
  const [fillColor, strokeColor] = palette.machine.status[currentState];
  const textWidth = currentState === 0 ? 25 : 17;
  const { t } = useTranslation(["glossary", "common"]);
  return (
    <g key={`${x}_${y}_overlay`}>
      <rect
        key={`${x}_${y}_rect`}
        x={x}
        width={width}
        fill={fillColor}
        fillOpacity={0.08}
        y={y}
        height={height}
        stroke={strokeColor}
      />
      <rect
        key={`${x}_${y}_label_rect`}
        x={x}
        width={width}
        fill={strokeColor}
        fillOpacity={1}
        y={y - 12}
        height={12}
        stroke={strokeColor}
      />
      {width >= 57 && (
        <text
          x={(2 * x + width) / 2 - textWidth}
          y={y - 3}
          stroke="black"
          font-size="8"
          font-weight="10"
        >
          {currentState === 0 ? t("not_running") : t("running")}
        </text>
      )}
    </g>
  );
};

const binaryState = (state) => {
  return [3, 4].includes(state) ? 1 : 0;
};

export default pure(
  ({
    data,
    state_info,
    humanKeyToCfg,
    hideTimeRange,
    start,
    end,
    events,
    jobs,
    tiny,
    regressionLines,
    xAxisOrientation,
    xAxisTickMargin,
    displayVerticalLines,
    hideLegend,
    darkThresholdLines,
    containerHeight,
    facilityTimezone,
    timezone,
    zoomQuery,
    zoomCallback,
    zoomEnabled,
  }) => {
    const classes = useStyles();
    // list of datasets to exclude; mutated by Legend onClick handler
    const [exclude, setExclude] = useState([]);
    // return a function for formatting x axis ticks
    const [xFormatFn, setXFormatFn] = useState(() => (t) => t);
    const [records, setRecords] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [axes, setAxes] = useState([]);
    const [overlayData, setOverlayData] = useState([]);
    const [keyToCfg, setKeyToCfg] = useState({});
    const [refAreaLeft, setRefAreaLeft] = useState(null);
    const [refAreaRight, setRefAreaRight] = useState(null);
    const [refToDate, setRefToDate] = useState(null);
    const [refFromDate, setRefFromDate] = useState(null);
    const [zoomData, setZoomData] = useState({});
    const [zoomStateInfo, setZoomStateInfo] = useState({});
    const [zoomActive, setZoomActive] = useState(false);
    const [displayAnomalies, setDisplayAnomalies] = useState(false);
    const [displayBounds, setDisplayBounds] = useState(false);
    const [displayState, setDisplayState] = useState(false);
    const [displayEvents, setDisplayEvents] = useState(false);
    const [displayJobs, setDisplayJobs] = useState(false);
    const initialData = data;

    data = _.isEmpty(zoomData) ? data : zoomData;
    state_info = _.isEmpty(zoomStateInfo) ? state_info : zoomStateInfo;

    const formatMinute = useCallback(
      (t) => {
        const ts =
          timezone === "local" ? moment(t) : moment.tz(t, facilityTimezone);
        return (
          <text x={0} y={0} fontSize={12}>
            {ts.format("h:mm A")}
          </text>
        );
      },
      [timezone, facilityTimezone]
    );

    const formatHour = useCallback(
      (t) => {
        const ts =
          timezone === "local" ? moment(t) : moment.tz(t, facilityTimezone);
        return (
          <text x={0} y={0} fontSize={12}>
            {ts.format("h A")}
          </text>
        );
      },
      [timezone, facilityTimezone]
    );

    const formatDay = useCallback(
      (t) => {
        const ts =
          timezone === "local" ? moment(t) : moment.tz(t, facilityTimezone);
        return (
          <text x={0} y={0} fontSize={12}>
            {ts.format("MMM D")}
          </text>
        );
      },
      [timezone, facilityTimezone]
    );

    const formatDayAndHour = useCallback(
      (t) => {
        const ts =
          timezone === "local" ? moment(t) : moment.tz(t, facilityTimezone);
        return (
          <>
            <text x={0} y={0} fontSize={12}>
              {ts.format("MMM D")}
            </text>
            <br />
            <text x={5} y={12} fontSize={9}>
              {ts.format("h A")}
            </text>
          </>
        );
      },
      [timezone, facilityTimezone]
    );

    //for some reason functions in useState require being wrapped in anonymous function
    useEffect(() => {
      let [s, e] = [null, null];

      if (zoomActive) {
        s = moment(refFromDate);
        e = moment(refToDate);
      } else {
        s = moment(start);
        e = moment(end);
      }

      const duration = moment.duration(e.diff(s));
      const hours = duration.asHours();
      if (hours <= 1) {
        setXFormatFn(() => formatMinute);
      } else if (hours <= 48) {
        setXFormatFn(() => formatHour);
      } else if (hours > 48 && hours <= 120) {
        setXFormatFn(() => formatDayAndHour);
      } else if (hours > 120) {
        setXFormatFn(() => formatDay);
      }
    }, [
      start,
      end,
      refToDate,
      refFromDate,
      zoomActive,
      formatMinute,
      formatHour,
      formatDayAndHour,
      formatDay,
    ]);

    useEffect(() => {
      if (humanKeyToCfg) {
        setKeyToCfg(humanKeyToCfg);
      }
    }, [humanKeyToCfg]);

    const thresholdBoundsToggle = (_value) => {
      setDisplayBounds(!displayBounds);
    };

    const overlayStateToggle = (_value) => {
      setDisplayState(!displayState);
    };

    const anomalousPctToggle = (_value) => {
      setDisplayAnomalies(!displayAnomalies);
    };

    const eventsDisplayToggle = (_value) => {
      setDisplayEvents(!displayEvents);
    };

    const jobsDisplayToggle = (_value) => {
      setDisplayJobs(!displayJobs);
    };

    //setup the data records (upper and lower bounds too)
    useEffect(() => {
      let axes = [];
      let metric_names = [];
      let metric_regressors_names = [];
      let regressors = {};
      const usedRegressionLines = regressionLines ? regressionLines : [];
      if (!data) return;
      const records = Object.values(
        _.reduce(
          data,
          (acc, item, metric_name) => {
            let xs = [],
              ys = [];
            item.forEach((x) => {
              if (x.value !== null) {
                xs.push(moment.utc(x.time).unix());
                ys.push(x.value);
              }
            });
            const minY = _.min(ys);
            metric_names.push(metric_name);
            const metricRegressors = usedRegressionLines.reduce(
              (acc, regrLine) => {
                metric_regressors_names.push(
                  `${metric_name}_regressor_${regrLine}`
                );
                acc[regrLine] = buildRegressor(regrLine, xs, ys, xs[0], minY);
                return acc;
              },
              {}
            );
            regressors[metric_name] = metricRegressors;
            axes.push({ id: metric_name });
            item.forEach((entry) => {
              if (acc[entry.time]) {
                acc[entry.time][metric_name] = entry.value;
                acc[entry.time][`${metric_name}_ad_upper`] = entry.upper;
                acc[entry.time][`${metric_name}_ad_lower`] = entry.lower;
                acc[entry.time][`${metric_name}_ad_flag`] = entry.ad_flag;
                usedRegressionLines.forEach((regrLine) => {
                  if (entry.value === null) {
                    acc[entry.time][
                      `${metric_name}_regressor_${regrLine}`
                    ] = null;
                  } else {
                    acc[entry.time][
                      `${metric_name}_regressor_${regrLine}`
                    ] = reverseTransform(
                      regrLine,
                      regressors[metric_name][regrLine].predict(
                        moment.utc(entry.time).unix()
                      ),
                      minY
                    );
                  }
                });
              } else {
                let obj = {};
                obj[metric_name] = entry.value;
                obj[`${metric_name}_ad_upper`] = entry.upper;
                obj[`${metric_name}_ad_lower`] = entry.lower;
                obj[`${metric_name}_ad_flag`] = entry.ad_flag;
                usedRegressionLines.forEach((regrLine) => {
                  if (entry.value === null) {
                    obj[`${metric_name}_regressor_${regrLine}`] = null;
                  } else {
                    obj[
                      `${metric_name}_regressor_${regrLine}`
                    ] = reverseTransform(
                      regrLine,
                      regressors[metric_name][regrLine].predict(
                        moment.utc(entry.time).unix()
                      ),
                      minY
                    );
                  }
                });
                obj["time"] = moment.utc(entry.time).valueOf();
                acc[entry.time] = obj;
              }
            });
            return acc;
          },
          {}
        )
      );
      setAxes(axes);
      setRecords(records);
      setMetrics([...metric_names, ...metric_regressors_names]);
    }, [data, regressionLines]);

    //setup the machine_state overlays
    useEffect(() => {
      if (!state_info) return;
      const overlay = state_info.reduce((prev, x) => {
        let [last] = prev;
        if (last && last.machine_state !== x.machine_state) {
          last.x2 = moment.utc(x.time).toDate().valueOf();
          return [
            {
              x1: moment.utc(x.time).toDate().valueOf(),
              machine_state: x.machine_state,
              last_ts: x.time,
            },
            ...prev,
          ];
        } else if (!last) {
          return [
            { x1: null, machine_state: x.machine_state, last_ts: x.time },
            ...prev,
          ];
        }
        last.last_ts = x.time;
        return prev;
      }, []);
      setOverlayData(overlay);
    }, [state_info, displayAnomalies]);

    // group events by their closest x-axis entry.
    // returns a list where each entry looks like:
    // [timestamp, [eventsWhoseClosestXIsTimestamp]]
    // returns a list because objects don't guarantee order.
    let currentIndex = 0;
    const placeableEvents = _(events)
      // first, sort the events by start_at
      .sortBy((e) => e.start_at)
      .reduce((acc, currEvent, ix) => {
        // in case there are no timestamps, add current index and event as a fallback
        if (!records.length) return [...acc, [ix, [currEvent]]];
        // reject event if it's not within bounds
        if (
          moment(currEvent.start_at) < moment(refFromDate) ||
          moment(currEvent.start_at) > moment(refToDate)
        )
          return acc;

        const timestamp = moment.utc(currEvent.start_at).valueOf();
        let currentElem = records[currentIndex];
        let currentDistance = Math.abs(timestamp - currentElem.time);
        while (
          records[currentIndex + 1] &&
          Math.abs(timestamp - records[currentIndex + 1].time) <=
            currentDistance
        ) {
          currentElem = records[currentIndex + 1];
          currentDistance = Math.abs(
            timestamp - records[currentIndex + 1].time
          );
          currentIndex += 1;
        }
        const lastTs = acc.pop();
        if (lastTs) {
          if (lastTs[0] === currentElem.time)
            return [...acc, [lastTs[0], [...lastTs[1], currEvent]]];
          return [...acc, lastTs, [currentElem.time, [currEvent]]];
        }
        return [...acc, [currentElem.time, [currEvent]]];
      }, []);

    // group jobs by their closest x-axis entry.
    // returns a list where each entry looks like:
    // [timestamp, [jobsWhoseClosestXIsTimestamp]]
    // returns a list because objects don't guarantee order.
    currentIndex = 0;
    const placeableJobs = _(jobs.data)
      // first, sort the jobs by start_at
      .sortBy((j) => j.start_at)
      .reduce((acc, currJob, ix) => {
        // in case there are no timestamps, add current index and job as a fallback
        if (!records.length) return [...acc, [ix, [currJob]]];
        // reject job if it's not within bounds
        if (
          moment(currJob.start_at) < moment(refFromDate) ||
          moment(currJob.start_at) > moment(refToDate)
        )
          return acc;

        const timestamp = moment.utc(currJob.start_at).valueOf();
        let currentElem = records[currentIndex];
        let currentDistance = Math.abs(timestamp - currentElem.time);
        while (
          records[currentIndex + 1] &&
          Math.abs(timestamp - records[currentIndex + 1].time) <=
            currentDistance
        ) {
          currentElem = records[currentIndex + 1];
          currentDistance = Math.abs(
            timestamp - records[currentIndex + 1].time
          );
          currentIndex += 1;
        }
        const lastTs = acc.pop();
        if (lastTs) {
          if (lastTs[0] === currentElem.time)
            return [...acc, [lastTs[0], [...lastTs[1], currJob]]];
          return [...acc, lastTs, [currentElem.time, [currJob]]];
        }
        return [...acc, [currentElem.time, [currJob]]];
      }, []);

    const handleLegendClick = (key) => {
      if (typeof key !== "string") return;
      if (_.includes(exclude, key)) {
        setExclude((exclude) => {
          return exclude.filter((k) => k !== key);
        });
      } else {
        setExclude((exclude) => [...exclude, key]);
      }
    };

    const CustomLegend = (props) => {
      const { payload } = props;

      return (
        <div className="customized-legend" style={{ textAlign: "center" }}>
          {payload.map((entry) => {
            const { dataKey, color } = entry;
            return (
              entry.type !== "none" &&
              !dataKey.endsWith(ad_flag_suffix) && (
                <span
                  className="legend-item"
                  key={dataKey}
                  onClick={() => handleLegendClick(dataKey)}
                  style={{ marginLeft: 20 }}
                >
                  <Surface
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    onClick={() => handleLegendClick(dataKey)}
                  >
                    <Rectangle
                      x={0}
                      y={5}
                      width={12}
                      height={2}
                      fill={color}
                      radius={3}
                      onClick={() => handleLegendClick(dataKey)}
                    />
                  </Surface>
                  <span onClick={handleLegendClick} style={{ marginLeft: 5 }}>
                    {keyToCfg[dataKey]?.title || dataKey}
                  </span>
                </span>
              )
            );
          })}
          {displayBounds && (
            <span className="legend-item" style={{ marginLeft: 20 }}>
              <Surface width={12} height={12} viewBox="0 0 12 12">
                {_.range(4).map((i) => {
                  return (
                    <Rectangle
                      x={3 * i}
                      y={5}
                      width={2}
                      height={2}
                      fill="black"
                    />
                  );
                })}
              </Surface>
              <span style={{ marginLeft: 5 }}>Threshold bounds</span>
            </span>
          )}
          {displayState &&
            ["Not Running", "Running"].map((state, index) => {
              return (
                <span className="legend-item" style={{ marginLeft: 20 }}>
                  <Surface width={15} height={15} viewBox="0 0 15 15">
                    <Rectangle
                      x={0}
                      y={0}
                      width={12}
                      height={12}
                      radius={3}
                      fill={palette.machine.status[index][0]}
                      stroke={palette.machine.status[index][1]}
                    />
                  </Surface>
                  <span style={{ marginLeft: 5 }}>{state}</span>
                </span>
              );
            })}
          {displayAnomalies && (
            <span className="legend-item" style={{ marginLeft: 20 }}>
              <Surface width={12} height={12} viewBox="0 0 12 12">
                {_.range(3).map((i) => {
                  return (
                    <Rectangle
                      key={`barchart-icon-bar-${i}`}
                      x={4 * i}
                      y={10 - (9 - ((i + 1) % 2) * 3)}
                      width={3}
                      height={9 - ((i + 1) % 2) * 3}
                      fill="#555"
                    />
                  );
                })}
              </Surface>
              <span style={{ marginLeft: 5 }}>{t("anomalous_percentage")}</span>
            </span>
          )}
        </div>
      );
    };

    const CustomTick = (props) => {
      const { x, y, payload } = props;
      const { value } = payload;
      return <g transform={`translate(${x - 17},${y})`}>{xFormatFn(value)}</g>;
    };

    const zoom = () => {
      let [fromDate, toDate] = [refAreaLeft, refAreaRight];
      if (fromDate === toDate || toDate === null || toDate === undefined) {
        setRefAreaLeft(null);
        setRefAreaRight(null);
        return;
      }

      // xAxis domain
      if (fromDate > toDate) [fromDate, toDate] = [toDate, fromDate];

      zoomQuery(moment(fromDate), moment(toDate))
        .then((r) => {
          if (r.status < 300) {
            setZoomActive(true);
            setRefToDate(toDate);
            setRefFromDate(fromDate);
            r.json().then(({ data, state_info }) => {
              setZoomData(data);
              setZoomStateInfo(state_info);
              const eventCount = _.filter(
                events,
                (e) =>
                  moment(e.start_at) >= fromDate && moment(e.start_at) <= toDate
              ).length;
              if (zoomCallback)
                zoomCallback(fromDate, toDate, data, eventCount);
            });
          } else {
            console.log(`Error: ${r.statusText}`);
            resetZoom();
          }
        })
        .catch((e) => {
          console.log(e.message);
          resetZoom();
        });

      setRefAreaLeft(null);
      setRefAreaRight(null);
    };

    const resetZoom = () => {
      setZoomData({});
      setZoomStateInfo([]);
      setRefAreaLeft(null);
      setRefAreaRight(null);
      setRefToDate(null);
      setRefFromDate(null);
      setZoomActive(false);
      if (zoomCallback) zoomCallback(start, end, initialData, events.length);
    };

    const chartArgs = zoomEnabled
      ? {
          onMouseDown: (e) => setRefAreaLeft(e?.activeLabel || null),
          onMouseMove: (e) => refAreaLeft && setRefAreaRight(e.activeLabel),
          onMouseUp: zoom,
        }
      : {};

    const { t } = useTranslation(["glossary", "common"]);

    return (
      <>
        {!tiny && !hideTimeRange && (
          <CardHeader
            disableTypography
            title={<DateTimeHeader startDate={start} endDate={end} />}
          />
        )}
        <CardContent style={{ userSelect: "none" }}>
          {zoomEnabled && (
            <Grid container style={{ padding: "0 30px" }}>
              <Grid item md={6} style={{ textAlign: "left" }}>
                <div>
                  <Typography variant="subtitle1" display="block">
                    {t("behavior_over_time")}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {t("click_to_drag_and_zoom")}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  height: "35px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={resetZoom}
                  style={{ marginRight: "5px" }}
                >
                  {t("reset_zoom")}
                </Button>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="displayOptions"
                    style={{ transform: "translate(10px, 10px) scale(1)" }}
                  >
                    {t("display_options")}
                  </InputLabel>
                  <Select
                    multiple
                    value={[]}
                    MenuProps={MenuProps}
                    variant="outlined"
                    color="primary"
                    style={{ height: "35px" }}
                  >
                    <MenuItem
                      key="threshold-bounds-display-opt"
                      value="Threshold Bounds"
                    >
                      <Checkbox
                        checked={displayBounds}
                        onChange={thresholdBoundsToggle}
                      />
                      <ListItemText
                        primary={t("threshold_bounds")}
                        onClick={thresholdBoundsToggle}
                      />
                    </MenuItem>
                    <MenuItem
                      key="machine-state-display-opt"
                      value="Machine State"
                    >
                      <Checkbox
                        checked={displayState}
                        onChange={overlayStateToggle}
                      />
                      <ListItemText
                        primary={t("machine_state")}
                        onClick={overlayStateToggle}
                      />
                    </MenuItem>
                    <MenuItem
                      key="anomalous-pct-display-opt"
                      value="Anomalous pct"
                    >
                      <Checkbox
                        checked={displayAnomalies}
                        onChange={anomalousPctToggle}
                      />
                      <ListItemText
                        primary={t("anomalous_pct_2")}
                        onClick={anomalousPctToggle}
                      />
                    </MenuItem>
                    <MenuItem
                      key="event-flag-display-opt"
                      value="Reported Events"
                    >
                      <Checkbox
                        checked={displayEvents}
                        onChange={eventsDisplayToggle}
                      />
                      <ListItemText
                        primary={t("reported_events")}
                        onClick={eventsDisplayToggle}
                      />
                    </MenuItem>
                    <MenuItem key="job-flag-display-opt" value="Jobs">
                      <Checkbox
                        checked={displayJobs}
                        onChange={jobsDisplayToggle}
                      />
                      <ListItemText
                        primary={t("jobs")}
                        onClick={jobsDisplayToggle}
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <ResponsiveContainer
            width="100%"
            height={
              tiny
                ? 200
                : displayAnomalies
                ? 400
                : containerHeight
                ? containerHeight
                : 530
            }
          >
            <LineChart
              key="composed_chart"
              margin={{
                top: 30,
                right: 30,
                left: tiny
                  ? 0
                  : displayAnomalies && metrics.length - exclude.length > 2
                  ? 65
                  : 20,
                bottom: 5,
              }}
              data={records}
              syncId="timeseries-charts"
              {...chartArgs}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={!!displayVerticalLines}
              />
              <XAxis
                xAxisId={0}
                // Duplicated category has to be disabled when enabling threshold bounds
                // but enabled the rest of the time. See PR #534 for more details.
                allowDuplicatedCategory={!displayBounds}
                axisLine={false}
                dataKey="time"
                minTickGap={50}
                tick={<CustomTick />}
                orientation={xAxisOrientation || "top"}
                tickMargin={xAxisTickMargin || 30}
                tickLine={false}
              />
              {axes.map((axis) => {
                return (
                  <YAxis
                    yAxisId={axis.id}
                    key={axis.id}
                    //hide the axis if there is a lot of metrics
                    hide={
                      _.includes(exclude, axis.id) ||
                      axes.length - exclude.length > 2
                    }
                    domain={["auto", "auto"]}
                    axisLine={false}
                    tickMargin={10}
                    tickLine={false}
                    tick={{
                      fontSize: 12,
                    }}
                  />
                );
              })}
              <YAxis
                yAxisId="static_axis"
                key="static_axis"
                axisLine={false}
                domain={[0, 6]}
                hide={true}
                tickLine={false}
              />
              <Tooltip
                wrapperStyle={{
                  fontSize: 12,
                }}
                content={
                  <CustomTooltip
                    keyToCfg={keyToCfg}
                    displayAnomalies={displayAnomalies}
                    timezone={timezone}
                    facilityTimezone={facilityTimezone}
                    chartType="linechart"
                  />
                }
              />
              {!tiny && !hideLegend && (
                <Legend
                  wrapperStyle={{
                    paddingTop: 20,
                  }}
                  content={<CustomLegend />}
                />
              )}
              {displayState &&
                overlayData.map((overlay) => {
                  return (
                    <ReferenceArea
                      fill="black"
                      fillOpacity={1}
                      xAxisId={0}
                      yAxisId={metrics[0]}
                      shape={
                        <StateOverlayShape state={overlay.machine_state} />
                      }
                      x1={overlay.x1}
                      x2={overlay.x2}
                      isFront
                    />
                  );
                })}
              {metrics.map((name, index) => {
                const color = toColor(name, index);
                return (
                  <Line
                    key={`${name}_line`}
                    yAxisId={axisName(name)}
                    type={name.includes("regressor") ? "natural" : "step"}
                    hide={_.includes(exclude, name)}
                    dataKey={name}
                    stroke={color}
                    activeDot={{ r: 5 }}
                    dot={false}
                    strokeWidth={name.includes("regressor") ? 1 : 2}
                    strokeDasharray={name.includes("regressor") ? "5 3" : ""}
                  />
                );
              })}
              {displayEvents &&
                placeableEvents &&
                placeableEvents.length &&
                placeableEvents.map(([closestX, evs], index) => {
                  return (
                    <ReferenceDot
                      key={`reference-dot-${index}`}
                      x={closestX}
                      y={0}
                      shape={flagEventShape(evs, timezone, facilityTimezone)}
                      r={8}
                      xAxisId={0}
                      yAxisId="static_axis"
                    />
                  );
                })}
              {displayJobs &&
                placeableJobs &&
                placeableJobs.length &&
                placeableJobs.map(([closestX, jbs], index) => {
                  return (
                    <ReferenceDot
                      key={`job-reference-dot-${index}`}
                      x={closestX}
                      y={0}
                      shape={flagJobShape(jbs, timezone, facilityTimezone)}
                      r={8}
                      xAxisId={0}
                      yAxisId="static_axis"
                    />
                  );
                })}
              {displayBounds &&
                metrics.map((name, index) => {
                  return (
                    <>
                      <Line
                        key={`${name}_ad_lower`}
                        yAxisId={name}
                        strokeDasharray="3 4 5 2"
                        type="step"
                        hide={_.includes(exclude, name)}
                        dataKey={`${name}_ad_lower`}
                        data={records}
                        stroke={
                          !darkThresholdLines
                            ? palette.status.error.light
                            : "black"
                        }
                        dot={false}
                        stackOffset="wiggle"
                        legendType="none"
                      />
                      <Line
                        key={`${name}_ad_upper`}
                        yAxisId={name}
                        strokeDasharray="3 4 5 2"
                        baseLine={200}
                        type="step"
                        hide={_.includes(exclude, name)}
                        dataKey={`${name}_ad_upper`}
                        data={records}
                        stroke={
                          !darkThresholdLines
                            ? palette.status.error.light
                            : "black"
                        }
                        dot={false}
                        legendType="none"
                      />
                    </>
                  );
                })}
              {zoomEnabled && refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  yAxisId={axes[0]?.id}
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
          {displayAnomalies && (
            <ResponsiveContainer width="100%" height={150}>
              <BarChart
                data={records}
                margin={{
                  right: 30,
                  left:
                    metrics.length - exclude.length === 2
                      ? 75
                      : metrics.length - exclude.length === 1
                      ? 20
                      : 0,
                }}
                syncId="timeseries-charts"
                {...chartArgs}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={!!displayVerticalLines}
                />
                <XAxis
                  xAxisId={0}
                  axisLine={false}
                  dataKey="time"
                  minTickGap={50}
                  tick={<CustomTick />}
                  orientation={xAxisOrientation || "top"}
                  tickMargin={xAxisTickMargin || 30}
                  tickLine={false}
                />
                <YAxis
                  yAxisId="ad_flag_axis"
                  key="ad_flag_axis"
                  axisLine={false}
                  tickMargin={10}
                  tickLine={false}
                  tick={{
                    fontSize: 12,
                  }}
                  tickFormatter={(x) => x * 100}
                />
                <Tooltip
                  wrapperStyle={{
                    fontSize: 12,
                  }}
                  content={
                    <CustomTooltip
                      keyToCfg={keyToCfg}
                      displayAnomalies={displayAnomalies}
                      timezone={timezone}
                      facilityTimezone={facilityTimezone}
                      chartType="barchart"
                    />
                  }
                />
                {metrics.map((name, index) => {
                  const color = toColor(name, index);
                  return (
                    <Bar
                      key={`${name}_ad_flag`}
                      yAxisId="ad_flag_axis"
                      hide={_.includes(exclude, name)}
                      dataKey={`${name}_ad_flag`}
                      fill={color}
                      stackId="a"
                    />
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          )}
        </CardContent>
      </>
    );
  }
);
