import React from "react";
import { MetricsChart } from "src/components/Dashboard";
import { Gauge } from "src/components/Gauge";
import { graphPercentage } from "src/utils/gauge";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import palette from "src/theme/palette";
import _ from "lodash";
import { Alert } from "@material-ui/lab";
import { useTranslation, Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "space-evenly",
      flexDirection: "row",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  subTitle: {
    fontSize: "14px",
    color: palette.text.caption,
    fontWeight: 400,
  },
  metrics: {
    width: 240,
    height: 40,
  },
  alert: {
    maxWidth: 250,
    height: 70,
    padding: "0 6px",
    lineHeight: "16px",
    textAlign: "justify",
    "& div[class|='MuiAlert-message']": {
      padding: "4px 0",
    },
  },
}));

const SectionTitle = () => {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <div className={classes.titleContainer}>
      <div>{t("machine_health")}</div>
      <div className={classes.subTitle}>{t("with_4_hour_history_graph")}</div>
    </div>
  );
};

const gaugeAngle = 280;

const MachineHealth = ({
  downtimeLoading,
  opData,
  uptimeData,
  chartData,
  downtimeData,
}) => {
  const classes = useStyles();
  const downtime = downtimeData?.prediction
    ? _.round(downtimeData?.prediction * 100, 1)
    : 0;
  const [opValue, opTotal] = graphPercentage(opData, gaugeAngle);
  const opGauge = [{ value: opValue }, { value: opTotal }];
  const opLabel = opData ? `${opData}%` : "--";

  const [uptimeValue, uptimeTotal] = graphPercentage(uptimeData, gaugeAngle);
  const uptimeGauge = [{ value: uptimeValue }, { value: uptimeTotal }];
  const uptimeLabel = uptimeData ? `${uptimeData}%` : "--";

  const [downtimeValue, downtimeTotal] = graphPercentage(downtime, gaugeAngle);
  const downtimeGauge = [{ value: downtimeValue }, { value: downtimeTotal }];
  const downtimeLabel = downtime ? `${downtime}%` : "--";
  const { t } = useTranslation(["glossary", "common"]);
  const downtimeCaption = downtimeData?.horizon
    ? t("in_count_minutes", { count: downtimeData.horizon })
    : "";

  return (
    <Card>
      <CardHeader title={SectionTitle()} />
      <Divider />
      <CardContent className={classes.flexRow}>
        <div>
          <Gauge
            height={200}
            width={250}
            data={downtimeGauge}
            value={downtimeLabel}
            rawValue={downtime}
            label={t("chance_of_downtime")}
            caption={downtimeCaption}
            colorRules="downtime"
          />
          {!downtimeLoading && !downtimeData?.prediction ? (
            <Alert severity="info" className={classes.alert}>
              <Trans
                i18nKey="glossary:more_data_message"
                components={{
                  1: <a href="mailto:sales@gohelios.us">gohelios.us</a>,
                }}
              />
            </Alert>
          ) : (
            <div className={classes.metrics} />
          )}
        </div>
        <div>
          <Gauge
            height={200}
            width={250}
            data={opGauge}
            value={opLabel}
            rawValue={opData}
            label={t("OP")}
            colorRules="op-uptime"
          />
          <MetricsChart
            data={chartData?.operating_productivity}
            width={240}
            height={40}
            margin={{ left: 10, right: 0, top: 0, bottom: 0 }}
          />
        </div>
        <div>
          <Gauge
            height={200}
            width={250}
            data={uptimeGauge}
            value={uptimeLabel}
            rawValue={uptimeData}
            label={t("uptime_over_4hr")}
            colorRules="op-uptime"
          />
          <MetricsChart
            data={chartData?.machine_running}
            width={240}
            height={40}
            margin={{ left: 10, right: 0, top: 0, bottom: 0 }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default MachineHealth;
