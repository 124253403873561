import React from "react";
import { currentUserRole } from "../utils/session";

export const ROLES = {
  ADMIN: "admin",
  USER: "user",
  SUPERUSER: "superuser",
};

export const RoleGate = ({ children, permittedRoles }) => {
  const userRole = currentUserRole();
  if (permittedRoles.includes(userRole)) return <>{children}</>;
  else return null;
};

export const SuperUserGate = ({ children }) => {
  return <RoleGate permittedRoles={[ROLES.SUPERUSER]}>{children}</RoleGate>;
};

export const CustomerAdminGate = ({ children }) => {
  return <RoleGate permittedRoles={[ROLES.ADMIN]}>{children}</RoleGate>;
};

export const AdminGate = ({ children }) => {
  return (
    <RoleGate permittedRoles={[ROLES.SUPERUSER, ROLES.ADMIN]}>
      {children}
    </RoleGate>
  );
};

export const UnprivilegedUserGate = ({ children }) => {
  return <RoleGate permittedRoles={[ROLES.USER]}>{children}</RoleGate>;
};

export const NonSuperUserGate = ({ children }) => {
  return (
    <RoleGate permittedRoles={[ROLES.USER, ROLES.ADMIN]}>{children}</RoleGate>
  );
};
