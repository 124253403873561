import mock from "src/utils/mock";

mock.onGet("/api/customer/users/list").reply(200, {
  users: [],
});
mock.onGet("/api/customer/users/list/sample").reply(200, {
  users: [
    {
      id: 1,
      first_name: "Gustie",
      last_name: "Duce",
      email: "gduce0@oakley.com",
      phone: "781-778-6544",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Chattanooga",
    },
    {
      id: 2,
      first_name: "Aurore",
      last_name: "Grimoldby",
      email: "agrimoldby1@digg.com",
      phone: "725-720-1596",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Memphis",
    },
    {
      id: 3,
      first_name: "Becky",
      last_name: "Glidder",
      email: "bglidder2@nps.gov",
      phone: "282-314-9073",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Atlanta",
    },
    {
      id: 4,
      first_name: "Timothea",
      last_name: "Timpany",
      email: "ttimpany3@webs.com",
      phone: "898-350-9293",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Chattanooga",
    },
    {
      id: 5,
      first_name: "Frazier",
      last_name: "Gooderham",
      email: "fgooderham4@unesco.org",
      phone: "879-717-1724",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Memphis",
    },
    {
      id: 6,
      first_name: "Dulci",
      last_name: "Stanyard",
      email: "dstanyard5@naver.com",
      phone: "791-130-3327",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Memphis",
    },
    {
      id: 7,
      first_name: "Crista",
      last_name: "Baribal",
      email: "cbaribal6@cnet.com",
      phone: "984-981-2912",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Atlanta",
    },
    {
      id: 8,
      first_name: "Jamil",
      last_name: "Wapol",
      email: "jwapol7@alibaba.com",
      phone: "563-631-7632",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Atlanta",
    },
    {
      id: 9,
      first_name: "Addy",
      last_name: "Bentz",
      email: "abentz8@hubpages.com",
      phone: "154-678-8178",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Knoxville",
    },
    {
      id: 10,
      first_name: "Brocky",
      last_name: "Mariyushkin",
      email: "bmariyushkin9@netscape.com",
      phone: "465-913-3245",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Knoxville",
    },
    {
      id: 11,
      first_name: "Zola",
      last_name: "Beauchamp",
      email: "zbeauchampa@vistaprint.com",
      phone: "351-941-3928",
      customer: "ACME Company",
      role: "User",
      status: "Disabled",
      facility: "Memphis",
    },
    {
      id: 12,
      first_name: "Minni",
      last_name: "Valencia",
      email: "mvalenciab@ameblo.jp",
      phone: "929-355-1219",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Chattanooga",
    },
    {
      id: 13,
      first_name: "Verney",
      last_name: "Gooddy",
      email: "vgooddyc@home.pl",
      phone: "579-477-6023",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Chattanooga",
    },
    {
      id: 14,
      first_name: "Harp",
      last_name: "Collymore",
      email: "hcollymored@earthlink.net",
      phone: "673-246-5897",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Memphis",
    },
    {
      id: 15,
      first_name: "Rudiger",
      last_name: "Defraine",
      email: "rdefrainee@paypal.com",
      phone: "850-871-9168",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Memphis",
    },
    {
      id: 16,
      first_name: "Raddy",
      last_name: "McCurrie",
      email: "rmccurrief@pcworld.com",
      phone: "827-317-4107",
      customer: "ACME Company",
      role: "User",
      status: "Invited",
      facility: "Knoxville",
    },
    {
      id: 17,
      first_name: "Agace",
      last_name: "Girardin",
      email: "agirarding@goodreads.com",
      phone: "946-341-7046",
      customer: "ACME Company",
      role: "Admin",
      status: "Active",
      facility: "Atlanta",
    },
    {
      id: 18,
      first_name: "Corrinne",
      last_name: "Blonden",
      email: "cblondenh@dagondesign.com",
      phone: "284-469-5009",
      customer: "ACME Company",
      role: "Admin",
      status: "Active",
      facility: "Atlanta",
    },
    {
      id: 19,
      first_name: "Myles",
      last_name: "Irce",
      email: "mircei@webnode.com",
      phone: "956-492-7077",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Atlanta",
    },
    {
      id: 20,
      first_name: "Bessie",
      last_name: "Dewdney",
      email: "bdewdneyj@engadget.com",
      phone: "712-661-7161",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Atlanta",
    },
    {
      id: 21,
      first_name: "Shaun",
      last_name: "Grotty",
      email: "sgrottyk@hugedomains.com",
      phone: "523-175-5150",
      customer: "ACME Company",
      role: "User",
      status: "Disabled",
      facility: "Knoxville",
    },
    {
      id: 22,
      first_name: "Nora",
      last_name: "Temple",
      email: "ntemplel@sitemeter.com",
      phone: "825-507-9145",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Knoxville",
    },
    {
      id: 23,
      first_name: "Coleman",
      last_name: "Chiles",
      email: "cchilesm@unicef.org",
      phone: "513-346-4908",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Atlanta",
    },
    {
      id: 24,
      first_name: "Hanny",
      last_name: "Hedaux",
      email: "hhedauxn@answers.com",
      phone: "662-553-0653",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Chattanooga",
    },
    {
      id: 25,
      first_name: "Feliks",
      last_name: "Riepl",
      email: "frieplo@com.com",
      phone: "805-185-7316",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Knoxville",
    },
    {
      id: 26,
      first_name: "Lexy",
      last_name: "Kolodziej",
      email: "lkolodziejp@imageshack.us",
      phone: "244-582-0191",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Chattanooga",
    },
    {
      id: 27,
      first_name: "Storm",
      last_name: "Bowes",
      email: "sbowesq@wufoo.com",
      phone: "682-709-6834",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Memphis",
    },
    {
      id: 28,
      first_name: "Georgie",
      last_name: "MacCrackan",
      email: "gmaccrackanr@gizmodo.com",
      phone: "937-451-3245",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Memphis",
    },
    {
      id: 29,
      first_name: "Zoe",
      last_name: "Wollers",
      email: "zwollerss@prnewswire.com",
      phone: "863-171-2788",
      customer: "ACME Company",
      role: "User",
      status: "Active",
      facility: "Knoxville",
    },
    {
      id: 30,
      first_name: "Anson",
      last_name: "Denacamp",
      email: "adenacampt@nyu.edu",
      phone: "374-691-2994",
      customer: "ACME Company",
      role: "Admin",
      status: "Active",
      facility: "Memphis",
    },
  ],
});
