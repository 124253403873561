import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import SearchCard from "./SearchCard";
import { useQueryParam } from "src/utils/hooks";
import { getDeviceTelemDispCfgs, getDeviceSections } from "src/repos/devices";
import { getDeviceMachineKeyToHumanKeys } from "src/repos/devices/views";
import _ from "lodash";
import { getAnomalySections } from "src/repos/anomalies";
import AnomalousTime from "./AnomalousTime";
import palette from "src/theme/palette";
import AnomalyView from "./AnomalyView";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  container: {
    padding: 0,
  },
  searchBox: {
    padding: "10px",
  },
}));

function Anomalies({ referrer = "/anomalies", machineId, device }) {
  const BASIC_FORM = "0";
  const ADVANCED_FORM = "1";

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [autoSubmit, setAutoSubmit] = useQueryParam("submit", false);
  const [isError, setIsError] = useState(false);
  const [result, setResult] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sortBy, setSortBy] = useState("desc:value");
  const { id: machineSerial = machineId } = useParams();
  const initialDatePast = moment().subtract(1, "week");
  const [timezone, setTimezone] = useQueryParam("timezone", "local");
  const [anomalyView, setAnomalyView] = useQueryParam("anomaly_view", {
    name: null,
    value: null,
    section: null,
    shared: false,
  });
  const [selectedDate, setSelectedDate] = useQueryParam(
    "date_range",
    [initialDatePast, moment()],
    // getter
    ([start_date, end_date]) => {
      if (timezone === "local") {
        return [moment(start_date), moment(end_date)];
      }
      return [
        moment.tz(start_date, device.facility_timezone),
        moment.tz(end_date, device.facility_timezone),
      ];
    },
    // setter
    ([start_date, end_date]) => [start_date.valueOf(), end_date.valueOf()]
  );
  const [anomalousTime, setAnomalousTime] = useQueryParam("anomalous_time", [
    0,
    100,
  ]);
  const [availableMetrics, setAvailableMetrics] = useState([]);
  const [deviceSections, setDeviceSections] = useState({});
  const [selectedMetrics, setSelectedMetrics] = useQueryParam("metrics", []);
  const [selectedStartDate, selectedEndDate] = selectedDate;
  const [humanKeyToCfg, setHumanKeyToCfg] = useState({});
  const [formType, setFormType] = useQueryParam("form", BASIC_FORM);
  const [lastSearchType, setLastSearchType] = useState();
  const [selectedSections, setSelectedSections] = useQueryParam("sections", {});
  const [errorMessage, setErrorMessage] = useState("");
  const [groupBySection, setGroupBySection] = useState(false);
  const isBasicSearch = lastSearchType === BASIC_FORM;
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    if (machineSerial)
      Promise.all([
        getDeviceTelemDispCfgs(machineSerial)
          .then((r) => r.json())
          .then(({ data }) => data),
        getDeviceMachineKeyToHumanKeys(machineSerial).then((data) => data),
        getDeviceSections(machineSerial)
          .then((r) => r.json())
          .then(({ data }) => data),
      ])
        .then(([cfgs, data, sectionData]) => {
          // set human_key to label mapping
          const humanKeyToCfg_ = cfgs.reduce((acc, cfg) => {
            acc[cfg.human_key] = cfg;
            return acc;
          }, {});
          setHumanKeyToCfg(humanKeyToCfg_);

          // sort and set available metrics
          const humanKeys = [];
          const humanKeysWithTitle = [];
          const machineKeys = [];
          _.each(data, (human_key, machine_key) => {
            if (human_key && humanKeyToCfg_[human_key]) {
              humanKeysWithTitle.push(human_key);
            } else if (human_key) {
              humanKeys.push(human_key);
            } else {
              machineKeys.push(machine_key);
            }
          });
          setDeviceSections(sectionData);
          setAvailableMetrics([
            ...humanKeysWithTitle.sort(),
            ...humanKeys.sort(),
            ...machineKeys.sort(),
          ]);
        })
        .catch(() => {
          setIsError(true);
        });
  }, [machineSerial]);

  const changeAnomalousTime = (event, newValue) => {
    setAnomalousTime(newValue);
  };

  const changeTimezone = (event, newZone) => {
    if (newZone === "local") {
      const startDate = selectedStartDate.toDate();
      const endDate = selectedEndDate.toDate();
      setSelectedDate([startDate, endDate]);
    } else {
      const startDate = moment.tz(selectedStartDate, device.facility_timezone);
      const endDate = moment.tz(selectedEndDate, device.facility_timezone);
      setSelectedDate([startDate, endDate]);
    }
    setTimezone(newZone);
  };

  const handleRangeChange = (startDate, endDate) => {
    setSelectedDate([startDate, endDate]);
  };

  const resetControl = (control) => {
    switch (control) {
      case "time_range":
        setSelectedDate([initialDatePast, moment()]);
        break;
      case "timezone":
        setTimezone("local");
        break;
      case "measures":
        setSelectedMetrics([]);
        break;
      case "anomalous_time":
        setAnomalousTime([0, 100]);
        break;
      default:
        break;
    }
  };

  const resetSectionControl = (control, section) => {
    let sectionSettings = selectedSections[section];

    if (control === "measures") {
      sectionSettings.selectedMeasures = [];
    } else {
      sectionSettings.anomalousTime = [0, 100];
    }

    setSelectedSections({
      ...selectedSections,
      [section]: sectionSettings,
    });
  };

  const getSelectedMetrics = () => {
    if (formType === BASIC_FORM) {
      return selectedMetrics;
    } else {
      return _.reduce(
        selectedSections,
        (metrics, { selectedMeasures }, sectionName) => {
          const sectionMetrics = _.isEmpty(selectedMeasures)
            ? _.has(deviceSections, sectionName)
              ? deviceSections[sectionName]
              : []
            : selectedMeasures;
          return [...metrics, ...sectionMetrics];
        },
        []
      );
    }
  };

  const handleSubmit = (form = "") => {
    if (!validateSections(form)) return;
    setLoading(true);
    setSubmit(true);
    setAutoSubmit(true);
    let pagination = {};

    if (form !== "") {
      setLastSearchType(formType);
      pagination =
        form === BASIC_FORM ? { page: page + 1, page_size: pageSize } : {};
    } else {
      pagination = isBasicSearch ? { page: page + 1, page_size: pageSize } : {};
    }

    const payload = {
      ...pagination,
      metrics: getSelectedMetrics(),
      start_time: selectedStartDate.toISOString(),
      end_time: selectedEndDate ? selectedEndDate.toISOString() : undefined,
      device_id: machineSerial,
      more_than: formType === BASIC_FORM ? anomalousTime[0] / 100 : 0,
      less_than: formType === BASIC_FORM ? anomalousTime[1] / 100 : 1,
      sort_by: sortBy,
    };

    getAnomalySections(payload)
      .then((r) => r.json())
      .then((data) => {
        setResult(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage(t("query_error"));
        setIsError(true);
      });
  };

  const validateSections = (form) => {
    if (form === ADVANCED_FORM && _.isEmpty(selectedSections)) {
      setIsError(true);
      setErrorMessage(t("section_error"));
      return false;
    }
    return true;
  };

  const isSectionColumnHidden = useMemo(() => {
    if (_.isEmpty(result) || _.isEmpty(result.data)) return false;
    if (!result.has_sections) return true;
    return false;
  }, [result]);

  const handlerOrderChange = (columnId, direction) => {
    if (columnId === -1) {
      setSortBy("desc:value");
    } else {
      const fieldName = columns[columnId].field;
      setSortBy(`${direction}:${fieldName}`);
      setPage(0);
    }
  };

  const handleErrorClose = () => {
    setIsError(false);
    setErrorMessage("");
  };

  const handleRowClick = (data) => {
    setAnomalyView({
      tagName: data.tag_name,
      value: data.value,
      section: data.section,
      shared: true,
    });
  };

  const handleTabChange = (event, newTabIndex) => {
    if (newTabIndex === "1") {
      setFormType(ADVANCED_FORM);
    } else {
      setFormType(BASIC_FORM);
    }
  };

  const handleSectionToogle = (e) => {
    let nextSections = {};
    const isSectionSelected = !!selectedSections[e.target.name];
    if (e.target.checked) {
      nextSections = isSectionSelected
        ? { ...selectedSections }
        : {
            ...selectedSections,
            [e.target.name]: { anomalousTime: [0, 100], selectedMeasures: [] },
          };
    } else {
      nextSections = _.omit(selectedSections, e.target.name);
    }

    setSelectedSections(nextSections);
  };

  const handleSelectSectionMetric = (sectionName, metrics) => {
    let sectionSettings = selectedSections[sectionName];
    sectionSettings.selectedMeasures = metrics;

    setSelectedSections({
      ...selectedSections,
      [sectionName]: sectionSettings,
    });
  };

  const handleChangeSectionAnomalousTime = (sectionName, anomalousTime) => {
    let sectionSettings = selectedSections[sectionName];
    sectionSettings.anomalousTime = anomalousTime;

    setSelectedSections({
      ...selectedSections,
      [sectionName]: sectionSettings,
    });
  };

  const toggleGroupBySection = () => {
    setGroupBySection(!groupBySection);
  };

  const resultGroupedBySection = useMemo(() => {
    return _.reduce(
      result.data,
      (acc, curr) => {
        const sectionName = curr.section || "others";
        if (!acc[sectionName]) acc[sectionName] = [];
        acc[sectionName].push(curr);
        return acc;
      },
      {}
    );
  }, [result]);

  useEffect(() => {
    if (submit && isBasicSearch) {
      handleSubmit();
    } // eslint-disable-next-line
  }, [page, sortBy, pageSize]);

  useEffect(() => {
    if (autoSubmit) handleSubmit();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: t("section_caps"),
      field: "section",
      width: "20%",
      sorting: false,
      headerStyle: {
        fontWeight: "600",
      },
      hidden: isSectionColumnHidden,
    },
    {
      title: t("measure_caps"),
      field: "tag_name",
    },
    {
      title: t("anomalous_time"),
      field: "value",
      render: (rowData) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <AnomalousTime
            value={rowData.value && Math.round(rowData.value * 100)}
          />
        </div>
      ),
      defaultSort: "desc",
      width: "20%",
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
  ];

  return (
    <Page className={classes.root} title="Anomalies">
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3} style={{ alignItems: "flex-start" }}>
          <Grid item xs={12} md={3} xl={3} className={classes.searchBox}>
            <SearchCard
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedDate={setSelectedDate}
              anomalousTime={anomalousTime}
              changeAnomalousTime={changeAnomalousTime}
              availableMetrics={availableMetrics}
              selectedMetrics={selectedMetrics}
              setSelectedMetrics={setSelectedMetrics}
              humanKeyToCfg={humanKeyToCfg}
              timezone={timezone}
              changeTimezone={changeTimezone}
              resetControl={resetControl}
              resetSectionControl={resetSectionControl}
              handleSubmit={handleSubmit}
              handleRangeChange={handleRangeChange}
              handleTabChange={handleTabChange}
              formType={formType}
              handleSectionToogle={handleSectionToogle}
              handleSelectSectionMetric={handleSelectSectionMetric}
              handleChangeSectionAnomalousTime={
                handleChangeSectionAnomalousTime
              }
              selectedSections={selectedSections}
              deviceSections={deviceSections}
            />
          </Grid>
          <Grid item xs={12} md={9} xl={9}>
            <Results
              result={result}
              loading={loading}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              referrer={referrer}
              columns={columns}
              handlerOrderChange={handlerOrderChange}
              handleRowClick={handleRowClick}
              wasLastSearchBasic={isBasicSearch}
              selectedSections={selectedSections}
              toggleGroupBySection={toggleGroupBySection}
              groupBySection={groupBySection}
              resultGroupedBySection={resultGroupedBySection}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
            />
          </Grid>
          {anomalyView.shared && (
            <AnomalyView
              open={anomalyView.shared}
              setAnomalyView={setAnomalyView}
              anomalyView={anomalyView}
              device={device}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              timezone={timezone}
            />
          )}
          <Dialog
            open={isError}
            onClose={handleErrorClose}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleErrorClose}
                color="secondary"
                autoFocus
                style={{ color: palette.status.error.main }}
              >
                {t("close")}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
    </Page>
  );
}

export default Anomalies;
