import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import TileCard from "./TileCard";
import machineSubscribe from "src/utils/machineSubscribe";
import { tileAvg } from "src/utils/number";
import { useTranslation } from "react-i18next";

const ThroughputCard = ({ machines, socket, chartData }) => {
  const [machinesThroughput, setMachinesThroughput] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const throughputAvg = useMemo(() => {
    if (!machines?.length) return "--";
    return tileAvg(machinesThroughput, machines.length);
  }, [machinesThroughput, machines]);
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    if (machines) {
      return machineSubscribe(
        machines,
        "sheets_per_hour",
        socket,
        setMachinesThroughput,
        setIsLoading
      );
    }
  }, [machines, socket]);

  if (isLoading) {
    return <TileCard isSkeleton />;
  }

  return (
    <TileCard
      label={t("throughput")}
      units={t("sheets/hr")}
      value={throughputAvg}
      chartData={chartData}
    />
  );
};

export default ThroughputCard;
