import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid } from "@material-ui/core";
import Page from "src/components/Page";
import FacilityForm from "./FacilityForm";
import FacilityMachineList from "./FacilityMachineList";
import PageHeader from "src/components/PageHeader";
import { useParams } from "react-router-dom";
import { getFacility } from "src/repos/facilities";
import { getCustomer } from "src/repos/customers";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
}));

function Configuration({ action }) {
  const classes = useStyles();
  const { id: customer_id, facility_id } = useParams();
  const [facility, setFacility] = useState({});
  const [customer, setCustomer] = useState({});
  const { t } = useTranslation(["glossary"]);

  useEffect(() => {
    if (customer_id && facility_id)
      getFacility(facility_id)
        .then((r) => r.json())
        .then((d) => setFacility(d.data));
    if (customer_id)
      getCustomer(customer_id)
        .then((r) => r.json())
        .then((d) => setCustomer(d.data));
  }, [customer_id, facility_id]);

  return (
    <Page className={classes.root} title={t("facility_detail")}>
      <Container maxWidth="md">
        <PageHeader
          pageTitle={
            action === "new" ? t("facility_new") : t("facility_manage")
          }
          customer={customer}
          facility={facility}
          loading={!(customer?.id && (facility?.id || facility_id !== "New"))}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FacilityForm
              facility={facility}
              setFacility={setFacility}
              customerID={customer_id}
              action={action}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FacilityMachineList facility={facility} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Configuration;
