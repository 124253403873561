import { TextField, useMediaQuery, useTheme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import palette from "src/theme/palette.js";

function MappedTelemetry({ telemetryDefinition: td, onEditClick }) {
  const theme = useTheme();
  const hideEdit = useMediaQuery(theme.breakpoints.up("sm"));
  const [hover, setHover] = useState(false);

  const inputProps = {
    readOnly: true,
    ...(!hideEdit || hover
      ? {
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={onEditClick}>
                <EditIcon style={{ color: palette.gray.main }} />
              </IconButton>
            </InputAdornment>
          ),
        }
      : {}),
  };

  return (
    <TextField
      label={td.human_key}
      id={td.machine_key}
      name={td.machine_key}
      variant="outlined"
      value={td.machine_key}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      InputProps={inputProps}
    />
  );
}

export default MappedTelemetry;
