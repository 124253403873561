import { Button, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "src/components/Page";
import { updateUser } from "src/repos/users";
import { currentUser, updateCurrentUser } from "src/utils/session";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 2),
    flexDirection: "column",
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {},
  body: {
    paddingBottom: "10px",
  },
  confirm: {
    marginRight: "5px",
  },
}));

function EmailUnsubscribe() {
  const classes = useStyles();
  const { t } = useTranslation(["common", "glossary"]);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const user = currentUser();

  const unsubscribe = () => {
    setUnsubscribing(true);
    updateUser({
      id: user.id,
      notifications_email: false,
    })
      .then((r) => r.json())
      .then(({ data }) => {
        updateCurrentUser(data);
        setUnsubscribing(false);
        setUnsubscribed(true);
      });
  };

  return (
    <Page className={classes.root} title={t("unsubscribe_email")}>
      <Card className={classes.card}>
        {!user.notifications_email && !unsubscribed ? (
          <CardContent className={classes.content}>
            <div>
              <span>{t("unsubscribe_email_confirmed_past_")}</span>
            </div>
          </CardContent>
        ) : unsubscribed ? (
          <CardContent className={classes.content}>
            <div>
              <span>{t("unsubscribe_email_confirmed_present_")}</span>
            </div>
          </CardContent>
        ) : (
          <CardContent className={classes.content}>
            <div className={classes.body}>
              <span>{t("unsubscribe_email_confirm_")}</span>
            </div>
            <Button
              className={classes.confirm}
              color="secondary"
              size="large"
              variant="contained"
              disabled={unsubscribing}
              onClick={unsubscribe}
            >
              {t("glossary:confirm")}
            </Button>
          </CardContent>
        )}
      </Card>
    </Page>
  );
}

export default EmailUnsubscribe;
