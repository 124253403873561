import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "src/components/Page";
import TopSnackbar from "src/components/TopSnackbar";
import {
  getDeviceDowntimes,
  getDevices,
  getDeviceTimeseries,
  getMetricAverages,
} from "src/repos/devices";
import { getFacilities } from "src/repos/facilities";
import { getRecommendedMaintenances } from "src/repos/maintenances";
import { getTriggeredAlarms } from "src/repos/alarms";
import {
  MachineFleet,
  ThroughputCard,
  MachineSpeed,
  MachineMSF,
  FacilitiesCard,
  GaugeCard,
} from "src/components/Dashboard";
import DateToggleButtonGroup from "src/components/DateToggleButtonGroup";
import MachineSnapshot from "./MachineSnapshot";
import SlideshowMachineSnapshot from "./SlideshowMachineSnapshot";
import { filterNumbers } from "src/utils/number";
import moment from "moment";
import palette from "src/theme/palette";
import { useSocketContext } from "src/context/SocketContext";
import { useTranslation } from "react-i18next";
import telemetries from "src/repos/telemetries";
import { isSuperUser } from "src/utils/session";
import Maintenance from "src/views/SunScreens/Machines/Details/Overview/Maintenance";
import TriggeredAlarms from "./TriggeredAlarms";

const useStyles = makeStyles((theme) => ({
  root: {},
  slideshowButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  slideshowButton: {
    height: "40px",
  },
  dashboardButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
    marginRight: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(2),
  },
  grid: {
    marginTop: theme.spacing(3),
    justifyContent: "space-between",
  },
  gridTiles: {
    marginBottom: theme.spacing(4),
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
  },
  gridTiles2: {
    marginBottom: theme.spacing(4),
    justifyContent: "flex-start",
    display: "flex",
    flexWrap: "wrap",
  },
  gridSnapshot: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    justifyContent: "start",
    alignItems: "center",
    padding: "12px",
  },
  maintenanceTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    justifyContent: "start",
    alignItems: "center",
    padding: "12px",
  },
  gridItem: {
    width: "240px",
    paddingTop: "20px",
    [theme.breakpoints.up("xl")]: {
      width: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "300px",
    },
  },
  buttonGroup: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20px",
    },
  },
}));

function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const { socket } = useSocketContext();
  const utc_now = useMemo(() => moment.utc(), []);
  const { t } = useTranslation(["glossary", "common"]);
  const [maintenances, setMaintenances] = useState([]);
  const [maintenanceIsLoading, setMaintenanceIsLoading] = useState([true]);
  const [triggeredAlarms, setTriggeredAlarms] = useState([]);
  const [triggeredAlarmsIsLoading, setTriggeredAlarmsIsLoading] = useState([
    true,
  ]);

  const DateRanges = useMemo(() => {
    return {
      "4hr": moment.utc().subtract(4, "hour"),
      "8hr": moment.utc().subtract(8, "hour"),
      day: moment.utc().subtract(1, "days"),
      week: moment.utc().subtract(7, "days"),
    };
  }, []);

  const defaultDateRange = "4hr";

  const getSnackbarState = () => {
    if (history.location) {
      if (history.location.state) {
        if (history.location.state.confirmation) {
          return history.location.state.confirmation;
        }
      }
    }
    return false;
  };

  const [openSnackbar, setOpenSnackbar] = useState(getSnackbarState);
  const [facilitiesOpen, setFacilitiesOpen] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  const [facilitiesIsLoading, setFacilitiesIsLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [allDevices, setAllDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState();
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [metricAverages, setMetricAverages] = useState({});
  const [MSF, setMSF] = useState({});
  const [fleetAggregate, setFleetAggregate] = useState({});
  const [metricsAggregate, setMetricsAggregate] = useState({});
  const radioGroupRef = React.useRef(null);
  const [metricAveragesLoading, setMetricAveragesLoading] = useState(true);
  const [MSFLoading, setMSFLoading] = useState(true);
  const [downtime, setDowntime] = useState({});
  const [downtimeLoading, setDowntimeLoading] = useState(true);
  const [slideshow, setSlideshow] = useState(false);
  const [machineStates, setMachineStates] = useState({});

  const opAndUptimeAvg = useMemo(() => {
    const runningVals = [];
    const opVals = [];
    const machineCounter = { running: 0, operating: 0 };
    Object.values(metricAverages).forEach((avg) => {
      if (avg.machine_running) {
        const machine_running = Math.abs(_.round(avg.machine_running * 100, 0));
        machineCounter["running"] = machineCounter.running += 1;
        runningVals.push(machine_running);
      }
      if (avg.operating_productivity) {
        const operating_productivity = Math.abs(
          _.round(avg.operating_productivity * 100, 0)
        );
        machineCounter["operating"] = machineCounter.operating += 1;
        opVals.push(operating_productivity);
      }
    });

    const opData =
      _.sum(filterNumbers(opVals) || [0]) / machineCounter.operating;
    const uptimeData =
      _.sum(filterNumbers(runningVals) || [0]) / machineCounter.running;
    return {
      opData: _.round(opData, 1),
      uptimeData: _.round(uptimeData, 1),
    };
  }, [metricAverages]);

  useEffect(() => {
    getRecommendedMaintenances()
      .then((response) => response.json())
      .then((data) => {
        setMaintenances(data.maintenances);
        setMaintenanceIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        setMaintenanceIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getTriggeredAlarms()
      .then((response) => response.json())
      .then((data) => {
        setTriggeredAlarms(data.data);
        setTriggeredAlarmsIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        setTriggeredAlarmsIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getDevices()
      .then((r) => r.json())
      .then((d) => {
        setAllDevices(d.data);
        setSelectedDevices(d.data);
      })
      .catch(() => {
        setAllDevices([]);
        setSelectedDevices([]);
      });
    getFacilities()
      .then((r) => r.json())
      .then((d) => {
        setFacilityList(
          _.map(d.data, (facility) => ({
            facilityName: facility.name,
            active: true,
            id: facility.id,
          }))
        );
        setFacilitiesIsLoading(false);
      })
      .catch(() => {
        setFacilityList([]);
        setFacilitiesIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const facilityIds = facilityList
      .filter((f) => f.active === true)
      .map((f) => f.id);
    setSelectedDevices(
      allDevices.filter((device) => {
        return facilityIds.includes(device.facility_id);
      })
    );
  }, [facilityList, allDevices]);

  useEffect(() => {
    if (!_.isEmpty(selectedDevices)) {
      setMetricAveragesLoading(true);
      getMetricAverages(
        selectedDevices.map((device) => device.id),
        ["operating_productivity", "machine_running"],
        DateRanges[dateRange],
        utc_now.format()
      )
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            setMetricAverages(data.data.metric_averages?.values || {});
          }
        })
        .catch(() => setMetricAverages({}));
    }
  }, [selectedDevices, dateRange, DateRanges, utc_now]);

  useEffect(() => {
    if (!facilitiesIsLoading) {
      if (!_.isEmpty(selectedDevices)) {
        setMSFLoading(true);
        getMetricAverages(
          selectedDevices.map((device) => device.id),
          ["msf_hr"],
          DateRanges["8hr"],
          utc_now.format()
        )
          .then((r) => r.json())
          .then((data) => {
            if (data.data) {
              let values = _.mapValues(
                data.data.metric_averages?.values,
                (o) => o.msf_hr
              );
              setMSF(values || {});
              setMSFLoading(false);
            }
          })
          .catch(() => {
            setMSF({});
            setMSFLoading(false);
          });
      } else if (_.isEmpty(facilityList)) {
        setMSFLoading(false);
      }
    }
  }, [facilitiesIsLoading, facilityList, selectedDevices, DateRanges, utc_now]);

  useEffect(() => {
    if (!_.isEmpty(selectedDevices)) {
      setMetricAveragesLoading(true);
      getDeviceTimeseries({
        devices: selectedDevices.map((device) => device.id),
        metrics: ["operating_productivity", "machine_running"],
        start_date: DateRanges[dateRange].format(),
        end_date: utc_now.format(),
        datapoints: 4,
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            setFleetAggregate(data.data);
            setMetricAveragesLoading(false);
          }
        })
        .catch(() => {
          setFleetAggregate({});
          setMetricAveragesLoading(false);
        });
    }
  }, [selectedDevices, dateRange, DateRanges, utc_now]);

  useEffect(() => {
    if (!_.isEmpty(selectedDevices)) {
      getDeviceTimeseries({
        devices: selectedDevices.map((device) => device.id),
        metrics: ["sheets_per_hour", "machine_speed", "msf_hr"],
        start_date: DateRanges["4hr"].format(),
        end_date: utc_now.format(),
        datapoints: 4,
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            setMetricsAggregate(data.data);
          }
        })
        .catch(() => setMetricsAggregate({}));
    }
  }, [selectedDevices, dateRange, DateRanges, utc_now]);

  useEffect(() => {
    if (!_.isEmpty(selectedDevices)) {
      setDowntimeLoading(true);
      getDeviceDowntimes(selectedDevices.map((device) => device.id))
        .then((r) => r.json())
        .then((data) => {
          if (data.data) setDowntime(data.data);
          setDowntimeLoading(false);
        })
        .catch(() => {
          setDowntime({});
          setDowntimeLoading(false);
        });
    }
  }, [selectedDevices]);

  useEffect(() => {
    const unsubscribeHandles = [];

    allDevices.forEach((device) => {
      const unsubscribe = telemetries.subscribe(
        [device.id],
        ["machine_state"],
        (_, state) => {
          setMachineStates((prevStates) => ({
            ...prevStates,
            [device.id]: state.machine_state || "unknown",
          }));
        },
        socket
      );

      unsubscribeHandles.push(unsubscribe);
    });

    return () => unsubscribeHandles.forEach((unsubscribe) => unsubscribe());
  }, [allDevices, socket]);

  const handleFacilityChange = (id, checked) => {
    let newFacilities = facilityList.map((f) =>
      f.id === id ? { ...f, active: checked } : f
    );
    setFacilityList(newFacilities);
  };

  const handleBulkSelectFacilities = (isActive) => {
    let newFacilities = facilityList.map((f) => ({ ...f, active: isActive }));
    setFacilityList(newFacilities);
  };

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const onCloseFacilityDialog = () => {
    setFacilitiesOpen(false);
  };

  const handleOpenFacilities = () => {
    setFacilitiesOpen(true);
  };

  const handleDateRange = (event, newDateRange) => {
    if (_.isNil(newDateRange)) {
      newDateRange = defaultDateRange;
    }
    setDateRange(newDateRange);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Page className={classes.root} title="Dashboard">
      {!slideshow && (
        <>
          <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FacilitiesCard
                  facilityList={facilityList}
                  handleOpenFacilities={handleOpenFacilities}
                  facilitiesOpen={facilitiesOpen}
                  handleEntering={handleEntering}
                  handleBulkSelectFacilities={handleBulkSelectFacilities}
                  handleFacilityChange={handleFacilityChange}
                  onCloseFacilityDialog={onCloseFacilityDialog}
                />
              </Grid>
              {isSuperUser() && (
                <div className={classes.slideshowButtonContainer}>
                  <Button
                    className={classes.slideshowButton}
                    aria-label={t("slideshow")}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSlideshow(!slideshow);
                    }}
                  >
                    {t("slideshow")}
                  </Button>
                </div>
              )}
            </Grid>
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <MachineFleet machines={selectedDevices} socket={socket} />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <ThroughputCard
                  machines={selectedDevices}
                  socket={socket}
                  chartData={metricsAggregate?.sheets_per_hour}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <MachineSpeed
                  machines={selectedDevices}
                  socket={socket}
                  chartData={metricsAggregate?.machine_speed}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <MachineMSF
                  isLoading={MSFLoading}
                  MSFData={MSF}
                  chartData={metricsAggregate?.msf_hr}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.grid}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ paddingTop: 0 }}
              >
                <GaugeCard
                  dateRange={dateRange}
                  handleDateRange={handleDateRange}
                  title={t("fleet_op")}
                  gaugeLabel={t("op_efficiency")}
                  content={opAndUptimeAvg.opData}
                  colorRules="op-uptime"
                  chartData={fleetAggregate?.operating_productivity}
                  loading={facilitiesIsLoading && metricAveragesLoading}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ paddingTop: 0 }}
              >
                <GaugeCard
                  dateRange={dateRange}
                  handleDateRange={handleDateRange}
                  title={t("fleet_uptime")}
                  gaugeLabel={t("uptime")}
                  content={opAndUptimeAvg.uptimeData}
                  colorRules="op-uptime"
                  chartData={fleetAggregate?.machine_running}
                  loading={facilitiesIsLoading && metricAveragesLoading}
                />
              </Grid>
            </Grid>
            <Grid className={classes.gridTiles}>
              <Grid container spacing={3} className={classes.gridSnapshot}>
                <div>
                  <Typography variant="h3">
                    {t("requires_attention")}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ paddingTop: 0 }}
              >
                <Maintenance
                  isLoading={maintenanceIsLoading}
                  table_name={t("upcoming_maintenances")}
                  maintenance_list={maintenances}
                  style={{
                    marginRight: 12.5,
                    marginBottom: 25,
                    height: "100%",
                  }}
                  showDeviceNameColumn={true}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ paddingTop: 0 }}
              >
                <TriggeredAlarms
                  isLoading={triggeredAlarmsIsLoading}
                  alarm_list={triggeredAlarms}
                  showDeviceNameColumn={true}
                  style={{
                    marginLeft: 12.5,
                    height: "100%",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.gridSnapshot}>
              <div>
                <Typography variant="h3">{t("machine_snapshot")}</Typography>
              </div>
              <div className={classes.buttonGroup}>
                <DateToggleButtonGroup
                  dateRange={dateRange}
                  handleDateRange={handleDateRange}
                  orientation="horizontal"
                />
              </div>
            </Grid>
            <MachineSnapshot
              facilityList={facilityList}
              machines={allDevices}
              metricAverages={metricAverages}
              downtime={downtime}
              downtimeLoading={downtimeLoading}
            />
            <TopSnackbar
              onClose={handleSnackbarClose}
              open={openSnackbar}
              message={t("form_saved_successfully")}
            />
          </Container>
          <Dialog
            open={
              dialogOpen && !facilitiesIsLoading && facilityList.length === 0
            }
            maxWidth={"md"}
          >
            <DialogTitle>{t("no_facilities_assigned")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("facilities_access_message")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDialogOpen(false)}
                color="secondary"
                autoFocus
                style={{ color: palette.status.error.main }}
              >
                {t("close")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {slideshow && (
        <>
          <div className={classes.dashboardButtonContainer}>
            <Button
              aria-label={t("slideshow")}
              color="primary"
              variant="contained"
              onClick={() => {
                setSlideshow(!slideshow);
              }}
            >
              {t("back_to_dashboard")}
            </Button>
          </div>
          <SlideshowMachineSnapshot
            facilityList={facilityList}
            machines={allDevices.filter(
              (device) =>
                machineStates[device.id] !== "unknown" &&
                machineStates[device.id] !== "off"
            )}
            metricAverages={metricAverages}
            downtime={downtime}
            downtimeLoading={downtimeLoading}
            isLoading={MSFLoading}
            MSFData={MSF}
            chartData={metricsAggregate?.msf_hr}
          />
        </>
      )}
    </Page>
  );
}

export default Dashboard;
