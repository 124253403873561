import { colors, Container, Divider, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import CustomerList from "../Lists/CustomerList";
import Notifications from "./Notifications";
import Users from "./Users";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    backgroundColor: colors.grey[300],
  },
  tabsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

function Admin() {
  let history = useHistory();
  const classes = useStyles();
  let { id, tab } = useParams();
  const { t } = useTranslation(["glossary", "common"]);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: "customers", label: t("customers") },
    { value: "notifications", label: t("notifications") },
    { value: "users", label: t("manage_users") },
  ];

  if (!tab) {
    return <Redirect to={`/admin/customers`} />;
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <Page className={classes.root} title={id}>
      <Container maxWidth="lg">
        <PageHeader pageTitle="Admin" />
        <div className={classes.tabsWrapper}>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={tab}
            variant="scrollable"
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === "customers" && (
            <CustomerList linkPrefix={"/admin/customers"} />
          )}
          {tab === "notifications" && <Notifications />}
          {tab === "users" && <Users linkPrefix={"/admin/users"} />}
        </div>
      </Container>
    </Page>
  );
}

Admin.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Admin;
