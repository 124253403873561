import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import Cookies from "js-cookie";
import _ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number-input";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TopSnackbar from "src/components/TopSnackbar";
import { CustomerAdminGate, SuperUserGate } from "src/components/UserGate";
import { updateUser } from "src/repos/users";
import palette from "src/theme/palette";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    padding: 0,
  },
  error: {
    color: palette.status.error.main,
    marginBottom: 0,
  },
  roleHeader: {
    padding: "0px 24px 16px",
  },
}));

const humanize = (str) => {
  return _.capitalize(
    _.trim(_.snakeCase(str).replace(/_id$/, "").replace(/_/g, " "))
  );
};

function Account({ user, className, ...rest }) {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    error: false,
  });
  const tempData = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    notifications_email: true,
  };
  const [values, setValues] = useState({
    first_name: tempData.first_name,
    last_name: tempData.last_name,
    email: tempData.email,
    password: tempData.password,
    password_confirmation: tempData.password_confirmation,
    notifications_email: tempData.notifications_email,
  });

  const errorMessages = errors
    ? Object.keys(errors).map((key) =>
        errors[key].map((errorMessage, messageIndex) => (
          <li key={`${key}${messageIndex}`}>{`${humanize(
            key
          )} ${errorMessage}`}</li>
        ))
      )
    : null;

  const errorDisplay = (
    <>
      {errors && (
        <Typography variant="body2" className={classes.error}>
          {errorMessages}
        </Typography>
      )}
    </>
  );

  const { t } = useTranslation(["glossary", "common"]);

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    if (!["+", "+1"].includes(value)) {
      setValues({
        ...values,
        phone: value,
      });
    } else {
      setValues({
        ...values,
        phone: "",
      });
    }
  };

  useEffect(() => {
    if (user) setValues(user);
  }, [user]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload;
    if (
      (_.isNil(values.password) || values.password === "") &&
      (_.isNil(values.password_confirmation) ||
        values.password_confirmation === "")
    ) {
      payload = _.omit(values, ["password", "password_confirmation"]);
    } else if (_.isNil(values.password_confirmation)) {
      payload = { ...values, password_confirmation: "" };
    } else {
      payload = values;
    }
    updateUser(payload)
      .then((r) =>
        r.json().then((d) => {
          if (r.status === 200) {
            let user_ = d.data;
            setValues({ ...user_, password: "", password_confirmation: "" });
            Cookies.set("currentUser", JSON.stringify(user_));
            setSnackbar({
              open: true,
              error: false,
              message: "User update successful.",
            });
            setTimeout(() => {
              setSnackbar({
                open: false,
                error: false,
                message: "",
              });
            }, 3000);
          } else {
            setErrors(d.errors);
          }
        })
      )
      .catch((e) => {
        setSnackbar({
          open: true,
          error: true,
          message: "Sorry. Trouble commuicating with server. Try again later.",
        });
        throw e;
      });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit}>
        <CardHeader title={t("account_details")} />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SuperUserGate>
              <Typography className={classes.roleHeader} variant="h6">
                {t("super_user")}
              </Typography>
            </SuperUserGate>
            <CustomerAdminGate>
              <Typography className={classes.roleHeader} variant="h6">
                {t("admin")}
              </Typography>
            </CustomerAdminGate>
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("first_name")}
                name="first_name"
                onChange={handleChange}
                required
                value={values.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("last_name")}
                name="last_name"
                onChange={handleChange}
                required
                value={values.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("email")}
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPhoneNumber
                color="primary"
                defaultCountry={"us"}
                fullWidth
                label={t("phone_number")}
                name="phone"
                onChange={handlePhoneChange}
                onlyCountries={["us"]}
                required
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("common:auth:password")}
                name="password"
                onChange={handleChange}
                value={values.password}
                variant="outlined"
                dataShrink={false}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("common:auth:password_confirmation")}
                name="password_confirmation"
                onChange={handleChange}
                value={values.password_confirmation}
                dataShrink={false}
                variant="outlined"
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="notifications_email"
                    onChange={handleChange}
                    checked={values.notifications_email}
                    color="primary"
                  />
                }
                label={t("email_notifications_enabled")}
              />
            </Grid>
            <Grid item xs={12}>
              {errorDisplay}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
            color="secondary"
          >
            {t("save_changes")}
          </Button>
        </CardActions>
      </form>
      <TopSnackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
      />
    </Card>
  );
}

Account.propTypes = {
  className: PropTypes.string,
};

export default Account;
