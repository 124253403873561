import moment from "moment";

export const timeRangeToString = (startAt: string, endAt: string | undefined) =>
  `${moment(startAt).format("M/D/YY h:mm A")} - ${
    moment(endAt).isValid()
      ? moment(endAt).format("M/D/YY h:mm A")
      : moment().format("M/D/YY h:mm A")
  }`;

export const timeRangeDuration = (
  startAt: string,
  endAt: string | undefined
) => {
  const end = moment(endAt).isValid() ? moment(endAt) : moment();
  const duration = moment.duration(end.diff(moment(startAt)));

  return duration.humanize();
};

export const formatTimeRange = (start: Date, end: Date) => {
  if (moment(start).isValid()) {
    return `${moment(start).format("M/D/YY h:mma")} - ${
      moment(end).isValid()
        ? moment(end).format("M/D/YY h:mma")
        : moment().format("M/D/YY h:mma")
    }`;
  } else {
    return "";
  }
};
