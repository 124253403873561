// @ts-nocheck
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import FeatureNotAvailable from "src/components/FeatureNotAvailable";
import palette from "src/theme/palette";
import { demoValue } from "src/utils/demoMode";
import { isAllowed } from "src/utils/tier_permissions";
import { timeRangeDuration, timeRangeToString } from "src/utils/timeRange";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => ({
  drawer: {
    padding: theme.spacing(0),
    maxHeight: "100%",
  },
  header: {
    backgroundColor: palette.primary.main,
    display: "flex",
  },
  title: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
  },
  separator: {
    width: "100%",
    fontWeight: 800,
    fontSize: 20,
  },
  mainCard: {
    width: 550,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: `${theme.spacing(0)}px auto`,
    padding: theme.spacing(0),
    overflow: "auto",
  },
}));

export function ShowEventContents({ event, device, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  const eventAuthor = () => {
    if (event.user) return event.user;
    if (event.sampler) return event.sampler.name;
    return t("no_author");
  };

  return (
    <Card square className={classes.mainCard}>
      <CardHeader
        className={classes.header}
        action={
          <IconButton aria-label={t("close")} onClick={() => onClose()}>
            <CloseIcon style={{ color: palette.gray.white }} />
          </IconButton>
        }
        title={
          <span className={classes.title} style={{ color: palette.gray.white }}>
            {t("view_event")}
          </span>
        }
      />
      <Divider />
      {isAllowed("accessEvents", device?.service_tier) ? (
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">{t("created_by")}</Typography>
              <Typography variant="body1">
                {demoValue(eventAuthor())}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h5">{t("machine")}</Typography>
              <Typography variant="body1">
                {event.device.name || event.device.serial_number}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h5">{t("event_name")}</Typography>
              <Typography variant="body1">{event.name}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="h5">{t("when_did_it_happen")}</Typography>
              <Typography variant="body1">
                {timeRangeToString(event.start_at, event.end_at)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {t("downtime_for_this_event")}
              </Typography>
              <Typography variant="body1">
                {_.isNil(event.downtime_start_at) ? "No" : "Yes"}
              </Typography>
            </Grid>

            {event.downtime_start_at && (
              <Grid item>
                <Typography variant="h5">{t("for_how_long")}</Typography>
                <Typography variant="body1">
                  {timeRangeDuration(
                    event.downtime_start_at,
                    event.downtime_end_at
                  )}{" "}
                  (
                  {timeRangeToString(
                    event.downtime_start_at,
                    event.downtime_end_at
                  )}
                  )
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography variant="h5">
                {t("interventions_performed")}
              </Typography>
              {!_.isEmpty(event.interventions) ? (
                event.interventions.map((intervention, index) => (
                  <Card square elevation={0} variant="outlined">
                    <CardContent>
                      <Typography variant="h4">
                        {t("intervention_number", { count: index + 1 })}
                      </Typography>
                      <Typography variant="subtitle2">{t("type")}</Typography>
                      <Typography variant="body2">
                        {intervention.type}
                      </Typography>
                      <Typography variant="subtitle2">
                        {t("component")}
                      </Typography>
                      <Typography variant="body2">
                        {intervention.component} -{" "}
                        {intervention.component_part_number}
                      </Typography>
                      <Typography variant="subtitle2">{t("reason")}</Typography>
                      <Typography variant="body2">
                        {intervention.reason}
                      </Typography>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <>
                  <Typography variant="subtitle2">{t("none_caps")}</Typography>
                  <Divider />
                </>
              )}
            </Grid>
            <Grid item>
              <Typography variant="h5">{t("notes")}</Typography>
              <Typography
                paragraph
                style={{ whiteSpace: "pre-line" }}
                variant="body1"
              >
                {event.notes}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <FeatureNotAvailable />
      )}
    </Card>
  );
}

export function ShowEvent({ event, device, onClose }) {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    setShowDrawer(true);
  }, []);

  return (
    <Drawer
      id="show-event-drawer"
      className={classes.drawer}
      anchor="right"
      open={showDrawer}
      onClose={() => onClose()}
    >
      {ShowEventContents({ event, device, onClose })}
    </Drawer>
  );
}
