import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useMemo } from "react";
import { MachineGauges, SnapshotTitle } from "src/components/MachineSnapshot";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
    flexGrow: 1,
    paddingBottom: 20,
    paddingTop: "0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "row",
    },
  },
  alert: {
    margin: "24px 24px",
  },
}));

const MachineCard = ({
  machine,
  facilityList,
  metricAverages,
  downtime,
  downtimeLoading,
}) => {
  const classes = useStyles();

  const machineData = useMemo(() => metricAverages[machine?.id] || null, [
    metricAverages,
    machine,
  ]);

  const facilityName = (facilityId) => {
    const facility = facilityList.find(
      (facility) => facility.id === facilityId
    );
    return facility?.facilityName;
  };

  const machineName = machine?.name || machine?.serial_number;

  if (!machine?.id) return <div />;

  return (
    <Card className={classes.card}>
      <CardHeader
        style={{ padding: "15px" }}
        title={
          <SnapshotTitle
            name={machineName}
            facility={facilityName(machine?.facility_id)}
            machine={machine}
          />
        }
      />
      <Divider />
      <CardContent className={classes.flexRow}>
        <MachineGauges machineData={machineData} downtimeData={downtime} />
      </CardContent>
      {!downtimeLoading && !downtime?.prediction && (
        <Alert severity="info" className={classes.alert}>
          <Trans
            i18nKey="glossary:more_data_message"
            components={{
              1: <a href="mailto:sales@gohelios.us">gohelios.us</a>,
            }}
          />
        </Alert>
      )}
    </Card>
  );
};

export default MachineCard;
