import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { ConfirmationDialog } from "src/components/ConfirmationDialog";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => ({
  root: { padding: "24px 32px" },
  divider: {
    margin: "24px 0",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
  },
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    alignItems: "flex-start",
  },

  flex: { display: "flex" },
  form: {
    marginLeft: "24px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: "16px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
}));

interface MaintenanceVerbs {
  past: string;
  present: string;
  noun: string;
}

interface MaintenanceOnboardingProps {
  id: string;
  name: string;
  target: string;
  verbs: MaintenanceVerbs;
  actions: Array<string>;
  onClick: Function;
  hidePrediction: boolean;
  lastPerformedAt: string;
}

function MaintenanceOnboarding({
  id,
  name,
  target,
  verbs,
  actions,
  onClick,
  hidePrediction,
  lastPerformedAt,
}: MaintenanceOnboardingProps) {
  const classes = useStyles();
  const [prediction, setPrediction] = useState("");
  const [steps, setSteps] = useState<string[]>([]);
  const [lastMaintenance, setLastMaintenance] = useState(new Date());
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { t } = useTranslation(["glossary", "common"]);

  const minDate =
    !_.isNil(lastPerformedAt) && moment(lastPerformedAt).isValid()
      ? moment(lastPerformedAt).toDate()
      : null;

  const handlePrediction = (_event: any, newPrediction: string) => {
    setPrediction(newPrediction);
  };

  const handleSubmit = () => onClick(id, lastMaintenance, prediction);

  return (
    <div>
      <Typography gutterBottom variant="h5">
        {t("last_time_action", { action: verbs?.past, target: target })}
      </Typography>
      <Typography variant="subtitle1" className={classes.marginBottom}>
        {t("predict_future_maintenance_message", { noun: verbs?.noun })}
      </Typography>
      <div className={classes.container}>
        <DatePicker
          inline
          onChange={(date: Date) => setLastMaintenance(date)}
          selected={lastMaintenance}
          minDate={minDate}
          maxDate={new Date()}
        ></DatePicker>
        <div className={classes.form}>
          <div className={classes.marginBottom}>
            <Typography gutterBottom variant="h5">
              {t("last_verb_noun", { noun: _.capitalize(verbs?.noun) })}
            </Typography>
            <Typography variant="subtitle1">
              {lastMaintenance
                ? moment(lastMaintenance).format("ll")
                : t("select_date")}
            </Typography>
          </div>

          {!hidePrediction && (
            <div className={classes.marginBottom}>
              <Typography gutterBottom variant="h5">
                {t("prediction_accuracy")}
              </Typography>
              <ToggleButtonGroup
                exclusive
                value={prediction}
                onChange={handlePrediction}
                aria-label={t("select_one_of_the_following")}
              >
                <ToggleButton value={0} aria-label={t("too_early")}>
                  <Typography>{t("too_early")}</Typography>
                </ToggleButton>
                <ToggleButton value={1} aria-label={t("right_on_time")}>
                  <Typography>{t("right_on_time")}</Typography>
                </ToggleButton>
                <ToggleButton value={2} aria-label={t("too_late")}>
                  <Typography>{t("too_late")}</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
          <FormGroup>
            {actions && actions.length ? (
              <>
                <Typography gutterBottom variant="h5">
                  {t("maintenance_steps")}
                </Typography>
                {actions.map((action, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={steps.indexOf(action) !== -1}
                          onChange={(e) => {
                            let i = steps.indexOf(e.target.name);
                            i === -1
                              ? steps.push(e.target.name)
                              : steps.splice(i, 1);
                            setSteps([...steps]);
                          }}
                          name={action}
                          color="primary"
                        />
                      }
                      label={action}
                      style={{
                        marginBottom: i === actions.length - 1 ? undefined : 0,
                      }}
                    />
                  );
                })}
              </>
            ) : null}
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => setShowConfirmationDialog(true)}
              disabled={actions && steps.length !== actions.length}
            >
              {t("submit")}
            </Button>
          </FormGroup>
        </div>
      </div>
      <ConfirmationDialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        title={t("confirm_replacement_date")}
        onConfirm={handleSubmit}
        cancelText={t("cancel")}
        confirmText={t("create")}
        body={
          <>
            {t("confirmation_body_part1", { noun: verbs?.noun })}{" "}
            <span style={{ fontWeight: 900 }}>
              {moment(lastMaintenance).format("ll")}
            </span>
            ?
            <br />
            {t("confirmation_body_part2")}
          </>
        }
      />
      <Divider className={classes.divider} />
    </div>
  );
}

export default MaintenanceOnboarding;
