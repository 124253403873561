import {
  IconButton,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { demoValue } from "src/utils/demoMode";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  fullHeight: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  formControl: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  modalHeader: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(3),
  },
  selectButton: {
    padding: theme.spacing(1),
    minWidth: 0,
    lineHeight: 1,
  },
  selectWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const FacilitiesCard = ({
  facilityList,
  handleOpenFacilities,
  facilitiesOpen,
  handleEntering,
  handleBulkSelectFacilities,
  handleFacilityChange,
  onCloseFacilityDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  const getNumActiveFacilities = (data) => {
    let numActive = 0;
    data.forEach(function (v) {
      if (v.active) {
        numActive += 1;
      }
    });
    return numActive;
  };

  return (
    <div>
      <Typography variant="h3">{t("fleet_dashboard")}</Typography>
      <div>
        <Typography variant="body1">
          <strong>
            {`${getNumActiveFacilities(facilityList)} ` +
              t("of") +
              ` ${facilityList.length} `}
          </strong>
          {t("facilities_displayed")}
          {facilityList?.length > 0 && (
            <Tooltip title={t("select_facilities")}>
              <IconButton size="small" onClick={handleOpenFacilities}>
                <img
                  alt={t("select_facilities")}
                  src="/images/presentation/filter.svg"
                />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
        {facilityList?.length > 0 && (
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={facilitiesOpen}
          >
            <div className={classes.modalHeader}>
              <DialogTitle id="confirmation-dialog-title">
                {t("select_one_or_more_facilities")}
              </DialogTitle>
              <div className={classes.selectWrapper}>
                <Typography variant="body2">{t("select")}</Typography>
                <Button
                  onClick={() => {
                    handleBulkSelectFacilities(true);
                  }}
                  color="primary"
                  variant="text"
                  size="small"
                  className={classes.selectButton}
                >
                  {t("all")}
                </Button>
                <Typography variant="body1">|</Typography>
                <Button
                  onClick={() => {
                    handleBulkSelectFacilities(false);
                  }}
                  color="primary"
                  variant="text"
                  size="small"
                  className={classes.selectButton}
                >
                  {t("none")}
                </Button>
              </div>
            </div>
            <DialogContent dividers>
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}
              >
                <FormGroup>
                  {facilityList.map((value, index) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={value.active}
                            onChange={(event) =>
                              handleFacilityChange(
                                value.id,
                                event.target.checked
                              )
                            }
                            name={value.facilityName}
                          />
                        }
                        label={demoValue(value.facilityName)}
                        key={index}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={onCloseFacilityDialog}
                color="secondary"
              >
                {t("save")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default FacilitiesCard;
