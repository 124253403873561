import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useMemo } from "react";
import { SnapshotTitle } from "src/components/MachineSnapshot";
import SlideshowMachineStats from "src/components/MachineSnapshot/SlideshowMachineStats";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
    flexGrow: 1,
    paddingBottom: 20,
    paddingTop: "0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "row",
    },
  },
  alert: {
    margin: "24px 24px",
  },
}));

const SlideshowMachineCard = ({ machine, facilityList, metricAverages }) => {
  const classes = useStyles();

  const machineData = useMemo(() => metricAverages[machine?.id] || null, [
    metricAverages,
    machine,
  ]);

  const facilityName = (facilityId) => {
    const facility = facilityList.find(
      (facility) => facility.id === facilityId
    );
    return facility?.facilityName;
  };

  const machineName = machine?.name || machine?.serial_number;

  if (!machine?.id) return <div />;

  return (
    <Card className={classes.card}>
      <CardHeader
        style={{ padding: "15px" }}
        title={
          <SnapshotTitle
            name={machineName}
            facility={facilityName(machine?.facility_id)}
            machine={machine}
          />
        }
      />
      <Divider />
      <CardContent className={classes.flexRow}>
        <SlideshowMachineStats machineData={machineData} machine={machine} />
      </CardContent>
    </Card>
  );
};

export default SlideshowMachineCard;
