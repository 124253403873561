import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Snackbar, SnackbarContent, colors } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlined";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: colors.green[600],
  },

  errorContent: {
    backgroundColor: colors.red[600],
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

function TopSnackbar({ open, onClose, message, error }) {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={6000}
      onClose={onClose}
      open={open}
    >
      <SnackbarContent
        className={error ? classes.errorContent : classes.content}
        message={
          <span className={classes.message}>
            <CheckCircleIcon className={classes.icon} />
            {message}
          </span>
        }
        variant="elevation"
      />
    </Snackbar>
  );
}

Snackbar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  message: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

Snackbar.defaultProps = {
  open: true,
  onClose: () => {},
  message: i18next.t("glossary:success!"),
};

export default TopSnackbar;
