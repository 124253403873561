import { colors, Container, Divider, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import { getCustomer } from "src/repos/customers";
import { isAdmin, isSuperUser } from "../../../../utils/session";
import CustomerDetails from "./CustomerDetails";
import Facilities from "./Facilities";
import ServiceTiers from "./ServiceTiers";
import Users from "./Users";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    backgroundColor: colors.grey[300],
  },
  tabsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

function CustomerScreens() {
  let history = useHistory();
  const classes = useStyles();
  let { id, tab } = useParams();
  const isSU = isSuperUser();
  const [customer, setCustomer] = useState({});
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    if (id !== "New")
      getCustomer(id)
        .then((r) => r.json())
        .then((d) => setCustomer(d.data));
  }, [id]);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const userTab =
    isAdmin() && id !== "New"
      ? [{ value: "users", label: t("manage_users") }]
      : [];
  const facilitiesTab =
    isSU && id !== "New"
      ? [{ value: "facilities", label: t("facilities") }]
      : [];

  const serviceTierTab =
    isSU && id !== "New"
      ? [{ value: "serviceTiers", label: t("service_tiers") }]
      : [];

  const tabs = [
    { value: "details", label: t("customer_details") },
    ...facilitiesTab,
    ...userTab,
    ...serviceTierTab,
  ];

  if (!tab) {
    return <Redirect to={`${id}/details`} />;
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <Page className={classes.root} title={id}>
      <Container maxWidth="lg">
        <PageHeader
          pageTitle={
            `${id === "New" ? t(id.toLowerCase()) : t("manage")} ` +
            t("customer")
          }
          customer={customer}
          loading={_.isEmpty(customer) && id !== "New"}
        />
        <div className={classes.tabsWrapper}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={tab}
            variant="scrollable"
            indicatorColor="primary"
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === "details" && (
            <CustomerDetails
              customer={customer}
              setCustomer={setCustomer}
              disableForm={!isSU}
            />
          )}
          {tab === "facilities" && <Facilities customerId={id} tab={tab} />}
          {tab === "users" && <Users />}
          {tab === "serviceTiers" && <ServiceTiers customerId={id} />}
        </div>
      </Container>
    </Page>
  );
}

CustomerScreens.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default CustomerScreens;
