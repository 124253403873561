import React, { createContext, useContext, useEffect, useState } from "react";
import { Socket } from "phoenix";
import { getAuthToken } from "src/utils/session";

function EndpointUrl(hostname) {
  if (hostname === "www.helios-app.com" || hostname === "helios-app.com") {
    return "wss://api.helios-app.com";
  } else if (
    hostname === "www.staging.helios-app.com" ||
    hostname === "staging.helios-app.com"
  ) {
    return "wss://api.staging.helios-app.com";
  } else {
    return "ws://localhost:4000/";
  }
}

const url = EndpointUrl(window.location.hostname);

const SocketContext = createContext({ socket: null, token: null });

const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("SocketContext must be used within a SocketProvider");
  }
  return context;
};

const SocketProvider = ({ children }) => {
  const [token, setToken] = useState(getAuthToken());
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    let s = null;

    if (token) {
      s = new Socket(`${url}/socket`, {
        params: { token: token },
      });
      s.onError((x) =>
        console.log(x, "there was an error with the WS connection!")
      );

      if (!s.isConnected()) {
        s.connect();
      }
      setSocket(s);
    }

    return () => {
      s && s.disconnect();
      setSocket(null);
    };
  }, [token]);

  return (
    <SocketContext.Provider value={{ socket, setToken }}>
      {children}
    </SocketContext.Provider>
  );
};

export { useSocketContext };
export default SocketProvider;
