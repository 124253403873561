import { TextField, useMediaQuery } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import { makeStyles } from "@material-ui/styles";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-bootstrap-daterangepicker";
import palette from "src/theme/palette";
import { useTheme } from "@material-ui/core/styles";
import { formatTimeRange } from "src/utils/timeRange";

const useStyles = makeStyles((theme) => ({
  timeRangeInput: {
    backgroundColor: palette.primary.ultraLight,
    height: "40px",
  },
}));

function DateRangePicker(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <DatePicker
      onApply={props.onApply}
      initialSettings={{
        parentEl: props.parentEl,
        opens: props.opens,
        drops: props.drops,
        startDate: props.startDate,
        endDate: props.endDate,
        maxDate: props.maxDate,
        timePicker: true,
        locale: {
          format: "M/DD hh:mm A",
        },
      }}
    >
      <TextField
        fullWidth
        required={props.isRequired}
        placeholder="- No Dates Selected -"
        variant="outlined"
        disabled={isMobile}
        value={formatTimeRange(props.inputStartDate, props.inputEndDate)}
        InputProps={{
          startAdornment: <TodayIcon fontSize="small" />,
          classes: { root: classes.timeRangeInput },
        }}
      />
    </DatePicker>
  );
}

DateRangePicker.defaultProps = {
  maxDate: moment(),
};

DateRangePicker.propTypes = {
  onApply: PropTypes.any,
  parentEl: PropTypes.string,
  open: PropTypes.string,
  drops: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  inputStartDate: PropTypes.object,
  inputEndDate: PropTypes.object,
  isRequired: PropTypes.bool,
  maxDate: PropTypes.object,
};

export default DateRangePicker;
