import { Card, CardContent, CardHeader } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { pure } from "recompose";
import moment from "moment";
import {
  buildRegressor,
  regressionNames,
  reverseTransform,
} from "src/utils/regressionLines";
import { formatLabelValue } from "src/utils/telemDispCfgs";

import DateTimeHeader from "src/components/dateTimeHeader";

const groupFilterSortData = (rawData, metric1, metric2) => {
  return Object.values(
    _.reduce(
      rawData,
      (acc, item, metric) => {
        item.forEach((entry) => {
          if (acc[entry.time]) {
            acc[entry.time][metric] = entry.value;
          } else {
            let obj = {};
            obj[metric] = entry.value;
            acc[entry.time] = obj;
          }
        });
        return acc;
      },
      {}
    )
  )
    .filter((obj) => obj[metric1] !== null && obj[metric2] !== null)
    .sort((a, b) => {
      if (a[metric1] > b[metric1]) {
        return 1;
      } else if (a[metric1] < b[metric1]) {
        return -1;
      }
      return 0;
    });
};

const findMinMetricVal = (data, metric) => {
  return data.reduce((min, data) => {
    if (min === null || data[metric] < min) {
      min = data[metric];
    }
    return min;
  }, null);
};

const calculateWeights = (data, metric1, metric2) => {
  return Object.values(
    data.reduce((acc, item) => {
      let key = `${item[metric1]}_${item[metric2]}`;
      if (acc[key]) {
        acc[key]["count"]++;
      } else {
        acc[key] = {};
        acc[key][metric1] = item[metric1];
        acc[key][metric2] = item[metric2];
        acc[key]["count"] = 1;
      }

      return acc;
    }, {})
  );
};

export default pure(
  ({ data, XAxisMetric, YAxisMetric, cfgs, regressionLines }) => {
    const [start, setStart] = useState();
    const [end, setEnd] = useState();

    useEffect(() => {
      const vals = Object.values(data);
      const first = vals && vals.length ? vals[0] : null;
      if (first && first.length) {
        setStart(moment.utc(first[0].time).toDate());
        setEnd(moment.utc(first[first.length - 1].time).toDate());
      }
    }, [data]);

    const xAxisMetricCfg = cfgs.find((el) => el.human_key === XAxisMetric);
    const yAxisMetricCfg = cfgs.find((el) => el.human_key === YAxisMetric);

    const xAxisTitle =
      xAxisMetricCfg && xAxisMetricCfg.title
        ? xAxisMetricCfg.title
        : XAxisMetric;
    const yAxisTitle =
      yAxisMetricCfg && yAxisMetricCfg.title
        ? yAxisMetricCfg.title
        : YAxisMetric;

    const preprocessedData = groupFilterSortData(
      data,
      XAxisMetric,
      YAxisMetric
    );
    const minY = findMinMetricVal(preprocessedData, YAxisMetric);
    const weighted = calculateWeights(
      preprocessedData,
      XAxisMetric,
      YAxisMetric
    );
    const regr_ready_data = weighted.map((x) => Object.values(x));
    let xs = [],
      ys = [];
    regr_ready_data.forEach(([x, y]) => {
      xs.push(x);
      ys.push(y);
    });
    const minX = xs && xs.length ? xs[0] : null;
    const lineColors = ["blue", "red", "green", "orange", "brown"];

    if (regressionLines.length) {
      const regressors = regressionLines.reduce((acc, regressionType) => {
        acc[regressionType] = buildRegressor(
          regressionType,
          xs,
          ys,
          minX,
          minY
        );
        return acc;
      }, {});

      weighted.reduce((acc, point) => {
        for (const regressionType of regressionLines) {
          if (!regressors[regressionType]) continue;
          point[regressionType] = reverseTransform(
            regressionType,
            regressors[regressionType].predict(point[XAxisMetric]),
            minY
          );
        }
        acc.push(point);
        return acc;
      }, []);
    }

    const keyToCfg = cfgs.reduce((acc, cfg) => {
      if (cfg && cfg.human_key) {
        acc[cfg.human_key] = cfg;
      }
      return acc;
    }, {});

    return (
      <Card style={{ marginBottom: 20 }}>
        <CardHeader
          disableTypography
          title={<DateTimeHeader startDate={start} endDate={end} />}
        />
        <CardContent>
          <ResponsiveContainer width="100%" height={500}>
            <ComposedChart
              data={weighted}
              margin={{
                top: 30,
                right: 30,
                left: 40,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                axisLine={false}
                dataKey={XAxisMetric}
                label={{
                  value: xAxisTitle,
                  position: "insideBottom",
                  offset: -5,
                }}
                scale="auto"
                tickLine={false}
                tick={{
                  fontSize: 12,
                }}
                type="number"
              />
              <YAxis
                axisLine={false}
                label={{
                  value: yAxisTitle,
                  angle: -90,
                  position: "insideLeft",
                  offset: -25,
                }}
                padding={{ top: 20 }}
                tickLine={false}
                tickMargin={30}
                tick={{
                  fontSize: 12,
                }}
                type="number"
              />
              <ZAxis
                dataKey="count"
                name="count"
                zAxisId="counts"
                range={[30, 150]}
              />
              <Tooltip
                wrapperStyle={{
                  fontSize: 12,
                }}
                content={({ active, payload, label }) => {
                  if (payload && payload.length) {
                    return (
                      <Card
                        style={{
                          border: "solid 1px #999",
                          borderRadius: 3,
                          padding: 10,
                        }}
                      >
                        <div
                          style={{
                            margin: 3,
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          {formatLabelValue(
                            keyToCfg,
                            XAxisMetric,
                            payload[0].payload[XAxisMetric]
                          ).map((value, i) => (
                            <span
                              style={i === 0 ? { paddingRight: 5 } : {}}
                              key={`x-${i}`}
                            >
                              {value}
                              {i === 0 ? ":" : ""}
                            </span>
                          ))}
                        </div>
                        <div
                          style={{
                            margin: 3,
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          {formatLabelValue(
                            keyToCfg,
                            YAxisMetric,
                            payload[0].payload[YAxisMetric]
                          ).map((value, i) => (
                            <span
                              style={i === 0 ? { paddingRight: 5 } : {}}
                              key={`y-${i}`}
                            >
                              {value}
                              {i === 0 ? ":" : ""}
                            </span>
                          ))}
                        </div>
                        <div
                          style={{
                            margin: 3,
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          <span style={{ paddingRight: 5 }}>
                            {`Times recorded: ${payload[0].payload.count}`}
                          </span>
                        </div>
                      </Card>
                    );
                  }
                  return null;
                }}
              />
              <Legend verticalAlign="bottom" wrapperStyle={{ paddingTop: 5 }} />
              {regressionLines.map((regrType) => {
                return (
                  <Line
                    dataKey={regrType}
                    key={regrType}
                    name={`${regrType} regression`}
                    stroke={
                      lineColors[
                        Object.values(regressionNames).indexOf(regrType)
                      ]
                    }
                    dot={false}
                    activeDot={false}
                    type="natural"
                  />
                );
              })}
              <Scatter
                dataKey={YAxisMetric}
                zAxisId="counts"
                legendType="none"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    );
  }
);
