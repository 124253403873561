import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import palette from "src/theme/palette";
import Results from "../../../Lists/MaintenanceList";
import { useTranslation } from "react-i18next";

const classes = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
}));

function Maintenance({
  className,
  table_name,
  maintenance_list,
  isLoading,
  showDeviceNameColumn,
  ...rest
}) {
  const checkDate = (date) => {
    var d = new Date().toISOString();
    if (date < d) {
      return true;
    } else {
      return false;
    }
  };
  const { t } = useTranslation(["glossary", "common"]);
  return (
    <Card {...rest}>
      <CardHeader title={table_name} />
      <Divider />
      <CardContent>
        <Results
          isLoading={isLoading}
          show_filters={false}
          show_grouping={false}
          show_header_actions={false}
          show_toolbar={false}
          className={classes.results}
          maintenance_list={maintenance_list}
          num_results={5}
          page_size_options={[]}
          empty_rows_when_paging={true}
          show_actions={false}
          showDeviceNameColumn={showDeviceNameColumn}
          display_columns={[
            {
              title: t("maintenance_type"),
              field: "name",
            },
            {
              title: t("last_service_date"),
              field: "last_performed_at",
              render: (rowData) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {rowData.last_performed_at
                    ? moment(moment(rowData.last_performed_at), "L").fromNow()
                    : "--"}
                </div>
              ),
            },
            {
              title: t("next_service_date"),
              width: 200,
              field: "recommended_date",
              type: "date",
              defaultSort: "asc",
              render: (rowData) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: checkDate(rowData.recommended_date) ? 700 : 500,
                    color: checkDate(rowData.recommended_date)
                      ? palette.status.error.main
                      : palette.gray.main,
                  }}
                >
                  {rowData.recommended_date
                    ? moment(moment(rowData.recommended_date), "L").fromNow()
                    : "--"}
                </div>
              ),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}

Maintenance.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  maintenance_list: PropTypes.array,
  showDeviceNameColumn: PropTypes.bool,
};

export default Maintenance;
