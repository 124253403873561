import {
  Avatar,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import ClockIcon from "@material-ui/icons/AccessTime";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import RepeatIcon from "@material-ui/icons/Repeat";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { isAdmin } from "src/utils/session";
import palette from "src/theme/palette";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 24px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  activeCard: {
    elevation: 8,
    borderBottom: "3px solid",
    borderBottomColor: theme.palette.primary.main,
  },
  inactiveCard: {
    background: theme.palette.gray.lightest,
    "&:hover": {
      background: theme.palette.gray.white,
    },
  },
  avatar: {
    marginRight: 8,
  },
  chip: {
    fontWeight: 600,
  },
  type: {
    textTransform: "capitalize",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },

  content: {
    marginTop: 16,
  },
  headerLeft: {
    display: "flex",
  },
  flex: { display: "flex", alignItems: "center" },

  backgroundIcon: {
    backgroundColor: theme.palette.primary.extraLight,
    color: theme.palette.primary.main,
  },
  new: {
    color: theme.palette.status.success.main,
    backgroundColor: theme.palette.status.success.extraLight,
  },
  overdue: {
    color: theme.palette.status.error.main,
    backgroundColor: theme.palette.status.error.extraLight,
  },
  dueSoon: {
    color: theme.palette.status.warning.dark,
    backgroundColor: theme.palette.status.warning.extraLight,
  },
  snoozed: {
    color: "#263238",
    backgroundColor: palette.status.normal.extraLight,
  },
  clock: {
    fontSize: "16px",
    marginRight: "4px",
  },
}));

function MaintenanceCard(props) {
  const classes = useStyles();
  const [elevation, setelevation] = useState(props.selected ? 5 : 1);
  const estimatedDate = props.estimatedDate
    ? moment(props.estimatedDate).format("ll")
    : "--";
  const relativeTime = props.estimatedDate
    ? moment(moment(props.estimatedDate), "L").fromNow()
    : "--";
  const snoozedUntil =
    props.snoozedUntil && moment(props.snoozedUntil).isAfter(moment())
      ? moment(props.snoozedUntil).format("ll")
      : "--";
  const onMouseOver = () => {
    setelevation(3);
  };
  const onMouseOut = () => {
    setelevation(1);
  };
  const { t } = useTranslation(["glossary", "common"]);

  const getStatus = (status) => {
    if (status === "Overdue") return classes.overdue;
    if (status === "Due Soon") return classes.dueSoon;
    if (status === "New") return classes.new;
    if (status === "Snoozed") return classes.snoozed;
  };

  const icons = {
    replacement: <RepeatIcon />,
    lube: <InvertColorsIcon />,
  };

  return (
    <Grid item xs={12}>
      <Paper
        className={clsx(
          classes.root,
          props.selected ? classes.activeCard : classes.inactiveCard
        )}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        elevation={elevation}
        onClick={() => props.onClick(props.id)}
      >
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <Avatar className={clsx(classes.backgroundIcon, classes.avatar)}>
              {icons[props.type]}
            </Avatar>
            <div>
              <Typography variant="h5">{props.name}</Typography>
              <Typography variant="body1" className={classes.type}>
                {props.type}
              </Typography>
            </div>
          </div>
          {props.status !== "Default" && (
            <Chip
              size="small"
              label={props.status}
              className={clsx(classes.chip, getStatus(props.status))}
            />
          )}
        </div>
        <div className={classes.content}>
          {props.current ? (
            <div>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body1">{t("current")}</Typography>
                  <Typography variant="subtitle2">{props.current} %</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    {_.capitalize(props.verbs.present)} At
                  </Typography>
                  <Typography variant="subtitle2">
                    {props.suggested} %
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">{t("estimated_date")}</Typography>
                  <Typography variant="subtitle2">{estimatedDate}</Typography>

                  {props.status !== "On time" && (
                    <div className={classes.flex}>
                      <ClockIcon className={classes.clock} color="inherit" />
                      <Typography>{relativeTime}</Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">{t("snoozed_until")}</Typography>
                  <Typography variant="subtitle2">{snoozedUntil}</Typography>
                </Grid>
              </Grid>
              {isAdmin() && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedB"
                      color="secondary"
                      checked={props.checked}
                    />
                  }
                  label={t("mark_as_done")}
                  onClick={props.onCheckClick}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                />
              )}
            </div>
          ) : (
            <div>
              <Typography variant="body1">
                {t("new_maintenance_available")}
              </Typography>
              <Typography variant="body1">
                {t("last_time_message", { pastVerb: props.verbs.past })}{" "}
              </Typography>
            </div>
          )}
        </div>
      </Paper>
    </Grid>
  );
}

MaintenanceCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(["replacement", "lube", "default"]),
  suggested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  current: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  estimatedDate: PropTypes.string,
  status: PropTypes.oneOf(["Overdue", "Due Soon", "New", "Default", "Snoozed"]),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onCheckClick: PropTypes.func,
  checked: PropTypes.bool,
};

export default MaintenanceCard;
