import { get, post } from "./base";

function getFacilitiesURI() {
  return `facilities`;
}

function getCustomerFacilitiesURI(customerID) {
  return `customers/${customerID}/facilities`;
}

export function getFacilities() {
  return get(getFacilitiesURI());
}

export function getCustomerFacilities(customerID) {
  return get(getCustomerFacilitiesURI(customerID));
}

export function getFacility(ID) {
  return get(`facilities/${ID}`);
}

export function updateCustomerFacility(customerID, facility) {
  return post(`customers/${customerID}/facilities/${facility.id}`, facility);
}

export function createCustomerFacility(customerID, facility) {
  return post(`customers/${customerID}/facilities`, facility);
}
