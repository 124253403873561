import { Box, Tooltip, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from "@material-ui/icons/Launch";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AddTelemDispCfg from "src/components/AddTelemDispCfg";
import telemetries from "src/repos/telemetries";
import palette from "src/theme/palette.js";
import { isAdmin } from "src/utils/session";
import { formatValue } from "src/utils/telemDispCfgs";
import { useSocketContext } from "src/context/SocketContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    alignItems: "left",
    position: "relative",
    flexDirection: "column",
  },
  card: {
    padding: theme.spacing(2, 3),
  },
  unreportedCard: {
    opacity: 0.4,
    padding: theme.spacing(2),
  },
  abnormalCard: {
    borderBottom: "4px solid" + theme.palette.status.error.main,
    padding: theme.spacing(2),
  },
  cardAboveAverage: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
    alignItems: "left",
    flexDirection: "column",
    borderBottom: "4px solid" + theme.palette.status.success.main,
  },
  date: {
    marginLeft: "auto",
    flexShrink: 0,
  },
  headerWrapper: {
    width: "100%",
    flexGrow: 1,
  },
  headerText: {
    color: theme.palette.text.primary,
    textAlign: "left",
    fontWeight: 600,
    fontSize: "0.9rem",
    justifyConotent: "left",
    alignItems: "left",
    lineHeight: 1,
  },
  iconBottom: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: 24,
    width: 24,
  },
  telemDispCfgContent: {
    display: "flex",
    alignItems: "center",
  },
  customWidth: {
    maxWidth: 180,
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  valueWrapper: {
    paddingLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  valueText: {
    paddingRight: 8,
    fontWeight: 400,
  },
  cardContainer: {
    padding: theme.spacing(2, 3),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: palette.primary.ultraLight,
    },
  },
}));

function StateCard({
  telemDispCfg,
  machine,
  tags,
  onSubmit,
  className,
  ...rest
}) {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const [telemDispCfgValue, setTelemDispCfgValue] = useState();
  const [telemDispCfgStatus, setTelemDispCfgStatus] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const { socket } = useSocketContext();
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    if (telemDispCfg && machine?.serial_number)
      return telemetries.subscribe(
        [machine.serial_number],
        [telemDispCfg.human_key, `${telemDispCfg.human_key}_ad_flag`],
        (_, state) => {
          let value = formatValue(telemDispCfg, state[telemDispCfg.human_key]);
          let status = "normal";
          if (value === undefined || value === null) {
            status = "unreported";
            value = "--";
          }
          setTelemDispCfgValue(value);
          setTelemDispCfgStatus(status);
        },
        socket
      );
  }, [telemDispCfg, machine, socket]);

  const handleMouseEnter = (event) => {
    setIsHovered(true);
  };

  const handleMouseLeave = (event) => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open(
      `/machines/${telemDispCfg.device_id}/analytics/measure/${telemDispCfg.human_key}`,
      "_blank"
    );
  };

  function getIcon(status) {
    if (status === "normal") {
      return (
        <CheckCircleIcon
          htmlColor={palette.status.success.main}
        ></CheckCircleIcon>
      );
    }
    if (status === "unreported") {
      return (
        <Tooltip
          classes={{ tooltip: classes.customWidth }}
          title={t("no_data_message")}
          arrow
        >
          <RemoveCircleIcon htmlColor={palette.gray.light}></RemoveCircleIcon>
        </Tooltip>
      );
    }
    if (status === "abnormal") {
      return (
        <Tooltip
          classes={{ tooltip: classes.customWidth }}
          title={t("anomalous_message")}
          arrow
        >
          <WarningIcon htmlColor={palette.status.warning.main}></WarningIcon>
        </Tooltip>
      );
    }
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box
        className={classes.cardContainer}
        //onClick={handleClick}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={(e) => handleMouseLeave(e)}
      >
        <div className={classes.cardContent}>
          <div className={classes.telemDispCfgContent}>
            {getIcon(telemDispCfgStatus)}
            <div className={classes.valueWrapper}>
              <Typography
                gutterBottom
                className={classes.headerText}
                variant="h5"
              >
                {telemDispCfg.title}
              </Typography>
              <div className={classes.telemDispCfgContent}>
                <Typography variant="body1" className={classes.valueText}>
                  {`${telemDispCfgValue}`}
                </Typography>
                <Typography variant="body1" className={classes.valueText}>
                  {telemDispCfg.units}
                </Typography>
              </div>
            </div>
          </div>
          <span>
            {isHovered && isAdmin() && (
              <EditIcon
                htmlColor={palette.gray.main}
                onClick={(e) => {
                  setOpenEdit(true);
                }}
              />
            )}
            {isHovered && (
              <LaunchIcon
                htmlColor={palette.gray.main}
                onClick={handleClick}
                style={{ marginLeft: "10px" }}
              />
            )}
          </span>
        </div>
      </Box>
      {openEdit && (
        <AddTelemDispCfg
          open={openEdit}
          telemDispCfg={telemDispCfg}
          tags={tags}
          machine={machine}
          onClose={() => setOpenEdit(false)}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
}

StateCard.propTypes = {
  telemDispCfg: PropTypes.object.isRequired,
  machine: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default StateCard;
