import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { ReactElement } from "react";
import palette from "src/theme/palette";

interface ConfirmationDialogProps {
  open: boolean;
  onClose?: Function;
  title: string;
  body: ReactElement;
  onConfirm: Function;
  cancelText: string;
  confirmText: string;
}

export function ConfirmationDialog({
  open,
  onClose,
  title,
  body,
  onConfirm,
  cancelText,
  confirmText,
}: ConfirmationDialogProps) {
  const handleClose = () => onClose && onClose();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {cancelText}
        </Button>
        <Button
          onClick={() => onConfirm()}
          color="secondary"
          autoFocus
          style={{ color: palette.status.error.main }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
