import { Card, CardContent, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "src/components/Page";
import TopSnackbar from "src/components/TopSnackbar";
import { getCustomerFacilities } from "src/repos/facilities";
import Results from "src/views/SunScreens/Lists/GenericList";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
}));

function Facilities({ customerId, tab }) {
  const classes = useStyles();
  const [facilityList, setFacilityList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation(["common", "glossary"]);

  useEffect(() => {
    if (customerId)
      getCustomerFacilities(customerId)
        .then((r) => r.json())
        .then((d) => setFacilityList(d.data))
        .then(() => setIsLoading(false));
  }, [customerId]);

  let history = useHistory();

  const getSnackbarState = () => {
    return !!history.location?.state?.confirmation;
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const [openSnackbar, setOpenSnackbar] = useState(getSnackbarState);
  const onRowClicked = (event, rowData) => {
    history.push(`/customers/${customerId}/facilities/${rowData.id}`, {
      referrer: `/customers/${customerId}/facilities`,
    });
  };
  const onAddNew = (event, value) => {
    history.push("/customers/" + customerId + "/facilities/new");
  };

  return (
    <Page className={classes.root} title="Facilities">
      <Container maxWidth={false} className={classes.container}>
        <Card>
          <CardContent className={classes.content}>
            <Results
              isLoading={isLoading}
              referrer={`/customers/${customerId}/facilities`}
              className={classes.results}
              title={t("glossary:facilities")}
              display_columns={[
                { title: t("name"), field: "name" },
                {
                  title: t("location"),
                  width: 300,
                  field: "city",
                  render: (rowData) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      {rowData.city}, {rowData.state} {rowData.country}
                    </div>
                  ),
                },
              ]}
              list_data={facilityList}
              onRowClicked={onRowClicked}
              onAddNew={onAddNew}
            />
          </CardContent>
          <TopSnackbar
            onClose={handleSnackbarClose}
            open={openSnackbar}
            message={t("form_saved_successfully")}
          />
        </Card>
      </Container>
    </Page>
  );
}

Facilities.propTypes = {
  customerId: PropTypes.string,
};

export default Facilities;
