import { Grid, Paper } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  activeCard: {
    padding: "24px 32px",
    background: theme.palette.gray.white,
    elevation: 8,
    borderBottom: "3px solid",
    borderBottomColor: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
    height: "100%",
  },
  inactiveCard: {
    padding: "24px 32px",
    background: theme.palette.gray.lightest,
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.gray.white,
    },
    height: "100%",
  },
  backgroundIcon: {
    backgroundColor: theme.palette.primary.extraLight,
    color: theme.palette.primary.main,
  },
  addSamplerIcon: {
    color: theme.palette.primary.main,
    marginTop: -3,
    marginRight: 5,
  },
}));

function SamplerCard({ sampler, selected, onClick }) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(1);
  const { t } = useTranslation(["glossary", "common"]);

  const onMouseOver = () => setElevation(3);
  const onMouseOut = () => setElevation(1);

  const isAddSamplerCard = sampler === undefined;

  let networkingConfigured = false;
  let body;

  if (isAddSamplerCard) {
    body = (
      <span>
        <AddCircleOutlineIcon
          fontSize="lg"
          className={classes.addSamplerIcon}
        />
        <b>{t("add_sampler")}</b>
      </span>
    );
  } else {
    networkingConfigured = Boolean(
      !isAddSamplerCard && sampler?.plc_address && sampler?.plc_port
    );
    const text = networkingConfigured
      ? `${sampler.name} (${sampler.spec}) - ${sampler.plc_address}:${sampler.plc_port}`
      : `${sampler.name} (${sampler.spec})`;
    body = (
      <span>
        <b>{text}</b>
      </span>
    );
  }

  return (
    <Grid
      item
      onClick={() => {
        if (onClick) onClick(sampler);
      }}
    >
      <Paper
        className={selected ? classes.activeCard : classes.inactiveCard}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        elevation={elevation}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            {body}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

SamplerCard.propTypes = {
  sampler: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SamplerCard;
