import { get, put, post } from "./base";

export function getCustomer(ID) {
  return get(`customers/${ID}`);
}

export function getCustomers() {
  return get("customers");
}

export function updateCustomer(customer) {
  return put(`customers/${customer.id}`, customer);
}

export function createCustomer(customer) {
  return post(`customers`, customer);
}
