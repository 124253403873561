import { get, makeQuery, REQUIRED } from "./base";

export function getTimeseries(query) {
  const params = makeQuery(query, {
    device_id: REQUIRED,
    // ["<metric>", "<metric>", ...]
    metrics: REQUIRED,
    start_time: REQUIRED,
    end_time: REQUIRED,
    // "second" | "minute" | "hour" | "day" - Typically determined by range of time queried for
    granularity: REQUIRED,
    // undefined | "csv"
    format: undefined,
    datapoints: 300,
  });
  return get(`telemetry_timeseries${params}`);
}
