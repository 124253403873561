import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import Results from "./TriggeredAlarmsList";
import { useTranslation } from "react-i18next";

const classes = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
}));

function TriggeredAlarms({
  className,
  alarm_list,
  isLoading,
  showDeviceNameColumn,
  ...rest
}) {
  const { t } = useTranslation(["glossary", "common"]);
  return (
    <Card {...rest}>
      <CardHeader title={t("recently_triggered_alarms")} />
      <Divider />
      <CardContent>
        <Results
          isLoading={isLoading}
          show_filters={false}
          show_grouping={false}
          show_header_actions={false}
          show_toolbar={false}
          className={classes.results}
          alarm_list={alarm_list}
          num_results={5}
          page_size_options={[]}
          empty_rows_when_paging={true}
          show_actions={false}
          showDeviceNameColumn={showDeviceNameColumn}
          display_columns={[
            {
              title: t("alarm"),
              field: "name",
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}

TriggeredAlarms.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  alarm_list: PropTypes.array,
  showDeviceNameColumn: PropTypes.bool,
};

export default TriggeredAlarms;
