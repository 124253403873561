import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { Gauge } from "src/components/Gauge";
import _ from "lodash";
import { graphPercentage } from "src/utils/gauge";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  gaugeItem: {
    padding: "0 10px 0 10px",
  },
  divider: {
    border: `0.01em solid ${theme.palette.gray.lighter}`,
    height: "59px",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const gaugeAngle = 280;

const MachineGauges = ({ machineData, downtimeData }) => {
  const classes = useStyles();
  const downtime = downtimeData?.prediction
    ? _.round(downtimeData?.prediction * 100, 1)
    : null;

  const gaugeData = useCallback(
    (key) => {
      const uptime = machineData?.[key];
      const percentage = uptime ? Math.abs(_.round(uptime * 100, 0)) : null;
      const [gauge, total] = graphPercentage(percentage, gaugeAngle);
      const label = typeof percentage === "number" ? `${percentage}%` : "--";

      return {
        data: [{ value: gauge }, { value: total }],
        label: label,
        rawValue: percentage,
      };
    },
    [machineData]
  );

  const { t } = useTranslation(["glossary", "common"]);

  const [downtimeValue, downtimeTotal] = graphPercentage(downtime, gaugeAngle);
  const downtimeGauge = [{ value: downtimeValue }, { value: downtimeTotal }];
  const downtimeLabel = typeof downtime === "number" ? `${downtime}%` : "--";
  const downtimeCaption =
    downtimeData?.horizon && typeof downtime === "number"
      ? t("downtime_message", { minutes: downtimeData.horizon })
      : t("chance_of_downtime");

  const uptimeData = useMemo(() => {
    return gaugeData("machine_running");
  }, [gaugeData]);

  const opData = useMemo(() => {
    return gaugeData("operating_productivity");
  }, [gaugeData]);

  return (
    <>
      <div className={classes.gaugeItem}>
        <Gauge
          height={150}
          width={150}
          data={downtimeGauge}
          value={downtimeLabel}
          rawValue={downtime}
          label={downtimeCaption}
          colorRules="downtime"
          variant="small"
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.gaugeItem}>
        <Gauge
          height={150}
          width={150}
          data={opData.data}
          value={opData.label}
          rawValue={opData.rawValue}
          label={t("op")}
          colorRules="op-uptime"
          variant="small"
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.gaugeItem}>
        <Gauge
          height={150}
          width={150}
          data={uptimeData.data}
          value={uptimeData.label}
          rawValue={uptimeData.rawValue}
          label={t("uptime")}
          colorRules="op-uptime"
          variant="small"
        />
      </div>
    </>
  );
};

export default MachineGauges;
