import mock from "src/utils/mock";

mock.onGet("/api/customer/list").reply(200, {
  customers: [],
});
mock.onGet("/api/customer/list/sample").reply(200, {
  customers: [
    {
      id: 7389,
      name: "Schaefer LLC",
      point_of_contact: "Auberta Sockell",
      phone: "920-203-6683",
      city: "Green Bay",
      state: "WI",
      country: "USA",
      num_machines: 15,
      created_date: "2020-01-03T17:40:35Z",
    },
    {
      id: 9515,
      name: "Schneider LLC",
      point_of_contact: "Merridie Verrell",
      phone: "334-434-7255",
      city: "Montgomery",
      state: "AL",
      country: "USA",
      num_machines: 51,
      created_date: "2019-12-11T10:50:54Z",
    },
    {
      id: 9927,
      name: "Bradtke Inc",
      point_of_contact: "Coralie Ros",
      phone: "214-173-1214",
      city: "Dallas",
      state: "TX",
      country: "USA",
      num_machines: 83,
      created_date: "2019-07-14T14:59:18Z",
    },
    {
      id: 6801,
      name: "Schaefer LLC",
      point_of_contact: "Bone Bowdidge",
      phone: "614-974-8590",
      city: "Columbus",
      state: "OH",
      country: "USA",
      num_machines: 93,
      created_date: "2019-08-19T03:04:18Z",
    },
    {
      id: 9708,
      name: "Rodriguez Inc",
      point_of_contact: "Bambie M'Chirrie",
      phone: "602-559-4416",
      city: "Phoenix",
      state: "AZ",
      country: "USA",
      num_machines: 30,
      created_date: "2020-03-03T05:08:11Z",
    },
    {
      id: 6904,
      name: "Dibbert-Ullrich",
      point_of_contact: "Auroora Warke",
      phone: "432-664-0412",
      city: "Midland",
      state: "TX",
      country: "USA",
      num_machines: 32,
      created_date: "2019-05-19T18:49:41Z",
    },
    {
      id: 7423,
      name: "Prohaska-Ferry",
      point_of_contact: "Cele Eustanch",
      phone: "404-113-4498",
      city: "Atlanta",
      state: "GA",
      country: "USA",
      num_machines: 58,
      created_date: "2020-01-03T14:02:23Z",
    },
    {
      id: 1654,
      name: "Hackett-Heidenreich",
      point_of_contact: "Sancho Giacomello",
      phone: "414-502-3460",
      city: "Milwaukee",
      state: "WI",
      country: "USA",
      num_machines: 45,
      created_date: "2019-03-05T19:11:27Z",
    },
    {
      id: 3313,
      name: "Keebler, Jacobson and Ondricka",
      point_of_contact: "Ailsun Yakovitch",
      phone: "916-468-9105",
      city: "Sacramento",
      state: "CA",
      country: "USA",
      num_machines: 13,
      created_date: "2019-06-11T18:38:33Z",
    },
    {
      id: 5163,
      name: "Zieme-Lind",
      point_of_contact: "Fleming Mahady",
      phone: "212-420-8815",
      city: "New York City",
      state: "NY",
      country: "USA",
      num_machines: 81,
      created_date: "2019-06-09T20:07:49Z",
    },
    {
      id: 4800,
      name: "Stroman-Hackett",
      point_of_contact: "Aubrey Hawkeswood",
      phone: "212-123-0131",
      city: "New York City",
      state: "NY",
      country: "USA",
      num_machines: 89,
      created_date: "2019-05-07T19:15:45Z",
    },
    {
      id: 9598,
      name: "Nolan-Herman",
      point_of_contact: "Cordie Polino",
      phone: "860-239-7325",
      city: "Hartford",
      state: "CT",
      country: "USA",
      num_machines: 54,
      created_date: "2019-03-25T04:04:02Z",
    },
    {
      id: 4925,
      name: "Satterfield-Kuphal",
      point_of_contact: "Trisha Ash",
      phone: "415-939-3598",
      city: "San Francisco",
      state: "CA",
      country: "USA",
      num_machines: 51,
      created_date: "2019-10-28T19:01:09Z",
    },
    {
      id: 6303,
      name: "Streich Inc",
      point_of_contact: "Lillis Franks",
      phone: "404-591-1866",
      city: "Atlanta",
      state: "GA",
      country: "USA",
      num_machines: 55,
      created_date: "2019-12-04T00:15:28Z",
    },
    {
      id: 5954,
      name: "Eichmann-Mosciski",
      point_of_contact: "Shoshana Chiechio",
      phone: "405-199-1423",
      city: "Oklahoma City",
      state: "OK",
      country: "USA",
      num_machines: 66,
      created_date: "2020-01-18T05:44:28Z",
    },
    {
      id: 8757,
      name: "Stoltenberg-Okuneva",
      point_of_contact: "Rachelle Earley",
      phone: "785-337-2247",
      city: "Topeka",
      state: "KS",
      country: "USA",
      num_machines: 31,
      created_date: "2019-07-14T09:02:12Z",
    },
    {
      id: 3726,
      name: "Botsford, Streich and O'Conner",
      point_of_contact: "Friederike Gabotti",
      phone: "540-311-8491",
      city: "Roanoke",
      state: "VA",
      country: "USA",
      num_machines: 10,
      created_date: "2019-06-20T02:34:16Z",
    },
    {
      id: 7527,
      name: "Ferry-Hudson",
      point_of_contact: "Allianora Riseam",
      phone: "212-209-3121",
      city: "New York City",
      state: "NY",
      country: "USA",
      num_machines: 3,
      created_date: "2019-03-09T19:09:56Z",
    },
    {
      id: 9668,
      name: "Abbott, Champlin and Jones",
      point_of_contact: "Jami Aery",
      phone: "704-941-0734",
      city: "Charlotte",
      state: "NC",
      country: "USA",
      num_machines: 7,
      created_date: "2019-06-30T15:25:19Z",
    },
    {
      id: 3695,
      name: "Jaskolski-Zemlak",
      point_of_contact: "Uri De Minico",
      phone: "816-123-5228",
      city: "Kansas City",
      state: "MO",
      country: "USA",
      num_machines: 60,
      created_date: "2019-03-19T07:13:45Z",
    },
    {
      id: 5103,
      name: "Stiedemann, Heller and Harber",
      point_of_contact: "Micky Runacres",
      phone: "208-501-1333",
      city: "Boise",
      state: "ID",
      country: "USA",
      num_machines: 100,
      created_date: "2019-03-29T18:45:38Z",
    },
    {
      id: 6420,
      name: "McKenzie LLC",
      point_of_contact: "Cathleen Bruntjen",
      phone: "316-710-8514",
      city: "Wichita",
      state: "KS",
      country: "USA",
      num_machines: 2,
      created_date: "2020-02-24T06:28:08Z",
    },
    {
      id: 1077,
      name: "Watsica LLC",
      point_of_contact: "Steward Feldon",
      phone: "817-554-8137",
      city: "Fort Worth",
      state: "TX",
      country: "USA",
      num_machines: 24,
      created_date: "2019-06-04T21:36:34Z",
    },
    {
      id: 4236,
      name: "Steuber-Purdy",
      point_of_contact: "Emery Pashan",
      phone: "571-803-6017",
      city: "Ashburn",
      state: "VA",
      country: "USA",
      num_machines: 52,
      created_date: "2019-06-16T00:19:43Z",
    },
    {
      id: 4583,
      name: "Kiehn-Sanford",
      point_of_contact: "Norri Meier",
      phone: "918-365-5579",
      city: "Tulsa",
      state: "OK",
      country: "USA",
      num_machines: 65,
      created_date: "2019-05-16T01:29:21Z",
    },
    {
      id: 9897,
      name: "Fahey, Gerlach and Murray",
      point_of_contact: "Gaby Kerswill",
      phone: "806-435-2842",
      city: "Amarillo",
      state: "TX",
      country: "USA",
      num_machines: 62,
      created_date: "2019-10-01T09:50:55Z",
    },
    {
      id: 9226,
      name: "Leffler Group",
      point_of_contact: "Valentine Dutnell",
      phone: "765-162-6193",
      city: "Anderson",
      state: "IN",
      country: "USA",
      num_machines: 82,
      created_date: "2019-07-10T10:01:58Z",
    },
    {
      id: 6466,
      name: "Bailey, Collier and Muller",
      point_of_contact: "Mattie Eiler",
      phone: "903-713-6175",
      city: "Longview",
      state: "TX",
      country: "USA",
      num_machines: 17,
      created_date: "2019-12-08T06:55:07Z",
    },
    {
      id: 7624,
      name: "Heller, Bogan and Kuvalis",
      point_of_contact: "Lester Karlowicz",
      phone: "225-760-7848",
      city: "Baton Rouge",
      state: "LA",
      country: "USA",
      num_machines: 9,
      created_date: "2019-06-19T01:26:14Z",
    },
    {
      id: 9266,
      name: "Gusikowski Inc",
      point_of_contact: "Florri Sackes",
      phone: "915-312-2486",
      city: "El Paso",
      state: "TX",
      country: "USA",
      num_machines: 19,
      created_date: "2019-07-18T06:52:03Z",
    },
    {
      id: 1461,
      name: "Dooley LLC",
      point_of_contact: "Elmira Yitzhakof",
      phone: "804-556-3128",
      city: "Richmond",
      state: "VA",
      country: "USA",
      num_machines: 91,
      created_date: "2019-09-20T23:16:42Z",
    },
    {
      id: 4084,
      name: "Hayes-Ledner",
      point_of_contact: "Nahum Levington",
      phone: "573-936-0793",
      city: "Jefferson City",
      state: "MO",
      country: "USA",
      num_machines: 67,
      created_date: "2020-02-17T16:26:47Z",
    },
    {
      id: 8127,
      name: "Schowalter and Sons",
      point_of_contact: "Konstanze Walbridge",
      phone: "614-276-3032",
      city: "Columbus",
      state: "OH",
      country: "USA",
      num_machines: 54,
      created_date: "2019-05-03T04:15:47Z",
    },
    {
      id: 6415,
      name: "Kessler-Gaylord",
      point_of_contact: "Thedrick Fairrie",
      phone: "310-557-2900",
      city: "Palo Alto",
      state: "CA",
      country: "USA",
      num_machines: 52,
      created_date: "2019-03-05T08:02:23Z",
    },
    {
      id: 5530,
      name: "Hudson and Sons",
      point_of_contact: "Ced Shipsey",
      phone: "512-580-0159",
      city: "Austin",
      state: "TX",
      country: "USA",
      num_machines: 83,
      created_date: "2019-09-29T23:56:32Z",
    },
    {
      id: 7448,
      name: "Schoen Group",
      point_of_contact: "Hayyim Chezelle",
      phone: "818-550-6850",
      city: "North Hollywood",
      state: "CA",
      country: "USA",
      num_machines: 100,
      created_date: "2020-01-09T18:08:52Z",
    },
    {
      id: 4962,
      name: "Bode, Mueller and Waelchi",
      point_of_contact: "Urson Tregaskis",
      phone: "757-818-6481",
      city: "Norfolk",
      state: "VA",
      country: "USA",
      num_machines: 46,
      created_date: "2020-01-29T00:22:01Z",
    },
    {
      id: 6159,
      name: "Labadie, Schimmel and Langosh",
      point_of_contact: "Dalila Babin",
      phone: "718-779-4322",
      city: "New York City",
      state: "NY",
      country: "USA",
      num_machines: 22,
      created_date: "2019-04-22T06:53:43Z",
    },
    {
      id: 3157,
      name: "O'Keefe-Kertzmann",
      point_of_contact: "Genni Kinnaird",
      phone: "347-274-6466",
      city: "New York City",
      state: "NY",
      country: "USA",
      num_machines: 31,
      created_date: "2019-06-19T13:11:17Z",
    },
    {
      id: 3111,
      name: "Leffler LLC",
      point_of_contact: "Lee Torfin",
      phone: "501-881-2058",
      city: "Little Rock",
      state: "AR",
      country: "USA",
      num_machines: 28,
      created_date: "2019-07-06T23:20:39Z",
    },
    {
      id: 3483,
      name: "Windler-Mayert",
      point_of_contact: "Brade Hutchence",
      phone: "706-784-6897",
      city: "Cumming",
      state: "GA",
      country: "USA",
      num_machines: 88,
      created_date: "2020-02-10T20:36:28Z",
    },
    {
      id: 5768,
      name: "Hirthe-Lang",
      point_of_contact: "Truman Mehew",
      phone: "216-590-1236",
      city: "Cleveland",
      state: "OH",
      country: "USA",
      num_machines: 86,
      created_date: "2019-06-14T17:49:21Z",
    },
    {
      id: 7679,
      name: "Welch-Crist",
      point_of_contact: "Osbert Markus",
      phone: "404-498-5177",
      city: "Atlanta",
      state: "GA",
      country: "USA",
      num_machines: 91,
      created_date: "2019-07-16T12:24:37Z",
    },
    {
      id: 2163,
      name: "Moore, Rutherford and Brown",
      point_of_contact: "Mignonne Lerhinan",
      phone: "415-239-8935",
      city: "San Francisco",
      state: "CA",
      country: "USA",
      num_machines: 18,
      created_date: "2019-03-31T16:28:58Z",
    },
    {
      id: 5796,
      name: "Hintz, Cummerata and Reinger",
      point_of_contact: "Maryl Vaszoly",
      phone: "251-975-8760",
      city: "Mobile",
      state: "AL",
      country: "USA",
      num_machines: 99,
      created_date: "2019-09-29T13:37:24Z",
    },
    {
      id: 2323,
      name: "Bruen and Sons",
      point_of_contact: "Carlen Colquit",
      phone: "850-975-2998",
      city: "Pensacola",
      state: "FL",
      country: "USA",
      num_machines: 43,
      created_date: "2019-09-27T01:03:35Z",
    },
    {
      id: 2837,
      name: "Bode-Franecki",
      point_of_contact: "Haleigh Dawid",
      phone: "304-986-7565",
      city: "Charleston",
      state: "WV",
      country: "USA",
      num_machines: 67,
      created_date: "2019-09-22T23:52:23Z",
    },
    {
      id: 3098,
      name: "Heaney LLC",
      point_of_contact: "Sidonia Raulstone",
      phone: "571-339-4566",
      city: "Merrifield",
      state: "VA",
      country: "USA",
      num_machines: 24,
      created_date: "2019-07-16T05:16:05Z",
    },
    {
      id: 7733,
      name: "Emard Group",
      point_of_contact: "Abra Pidgen",
      phone: "520-905-5741",
      city: "Tucson",
      state: "AZ",
      country: "USA",
      num_machines: 28,
      created_date: "2019-04-30T18:06:00Z",
    },
    {
      id: 2048,
      name: "Bernhard-Kihn",
      point_of_contact: "Donni Playhill",
      phone: "718-860-7952",
      city: "New York City",
      state: "NY",
      country: "USA",
      num_machines: 84,
      created_date: "2019-12-01T17:50:43Z",
    },
    {
      id: 4430,
      name: "Howe, Rath and Ernser",
      point_of_contact: "Thatch Stuchbury",
      phone: "251-197-4302",
      city: "Mobile",
      state: "AL",
      country: "USA",
      num_machines: 72,
      created_date: "2020-02-13T07:29:05Z",
    },
    {
      id: 2921,
      name: "Graham Inc",
      point_of_contact: "Pavla Rentcome",
      phone: "217-140-3698",
      city: "Springfield",
      state: "IL",
      country: "USA",
      num_machines: 88,
      created_date: "2019-10-13T22:13:59Z",
    },
    {
      id: 4363,
      name: "Schoen-Walter",
      point_of_contact: "Zollie Moylane",
      phone: "205-323-9903",
      city: "Birmingham",
      state: "AL",
      country: "USA",
      num_machines: 59,
      created_date: "2019-12-21T22:38:48Z",
    },
    {
      id: 2117,
      name: "Schneider-Gibson",
      point_of_contact: "Sergent Kobiera",
      phone: "215-650-7947",
      city: "Philadelphia",
      state: "PA",
      country: "USA",
      num_machines: 48,
      created_date: "2019-08-05T09:15:19Z",
    },
    {
      id: 2334,
      name: "Hickle and Sons",
      point_of_contact: "Carmela Kelly",
      phone: "601-167-8889",
      city: "Meridian",
      state: "MS",
      country: "USA",
      num_machines: 10,
      created_date: "2019-10-10T19:56:24Z",
    },
    {
      id: 9438,
      name: "Kirlin Group",
      point_of_contact: "Keir Philippart",
      phone: "508-694-1687",
      city: "New Bedford",
      state: "MA",
      country: "USA",
      num_machines: 34,
      created_date: "2019-04-07T05:32:20Z",
    },
    {
      id: 5488,
      name: "Kuphal, Schoen and Brekke",
      point_of_contact: "Nancee Rebert",
      phone: "904-805-8596",
      city: "Jacksonville",
      state: "FL",
      country: "USA",
      num_machines: 60,
      created_date: "2019-03-13T06:18:02Z",
    },
    {
      id: 3754,
      name: "Heller, Kassulke and Tillman",
      point_of_contact: "Jobi McGee",
      phone: "706-781-2301",
      city: "Columbus",
      state: "GA",
      country: "USA",
      num_machines: 87,
      created_date: "2019-03-10T06:43:15Z",
    },
    {
      id: 1843,
      name: "McClure, Keebler and Hermann",
      point_of_contact: "Olivie Allberry",
      phone: "301-754-4864",
      city: "Bethesda",
      state: "MD",
      country: "USA",
      num_machines: 98,
      created_date: "2019-04-28T02:15:53Z",
    },
    {
      id: 1507,
      name: "Kuphal, Jacobson and Thompson",
      point_of_contact: "Sibella Schlagtmans",
      phone: "915-252-5304",
      city: "El Paso",
      state: "TX",
      country: "USA",
      num_machines: 22,
      created_date: "2019-12-25T18:56:12Z",
    },
    {
      id: 7614,
      name: "Kuhn Group",
      point_of_contact: "Emanuele Schubuser",
      phone: "907-998-4717",
      city: "Anchorage",
      state: "AK",
      country: "USA",
      num_machines: 55,
      created_date: "2019-11-21T05:16:26Z",
    },
    {
      id: 8590,
      name: "Purdy, Emmerich and Mayer",
      point_of_contact: "Sibby Brinson",
      phone: "865-981-7077",
      city: "Knoxville",
      state: "TN",
      country: "USA",
      num_machines: 8,
      created_date: "2019-05-30T08:17:40Z",
    },
    {
      id: 4517,
      name: "Terry Inc",
      point_of_contact: "Mace Enticott",
      phone: "510-117-8752",
      city: "Oakland",
      state: "CA",
      country: "USA",
      num_machines: 1,
      created_date: "2019-10-15T23:56:09Z",
    },
    {
      id: 9827,
      name: "Schultz, Smitham and Bogan",
      point_of_contact: "Constantine Leveritt",
      phone: "504-831-0533",
      city: "New Orleans",
      state: "LA",
      country: "USA",
      num_machines: 8,
      created_date: "2019-09-19T22:26:50Z",
    },
    {
      id: 2199,
      name: "Thompson-Lebsack",
      point_of_contact: "Rodie Moral",
      phone: "412-431-0966",
      city: "Pittsburgh",
      state: "PA",
      country: "USA",
      num_machines: 68,
      created_date: "2020-01-09T06:04:07Z",
    },
    {
      id: 6032,
      name: "Kutch, Bruen and Stanton",
      point_of_contact: "Ashil Deakes",
      phone: "510-643-6713",
      city: "Berkeley",
      state: "CA",
      country: "USA",
      num_machines: 100,
      created_date: "2019-07-17T02:37:19Z",
    },
    {
      id: 4722,
      name: "Crist LLC",
      point_of_contact: "Arlie Nisby",
      phone: "253-240-5965",
      city: "Tacoma",
      state: "WA",
      country: "USA",
      num_machines: 85,
      created_date: "2019-08-15T20:34:22Z",
    },
    {
      id: 9301,
      name: "Bergstrom, Larkin and Doyle",
      point_of_contact: "Derrik Dundridge",
      phone: "916-338-0470",
      city: "Sacramento",
      state: "CA",
      country: "USA",
      num_machines: 14,
      created_date: "2019-09-18T15:32:24Z",
    },
    {
      id: 5103,
      name: "Heaney LLC",
      point_of_contact: "Robinetta Dmitrichenko",
      phone: "202-102-4028",
      city: "Washington",
      state: "DC",
      country: "USA",
      num_machines: 43,
      created_date: "2019-07-20T10:33:45Z",
    },
    {
      id: 8033,
      name: "Olson Group",
      point_of_contact: "Andy Rosenbaum",
      phone: "323-499-8459",
      city: "Los Angeles",
      state: "CA",
      country: "USA",
      num_machines: 40,
      created_date: "2019-10-14T19:00:57Z",
    },
    {
      id: 1769,
      name: "Wiegand-Balistreri",
      point_of_contact: "Dennie Grimsdale",
      phone: "858-555-8993",
      city: "San Diego",
      state: "CA",
      country: "USA",
      num_machines: 84,
      created_date: "2019-08-23T14:20:17Z",
    },
    {
      id: 4999,
      name: "Friesen, Murray and Harris",
      point_of_contact: "Violetta Titheridge",
      phone: "202-803-3538",
      city: "Washington",
      state: "DC",
      country: "USA",
      num_machines: 15,
      created_date: "2020-02-10T22:13:05Z",
    },
    {
      id: 2629,
      name: "Koch, Morissette and Leuschke",
      point_of_contact: "Glenna Bowne",
      phone: "615-189-1459",
      city: "Nashville",
      state: "TN",
      country: "USA",
      num_machines: 27,
      created_date: "2019-08-04T10:07:26Z",
    },
    {
      id: 4246,
      name: "Jaskolski-Dietrich",
      point_of_contact: "Lorie McRuvie",
      phone: "202-371-7772",
      city: "Washington",
      state: "DC",
      country: "USA",
      num_machines: 15,
      created_date: "2019-08-18T19:29:21Z",
    },
    {
      id: 8593,
      name: "Bauch-Haag",
      point_of_contact: "Adler Cavendish",
      phone: "617-278-0256",
      city: "Boston",
      state: "MA",
      country: "USA",
      num_machines: 4,
      created_date: "2019-06-05T12:54:23Z",
    },
    {
      id: 5617,
      name: "Hills-Beer",
      point_of_contact: "Petronella McIntee",
      phone: "202-265-6463",
      city: "Washington",
      state: "DC",
      country: "USA",
      num_machines: 88,
      created_date: "2019-03-23T08:50:16Z",
    },
    {
      id: 4981,
      name: "Greenfelder-Hahn",
      point_of_contact: "Lincoln Robley",
      phone: "801-896-0016",
      city: "Salt Lake City",
      state: "UT",
      country: "USA",
      num_machines: 97,
      created_date: "2019-03-10T06:08:03Z",
    },
    {
      id: 3169,
      name: "Kirlin, Kuhn and Hahn",
      point_of_contact: "Philip Clamo",
      phone: "253-619-4765",
      city: "Tacoma",
      state: "WA",
      country: "USA",
      num_machines: 64,
      created_date: "2020-02-02T21:42:06Z",
    },
    {
      id: 5497,
      name: "Heathcote-Hettinger",
      point_of_contact: "Fanny Martindale",
      phone: "719-882-1106",
      city: "Pueblo",
      state: "CO",
      country: "USA",
      num_machines: 44,
      created_date: "2019-11-17T03:50:33Z",
    },
    {
      id: 8562,
      name: "MacGyver Inc",
      point_of_contact: "Krystal Millott",
      phone: "212-617-6921",
      city: "Brooklyn",
      state: "NY",
      country: "USA",
      num_machines: 68,
      created_date: "2019-04-25T02:01:39Z",
    },
    {
      id: 9226,
      name: "Gibson Group",
      point_of_contact: "Martynne Vreede",
      phone: "512-446-2044",
      city: "Austin",
      state: "TX",
      country: "USA",
      num_machines: 40,
      created_date: "2019-12-31T04:02:28Z",
    },
    {
      id: 9073,
      name: "O'Hara, Stokes and Volkman",
      point_of_contact: "Teddi Tapner",
      phone: "336-755-0984",
      city: "Greensboro",
      state: "NC",
      country: "USA",
      num_machines: 37,
      created_date: "2019-05-22T17:49:05Z",
    },
    {
      id: 2652,
      name: "Vandervort LLC",
      point_of_contact: "Alford Nannini",
      phone: "719-900-3063",
      city: "Colorado Springs",
      state: "CO",
      country: "USA",
      num_machines: 44,
      created_date: "2019-12-21T06:17:40Z",
    },
    {
      id: 3322,
      name: "Koch Group",
      point_of_contact: "Melly Challenor",
      phone: "323-359-8136",
      city: "Van Nuys",
      state: "CA",
      country: "USA",
      num_machines: 93,
      created_date: "2019-12-05T10:50:39Z",
    },
    {
      id: 3747,
      name: "Fadel-Kiehn",
      point_of_contact: "Jourdan Keinrat",
      phone: "615-663-6697",
      city: "Nashville",
      state: "TN",
      country: "USA",
      num_machines: 58,
      created_date: "2019-12-25T16:39:07Z",
    },
    {
      id: 1317,
      name: "Schaefer, Keebler and Gleichner",
      point_of_contact: "Issie McQuode",
      phone: "713-722-7624",
      city: "Houston",
      state: "TX",
      country: "USA",
      num_machines: 49,
      created_date: "2019-03-05T21:24:12Z",
    },
    {
      id: 7151,
      name: "Nitzsche-Fisher",
      point_of_contact: "Petunia Anster",
      phone: "860-620-5553",
      city: "Hartford",
      state: "CT",
      country: "USA",
      num_machines: 1,
      created_date: "2020-02-10T03:12:53Z",
    },
    {
      id: 5152,
      name: "Tromp, Hand and Harris",
      point_of_contact: "Chuck Pharo",
      phone: "414-524-9931",
      city: "Milwaukee",
      state: "WI",
      country: "USA",
      num_machines: 83,
      created_date: "2019-10-01T15:39:12Z",
    },
    {
      id: 3610,
      name: "MacGyver, Kassulke and Collier",
      point_of_contact: "Angie Hedan",
      phone: "917-711-3153",
      city: "Jamaica",
      state: "NY",
      country: "USA",
      num_machines: 25,
      created_date: "2019-07-21T02:49:18Z",
    },
    {
      id: 7355,
      name: "Senger-Nolan",
      point_of_contact: "Fancie McNish",
      phone: "412-135-3493",
      city: "Pittsburgh",
      state: "PA",
      country: "USA",
      num_machines: 39,
      created_date: "2019-07-14T11:07:14Z",
    },
    {
      id: 9639,
      name: "D'Amore-Aufderhar",
      point_of_contact: "Gwenette Schult",
      phone: "480-390-8085",
      city: "Mesa",
      state: "AZ",
      country: "USA",
      num_machines: 63,
      created_date: "2019-06-10T20:08:49Z",
    },
    {
      id: 1124,
      name: "Rosenbaum-Hickle",
      point_of_contact: "Ringo Brixey",
      phone: "212-773-0624",
      city: "New York City",
      state: "NY",
      country: "USA",
      num_machines: 79,
      created_date: "2019-10-19T20:02:10Z",
    },
    {
      id: 9693,
      name: "Rolfson, Altenwerth and McClure",
      point_of_contact: "Brody Castleton",
      phone: "202-856-5912",
      city: "Washington",
      state: "DC",
      country: "USA",
      num_machines: 8,
      created_date: "2019-07-18T20:31:47Z",
    },
    {
      id: 9878,
      name: "Jakubowski Group",
      point_of_contact: "Marcellina Folder",
      phone: "515-557-3054",
      city: "Des Moines",
      state: "IA",
      country: "USA",
      num_machines: 39,
      created_date: "2020-01-09T06:11:50Z",
    },
    {
      id: 4400,
      name: "Powlowski Inc",
      point_of_contact: "Brendin Lorant",
      phone: "205-993-0785",
      city: "Birmingham",
      state: "AL",
      country: "USA",
      num_machines: 2,
      created_date: "2019-08-09T08:40:33Z",
    },
    {
      id: 5265,
      name: "Ondricka-Hettinger",
      point_of_contact: "Jocko Cheasman",
      phone: "408-557-2461",
      city: "San Jose",
      state: "CA",
      country: "USA",
      num_machines: 65,
      created_date: "2019-12-15T11:48:51Z",
    },
    {
      id: 1446,
      name: "Fadel, Cronin and Price",
      point_of_contact: "Mycah Guyonneau",
      phone: "214-141-3765",
      city: "Dallas",
      state: "TX",
      country: "USA",
      num_machines: 71,
      created_date: "2019-11-17T19:32:44Z",
    },
    {
      id: 9732,
      name: "Jaskolski-Flatley",
      point_of_contact: "Rurik Bussy",
      phone: "845-595-1501",
      city: "White Plains",
      state: "NY",
      country: "USA",
      num_machines: 78,
      created_date: "2019-07-18T00:53:05Z",
    },
    {
      id: 8923,
      name: "Block and Sons",
      point_of_contact: "Katine Struan",
      phone: "763-998-4894",
      city: "Monticello",
      state: "MN",
      country: "USA",
      num_machines: 93,
      created_date: "2019-07-03T19:36:03Z",
    },
    {
      id: 6551,
      name: "Zulauf-Hirthe",
      point_of_contact: "Phyllys Houtbie",
      phone: "440-957-2861",
      city: "Cleveland",
      state: "OH",
      country: "USA",
      num_machines: 29,
      created_date: "2019-10-16T21:56:07Z",
    },
  ],
});
