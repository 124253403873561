import React from "react";
import { Typography, Theme } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  flexCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    flexGrow: 1,
    paddingBottom: 0,
    paddingTop: 0,
  },
  flexRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexGrow: 1,
    paddingBottom: 20,
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  toggleButton: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12) !important",
  },
}));

type DateToggleButtonGroupProps = {
  dateRange: string;
  handleDateRange: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  orientation?: "horizontal" | "vertical";
};

const DateToggleButtonGroup = ({
  dateRange,
  handleDateRange,
  orientation = "vertical",
}: DateToggleButtonGroupProps) => {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <ToggleButtonGroup
      exclusive
      color="secondary"
      value={dateRange}
      onChange={handleDateRange}
      orientation={orientation}
    >
      <ToggleButton value="4hr" aria-label="bold">
        <Typography>{t("4_hrs")}</Typography>
      </ToggleButton>
      <ToggleButton
        value="day"
        aria-label="bold"
        className={classes.toggleButton}
      >
        <Typography>{t("24_hrs")}</Typography>
      </ToggleButton>
      <ToggleButton
        value="week"
        aria-label="bold"
        className={classes.toggleButton}
      >
        <Typography>{t("1_week")}</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default DateToggleButtonGroup;
