import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createDeviceReplacement, getDevices } from "src/repos/devices";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function ReplacementForm({ machine: device, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const [machines, setMachines] = useState([]);
  const [replacementDevice, setReplacementDevice] = useState({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { t } = useTranslation(["glossary", "common"]);
  const deviceNameOrSerial =
    replacementDevice.name || replacementDevice.serial_number;

  useEffect(() => {
    getDevices()
      .then((r) => r.json())
      .then((d) => setMachines(d.data));
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setConfirmModalOpen(true);
        }}
      >
        <CardHeader title={t("replace_a_machine")} />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Autocomplete
                variant="outlined"
                labelid="carrier-label"
                options={machines}
                value={replacementDevice}
                onChange={(event, newValue) => {
                  setReplacementDevice(newValue);
                }}
                getOptionLabel={(opt) => opt.name || opt.serial_number}
                getOptionDisabled={(opt) =>
                  opt.serial_number === device.serial_number
                }
                autoComplete={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      t("select_a_machine_to_replace") +
                      " " +
                      (device.serial_number
                        ? t("with") + " " + device.serial_number
                        : "")
                    }
                    variant="outlined"
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                disabled={!replacementDevice.serial_number}
                color="secondary"
              >
                {t("replace_machine")}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>

      <Dialog
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText>
            {t("replacement_message_1", {
              device: deviceNameOrSerial,
              serialNumber: replacementDevice.serial_number,
            })}
          </DialogContentText>
          <DialogContentText>
            {t("replacement_message_2", { device: deviceNameOrSerial })}
            {replacementDevice.name || replacementDevice.serial_number}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmModalOpen(false)} color="secondary">
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              createDeviceReplacement(device, replacementDevice)
                .then((r) => r.json())
                .then(() => {
                  setConfirmModalOpen(false);
                  history.push(`/machines`);
                });
            }}
            color="primary"
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default ReplacementForm;
