import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number-input";
import PropTypes from "prop-types";
import { default as React, useState } from "react";
import { useHistory } from "react-router-dom";
import TopSnackbar from "src/components/TopSnackbar";
import { createCustomer, updateCustomer } from "src/repos/customers";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(3) },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: 0,
  },
  keywords: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(2),
  },
  chips: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: 0,
  },
  selects: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    backgroundColor: colors.grey[50],
    padding: theme.spacing(1),
  },
  tagLabel: {
    marginLeft: "10px",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "15px",
  },
  formControl: {
    width: "100%",
  },
}));

function CustomerDetails({ customer, setCustomer, disableForm, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [errors, setErrors] = useState([]);
  const [values, setValues] = useState({
    name: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    external_id: "",
    city: "",
    state: "",
    country: "",
    logo: "",
  });
  const { t } = useTranslation(["glossary", "common"]);

  if (customer && customer.id !== values.id) {
    setValues(customer);
  }

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const humanize = (str) => {
    return _.capitalize(
      _.trim(_.snakeCase(str).replace(/_id$/, "").replace(/_/g, " "))
    );
  };

  const errorMessages = errors
    ? Object.keys(errors).map((key) =>
        errors[key].map((errorMessage, messageIndex) => (
          <li key={`${key}${messageIndex}`}>{`${humanize(
            key
          )} ${errorMessage}`}</li>
        ))
      )
    : null;

  const errorDisplay = (
    <>
      {errors && (
        <Typography variant="body2" className={classes.error}>
          {errorMessages}
        </Typography>
      )}
    </>
  );

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    if (!["+", "+1"].includes(value)) {
      setValues({
        ...values,
        contact_phone: value,
      });
    } else {
      setValues({
        ...values,
        contact_phone: "",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    (customer?.id
      ? updateCustomer({
          id: customer.id,
          ...values,
        })
      : createCustomer(values)
    )
      .then((r) => r.json())
      .then((d) => {
        if (d.data && !d.errors) {
          setCustomer(d.data);
          setOpenSnackbar();
          history.push("/customers/");
        } else {
          setErrors(d.errors);
        }
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Card {...rest} className={clsx(classes.root)}>
      <form onSubmit={handleSubmit}>
        <CardHeader title={t("customer_details")} />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("customer_name")}
                name="name"
                onChange={handleChange}
                disabled={disableForm}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("point_of_contact")}
                name="contact_name"
                disabled={disableForm}
                onChange={handleChange}
                required
                value={values.contact_name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("point_of_contact_email")}
                name="contact_email"
                disabled={disableForm}
                onChange={handleChange}
                required
                value={values.contact_email}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPhoneNumber
                color="primary"
                defaultCountry={"us"}
                disabled={disableForm}
                fullWidth
                label={t("point_of_contact_phone")}
                name="contact_phone"
                onChange={handlePhoneChange}
                onlyCountries={["us"]}
                required
                value={values.contact_phone}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("external_id")}
                name="external_id"
                disabled={disableForm}
                onChange={handleChange}
                value={values.external_id}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                helperText={t("please_enter_the_city")}
                label={t("city")}
                name="city"
                disabled={disableForm}
                onChange={handleChange}
                required
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="state-label">{t("state_province")}</InputLabel>
                <Select
                  color="primary"
                  variant="outlined"
                  labelId="state-label"
                  id="state"
                  disabled={disableForm}
                  value={values.state}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  name="state"
                >
                  <MenuItem value="AL">{t("glossary:states:alabama")}</MenuItem>
                  <MenuItem value="AK">{t("glossary:states:alaska")}</MenuItem>
                  <MenuItem value="AZ">{t("glossary:states:arizona")}</MenuItem>
                  <MenuItem value="AR">
                    {t("glossary:states:arkansas")}
                  </MenuItem>
                  <MenuItem value="CA">
                    {t("glossary:states:california")}
                  </MenuItem>
                  <MenuItem value="CO">
                    {t("glossary:states:colorado")}
                  </MenuItem>
                  <MenuItem value="CT">
                    {t("glossary:states:connecticut")}
                  </MenuItem>
                  <MenuItem value="DE">
                    {t("glossary:states:delaware")}
                  </MenuItem>
                  <MenuItem value="DC">
                    {t("glossary:states:district of columbia")}
                  </MenuItem>
                  <MenuItem value="FL">{t("glossary:states:florida")}</MenuItem>
                  <MenuItem value="GA">{t("glossary:states:georgia")}</MenuItem>
                  <MenuItem value="HI">{t("glossary:states:hawaii")}</MenuItem>
                  <MenuItem value="ID">{t("glossary:states:idaho")}</MenuItem>
                  <MenuItem value="IL">
                    {t("glossary:states:illinois")}
                  </MenuItem>
                  <MenuItem value="IN">{t("glossary:states:indiana")}</MenuItem>
                  <MenuItem value="IA">{t("glossary:states:iowa")}</MenuItem>
                  <MenuItem value="KS">{t("glossary:states:kansas")}</MenuItem>
                  <MenuItem value="KY">
                    {t("glossary:states:kentucky")}
                  </MenuItem>
                  <MenuItem value="LA">
                    {t("glossary:states:louisiana")}
                  </MenuItem>
                  <MenuItem value="ME">{t("glossary:states:maine")}</MenuItem>
                  <MenuItem value="MD">
                    {t("glossary:states:maryland")}
                  </MenuItem>
                  <MenuItem value="MA">
                    {t("glossary:states:massachusetts")}
                  </MenuItem>
                  <MenuItem value="MI">
                    {t("glossary:states:michigan")}
                  </MenuItem>
                  <MenuItem value="MN">
                    {t("glossary:states:minnesota")}
                  </MenuItem>
                  <MenuItem value="MS">
                    {t("glossary:states:mississippi")}
                  </MenuItem>
                  <MenuItem value="MO">
                    {t("glossary:states:missouri")}
                  </MenuItem>
                  <MenuItem value="MT">{t("glossary:states:montana")}</MenuItem>
                  <MenuItem value="NE">
                    {t("glossary:states:nebraska")}
                  </MenuItem>
                  <MenuItem value="NV">{t("glossary:states:nevada")}</MenuItem>
                  <MenuItem value="NH">
                    {t("glossary:states:new hampshire")}
                  </MenuItem>
                  <MenuItem value="NJ">
                    {t("glossary:states:new jersey")}
                  </MenuItem>
                  <MenuItem value="NM">
                    {t("glossary:states:new mexico")}
                  </MenuItem>
                  <MenuItem value="NY">
                    {t("glossary:states:new york")}
                  </MenuItem>
                  <MenuItem value="NC">
                    {t("glossary:states:north carolina")}
                  </MenuItem>
                  <MenuItem value="ND">
                    {t("glossary:states:north dakota")}
                  </MenuItem>
                  <MenuItem value="OH">{t("glossary:states:ohio")}</MenuItem>
                  <MenuItem value="OK">
                    {t("glossary:states:oklahoma")}
                  </MenuItem>
                  <MenuItem value="OR">{t("glossary:states:oregon")}</MenuItem>
                  <MenuItem value="PA">
                    {t("glossary:states:pennsylvania")}
                  </MenuItem>
                  <MenuItem value="RI">
                    {t("glossary:states:rhode island")}
                  </MenuItem>
                  <MenuItem value="SC">
                    {t("glossary:states:south carolina")}
                  </MenuItem>
                  <MenuItem value="SD">
                    {t("glossary:states:south dakota")}
                  </MenuItem>
                  <MenuItem value="TN">
                    {t("glossary:states:tennessee")}
                  </MenuItem>
                  <MenuItem value="TX">{t("glossary:states:texas")}</MenuItem>
                  <MenuItem value="UT">{t("glossary:states:utah")}</MenuItem>
                  <MenuItem value="VT">{t("glossary:states:vermont")}</MenuItem>
                  <MenuItem value="VA">
                    {t("glossary:states:virginia")}
                  </MenuItem>
                  <MenuItem value="WA">
                    {t("glossary:states:washington")}
                  </MenuItem>
                  <MenuItem value="WV">
                    {t("glossary:states:west virginia")}
                  </MenuItem>
                  <MenuItem value="WI">
                    {t("glossary:states:wisconsin")}
                  </MenuItem>
                  <MenuItem value="WY">{t("glossary:states:wyoming")}</MenuItem>
                  <MenuItem value="AB">{t("glossary:states:alberta")}</MenuItem>
                  <MenuItem value="BC">
                    {t("glossary:states:british columbia")}
                  </MenuItem>
                  <MenuItem value="MB">
                    {t("glossary:states:manitoba")}
                  </MenuItem>
                  <MenuItem value="NB">
                    {t("glossary:states:new brunswick")}
                  </MenuItem>
                  <MenuItem value="NL">
                    {t("glossary:states:newfoundland")}
                  </MenuItem>
                  <MenuItem value="NS">
                    {t("glossary:states:nova scotia")}
                  </MenuItem>
                  <MenuItem value="ON">{t("glossary:states:ontario")}</MenuItem>
                  <MenuItem value="PE">
                    {t("glossary:states:prince edward island")}
                  </MenuItem>
                  <MenuItem value="QC">{t("glossary:states:quebec")}</MenuItem>
                  <MenuItem value="SK">
                    {t("glossary:states:saskatchewan")}
                  </MenuItem>
                  <MenuItem value="NT">
                    {t("glossary:states:northwest territories")}
                  </MenuItem>
                  <MenuItem value="NU">{t("glossary:states:nunavut")}</MenuItem>
                  <MenuItem value="YT">{t("glossary:states:yukon")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="country-label">{t("country")}</InputLabel>
                <Select
                  color="primary"
                  variant="outlined"
                  labelId="country-label"
                  id="country"
                  value={values.country}
                  disabled={disableForm}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  name="country"
                >
                  <MenuItem value="usa">{t("usa")}</MenuItem>
                  <MenuItem value="canada">{t("canada")}</MenuItem>
                  <MenuItem value="mexico">{t("mexico")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="logo-label">{t("logo")}</InputLabel>
                <Select
                  color="primary"
                  variant="outlined"
                  labelId="logo-label"
                  id="logo"
                  value={values.logo}
                  disabled={disableForm}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  name="logo"
                >
                  <MenuItem value="helios-logo-fullColor.png">
                    helios-logo-fullColor.png
                  </MenuItem>
                  <MenuItem value="ip-logo.png">ip-logo.png</MenuItem>
                  <MenuItem value="pca-logo.png">pca-logo.png</MenuItem>
                  <MenuItem value="atlantic-logo.png">
                    atlantic-logo.png
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                margin="normal"
                inputVariant="outlined"
                color="primary"
                id="start-time-picker"
                label={t("start_date")}
                format="MM/DD/YYYY"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                disabled={disableForm}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                style={{ marginTop: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              {errorDisplay}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            disabled={disableForm}
            className={classes.saveButton}
            type="submit"
            variant="contained"
            color="secondary"
          >
            {t("save_changes")}
          </Button>
        </CardActions>
      </form>
      <TopSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
        message={t("form_saved_successfully")}
      />
    </Card>
  );
}

CustomerDetails.propTypes = {
  className: PropTypes.string,
  customerDetails: PropTypes.object,
  disableForm: PropTypes.bool,
};

export default CustomerDetails;
