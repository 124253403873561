import { Grid, Paper, Tooltip } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  activeCard: {
    padding: "24px 32px",
    background: theme.palette.gray.white,
    elevation: 8,
    borderBottom: "3px solid",
    borderBottomColor: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
    height: "100%",
  },
  inactiveCard: {
    padding: "24px 32px",
    background: theme.palette.gray.lightest,
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.gray.white,
    },
    height: "100%",
  },
  backgroundIcon: {
    backgroundColor: theme.palette.primary.extraLight,
    color: theme.palette.primary.main,
  },
  addAlarmIcon: {
    color: theme.palette.primary.main,
    marginTop: -3,
    marginRight: 5,
  },
  okAlarmIcon: {
    color: theme.palette.status.success.main,
    marginTop: -3,
    marginRight: 5,
  },
  triggeredAlarmIcon: {
    color: theme.palette.status.error.main,
    marginTop: -3,
    marginRight: 5,
  },
}));

function AlarmCard({ alarm, selected, onClick }) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(1);
  const { t } = useTranslation(["glossary", "common"]);

  const onMouseOver = () => setElevation(3);
  const onMouseOut = () => setElevation(1);

  const isAddAlarmCard = alarm === undefined;

  let body;

  if (isAddAlarmCard) {
    body = (
      <span>
        <AddCircleOutlineIcon fontSize="lg" className={classes.addAlarmIcon} />
        <b>{t("add_alarm")}</b>
      </span>
    );
  } else {
    const text = `${alarm.name}`;
    body = (
      <span>
        <b>{text}</b>
        <Tooltip title={alarm.in_alarm ? "TRIGGERED" : "OK"}>
          {alarm.in_alarm ? (
            <WarningIcon fontSize="lg" className={classes.triggeredAlarmIcon} />
          ) : (
            <CheckCircleIcon fontSize="lg" className={classes.okAlarmIcon} />
          )}
        </Tooltip>
      </span>
    );
  }

  return (
    <Grid
      item
      onClick={() => {
        if (onClick) onClick(alarm);
      }}
    >
      <Paper
        className={selected ? classes.activeCard : classes.inactiveCard}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        elevation={elevation}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            {body}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

AlarmCard.propTypes = {
  alarm: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AlarmCard;
