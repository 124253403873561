import React, { Suspense } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import Topbar from "./Topbar";
import { ThemeProvider, StylesProvider } from "@material-ui/core";
import { theme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
  },
  content: {
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    paddingTop: 64,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
}));

function Auth({ route }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Topbar />
        <div className={classes.container}>
          <div className={classes.content}>
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </div>
        </div>
      </StylesProvider>
    </ThemeProvider>
  );
}

Auth.propTypes = {
  route: PropTypes.object,
};

export default Auth;
