import { Button, TableCell, TableRow } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import AddOutlined from "@material-ui/icons/AddOutlined";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Skeleton } from "@material-ui/lab";
import MaterialTable, { MTableBody, MTableToolbar } from "material-table";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import palette from "src/theme/palette";
import { gridTheme } from "../../../theme";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddOutlined {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <SortIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  AssignmentTurnedInOutlined: forwardRef((props, ref) => (
    <AssignmentTurnedInOutlined {...props} ref={ref} />
  )),
};

function Results({
  onAddNew,
  actions,
  className,
  list_data,
  num_results,
  show_filters,
  show_grouping,
  show_header_actions,
  title,
  display_columns,
  page_size_options,
  onRowClicked,
  isLoading,
  ...rest
}) {
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={gridTheme}>
        <MaterialTable
          icons={tableIcons}
          components={{
            Toolbar: (props) =>
              onAddNew ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MTableToolbar {...props} />
                    <div>
                      <Button
                        startIcon={<AddOutlined />}
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={onAddNew}
                      >
                        {t("add_new")}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <MTableToolbar {...props} />
              ),
            Container: (props) => (
              <div style={{ background: palette.gray.white }}>
                {props.children}
              </div>
            ),
            Body: (props) =>
              isLoading ? (
                Array.apply(null, { length: 5 }).map((e, i) => (
                  <TableRow width="100%" key={i}>
                    {display_columns.map((item, index) => (
                      <TableCell scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <MTableBody {...props} />
              ),
          }}
          options={{
            selection: false,
            pageSize: num_results,
            filtering: show_filters,
            columnsButton: show_header_actions,
            exportButton: show_header_actions,
            filterCellStyle: { backgroundColor: palette.gray.lightest },
            grouping: show_grouping,
            actionsColumnIndex: -1,
            toolbar: true,
            search: show_header_actions,
            pageSizeOptions: page_size_options,
            emptyRowsWhenPaging: false,
            headerStyle: {
              padding: "16px",
            },
          }}
          columns={display_columns}
          data={list_data}
          title={title}
          localization={{
            body: {
              emptyDataSourceMessage: t("no_records_to_display"),
            },
            header: {
              actions: t("actions"),
            },
            toolbar: {
              exportTitle: t("export"),
              exportAriaLabel: t("export"),
              exportName: t("export"),
              showColumnsTitle: t("show_columns"),
              searchPlaceholder: t("search"),
              addRemoveColumns: t("add_or_remove_columns"),
            },
            grouping: {
              placeholder: t("drag_headers_message"),
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
              labelRowsSelect: t("rows"),
              firstTooltip: t("first_page"),
              previousTooltip: t("previous_page"),
              nextTooltip: t("next_page"),
              lastTooltip: t("last_page"),
            },
          }}
          onRowClick={onRowClicked}
          actions={actions}
        />
      </MuiThemeProvider>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  list_data: PropTypes.array.isRequired,
  num_results: PropTypes.number,
  show_filters: PropTypes.bool,
  show_grouping: PropTypes.bool,
  show_header_actions: PropTypes.bool,
  title: PropTypes.string,
  display_columns: PropTypes.array.isRequired,
  page_size_options: PropTypes.array,
  onRowClicked: PropTypes.func,
  actions: PropTypes.object,
  onAddNew: PropTypes.func,
  referrer: PropTypes.string,
};

Results.defaultProps = {
  num_results: 20,
  show_filters: false,
  show_grouping: false,
  show_header_actions: true,
  title: i18next.t("glossary:facilities"),
  page_size_options: [10, 20, 50],
  referrer: "/dashboard",
};

export default Results;
