import { get, del, post, put } from "./base";

export function deleteAlarm(alarmID) {
  return del(`alarms/${alarmID}`);
}

export function createAlarm(alarm) {
  return post("alarms", alarm);
}

export function updateAlarm(alarm) {
  return put(`alarms/${alarm.id}`, alarm);
}

export function getTriggeredAlarms() {
  return get(`alarms/triggered`);
}
