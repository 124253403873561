import palette from "../palette";

export default {
  root: {
    '&[data-shrink="false"]': {
      transform: "translate(10px, 20px) scale(1)",
      opacity: 0.65,
    },
    '&[data-shrink="true"]': {
      transform: "translate(14px, -5px) scale(0.75)",
      transformOrigin: "top left",
      backgroundColor: "white",
      color: palette.text.secondary,
      zIndex: 1,
      opacity: 1,
    },
  },
};
