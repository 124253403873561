import { get, put, post, del } from "./base";

export function getEmailCCs() {
  return get("notifications/email_ccs");
}

export function getPotentialEmails() {
  return get("notifications/email_ccs/potential_emails");
}

export function createEmailCC(emailCC) {
  return post("notifications/email_ccs", emailCC);
}

export function updateEmailCC(emailCC) {
  return put(`notifications/email_ccs/${emailCC.id}`, emailCC);
}

export function deleteEmailCC(emailCC) {
  return del(`notifications/email_ccs/${emailCC.id}`);
}

// export function getCustomerFacilities(customerID) {
//   return get(getCustomerFacilitiesURI(customerID));
// }

// export function getFacility(ID) {
//   return get(`facilities/${ID}`);
// }

// export function updateCustomerFacility(customerID, facility) {
//   return post(`customers/${customerID}/facilities/${facility.id}`, facility);
// }

// export function createCustomerFacility(customerID, facility) {
//   return post(`customers/${customerID}/facilities`, facility);
// }
