import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
} from "@material-ui/core";
import Page from "src/components/Page";
import ResetPasswordForm from "./ResetPasswordForm";
import { useTranslation } from "react-i18next";
import { useStyles } from "./LoginStyles";

const ResetPassword = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const initial = props?.initial;

  return (
    <Page className={classes.root} title={t("auth.login")}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h3">
            {initial ? t("auth.password_create") : t("auth.password_reset")}
          </Typography>
          <ResetPasswordForm className={classes.loginForm} initial={initial} />
          <Divider className={classes.divider} />
        </CardContent>
        <CardMedia
          className={classes.media}
          image="/images/auth.png"
          title="Cover"
        ></CardMedia>
      </Card>
    </Page>
  );
};

export default ResetPassword;
