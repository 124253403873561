import { makeStyles } from "@material-ui/styles";
import palette from "src/theme/palette";

export const useStyles = makeStyles((theme) => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontWeight: 600,
  },
  error: {
    color: palette.status.error.main,
    marginBottom: 0,
    marginTop: 20,
  },
}));
