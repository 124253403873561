import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import TopSnackbar from "src/components/TopSnackbar";
import { getCustomerDevices, updateDevice } from "src/repos/devices";
import { getServiceTiers } from "src/repos/serviceTiers";
import Results from "src/views/SunScreens/Lists/GenericList";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  button: { marginTop: theme.spacing(3), marginBottom: theme.spacing(2) },
  grid: {
    marginTop: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
}));

function ServiceTiers({ customerId }) {
  const classes = useStyles();
  const [machineList, setMachineList] = useState([]);
  const [machinesToUpdateList, setMachinesToUpdateList] = useState([]);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [namedTiers, setNamedTiers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState({
    visible: false,
    error: false,
    message: "",
  });
  const { t } = useTranslation(["glossary", "common"]);

  const handleSelect = (machine_index, event) => {
    var arr = [...machinesToUpdateList];
    const machine = {
      ...arr[machine_index],
      service_tier: namedTiers[event.target.value] || {},
    };

    arr[machine_index] = machine;
    setMachinesToUpdateList(arr);
  };

  const handleSubmit = () => {
    const updates = _.differenceWith(
      [...machinesToUpdateList],
      [...machineList],
      _.isEqual
    );

    Promise.all(
      _.map(updates, (device_info) =>
        updateDevice(device_info.serial_number, device_info).then(
          (r) => r.status
        )
      )
    )
      .then((status) => {
        if (_.every(status, (s) => s < 300)) {
          setToast({
            error: false,
            visible: true,
            message: t("devices_updated_successfully"),
          });
        } else {
          setToast({
            error: true,
            visible: true,
            message: t("devices_update_error"),
          });
        }
      })
      .catch((e) => {
        setToast({
          error: true,
          visible: true,
          message: t("server_error"),
        });
      })
      .finally(() => {
        setTimeout(() => {
          setToast((toast) => ({
            ...toast,
            visible: false,
          }));
        }, 3000);
      });
  };

  const submitDisabled = _.isEmpty(
    _.differenceWith([...machinesToUpdateList], [...machineList], _.isEqual)
  );

  //grab initial values
  useEffect(() => {
    getCustomerDevices(customerId)
      .then((r) => r.json())
      .then(({ data }) => {
        const list = _.map(
          data,
          ({ id, serial_number, name, service_tier }) => ({
            id,
            serial_number,
            name,
            service_tier,
          })
        );
        setMachineList(list);
        setMachinesToUpdateList(list);
        setIsLoading(false);
      });

    getServiceTiers()
      .then((r) => r.json())
      .then(({ tiers, available_permissions }) => {
        setNamedTiers(tiers);
        setAvailablePermissions(available_permissions);
      });
  }, [customerId]);

  return (
    <Page className={classes.root} title="Service Tiers">
      <Container maxWidth={false} className={classes.container}>
        <Card>
          <CardContent>
            <Results
              isLoading={isLoading}
              title={t("service_tiers")}
              referrer={`/customers/${customerId}/serviceTiers`}
              show_header_actions={false}
              display_columns={[
                { title: t("serial"), field: "serial_number" },
                { title: t("name"), field: "name", emptyValue: "- No Name -" },
                {
                  title: t("current_tier"),
                  render: (rowData) => (
                    <Select
                      value={_.findIndex(namedTiers, [
                        "id",
                        rowData.service_tier?.id,
                      ])}
                      onChange={(event) =>
                        handleSelect(rowData.tableData.id, event)
                      }
                    >
                      <MenuItem value="">
                        <em>{t("no_tier")}</em>
                      </MenuItem>
                      {_.map(namedTiers, ({ id, name }, index) => {
                        return (
                          <MenuItem key={name} value={index}>
                            {t(name.toString().toLowerCase())}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ),
                },
                {
                  title: t("custom_permissions"),
                  render: (rowData) => (
                    <Autocomplete
                      multiple
                      disabled
                      size="small"
                      options={availablePermissions}
                      disableCloseOnSelect
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            color="primary"
                          />
                          {option}
                        </>
                      )}
                      style={{ width: 600 }}
                      value={
                        _.flatMap(rowData.service_tier, (value, key) => {
                          return value === true ? [key] : [];
                        }) || []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={t("permissions")}
                        />
                      )}
                    />
                  ),
                },
              ]}
              list_data={machinesToUpdateList}
            />
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              className={classes.button}
              disabled={submitDisabled}
              onClick={handleSubmit}
            >
              {t("submit")}
            </Button>
          </CardContent>
        </Card>
      </Container>
      <TopSnackbar
        open={toast.visible}
        message={toast.message}
        error={toast.error}
        onClose={null}
      />
    </Page>
  );
}

export default ServiceTiers;
