import { Chip } from "@material-ui/core";
import React from "react";

const AnomalousTime = ({ value }) => {
  const ColorRules = [
    {
      range: [1, 29],
      color: "#D0E5D2",
    },
    {
      range: [30, 69],
      color: "#FFE199",
    },
    {
      range: [70, 100],
      color: "#F9D2D2",
    },
  ];
  const color =
    ColorRules.find((rule) => rule.range[0] <= value && value <= rule.range[1])
      ?.color || "#F8F8F8";

  return (
    <Chip
      size="small"
      style={{ backgroundColor: color, width: 60, height: 32 }}
      label={`${value}%`}
    />
  );
};

export default React.memo(AnomalousTime);
