import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import MachineConnectionStatus from "src/components/MachineConnectionStatus";
import MachineSignalStatus from "src/components/MachineSignalStatus";
import { Route } from "react-router";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { SuperUserGate } from "src/components/UserGate";
import { useTranslation } from "react-i18next";
import {
  camelCaseToWithPrefixChar,
  camelCaseToSnakeCase,
} from "src/utils/string";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
  breadcrumbs: { display: "flex", flexGrow: 1 },
  breadcrumbLink: { marginRight: 10 },
  chip: { marginBottom: 8, marginLeft: 8 },
}));

const tFromGlossaryIfExists = (v, t) => {
  const key = camelCaseToSnakeCase(v);
  const value = t(`glossary:${key}`);
  return value === key ? v : value;
};

function breadcrumbCleanup(breadcrumb, t, match, replace) {
  let output = breadcrumb;
  const swapped = match !== undefined && match.indexOf(output) !== -1;
  if (swapped) output = replace || output;
  else if (t) output = tFromGlossaryIfExists(output, t);
  output = output.replace("_", " ");
  return swapped
    ? output
    : camelCaseToWithPrefixChar(output, " ")
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
}

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

function PageHeader({
  className,
  pageTitle,
  buttonInfo,
  children,
  titleIndicator,
  machine,
  customer,
  facility,
  user,
  loading,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation(["common", "glossary"]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography gutterBottom component="h1" variant="h3">
              {pageTitle}
            </Typography>
            {machine?.serial_number && (
              <MachineSignalStatus
                className={classes.chip}
                machineId={machine.id}
              />
            )}
            {machine?.id && (
              <MachineConnectionStatus
                className={classes.chip}
                machineId={machine.id}
              />
            )}
          </div>
          <Route>
            {({ location }) => {
              const pathnames = location.pathname.split("/").filter((x) => x);
              let match = [];

              return (
                <SuperUserGate>
                  {/*
                    Tried using loading indicator here. `loading` becomes false so quickly where it is used, that it looks
                    worse and more confusing than simply not displaying to breadcrumbs until false.
                  */}
                  {loading || (
                    <Breadcrumbs aria-label="breadcrumb">
                      {pathnames[0] !== "admin" && (
                        <LinkRouter color="inherit" to="/dashboard">
                          {t("glossary:dashboard")}
                        </LinkRouter>
                      )}
                      {pathnames.map((value, index) => {
                        const last = index === pathnames.length - 1;
                        const to = `/${pathnames
                          .slice(0, index + 1)
                          .join("/")}`;
                        if (breadcrumbCleanup(value) === "Dashboard") {
                          return null;
                        }
                        if (breadcrumbCleanup(value) === "Machines")
                          match = [
                            [machine?.serial_number, machine?.id],
                            machine?.name,
                          ];
                        else if (breadcrumbCleanup(value) === "Customers")
                          match = [[customer?.id], customer?.name];
                        else if (breadcrumbCleanup(value) === "Facilities")
                          match = [[facility?.id], facility?.name];
                        else if (breadcrumbCleanup(value) === "Users")
                          match = [
                            [user?.id],
                            `${user?.first_name} ${user?.last_name}`,
                          ];
                        return last ? (
                          <Typography color="textPrimary" key={to}>
                            {breadcrumbCleanup(value, t, ...match)}
                          </Typography>
                        ) : (
                          <LinkRouter color="inherit" to={to} key={to}>
                            {breadcrumbCleanup(value, t, ...match)}
                          </LinkRouter>
                        );
                      })}
                    </Breadcrumbs>
                  )}
                </SuperUserGate>
              );
            }}
          </Route>
        </Grid>
        {buttonInfo && (
          <Grid item>
            <Button
              color={buttonInfo.color ? buttonInfo.color : "primary"}
              variant="contained"
              onClick={buttonInfo.onClick}
            >
              {buttonInfo.title}
            </Button>
          </Grid>
        )}
        {children && (
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {children}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

PageHeader.propTypes = {
  className: PropTypes.string,
  pageTitle: PropTypes.string,
  buttonInfo: PropTypes.object,
  machine: PropTypes.object,
  displayMachineState: PropTypes.bool,
};

export default withRouter(PageHeader);
