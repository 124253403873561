import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";

interface FeatureNotAvailableProps {
  upsellText?: string;
}

const defaultUpsellText = i18next.t("glossary:upsell_message_2");

export default function FeatureNotAvailable({
  upsellText = defaultUpsellText,
}: FeatureNotAvailableProps) {
  return (
    <Alert severity="info">
      <AlertTitle>{upsellText}</AlertTitle>
      <Trans
        i18nKey="glossary:please_upgrade_message"
        components={{ 1: <a href="mailto:sales@gohelios.us">gohelios.us</a> }}
      />
    </Alert>
  );
}
