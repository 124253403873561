import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AddOutlined from "@material-ui/icons/AddOutlined";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Skeleton from "@material-ui/lab/Skeleton";
import MaterialTable from "material-table";
import React, { forwardRef } from "react";
import palette from "src/theme/palette";
import { gridTheme } from "../../../theme";
import { useTranslation } from "react-i18next";

/*
 * This component exists because of issues trying to use the loading state
 * of material-table when the data is dynamically loaded.
 * See related issue and workaround: https://github.com/mbrn/material-table/issues/885.
 * In the case of helios_web, the workaround wouldn't work because the props
 * of the tables used for the separate states were conflicting in unpredictable ways.
 * Hence, a workaround is to use this component for the loading state in the parent component
 * and the regular EventList component for the state where data has been loaded.
 */

const tableIcons = {
  Add: forwardRef((props, ref) => <AddOutlined {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <SortIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  AssignmentTurnedInOutlined: forwardRef((props, ref) => (
    <AssignmentTurnedInOutlined {...props} ref={ref} />
  )),
};

function EmptyResults({ pageSize, pageSizeOptions }) {
  const { t } = useTranslation(["glossary", "common"]);

  const defaultColumns = [
    { title: t("name") },
    { title: t("start_date") },
    { title: t("end_date") },
    { title: t("downtime_duration") },
    { title: t("total_measures") },
    { title: t("authored_by") },
  ];

  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={gridTheme}>
        <MaterialTable
          icons={tableIcons}
          localization={{
            body: {
              emptyDataSourceMessage: t("no_records_to_display"),
            },
            toolbar: {
              exportTitle: t("export"),
              exportAriaLabel: t("export"),
              exportName: t("export"),
              showColumnsTitle: t("show_columns"),
              searchPlaceholder: t("search"),
              addRemoveColumns: t("add_or_remove_columns"),
            },
            grouping: {
              placeholder: t("drag_headers_message"),
            },
            pagination: {
              labelDisplayedRows: "0-0 " + t("of") + " 0",
              labelRowsSelect: t("rows"),
              firstTooltip: t("first_page"),
              previousTooltip: t("previous_page"),
              nextTooltip: t("next_page"),
              lastTooltip: t("last_page"),
            },
          }}
          options={{
            pageSize,
            showTitle: false,
            pageSizeOptions,
          }}
          components={{
            Body: (props) => (
              <tbody>
                {Array.apply(null, {
                  length: pageSize >= 10 ? 10 : pageSize,
                }).map((e, i) => (
                  <TableRow width="100%" key={i}>
                    {defaultColumns.map((item, index) => (
                      <TableCell scope="row" key={`skeleton-cell-${index}`}>
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </tbody>
            ),
            Container: (props) => (
              <div style={{ background: palette.gray.white }}>
                {props.children}
              </div>
            ),
          }}
          columns={defaultColumns}
          data={[]}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default EmptyResults;
