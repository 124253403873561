import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddEmailCC from "./AddEmailCC";
import {
  getEmailCCs,
  getPotentialEmails,
  createEmailCC,
  updateEmailCC,
  deleteEmailCC,
} from "src/repos/notifications";
import { manage } from "src/repos/base";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modalBody: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    outline: "none",
    width: "30%",
    [theme.breakpoints.up("lg")]: {
      width: "45%",
    },
    [theme.breakpoints.down("md")]: {
      width: "65%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function EmailCC({ users }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [cc, setCC] = useState(null);
  const [ccs, setCCs] = useState([]);
  const [emails, setEmails] = useState([]);
  const categories = Object.keys(
    ccs.reduce((acc, cc) => {
      if (cc.team) acc[cc.team] = true;
      return acc;
    }, {})
  );
  const { t } = useTranslation(["glossary", "common"]);

  const handleCreate = (cc) => {
    manage(createEmailCC(cc)).on("ok", (cc) => {
      setCCs((ccs) => [cc, ...ccs]);
    });
  };

  const handleUpdate = (cc) => {
    manage(updateEmailCC(cc)).on("ok", () => {
      setCCs((ccs) => {
        return ccs.map((cc_) => {
          return cc_.id === cc.id ? cc : cc_;
        });
      });
    });
  };

  const handleDelete = (cc) => {
    manage(deleteEmailCC(cc)).on("ok", () => {
      setCCs((ccs) => {
        return ccs.filter((cc_) => {
          return cc_.id !== cc.id;
        });
      });
    });
  };

  const filterByPagination = (ccs) => {
    const index = page * pageSize;
    const s = ccs.slice(index, index + pageSize);
    return s;
  };

  useEffect(() => {
    getEmailCCs()
      .then((r) => r.json())
      .then(({ data }) => setCCs(data));
    getPotentialEmails()
      .then((r) => r.json())
      .then(({ data }) => setEmails(data));
  }, []);

  return (
    <Card>
      <CardHeader
        title={t("notification_email_ccs")}
        action={
          <IconButton
            aria-label={t("delete")}
            onClick={() => {
              setCC(null);
              setOpen(true);
            }}
            style={{
              marginTop: -5,
              marginBottom: -15,
            }}
          >
            <AddCircleIcon />
          </IconButton>
        }
      />
      <Divider />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("name")}</TableCell>
              <TableCell align="center">{t("team")}</TableCell>
              <TableCell align="center">{t("email")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterByPagination(ccs).map((row) => (
              <TableRow
                key={row.email}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  setCC(row);
                  setOpen(true);
                }}
                hover
                className={classes.hover}
              >
                <TableCell component="th" scope="row" align="center">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.team}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={ccs.length}
        labelRowsPerPage={t("rows_per_page")}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} ` +
          t("of") +
          ` ${count !== -1 ? count : t("more_than") + ` ${to}`}`
        }
        rowsPerPage={pageSize}
        page={page}
        onChangePage={(e, value) => setPage(value)}
        onChangeRowsPerPage={(e) => setPageSize(parseInt(e.target.value, 10))}
      />
      {open && (
        <AddEmailCC
          open={open}
          cc={cc}
          categories={categories}
          emails={emails}
          onClose={() => {
            setCC(null);
            setOpen(false);
          }}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      )}
    </Card>
  );
}

export default EmailCC;
