import {
  Button,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import FeatureNotAvailable from "src/components/FeatureNotAvailable";
import { getCustomers } from "src/repos/customers";
import { getCustomerDevices } from "src/repos/devices";
import { getDevices } from "src/repos/devices";
import { createMaintenanceReport } from "src/repos/reports";
import palette from "src/theme/palette";
import { isOEMSuperUser, isSuperUser } from "src/utils/session";
import { isAllowed } from "src/utils/tier_permissions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: { marginTop: theme.spacing(3), marginBottom: theme.spacing(2) },
  checkboxWrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "stretch",
  },
  checkbox: {
    width: "250px",
  },
  flexCol: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    paddingBottom: 0,
    paddingTop: 0,
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    minWidth: "300px",
    width: "50%",
  },
  picker: { marginTop: theme.spacing(2) },
  link: {
    color: palette.text.link,
    fontSize: "14px",
    textTransform: "none",
    fontWeight: 400,
    cursor: "pointer",
  },
  buttonContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  captionContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },
  timer: {
    color: palette.secondary.dark,
  },
}));

function Maintenances() {
  const classes = useStyles();

  const [customer, setCustomer] = useState({});
  const [customers, setCustomers] = useState({});
  const [device, setDevice] = useState([]);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [available, setAvailable] = useState(false);
  const [nextReportDelay, setNextReportDelay] = useState(0);
  const { t } = useTranslation(["glossary", "common"]);

  const resourceMapById = (resources) => {
    const m = _.reduce(
      resources,
      (map, resource) => {
        map[resource.id] = resource;
        return map;
      },
      {}
    );
    return m;
  };

  const resourceMapLength = (resourceMap) => {
    return _.keys(resourceMap).length;
  };

  const setDeviceByID = (deviceID) => {
    const device_ = devices[deviceID];
    const allowed_ = isAllowed("generateReport", device_.service_tier);
    setDevice(device_);
    setAllowed(allowed_);
    setDeviceAvailable(device_);
  };

  const setDeviceAvailable = useCallback((device_) => {
    //device_ = device_;
    const delay = moment(device_.report_available_at).diff(moment(), "minutes");
    setAvailable(
      isSuperUser() ||
        (!device_.service_tier.unlimited_reports &&
          !moment(device_.report_available_at).isAfter(moment()))
    );
    setNextReportDelay(delay < 0 ? 0 : delay);
  }, []);

  useEffect(() => {
    if (isOEMSuperUser()) {
      getDevices()
        .then((r) => r.json())
        .then((d) => d.data)
        .then(resourceMapById)
        .then(setDevices);
    } else {
      if (_.isEmpty(customers))
        getCustomers()
          .then((r) => r.json())
          .then((d) => d.data)
          .then(resourceMapById)
          .then((customers_) => {
            setCustomers(customers_);
            if (resourceMapLength(customers_) === 1)
              setCustomer(_.values(customers_)[0]);
          });
      if (customer.id)
        getCustomerDevices(customer.id)
          .then((r) => r.json())
          .then((d) => d.data)
          .then(resourceMapById)
          .then(setDevices);
    }
  }, [customer, customers]);

  useEffect(() => {
    if (!_.isEmpty(device) && !available) {
      const interval = setInterval(() => {
        if (available) clearInterval(interval);
        else setDeviceAvailable(device);
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [device, available, setDeviceAvailable]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    createMaintenanceReport(device.id)
      .then((r) => r.text())
      .then((csv) => {
        const a = document.createElement("a");
        a.href = `data:text/csv;base64,${btoa(csv)}`;
        a.type = "text/csv";
        a.download = "HeliosMaintenanceReport.csv";
        a.style.display = "none";
        a.style.visibility = "hidden";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
        return true;
      })
      .then(() => {
        if (!isOEMSuperUser()) {
          setDevice({});
          getCustomerDevices(customer.id)
            .then((r) => r.json())
            .then((d) => d.data)
            .then(resourceMapById)
            .then(setDevices);
        }
      });
  };

  const isFormValid = () => {
    if (isOEMSuperUser()) {
      return !_.some([device], _.isEmpty) && !loading && allowed && available;
    } else {
      return (
        !_.some([device, customer], _.isEmpty) &&
        !loading &&
        allowed &&
        available
      );
    }
  };

  const upsellText = !allowed
    ? t("upsell_message_3")
    : t("upsell_message_4") +
      t("upsell_message_5", { count: nextReportDelay || 1 });

  return (
    <Card>
      <CardContent>
        <form
          onSubmit={(e) => {
            onSubmitHandler(e);
          }}
        >
          {device.id && (!allowed || !available) && (
            <FeatureNotAvailable upsellText={upsellText} />
          )}
          <div className={classes.flexCol}>
            {resourceMapLength(customers) >= 2 && (
              <FormControl required className={classes.formControl}>
                <Typography gutterBottom variant="h5">
                  {t("customer")}
                </Typography>
                <Select
                  variant="outlined"
                  color="primary"
                  className={classes.input}
                  value={customer.id || ""}
                  onChange={(e) => {
                    setCustomer(customers[e.target.value]);
                    setDevice({});
                  }}
                >
                  {_.map(_.values(customers), (customer_, i) => {
                    return (
                      <MenuItem value={customer_.id} key={i}>
                        {customer_.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <FormControl required className={classes.formControl}>
              <Typography gutterBottom variant="h5">
                {t("which_machine_message")}
              </Typography>
              <Select
                variant="outlined"
                color="primary"
                className={classes.input}
                value={device.id || ""}
                onChange={(e) => setDeviceByID(e.target.value)}
              >
                {_.map(_.values(devices), (device_, i) => {
                  return (
                    <MenuItem value={device_.id} key={i}>
                      {device_.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                className={classes.button}
                disabled={!isFormValid()}
              >
                {loading ? t("generating") : t("export")}
              </Button>
            </div>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

export default Maintenances;
