import React, { forwardRef, useEffect, useState } from "react";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable, { MTableBody } from "material-table";
import { getFacilityDevices } from "src/repos/devices/index";
import { Skeleton } from "@material-ui/lab";
import { TableCell, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const tableIcons = {
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
};

function FacilityMachineList({ facility }) {
  const [machineList, setMachineList] = useState([]);
  const [resultsLoaded, setResultsLoaded] = useState(false);
  const { t } = useTranslation(["glossary", "common"]);
  const facilityColumns = [
    { title: t("serial_number"), field: "serial_number" },
    { title: t("name"), field: "name" },
    { title: t("make"), field: "make" },
    { title: t("model"), field: "model" },
  ];

  useEffect(() => {
    if (!facility || !facility.id) return;
    getFacilityDevices(facility.id)
      .then((r) => r.json())
      .then((d) => d.data)
      .then((devices) => {
        setMachineList(devices);
        setResultsLoaded(true);
      });
  }, [facility]);

  return (
    <MaterialTable
      icons={tableIcons}
      localization={{
        body: {
          emptyDataSourceMessage: t("no_records_to_display"),
        },
        header: {
          actions: t("actions"),
        },
        toolbar: {
          exportTitle: t("export"),
          exportAriaLabel: t("export"),
          exportName: t("export"),
          showColumnsTitle: t("show_columns"),
          searchPlaceholder: t("search"),
          addRemoveColumns: t("add_or_remove_columns"),
        },
        grouping: {
          placeholder: t("drag_headers_message"),
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
          labelRowsSelect: t("rows"),
          firstTooltip: t("first_page"),
          previousTooltip: t("previous_page"),
          nextTooltip: t("next_page"),
          lastTooltip: t("last_page"),
        },
      }}
      components={{
        Body: (props) =>
          machineList.length === 0 && !resultsLoaded ? (
            Array.apply(null, { length: 20 }).map((e, i) => (
              <TableRow width="100%" key={i}>
                {facilityColumns.map((item, index) => (
                  <TableCell scope="row">
                    <Skeleton animation="wave" />
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <MTableBody {...props} />
          ),
      }}
      options={{
        selection: false,
        pageSize: 20,
        emptyRowsWhenPaging: false,
        search: false,
      }}
      columns={facilityColumns}
      data={machineList}
      title={t("devices_in_facility")}
    />
  );
}

export default FacilityMachineList;
