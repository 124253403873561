import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  colors,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HelpIcon from "@material-ui/icons/Help";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { doesDeviceHaveHumanKey } from "src/repos/devices/views";
import { doesSamplerHaveMachineKey } from "src/repos/samplers/views";
import {
  availableSections,
  suggestedTags,
} from "src/repos/telemetryDefinitions";
import { isValidHumanKey } from "src/utils/humanKey";
import { createMachineKey, parseMachineKey } from "src/utils/machineKey";
import { useTranslation } from "react-i18next";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  modalBody: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    outline: "none",
    width: "30%",
    [theme.breakpoints.up("lg")]: {
      width: "45%",
    },
    [theme.breakpoints.down("md")]: {
      width: "65%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  takenMessage: {
    fontSize: "14px",
    color: "red",
    paddingLeft: "10px",
  },
  deleteButton: {
    backgroundColor: colors.red[700],
    "&:hover": {
      backgroundColor: colors.red[900],
    },
    color: "white",
  },
  deleteConfirm: {
    paddingRight: 10,
    marginTop: -1,
    width: 140,
  },
}));

function TelemetryDefinitionForm({
  open,
  onClose,
  onUpdate,
  onCreate,
  onDelete,
  telemetryDefinition,
  telemetryTypes,
  machine,
  sampler,
  defaultHumanTags,
  utsAdEnabledCount,
}) {
  const classes = useStyles();
  const [telemetryType, setTelemetryType] = useState({});
  const [parent, setParent] = useState("");
  const [parents, setParents] = useState([]);
  const [child, setChild] = useState("");
  const [bit, setBit] = useState("");
  const [primarySections, setPrimarySections] = useState([]);
  const [primarySection, setPrimarySection] = useState("");
  const [utsAnomalyDetectionEnabled, setUtsAnomalyDetectionEnabled] = useState(
    false
  );
  const [
    thresholdAnomalyDetectionEnabled,
    setThresholdAnomalyDetectionEnabled,
  ] = useState(true);
  const [
    thresholdLowerBoundPercentile,
    setThresholdLowerBoundPercentile,
  ] = useState("0.1");
  const [
    thresholdUpperBoundPercentile,
    setThresholdUpperBoundPercentile,
  ] = useState("99.9");
  const [humanKey, setHumanKey] = useState("");
  const [arrayIndex, setArrayIndex] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const newTelemetryDefinition =
    !telemetryDefinition || _.isEmpty(telemetryDefinition);
  const [machineKeyTaken, setMachineKeyTaken] = useState(
    !newTelemetryDefinition
  );
  const [humanKeyTaken, setHumanKeyTaken] = useState(!newTelemetryDefinition);
  // eslint-disable-next-line
  const [machineKeyTakenTask, setMachineKeyTakenTask] = useState(null);
  // eslint-disable-next-line
  const [humanKeyTakenTask, setHumanKeyTakenTask] = useState(null);
  const [validating, setValidating] = useState(false);
  const [humanTags, setHumanTags] = useState(defaultHumanTags);
  const [canReToggle, setCanReToggle] = useState(false);
  const { t } = useTranslation(["glossary", "common"]);

  const machineKey = createMachineKey(
    parent,
    child,
    arrayIndex === "null" ? "" : arrayIndex,
    bit
  );
  const humanKeyValid = isValidHumanKey(humanKey);
  const validationError = machineKeyTaken
    ? t("machine_tag_already_in_use")
    : humanKeyTaken
    ? t("human_tag_already_in_use")
    : !humanKeyValid && humanKey
    ? t("human_tag_restrictions_message")
    : // empty text evaluates to 0 in the <= so doing the strict check of 0 separately
    parseFloat(thresholdLowerBoundPercentile) !== 0 &&
      (thresholdLowerBoundPercentile <= 0 ||
        thresholdLowerBoundPercentile >= 50)
    ? t("lower_bound_range_message")
    : thresholdUpperBoundPercentile > 100 || thresholdUpperBoundPercentile <= 50
    ? t("upper_bound_range_message")
    : "";

  useEffect(() => {
    if (telemetryTypes && telemetryTypes.length)
      setParents(telemetryTypes.map((t) => t.name));
  }, [telemetryTypes]);

  useEffect(() => {
    if (open && telemetryDefinition?.id) {
      const [parent, child, arrayIndex, bit] = parseMachineKey(
        telemetryDefinition.machine_key
      );
      setParent(parent);
      setChild(child);
      setArrayIndex(arrayIndex);
      setBit(bit);
      setHumanKey(telemetryDefinition.human_key);
      setTelemetryType(
        telemetryTypes.find((t) => {
          return t.name === parent;
        })
      );
      setPrimarySection(telemetryDefinition.primary_section);
      setUtsAnomalyDetectionEnabled(
        telemetryDefinition.uts_anomaly_detection_enabled
      );
      setThresholdAnomalyDetectionEnabled(
        telemetryDefinition.threshold_anomaly_detection_enabled
      );
      setThresholdLowerBoundPercentile(
        telemetryDefinition.threshold_lower_bound_percentile
      );
      setThresholdUpperBoundPercentile(
        telemetryDefinition.threshold_upper_bound_percentile
      );
    }
  }, [open, telemetryDefinition, telemetryTypes]);

  useEffect(() => {
    if (machineKey && machineKey !== telemetryDefinition.machine_key) {
      setMachineKeyTakenTask((task) => {
        setValidating(true);
        clearTimeout(task);
        return setTimeout(() => {
          doesSamplerHaveMachineKey(sampler.id, machineKey).then((result) => {
            setMachineKeyTaken(result);
            setValidating(false);
          });
        }, 500);
      });
    } else {
      setMachineKeyTaken(false);
    }
  }, [machineKey, sampler, telemetryDefinition]);

  useEffect(() => {
    if (humanKey && humanKey !== telemetryDefinition.human_key) {
      setHumanKeyTakenTask((task) => {
        setValidating(true);
        clearTimeout(task);
        return setTimeout(() => {
          doesDeviceHaveHumanKey(machine.id, humanKey).then((result) => {
            setHumanKeyTaken(result);
            setValidating(false);
          });
        }, 500);
      });
    } else {
      setHumanKeyTaken(false);
    }
  }, [humanKey, machine, telemetryDefinition]);

  useEffect(() => {
    if (parent !== "")
      setTelemetryType(
        telemetryTypes.find((t) => {
          return t.name === parent;
        })
      );
  }, [parent, telemetryTypes]);

  useEffect(() => {
    if (parent !== "") {
      suggestedTags(machine?.id, parent)
        .then((r) => r.json())
        .then((data) => {
          if (data.data) setHumanTags([...data.data, ...defaultHumanTags]);
        })
        .catch(() => setHumanTags([]));
    }
  }, [parent, machine, defaultHumanTags]);

  useEffect(() => {
    availableSections(machine?.make, machine?.model)
      .then((r) => r.json())
      .then(({ data }) => {
        if (data) setPrimarySections(_.reject(data, (x) => x === null).sort());
      })
      .catch(() => setPrimarySections([]));
  }, [machine]);

  const submitEnabled = () => {
    if (parent === "") return false;
    else if (
      String(thresholdLowerBoundPercentile) === "" ||
      String(thresholdUpperBoundPercentile) === ""
    )
      return false;
    else if (
      telemetryType?.members &&
      telemetryType.members.length &&
      child === ""
    )
      return false;
    else
      return (
        humanKeyValid &&
        !machineKeyTaken &&
        !humanKeyTaken &&
        !deleting &&
        !validating
      );
  };

  const submit = () => {
    if (newTelemetryDefinition)
      onCreate({
        sampler_id: telemetryType.sampler_id,
        device_id: machine.id,
        machine_key: machineKey,
        human_key: humanKey || null,
        telemetry_type_id: telemetryType.id,
        primary_section: primarySection,
        uts_anomaly_detection_enabled: utsAnomalyDetectionEnabled,
        threshold_anomaly_detection_enabled: thresholdAnomalyDetectionEnabled,
        threshold_lower_bound_percentile: thresholdLowerBoundPercentile,
        threshold_upper_bound_percentile: thresholdUpperBoundPercentile,
      });
    else
      onUpdate({
        ...telemetryDefinition,
        machine_key: machineKey,
        human_key: humanKey,
        telemetry_type_id: telemetryType.id,
        primary_section: primarySection,
        uts_anomaly_detection_enabled: utsAnomalyDetectionEnabled,
        threshold_anomaly_detection_enabled: thresholdAnomalyDetectionEnabled,
        threshold_lower_bound_percentile: thresholdLowerBoundPercentile,
        threshold_upper_bound_percentile: thresholdUpperBoundPercentile,
      });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} style={{ overflow: "scroll" }}>
      <div className={classes.modalBody}>
        <Card elevation={1}>
          <CardHeader
            title={
              <span style={{ fontSize: "20px" }}>
                {newTelemetryDefinition ? t("add") : t("update")}{" "}
                {t("telemetry_mapping")}
              </span>
            }
          />
          <Divider />
          <CardHeader
            title={t("machine_tag")}
            style={{ paddingBottom: "0px" }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disableClearable
                  id="parent-tag"
                  options={parents}
                  name="parent"
                  onChange={(previous, value) => {
                    setParent(value);
                    if (previous !== "") {
                      setChild("");
                      setArrayIndex("");
                    }
                  }}
                  value={parent}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("parent_tag")}
                      variant="outlined"
                      color="primary"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="type"
                  type="text"
                  fullWidth
                  label={t("parent_tag_type")}
                  name="type"
                  variant="outlined"
                  placeholder="None"
                  value={telemetryType?.type || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              {(telemetryType?.members || []).length > 0 && (
                <Grid item xs={12} md={6}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink id="child-label">
                      {t("child_tag")}
                    </InputLabel>
                    <Select
                      variant="outlined"
                      color="primary"
                      id="child"
                      type="text"
                      fullWidth
                      labelId="child-label"
                      name="child"
                      onChange={(e) => setChild(e.target.value)}
                      style={{ width: "100%" }}
                      value={child}
                    >
                      {telemetryType.members.map((value) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {(telemetryType?.length || 0) > 0 && (
                <Grid item xs={12} md={6}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink id="array-index-label">
                      {t("array_index")}
                    </InputLabel>
                    <Select
                      variant="outlined"
                      color="primary"
                      id="array-index"
                      type="text"
                      fullWidth
                      labelId="child-label"
                      name="array_index"
                      onChange={(e) => setArrayIndex(e.target.value)}
                      style={{ width: "100%" }}
                      value={arrayIndex === "" ? "null" : arrayIndex}
                    >
                      <MenuItem key={"null"} value={"null"}>
                        {t("none")}
                      </MenuItem>
                      {[...Array(telemetryType.length)].map((_, value) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  id="bit"
                  type="number"
                  fullWidth
                  label={t("extract_bit")}
                  name="bit"
                  variant="outlined"
                  value={bit}
                  onChange={(e) => setBit(e.target.value)}
                  placeholder="None"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardHeader
            title={t("tag")}
            style={{ paddingBottom: "0px", paddingTop: "0px" }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={humanKey}
                  onChange={(_event, newValue) => {
                    if (typeof newValue === "string") {
                      setHumanKey(newValue);
                    } else if (newValue && newValue.inputValue) {
                      setHumanKey(newValue.inputValue);
                    } else {
                      setHumanKey(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                      });
                    }

                    return filtered;
                  }}
                  clearOnBlur
                  handleHomeEndKeys
                  options={humanTags}
                  getOptionSelected={(option, value) => option === value}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option;
                  }}
                  freeSolo
                  autoSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="human_key"
                      type="text"
                      fullWidth
                      label={t("human_tag")}
                      name="human_key"
                      variant="outlined"
                      value={humanKey}
                      placeholder="None"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disableClearable
                  id="primary-section"
                  options={primarySections}
                  name="primary_section"
                  onChange={(previous, value) => {
                    setPrimarySection(value);
                  }}
                  value={primarySection}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("primary_section")}
                      variant="outlined"
                      color="primary"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        checked={utsAnomalyDetectionEnabled}
                        onChange={(previous, value) => {
                          if (value === false) setCanReToggle(true);
                          setUtsAnomalyDetectionEnabled(value);
                        }}
                        name="uts_anomaly_detection_enabled"
                        value={utsAnomalyDetectionEnabled}
                        disabled={
                          utsAdEnabledCount >= 200 &&
                          !utsAnomalyDetectionEnabled &&
                          !canReToggle
                        }
                        color="primary"
                      />
                    </>
                  }
                  label={
                    <span>
                      {t("enable_uts_anomaly_detection")}
                      <Tooltip
                        title={t("enable_uts_anomaly_detection_tooltip")}
                      >
                        <HelpIcon
                          style={{
                            display: "inline",
                            fontSize: 16,
                            marginLeft: 3,
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                />
                {utsAdEnabledCount >= 200 &&
                  !utsAnomalyDetectionEnabled &&
                  !canReToggle && (
                    <Typography display="block" variant="caption" color="error">
                      {t("anomaly_tag_limit_message")}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        checked={thresholdAnomalyDetectionEnabled}
                        onChange={(previous, value) => {
                          setThresholdAnomalyDetectionEnabled(value);
                        }}
                        name="threshold_anomaly_detection_enabled"
                        value={thresholdAnomalyDetectionEnabled}
                        color="primary"
                      />
                    </>
                  }
                  label={
                    <span>
                      {t("enable_threshold_anomaly_detection")}
                      <Tooltip
                        title={t("enable_threshold_anomaly_detection_tooltip")}
                      >
                        <HelpIcon
                          style={{
                            display: "inline",
                            fontSize: 16,
                            marginLeft: 3,
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                />
                {thresholdAnomalyDetectionEnabled && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Tooltip title={t("threshold_lower_percentile_tooltip")}>
                        <TextField
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          required
                          id="lower-bound-percentile"
                          name="lower_bound_percentile"
                          onChange={(e) => {
                            setThresholdLowerBoundPercentile(e.target.value);
                          }}
                          value={thresholdLowerBoundPercentile}
                          label={t("threshold_lower_percentile")}
                          variant="outlined"
                          placeholder={t("none")}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Tooltip title={t("threshold_upper_percentile_tooltip")}>
                        <TextField
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          required
                          id="upper-bound-percentile"
                          name="upper_bound_percentile"
                          onChange={(e) => {
                            setThresholdUpperBoundPercentile(e.target.value);
                          }}
                          value={thresholdUpperBoundPercentile}
                          label={t("threshold_upper_percentile")}
                          variant="outlined"
                          placeholder={t("none")}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions
            style={{
              justifyContent: "space-between",
            }}
          >
            {!deleting && (
              <span>
                <Button
                  disabled={!submitEnabled()}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={submit}
                >
                  {newTelemetryDefinition ? t("create") : t("update")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={onClose}
                  style={{ marginLeft: "10px" }}
                >
                  {t("close")}
                </Button>
                {validationError && (
                  <span className={classes.takenMessage}>
                    {validationError}
                  </span>
                )}
              </span>
            )}
            {deleting && (
              <span>
                <TextField
                  id="delete"
                  name="bit"
                  size={"small"}
                  variant="outlined"
                  className={classes.deleteConfirm}
                  onChange={(e) => {
                    const del = e.target.value.toLowerCase() === "delete";
                    if (deleteConfirmed !== del) setDeleteConfirmed(del);
                  }}
                  placeholder={'Type "Delete"'}
                />
              </span>
            )}
            <span>
              {!newTelemetryDefinition && (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.deleteButton}
                    disabled={deleting && !deleteConfirmed}
                    onClick={() => {
                      if (!deleting) {
                        setDeleting(true);
                        setDeleteConfirmed(false);
                      } else {
                        onDelete(telemetryDefinition);
                        onClose();
                      }
                    }}
                  >
                    {t("delete")}
                  </Button>
                  {deleting && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "10px" }}
                      onClick={() => setDeleting(false)}
                    >
                      {t("cancel")}
                    </Button>
                  )}
                </>
              )}
            </span>
          </CardActions>
        </Card>
      </div>
    </Modal>
  );
}

export default TelemetryDefinitionForm;
