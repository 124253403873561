import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number-input";
import * as moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TopSnackbar from "src/components/TopSnackbar";
import {
  createCustomerFacility,
  updateCustomerFacility,
} from "src/repos/facilities";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    padding: 0,
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: 0,
  },
  formControl: {
    width: "100%",
  },
}));

const COUNTRY_TO_CODE_2_LETTER = {
  usa: "US",
  canada: "CA",
  mexico: "MX",
};

const COUNTRY_TO_STATES = {
  usa: [
    ["AL", "Alabama"],
    ["AK", "Alaska"],
    ["AZ", "Arizona"],
    ["AR", "Arkansas"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DE", "Delaware"],
    ["DC", "District Of Columbia"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["HI", "Hawaii"],
    ["ID", "Idaho"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["IA", "Iowa"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["ME", "Maine"],
    ["MD", "Maryland"],
    ["MA", "Massachusetts"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MS", "Mississippi"],
    ["MO", "Missouri"],
    ["MT", "Montana"],
    ["NE", "Nebraska"],
    ["NV", "Nevada"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NY", "New York"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PA", "Pennsylvania"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UT", "Utah"],
    ["VT", "Vermont"],
    ["VA", "Virginia"],
    ["WA", "Washington"],
    ["WV", "West Virginia"],
    ["WI", "Wisconsin"],
    ["WY", "Wyoming"],
  ],
  canada: [
    ["AB", "Alberta"],
    ["BC", "British Columbia"],
    ["MB", "Manitoba"],
    ["NB", "New Brunswick"],
    ["NL", "Newfoundland"],
    ["NS", "Nova Scotia"],
    ["ON", "Ontario"],
    ["PE", "Prince Edward Island"],
    ["QC", "Quebec"],
    ["SK", "Saskatchewan"],
    ["NT", "Northwest Territories"],
    ["NU", "Nunavut"],
    ["YT", "Yukon"],
  ],
  mexico: [
    ["AG", "Aguascalientes"],
    ["BC", "Baja California"],
    ["BS", "Baja California Sur"],
    ["CM", "Campeche"],
    ["CS", "Chiapas"],
    ["CH", "Chihuahua"],
    ["CO", "Coahuila"],
    ["CL", "Colima"],
    ["DF", "Mexico City"],
    ["DG", "Durango"],
    ["GT", "Guanajuato"],
    ["GR", "Guerrero"],
    ["HG", "Hidalgo"],
    ["JA", "Jalisco"],
    ["EM", "México"],
    ["MI", "Michoacán"],
    ["MO", "Morelos"],
    ["NA", "Nayarit"],
    ["NL", "Nuevo León"],
    ["OA", "Oaxaca"],
    ["PU", "Puebla"],
    ["QT", "Querétaro"],
    ["QR", "Quintana Roo"],
    ["SL", "San Luis Potosí"],
    ["SI", "Sinaloa"],
    ["SO", "Sonora"],
    ["TB", "Tabasco"],
    ["TM", "Tamaulipas"],
    ["TL", "Tlaxcala"],
    ["VE", "Veracruz"],
    ["YU", "Yucatán"],
    ["ZA", "Zacatecas"],
  ],
};

const countryToTimezones = (country) =>
  moment.tz.zonesForCountry(COUNTRY_TO_CODE_2_LETTER[country]);

const countryToStates = (country) => COUNTRY_TO_STATES[country];

function FacilityForm({ facility, setFacility, customerID, action, ...rest }) {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    city: "",
    state: "",
    country: "",
    timezone: "",
    sap_protocol: null,
    id: "",
    customer_id: customerID || "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
  });
  const [timezones, setTimezones] = useState([]);
  const [errors, setErrors] = useState([]);
  const [states, setStates] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { t } = useTranslation(["glossary", "common"]);

  const humanize = (str) => {
    return _.capitalize(
      _.trim(_.snakeCase(str).replace(/_id$/, "").replace(/_/g, " "))
    );
  };

  const errorMessages = errors
    ? Object.keys(errors).map((key) =>
        errors[key].map((errorMessage, messageIndex) => (
          <li key={`${key}${messageIndex}`}>{`${humanize(
            key
          )} ${errorMessage}`}</li>
        ))
      )
    : null;

  const errorDisplay = (
    <>
      {errors && (
        <Typography variant="body2" className={classes.error}>
          {errorMessages}
        </Typography>
      )}
    </>
  );

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      // If country changes, state and timezone (potentially) becomes invalid. Reset
      ...(event.target.name === "country"
        ? {
            state: "",
            timezone: "",
          }
        : {}),
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    if (!["+", "+1"].includes(value)) {
      setValues({
        ...values,
        contact_phone: value,
      });
    } else {
      setValues({
        ...values,
        contact_phone: "",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    (action === "new"
      ? createCustomerFacility(customerID, values)
      : updateCustomerFacility(customerID, values)
    )
      .then((r) => r.json())
      .then((d) => {
        if (d.data && !d.errors) {
          setFacility(d.data);
          setOpenSnackbar();
        } else {
          setErrors(d.errors);
        }
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (values.country !== "") {
      setStates(countryToStates(values.country));
      setTimezones(countryToTimezones(values.country));
    }
  }, [values.country]);

  useEffect(() => {
    if (facility?.id) {
      setValues(facility);
    }
  }, [facility]);

  return (
    <Card {...rest} className={clsx(classes.root)}>
      <form onSubmit={handleSubmit}>
        <CardHeader title={t("facility_detail")} />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("facility_name")}
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("city")}
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="country-label" required>
                  {t("country")}
                </InputLabel>
                <Select
                  color="primary"
                  variant="outlined"
                  labelId="country-label"
                  id="country"
                  value={values.country}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  name="country"
                  required
                >
                  <MenuItem value="usa">{t("usa")}</MenuItem>
                  <MenuItem value="canada">{t("canada")}</MenuItem>
                  <MenuItem value="mexico">{t("mexico")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="state-label" required>
                  {t("state_province")}
                </InputLabel>
                <Select
                  color="primary"
                  variant="outlined"
                  labelId="state-label"
                  id="state"
                  value={values.state}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  name="state"
                  disabled={values.country === ""}
                  required
                >
                  {states.map(([code, state]) => (
                    <MenuItem value={code}>
                      {t("glossary:states:" + state.toString().toLowerCase())}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel required id="timezone-label">
                  {t("timezone")}
                </InputLabel>
                <Select
                  color="primary"
                  variant="outlined"
                  labelId="timezone-label"
                  id="timezone"
                  value={values.timezone}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  name="timezone"
                  disabled={values.state === ""}
                  required
                >
                  {timezones.map((timezone) => (
                    <MenuItem value={timezone}>{`${timezone} (${moment
                      .tz(timezone)
                      .zoneAbbr()})`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="sap-protocol-label">
                  {t("sap_protocol")}
                </InputLabel>
                <Select
                  color="primary"
                  variant="outlined"
                  labelId="sap-protocol-label"
                  id="sap_protocol"
                  name="sap_protocol"
                  style={{ width: "100%" }}
                  value={values.sap_protocol || ""}
                  onChange={handleChange}
                >
                  <MenuItem value={null}>{t("none")}</MenuItem>
                  <MenuItem value="IP">IP</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader title="Facility Maintenance Contact" />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("name")}
                name="contact_name"
                onChange={handleChange}
                required
                value={values.contact_name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPhoneNumber
                color="primary"
                defaultCountry={"us"}
                fullWidth
                label={t("phone")}
                name="contact_phone"
                onChange={handlePhoneChange}
                onlyCountries={["us"]}
                required
                value={values.contact_phone}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("email")}
                name="contact_email"
                onChange={handleChange}
                required
                value={values.contact_email}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              {errorDisplay}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
            color="secondary"
          >
            {t("save_changes")}
          </Button>
        </CardActions>
      </form>
      <TopSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
        message={t("common:form_saved_successfully")}
      />
    </Card>
  );
}

FacilityForm.propTypes = {
  className: PropTypes.string,
  facilityDetails: PropTypes.object,
};

export default FacilityForm;
