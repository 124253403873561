import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import telemetries from "src/repos/telemetries";
import { categories, types } from "src/utils/telemDispCfgs";
import { useSocketContext } from "src/context/SocketContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modalBody: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    outline: "none",
    width: "30%",
    [theme.breakpoints.up("lg")]: {
      width: "45%",
    },
    [theme.breakpoints.down("md")]: {
      width: "65%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

const castValue = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

function AddTelemDispCfg({
  open,
  telemDispCfg,
  selectedCategory,
  tags,
  machine,
  onClose,
  onSubmit,
}) {
  const classes = useStyles();
  const { socket } = useSocketContext();
  const newTelemDispCfg = telemDispCfg === undefined || _.isEmpty(telemDispCfg);
  telemDispCfg = newTelemDispCfg ? {} : telemDispCfg;
  const [name, setName] = useState(telemDispCfg.title || "");
  const [tag, setTag] = useState(telemDispCfg.human_key || "");
  const [tagValue, setTagValue] = useState("");
  const [category, setCategory] = useState(
    telemDispCfg.category || selectedCategory || ""
  );
  const [type, setType] = useState(telemDispCfg.type || "passthrough");
  const [units, setUnits] = useState(telemDispCfg.units || "");
  const [precision, setPrecision] = useState(telemDispCfg.precision || "");
  const [encoding, setEncoding] = useState(
    telemDispCfg.encoding || [{ from: "", to: "" }]
  );
  const categoriesDisabled = selectedCategory && selectedCategory !== "";
  const { t } = useTranslation(["glossary", "common"]);

  const submit = () => {
    const action = newTelemDispCfg ? "create" : "update";
    onSubmit(action, telemDispCfg, {
      ...telemDispCfg,
      title: name,
      device_id: machine.id,
      human_key: tag,
      category,
      type,
      units: units || null,
      precision: castValue(precision || "null"),
      encoding: type === "encoded" ? encoding : null,
      static: false,
    });
    onClose();
  };

  const submitEnabled = () => {
    const base = name !== "" && tag !== "" && category !== "" && type !== "";
    if (!base) return false;
    else if (type === "encoded")
      return (
        base &&
        _.every(encoding, (e) => {
          return e.from !== "" && e.to !== "";
        })
      );
    else return true;
  };

  const submitDelete = () => {
    onSubmit("delete", telemDispCfg);
    onClose();
  };

  useEffect(() => {
    if (tag !== "" && machine?.id) {
      setTagValue("");
      return telemetries.subscribe(
        [machine.id],
        [tag],
        (_, state) => {
          setTagValue(state[tag] === undefined ? "" : state[tag]);
        },
        socket
      );
    } else {
      setTagValue("");
    }
  }, [tag, machine, socket]);

  return (
    <Modal open={open} onClose={onClose} style={{ overflow: "scroll" }}>
      <div className={classes.modalBody}>
        <Card elevation={1}>
          <CardHeader
            title={
              <span style={{ fontSize: "20px" }}>
                {newTelemDispCfg ? "Add" : "Update"} Measure
              </span>
            }
          />
          <Divider />
          <CardHeader title="Name" style={{ paddingBottom: "0px" }} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="name"
                  type="text"
                  fullWidth
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink id="category">
                    {t("category")}
                  </InputLabel>
                  <Select
                    variant="outlined"
                    color="primary"
                    labelId="category-label"
                    id="category"
                    name="category"
                    disabled={categoriesDisabled}
                    onChange={(e) => setCategory(e.target.value)}
                    style={{ width: "100%" }}
                    value={category}
                  >
                    {categories.map(([label, value]) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <CardHeader
            title={t("tag")}
            style={{ paddingBottom: "0px", paddingTop: "0px" }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disableClearable
                  id="tag"
                  options={tags}
                  name="tag"
                  onChange={(_, value) => {
                    setTag(value);
                  }}
                  value={tag}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("tag")}
                      variant="outlined"
                      color="primary"
                    />
                  )}
                />
                {/*<FormControl style={{ width: "100%" }}>
                  <InputLabel shrink id="tag">
                    Tag
                  </InputLabel>
                  <Select
                    variant="outlined"
                    color="primary"
                    labelId="tag-label"
                    id="tag"
                    name="tag"
                    onChange={(e) => setTag(e.target.value)}
                    style={{ width: "100%" }}
                    value={tag}
                  >
                    {tags.map((value) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>*/}
              </Grid>
              {tag !== "" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    id="tag-value"
                    type="text"
                    fullWidth
                    label={t("current_value")}
                    name="tag-value"
                    variant="outlined"
                    value={tagValue}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardHeader
            title={t("details")}
            style={{ paddingBottom: "0px", paddingTop: "0px" }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink id="category">
                    {t("value_type")}
                  </InputLabel>
                  <Select
                    variant="outlined"
                    color="primary"
                    labelId="category-label"
                    id="category"
                    name="category"
                    onChange={(e) => setType(e.target.value)}
                    style={{ width: "100%" }}
                    value={type}
                  >
                    {types.map(([label, value]) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="units"
                  type="text"
                  fullWidth
                  label={t("units_optional")}
                  name="units"
                  variant="outlined"
                  value={units}
                  placeholder={t("default_none")}
                  onChange={(e) => setUnits(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </CardContent>
          {_.includes(["float", "encoded"], type) && (
            <>
              <CardHeader
                title={t("details_additional")}
                style={{ paddingBottom: "0px", paddingTop: "0px" }}
              />
              <CardContent>
                <Grid container spacing={2}>
                  {type === "float" && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="precision"
                        type="number"
                        fullWidth
                        label={t("precision")}
                        name="precision"
                        variant="outlined"
                        value={precision}
                        placeholder={t("default_0")}
                        onChange={(e) => setPrecision(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                  {type === "encoded" &&
                    encoding.map(({ from, to }, index) => {
                      const from_name = `from-${index}`;
                      const to_name = `to-${index}`;
                      return (
                        <>
                          <Grid item xs={12} md={5}>
                            <TextField
                              id={from_name}
                              type="text"
                              fullWidth
                              label={t("from")}
                              name={from_name}
                              variant="outlined"
                              value={from}
                              onChange={(e) => {
                                e.persist();
                                setEncoding((value) => {
                                  value = _.cloneDeep(value);
                                  value[index].from = castValue(e.target.value);
                                  return value;
                                });
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <TextField
                              id={to_name}
                              type="text"
                              fullWidth
                              label={t("to")}
                              name={to_name}
                              variant="outlined"
                              value={to}
                              onChange={(e) => {
                                e.persist();
                                setEncoding((value) => {
                                  value = _.cloneDeep(value);
                                  value[index].to = castValue(e.target.value);
                                  return value;
                                });
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            {encoding.length > 1 && (
                              <Button
                                color="primary"
                                onClick={(e) => {
                                  e.persist();
                                  setEncoding((value) => {
                                    value = _.cloneDeep(value);
                                    value.splice(index, 1);
                                    return value;
                                  });
                                }}
                              >
                                {t("remove")}
                              </Button>
                            )}
                          </Grid>
                        </>
                      );
                    })}
                  {type === "encoded" && (
                    <Grid item xs={12} md={6}>
                      <Button
                        color="primary"
                        onClick={(e) => {
                          e.persist();
                          setEncoding((value) => {
                            value = _.cloneDeep(value);
                            value.push({ from: "", to: "" });
                            return value;
                          });
                        }}
                      >
                        {t("add_row")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </>
          )}
          <Divider />
          <CardActions
            style={{
              justifyContent: "space-between",
            }}
          >
            <span>
              <Button
                disabled={!submitEnabled()}
                type="submit"
                variant="contained"
                color="secondary"
                onClick={submit}
              >
                {t("submit")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={onClose}
                style={{ marginLeft: "5px" }}
              >
                {t("close")}
              </Button>
            </span>
            <span>
              {!newTelemDispCfg && (
                <Button
                  type="submit"
                  variant="contained"
                  color="red"
                  onClick={submitDelete}
                >
                  {t("delete")}
                </Button>
              )}
            </span>
          </CardActions>
        </Card>
      </div>
    </Modal>
  );
}

export default AddTelemDispCfg;
