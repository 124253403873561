import { Card, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import telemetries from "src/repos/telemetries";
import { convertDeviceDataToTypes } from "src/utils/machineTelemetryMapping";
import { useSocketContext } from "src/context/SocketContext";
import { MetricsChart } from "./Dashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    height: 150,
    justifyContent: "center",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
  },
  value: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  unitLabel: {
    color: theme.palette.gray.main,
    marginLeft: 10,
  },
  valueWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function StatCard({
  className,
  value,
  machineId,
  telemetry,
  label,
  units,
  chartData,
  ...rest
}) {
  const classes = useStyles();
  const [value_, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { socket } = useSocketContext();

  useEffect(() => {
    if (machineId && telemetry) {
      return telemetries.subscribe(
        [machineId],
        [telemetry],
        (_, state) => {
          state = convertDeviceDataToTypes(state);
          setValue(state[telemetry]);
          setIsLoading(false);
        },
        socket
      );
    }
  }, [machineId, telemetry, socket]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div>
        <Typography variant="subtitle2">{label}</Typography>
      </div>
      <div className={classes.valueWrapper}>
        {telemetry && isLoading ? (
          <div className={classes.value}>
            <Skeleton width={80}>
              <Typography variant="h3">placeholder</Typography>
            </Skeleton>
            <Skeleton width={25} className={classes.unitLabel}>
              <Typography variant="body2">placehold</Typography>
            </Skeleton>
          </div>
        ) : (
          <div className={classes.value}>
            <Typography variant="h2">
              {value_ ? value_ : value ? value : "—"}
            </Typography>
            {(value_ || value) && (
              <Typography variant="body2" className={classes.unitLabel}>
                {units}
              </Typography>
            )}
          </div>
        )}
      </div>
      {chartData && (
        <MetricsChart
          data={chartData}
          height={50}
          margin={{ left: 0, right: 0, top: 20, bottom: 0 }}
        />
      )}
    </Card>
  );
}
