import { AppBar, IconButton, Toolbar, Tooltip } from "@material-ui/core";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.10)",
  },
  logo: {
    lineHeight: 0,
    paddingTop: 4,
    paddingBottom: 4,
    width: 100,
    height: 30,
  },
  logo_container: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/" className={classes.logo_container}>
          <img
            className={classes.logo}
            alt="Logo"
            src="/images/logos/helios-logo-fullColor.png"
          />
        </RouterLink>
        <Tooltip title={t("help_contact")}>
          <IconButton
            size="small"
            onClick={() =>
              window.open(
                "https://sunautomation.atlassian.net/servicedesk/customer/portal/3"
              )
            }
          >
            <FeedbackIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
