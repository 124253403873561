import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function ButtonConfirm({
  onConfirm,
  buttonText,
  dialogLabel,
  dialogText,
  textConfirm,
  textCancel,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <>
      <Button {...rest} onClick={(e) => setOpen(true)}>
        {buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="button-confirm-dialog-label"
      >
        <DialogTitle id="button-confirm-dialog-label">
          {dialogLabel}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
          <Button
            color="secondary"
            variant="contained"
            size="medium"
            onClick={(e) => {
              onConfirm && onConfirm(e);
              setOpen(false);
            }}
          >
            {textConfirm || t("confirm")}
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={(e) => setOpen(false)}
          >
            {textCancel || t("cancel")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ButtonConfirm;
