import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect, useMemo } from "react";
import { Gauge } from "src/components/Gauge";
import { graphPercentage } from "src/utils/gauge";
import DateToggleButtonGroup from "../DateToggleButtonGroup";
import { MetricsChart } from ".";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
    flexGrow: 1,
    paddingBottom: 20,
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      padding: "0px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "space-evenly",
    },
  },
  buttonGroup: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
    },
  },
  toggleButton: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12) !important",
  },
  titleContainer: {
    display: "flex",
  },
  buttonSkeleton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  skeletonToggle: {
    marginBottom: "5px",
    borderRadius: "4px",
  },
  gaugeSkeleton: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

type ChartData = {
  time: string;
  value: number;
};

type GaugeCardProps = {
  title: string;
  gaugeLabel: string;
  dateRange: string;
  handleDateRange: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  content: number;
  colorRules: "op-uptime" | "downtime";
  chartData: Record<string, ChartData[]>;
  loading: boolean;
};

const gaugeAngle = 280;

const GaugeTitle = ({ title }: { title: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <div>{title}</div>
    </div>
  );
};

const GaugeCard = ({
  title,
  gaugeLabel,
  dateRange,
  handleDateRange,
  content,
  colorRules,
  chartData = {},
  loading,
}: GaugeCardProps) => {
  const classes = useStyles();
  const [data, setData] = useState([{ value: 0 }, { value: gaugeAngle }]);
  const contentLabel = useMemo(() => (content ? `${content}%` : "--"), [
    content,
  ]);

  useEffect(() => {
    const [gauge, total] = graphPercentage(content, gaugeAngle);
    setData([{ value: gauge }, { value: total }]);
  }, [content]);

  return (
    <Card className={classes.card}>
      <CardHeader title={GaugeTitle({ title: title })} />
      <Divider />
      <CardContent className={classes.flexRow}>
        {loading ? (
          <>
            <div className={classes.buttonSkeleton}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={65}
                height={40}
                className={classes.skeletonToggle}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                width={65}
                height={40}
                className={classes.skeletonToggle}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                width={65}
                height={40}
                className={classes.skeletonToggle}
              />
            </div>
            <div className={classes.gaugeSkeleton}>
              <Skeleton
                animation="wave"
                height={200}
                width={200}
                variant="circle"
              />
              <div>
                <Skeleton animation="wave" height={50} width={300} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.buttonGroup}>
              <DateToggleButtonGroup
                dateRange={dateRange}
                handleDateRange={handleDateRange}
              />
            </div>
            <div>
              <Gauge
                height={220}
                width={300}
                data={data}
                value={contentLabel}
                rawValue={content}
                label={gaugeLabel}
                colorRules={colorRules}
              />
              <MetricsChart data={chartData} />
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default GaugeCard;
