import { put, post, del, get, makeQuery } from "./base";

export function updateTelemetryDefinition(telemetry_definition) {
  return put(
    `telemetry_definitions/${telemetry_definition.id}`,
    telemetry_definition
  );
}

export function availableSections(make, model) {
  const query = makeQuery({ make: make, model: model });
  return get(`telemetry_definitions/available_sections${query}`);
}

export function createTelemetryDefinition(telemetry_definition) {
  return post(`telemetry_definitions`, telemetry_definition);
}

export function deleteTelemetryDefinition(telemetry_definition) {
  return del(`telemetry_definitions/${telemetry_definition.id}`);
}

export function suggestedTags(deviceId, tag) {
  const query = makeQuery({ machine_key: tag, device_id: deviceId });
  return get(`telemetry_definitions/suggested_tags${query}`);
}

export function defaultMappings() {
  return get(`telemetry_definitions/default_mappings`);
}

export function machineStates() {
  return get(`telemetry_definitions/machine_states`);
}
