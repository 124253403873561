import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TopSnackbar from "src/components/TopSnackbar";
import { getCustomers } from "src/repos/customers";
import {
  forceShadowUpdate,
  getDeviceMakeModels,
  updateDevice,
} from "src/repos/devices";
import { getFacilities } from "src/repos/facilities";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: 0,
  },
  keywords: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(2),
  },
  chips: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  select: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: theme.spacing(1),
  },
  tagLabel: {
    marginLeft: "10px",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "15px",
  },
  formControl: {
    width: "100%",
  },
  button: {
    backgroundColor: colors.red[700],
    "&:hover": {
      backgroundColor: colors.red[900],
    },
  },
}));

function ConfigurationForm({
  machine: device,
  setMachine: setDevice,
  className,
  ...rest
}) {
  const classes = useStyles();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [makeModel, setMakeModel] = useState({});
  const [customers, setCustomers] = useState([]);
  const [lastUpdated, setLastUpdated] = useState([]);
  const [customerToFacility, setCustomerToFacility] = useState({});
  const [formValues, setFormValues] = useState({
    facility_id: device?.facility_id === null ? "null" : device?.facility_id,
    manufacturer_serial_number: device?.manufacturer_serial_number || "",
    name: device?.name,
    make: device.make || "",
    model: device?.model || "",
  });
  const [toast, setToast] = useState({
    error: false,
    visible: false,
    message: "",
  });

  const { t } = useTranslation(["glossary", "common"]);

  const forceUpdateText = t("force_update_text") + ` ${lastUpdated}`;

  useEffect(() => {
    if (device?.serial_number)
      Promise.all([
        getCustomers()
          .then((r) => r.json())
          .then((d) => d.data),
        getFacilities()
          .then((r) => r.json())
          .then((d) => d.data),
        getDeviceMakeModels()
          .then((r) => r.json())
          .then((d) => d.data),
      ]).then(([customers_, facilities_, makeModels_]) => {
        setMakeModel(
          _.reduce(
            makeModels_,
            (a, v) => {
              a[v[0]] = a[v[0]] || [];
              a[v[0]].push(v[1]);
              return a;
            },
            {}
          )
        );
        setCustomers(
          customers_.reduce((a, c) => {
            a[c.id] = c;
            return a;
          }, {})
        );
        setCustomerToFacility(
          facilities_.reduce((a, f) => {
            a[f.customer_id] = a[f.customer_id] || [];
            a[f.customer_id].push(f);
            if (f.id === device.facility_id) setSelectedCustomer(f.customer_id);
            return a;
          }, {})
        );
        setFormValues({
          facility_id: device.facility_id,
          manufacturer_serial_number: device.manufacturer_serial_number,
          name: device.name,
          make: device.make,
          model: device.model,
        });
        setLastUpdated(lastUpdatedText(device.last_shadow_update_at));
      });
  }, [device]);

  const handleFormChange = (event) => {
    event.persist();
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleForceUpdate = () => {
    forceShadowUpdate(device.id)
      .then((r) => r.json())
      .then((d) => {
        setLastUpdated(lastUpdatedText(d.data.last_shadow_update_at));
      });
  };

  const lastUpdatedText = (date) => {
    const d = new Date(date);
    return i18next.t("glossary:last_update_text", {
      date: d.toLocaleDateString(),
      time: d.toLocaleTimeString(),
    });
  };

  const handleMakeChange = (event) => {
    event.persist();
    setFormValues({
      ...formValues,
      model: null,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateDevice(device.serial_number, {
      ...formValues,
      facility_id:
        formValues.facility_id === "null" ? null : formValues.facility_id,
    }).then((r) => {
      if (r.ok) {
        r.json().then(({ data }) => {
          setDevice((device) => {
            return {
              ...device,
              facility_id:
                data?.facility_id === null ? "null" : data?.facility_id,
              manufacturer_serial_number: data?.manufacturer_serial_number,
              name: data?.name,
              make: data.make || "",
              model: data?.model || "",
            };
          });
          let toast_ = {
            ...toast,
            message: t("device_update_was_successful"),
            visible: true,
          };
          setToast(toast_);
          setTimeout(() => {
            setToast({
              ...toast_,
              visible: false,
            });
          }, 4000);
        });
      } else {
        r.text().then((t) => {
          let toast_ = {
            error: true,
            message: t,
            visible: true,
          };
          setToast(toast_);
          setTimeout(() => {
            setToast({
              ...toast_,
              visible: false,
            });
          }, 4000);
        });
      }
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t("machine_configuration")} />
      <Divider />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                color="primary"
                fullWidth
                label={t("display_name")}
                name="name"
                InputLabelProps={{ shrink: true }}
                value={formValues.name}
                onChange={handleFormChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                color="primary"
                fullWidth
                helperText={t("machine_serial_message")}
                label={t("machine_serial_number")}
                name="manufacturer_serial_number"
                InputLabelProps={{ shrink: true }}
                value={formValues.manufacturer_serial_number}
                onChange={handleFormChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink={true} id="make">
                  {t("make")}
                </InputLabel>
                <Select
                  variant="outlined"
                  color="primary"
                  labelId="make-label"
                  id="make"
                  name="make"
                  onChange={handleMakeChange}
                  style={{ width: "100%" }}
                  value={formValues.make || ""}
                >
                  {_.keys(makeModel).map((make) => {
                    return (
                      <MenuItem key={make} value={make}>
                        {make}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink={true} id="model">
                  {t("model")}
                </InputLabel>
                <Select
                  variant="outlined"
                  color="primary"
                  labelId="model-label"
                  id="model"
                  name="model"
                  onChange={handleFormChange}
                  style={{ width: "100%" }}
                  value={formValues.model || ""}
                >
                  {(makeModel[formValues.make] || []).map((model) => {
                    return (
                      <MenuItem key={model} value={model}>
                        {model}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip title={t("service_tier_tooltip")}>
                <TextField
                  color="primary"
                  label={t("service_tier")}
                  value={
                    t(device?.service_tier?.name.toString().toLowerCase()) ||
                    t("custom_tier")
                  }
                  disabled
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
        <CardHeader title={t("facility_information")} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink id="customer">
                  {t("customer")}
                </InputLabel>
                <Select
                  variant="outlined"
                  color="primary"
                  labelId="customer-label"
                  id="customer"
                  name="customer"
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                  style={{ width: "100%" }}
                  value={selectedCustomer || ""}
                >
                  {Object.keys(customers).map((customer_id) => {
                    return (
                      <MenuItem key={customer_id} value={customer_id}>
                        {customers[customer_id].name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="facility">{t("facility")}</InputLabel>
                <Select
                  variant="outlined"
                  color="primary"
                  labelId="facility-label"
                  id="facility"
                  name="facility_id"
                  value={formValues.facility_id || "null"}
                  onChange={handleFormChange}
                  style={{ width: "100%" }}
                >
                  <MenuItem key={`facility-null`} value={"null"}>
                    {t("unconfigured")}
                  </MenuItem>
                  {(customerToFacility[selectedCustomer] || []).map(
                    (facility) => {
                      return (
                        <MenuItem key={`facility-$id}`} value={facility.id}>
                          {facility.name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t("save_changes")}
              </Button>
            </Grid>
            <Grid item container justify="flex-end" xs={6}>
              <Tooltip title={forceUpdateText}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={handleForceUpdate}
                  startIcon={<SyncIcon />}
                >
                  {t("force_update")}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </form>
      <TopSnackbar
        open={toast.visible}
        message={toast.message}
        error={toast.error}
      />
    </Card>
  );
}

ConfigurationForm.propTypes = {
  className: PropTypes.string,
  machine: PropTypes.object.isRequired,
};

export default ConfigurationForm;
