import { makeStyles } from "@material-ui/styles";
import palette from "src/theme/palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  select: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: theme.spacing(1),
    marginBottom: "40px",
    borderColor: palette.gray.lighter,
    backgroundColor: "white",
    borderRadius: "5px",
    fontSize: "14px",
    marginTop: "5px",
    border: "solid",
    borderWidth: "thin",
    maxWidth: "350px",
  },
  picker: {
    marginBottom: 20,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${palette.gray.main}`,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      outline: 0,
    },
  },
  content: {
    padding: 0,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  selectionContent: {},
  container: {
    marginTop: theme.spacing(3),
    padding: 0,
  },
  subLabel: {
    display: "block",
    fontSize: "0.8rem",
  },
  inner: {
    minWidth: 700,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    flexGrow: 1,
  },
  header: {
    textTransform: "uppercase",
  },
  flexCol: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItem: {
    "& .deleteIcon": {
      display: "none",
    },
    "&:hover .deleteIcon": {
      display: "block",
    },
  },
  scroll: {
    display: "flex",
  },
}));
