import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getTimeseries } from "src/repos/timeseries";
import { getJobs } from "src/repos/jobs";
import { calcGranularity } from "src/utils/analytics";
import CloseIcon from "@material-ui/icons/Close";
import Chart2 from "../Analytics/Chart2";
import HelpIcon from "@material-ui/icons/Help";
import palette from "src/theme/palette";
import Lottie from "react-lottie";
import loadingData from "./loading.json";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modalBody: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    outline: "none",
    width: "80%",
    [theme.breakpoints.up("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "65%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  cardHeader: {
    textAlign: "center",
  },
  cardContent: {
    background: "#FFFFFF",
    padding: 0,
  },
  contentHeader: {
    borderCollapse: "collapse",
    width: "100%",
  },
  contentItem: {
    paddingLeft: "24px",
    paddingTop: "12px",
    paddingBottom: "12px",
    verticalAlign: "middle",
    border: "1px solid #ECEFF1",
  },
  itemLabel: {
    fontSize: 12,
    fontWeight: 400,
    display: "block",
  },
  itemValue: {
    fontSize: 14,
    fontWeight: 600,
    display: "block",
  },
  optionsSection: {
    borderTop: "1px solid #ECEFF1",
    borderBottom: "1px solid #ECEFF1",
    padding: "17px 24px 1px",
  },
  copiedTooltip: {
    display: "none",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    background: "#000000",
    borderRadius: " 2px",
    height: 30,
  },
  copiedText: {
    fontWeight: 400,
    fontSize: 14,
    color: "#FFFFFF",
  },
  closeButton: {
    border: "1px solid #ECEFF1",
  },
  loading: {
    marginTop: 30,
    marginBottom: 20,
    textAlign: "center",
  },
}));

const AnomalyView = ({
  open,
  setAnomalyView,
  anomalyView,
  device,
  selectedStartDate,
  selectedEndDate,
  timezone,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [eventsData, setEventsData] = useState([]);
  const [anomalousTime, setAnomalousTime] = useState(anomalyView?.value);
  const [displayFrom, setDisplayFrom] = useState(selectedStartDate);
  const [displayTo, setDisplayTo] = useState(selectedEndDate);
  const [displayEventCount, setDisplayEventCount] = useState(
    eventsData?.length
  );
  const copiedRef = useRef(null);
  const { t } = useTranslation(["glossary", "common"]);

  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(device) && !_.isEmpty(anomalyView.tagName))
      getTimeseries({
        metrics: [anomalyView.tagName],
        start_time: selectedStartDate.toISOString(),
        end_time: selectedEndDate ? selectedEndDate.toISOString() : undefined,
        device_id: device.id,
        granularity: calcGranularity(
          selectedStartDate.toISOString(),
          selectedEndDate.toISOString()
        ),
      })
        .then((r) => r.json())
        .then((data) => {
          setLoading(false);
          setChartData(data);
          const filteredEvents = _.filter(data.events, (x) =>
            [4, 6, null].includes(x.kiwi_class)
          );
          setEventsData(filteredEvents);
          setDisplayEventCount(filteredEvents.length);
        });
    const jobsPayload = {
      start_time: selectedStartDate.toISOString(),
      end_time: selectedEndDate ? selectedEndDate.toISOString() : undefined,
      device_id: device.id,
    };
    getJobs(jobsPayload)
      .then((jobsResponse) => {
        if (jobsResponse.ok) {
          return jobsResponse.json().then((jobsData) => {
            setJobs(jobsData);
          });
        } else {
          // Handle jobs response error
        }
      })
      .catch((error) => {
        // Handle jobs API call error
      });
  }, [device, selectedStartDate, selectedEndDate, anomalyView]);

  const zoomQuery = (fromDate, toDate) => {
    if (!_.isEmpty(device) && !_.isEmpty(anomalyView.tagName)) {
      return getTimeseries({
        metrics: [anomalyView.tagName],
        start_time: fromDate.toISOString(),
        end_time: toDate ? toDate.toISOString() : undefined,
        device_id: device.id,
        granularity: calcGranularity(
          fromDate.toISOString(),
          toDate.toISOString()
        ),
      });
    }

    return new Promise();
  };

  const zoomCallback = (from, to, anomalyData, eventsCount) => {
    setDisplayFrom(from);
    setDisplayTo(to);
    setDisplayEventCount(eventsCount);
    const anomalousPct = _.chain(anomalyData[anomalyView.tagName])
      .reject((x) => x.ad_flag === null)
      .meanBy((x) => x.ad_flag)
      .value();
    setAnomalousTime(anomalousPct);
  };

  const onClose = () => {
    setAnomalyView({ name: null, value: null, section: null, shared: false });
    setChartData({});
  };

  const shareAction = () => {
    const url = window.location.href;
    try {
      navigator.clipboard.writeText(url);
      if (copiedRef.current) {
        copiedRef.current.style.display = "flex";
        setTimeout(() => {
          if (copiedRef.current) copiedRef.current.style.display = "none";
        }, 3000);
      }
    } catch (err) {
      console.log("Failed to copy!");
    }
  };

  return (
    <Modal open={open} onClose={onClose} style={{ overflow: "scroll" }}>
      <div className={classes.modalBody}>
        <Card elevation={1}>
          <CardHeader
            className={classes.cardHeader}
            action={
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon style={{ color: palette.gray.dark }} />
              </IconButton>
            }
            title={
              <span style={{ fontSize: "16px", fontWeight: "500" }}>
                {anomalyView.tagName}
              </span>
            }
          />
          <Divider />
          <CardContent className={classes.cardContent}>
            <table className={classes.contentHeader}>
              <tbody>
                <tr>
                  <td className={classes.contentItem}>
                    <Typography variant="body2" className={classes.itemLabel}>
                      {t("from")}
                    </Typography>
                    <Typography variant="body2" className={classes.itemValue}>
                      {moment(displayFrom).format("M/D/YY h:mma")}
                    </Typography>
                  </td>
                  <td className={classes.contentItem}>
                    <Typography variant="body2" className={classes.itemLabel}>
                      {t("to")}
                    </Typography>
                    <Typography variant="body2" className={classes.itemValue}>
                      {moment(displayTo).format("M/D/YY h:mma")}
                    </Typography>
                  </td>
                  <td className={classes.contentItem}>
                    <div className={classes.itemLabel}>
                      <Typography variant="body2" style={{ display: "inline" }}>
                        {t("anomalous_time")}
                      </Typography>
                      <Tooltip title={t("anomalous_time_message_2")}>
                        <HelpIcon
                          style={{
                            fontSize: 14,
                            marginLeft: 3,
                            color: "#C2C2C2",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <Typography variant="body2" className={classes.itemValue}>
                      {isNaN(anomalousTime)
                        ? t("n/a")
                        : `${Math.round(anomalousTime * 100)}%`}
                    </Typography>
                  </td>
                  <td className={classes.contentItem}>
                    <div className={classes.itemLabel}>
                      <Typography variant="body2" style={{ display: "inline" }}>
                        {t("events_reported")}
                      </Typography>
                      <Tooltip title={t("number_of_events_message")}>
                        <HelpIcon
                          style={{
                            fontSize: 14,
                            marginLeft: 3,
                            color: "#C2C2C2",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <Typography variant="body2" className={classes.itemValue}>
                      {displayEventCount}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
            {loading ? (
              <div className={classes.loading}>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width={200}
                  height={100}
                />
              </div>
            ) : (
              chartData &&
              chartData.data &&
              _.map(chartData.data, (metricData, name) => {
                return (
                  <Grid item xs={12} sm={12} lg={12} key={`${name}_chart_grid`}>
                    <Chart2
                      data={{ [name]: metricData }}
                      start={selectedStartDate}
                      end={selectedEndDate}
                      maintainAspectRatio={true}
                      pointRadius={2}
                      events={eventsData}
                      jobs={jobs}
                      state_info={chartData.state_info}
                      hideTimeRange={true}
                      yTicksLimit={5}
                      highlightedRegionStart={selectedStartDate}
                      highlightedRegionEnd={selectedEndDate}
                      xAxisOrientation="bottom"
                      xAxisTickMargin={20}
                      displayVerticalLines={true}
                      darkThresholdLines={true}
                      containerHeight={350}
                      timezone={timezone}
                      facilityTimezone={device.facility_timezone}
                      zoomQuery={zoomQuery}
                      zoomCallback={zoomCallback}
                      zoomEnabled
                    />
                  </Grid>
                );
              })
            )}
          </CardContent>
          <CardActions
            style={{
              justifyContent: "end",
            }}
          >
            <div className={classes.copiedTooltip} ref={copiedRef}>
              <span className={classes.copiedText}>
                {t("link_copied_to_clipboard")}
              </span>
            </div>
            <span>
              <Button
                variant="outlined"
                style={{ marginRight: "5px" }}
                component={Link}
                to={
                  "/machines/" +
                  device.id +
                  '/analytics?metrics=%5B"' +
                  anomalyView.tagName +
                  '"%5D&date_range=%5B' +
                  selectedStartDate +
                  "%2C" +
                  selectedEndDate +
                  "%5D"
                }
              >
                {t("view_in_analytics")}
              </Button>
              <Button
                type="submit"
                variant="outlined"
                onClick={shareAction}
                style={{ marginRight: "30px" }}
              >
                {t("share")}
              </Button>
            </span>
          </CardActions>
        </Card>
      </div>
    </Modal>
  );
};

export default AnomalyView;
