import { Grid, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import { isAdmin } from "src/utils/session";
import StateCard from "./StateCard";
import StateCardAction from "./StateCardAction";
import palette from "src/theme/palette.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(2),
  },
  accordion: {
    width: "100%",
  },
  accordionDetails: {
    margin: 0,
    borderTop: "1px solid" + theme.palette.gray.lighter,
    padding: 0,
  },
  telemDispCfgCard: {
    [theme.breakpoints.up("lg")]: {
      borderRight: "1px solid" + theme.palette.gray.lighter,
      borderBottom: "1px solid" + theme.palette.gray.lighter,
      "&:nth-child(3n)": {
        borderRight: "none",
      },
      "&:nth-child(3n+1):nth-last-child(-n+3), &:nth-child(3n+2):nth-last-child(-n+2), &:nth-child(3n+3):nth-last-child(-n+1)": {
        borderBottom: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      borderRight: "1px solid" + theme.palette.gray.lighter,
      borderBottom: "1px solid" + theme.palette.gray.lighter,
      "&:nth-child(even)": {
        borderRight: "none",
      },
      "&:nth-child(2n+1):nth-last-child(-n+2), &:nth-child(2n+2):nth-last-child(-n+1)": {
        borderBottom: "none",
      },
    },
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
      borderBottom: "1px solid" + theme.palette.gray.lighter,
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  cardContainer: {
    padding: theme.spacing(3, 3),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: palette.primary.ultraLight,
    },
    height: "100%",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  cardContent: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.primary,
    textAlign: "left",
    fontWeight: 600,
    fontSize: "0.9rem",
    justifyConotent: "left",
    alignItems: "left",
    lineHeight: 1,
  },
}));

function StateSection({
  name,
  tags,
  category,
  telemDispCfgs,
  machine,
  onSubmitTelemDispCfg,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === "panel"}
        onChange={handleChange("panel")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="expand-section"
        >
          <Typography className={classes.heading} variant="h5">
            {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container>
            {telemDispCfgs &&
              telemDispCfgs.map((telemDispCfg, index) => (
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                  className={classes.telemDispCfgCard}
                >
                  <StateCard
                    telemDispCfg={telemDispCfg}
                    machine={machine}
                    className={classes.machineState}
                    key={index}
                    tags={tags}
                    onSubmit={onSubmitTelemDispCfg}
                  />
                </Grid>
              ))}
            {isAdmin() && telemDispCfgs && telemDispCfgs.length && (
              <Grid
                item
                lg={4}
                sm={6}
                xs={12}
                className={classes.telemDispCfgCard}
              >
                <StateCardAction
                  action={t("add_measure")}
                  category={category}
                  tags={tags}
                  machine={machine}
                  onSubmit={onSubmitTelemDispCfg}
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

StateSection.propTypes = {
  name: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
  telemDispCfgs: PropTypes.array.isRequired,
  machine: PropTypes.object.isRequired,
  onSubmitTelemDispCfg: PropTypes.func.isRequired,
};

export default StateSection;
