import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import Page from "src/components/Page";
import TopSnackbar from "src/components/TopSnackbar";
import ConfigurationForm from "./ConfigurationForm";
import ReplacementForm from "./ReplacementForm";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  container: {
    padding: 0,
  },
}));

function Configuration({ machine: device, setMachine: setDevice }) {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarError, setSnackbarError] = useState("");
  const [configMode, setConfigMode] = useState(null);
  const { t } = useTranslation(["glossary", "common"]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarError(false);
  };

  return (
    <Page className={classes.root} title="Configuration">
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {configMode === "replace" ? (
              <ReplacementForm machine={device} />
            ) : (
              <ConfigurationForm machine={device} setMachine={setDevice} />
            )}
          </Grid>
        </Grid>
      </Container>
      {device.configured === false && (
        <Dialog
          open={!configMode}
          onClose={() => setConfigMode("new")}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
        >
          <DialogTitle>{t("unconfigured_device")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("unconfigured_device_message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setConfigMode("new")}
              color="secondary"
            >
              {t("set_up_as_new")}
            </Button>
            <Button
              variant="contained"
              onClick={() => setConfigMode("replace")}
              color="primary"
            >
              {t("replace_a_device")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <TopSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
        error={snackbarError}
        message={snackbarError || t("sampler_saved_successfully")}
      />
    </Page>
  );
}

export default Configuration;
