import ExponentialRegression from "ml-regression-exponential";
import PolynomialRegression from "ml-regression-polynomial";
import SimpleLinearRegression from "ml-regression-simple-linear";
import TheilSenRegression from "ml-regression-theil-sen";

export const regressionNames = {
  LINEAR: "linear",
  EXPONENTIAL: "exponential",
  POLYNOMIAL: "polynomial",
  THEIL_SEN: "theil_sen",
};

export const buildRegressor = (regrType, xs, ys, minx, miny) => {
  if (!xs.length || !ys.length) return null;
  try {
    switch (regrType) {
      case regressionNames.LINEAR:
        return linearRegression(xs, ys);
      case regressionNames.EXPONENTIAL:
        return exponentialRegression(xs, ys, minx, miny);
      case regressionNames.POLYNOMIAL:
        return polynomialRegression(xs, ys);
      case regressionNames.THEIL_SEN:
        return theilSenRegression(xs, ys);
      default:
        return null;
    }
  } catch (error) {
    return null;
  }
};

const translateToPositive = (data, min) => {
  return min < 0 ? data.map((x) => x + Math.abs(min) + 1) : data;
};

export const linearRegression = (xs, ys) => {
  return new SimpleLinearRegression(xs, ys);
};

export const exponentialRegression = (xs, ys, minX, minY) => {
  return new ExponentialRegression(xs, translateToPositive(ys, minY));
};

export const polynomialRegression = (xs, ys) => {
  return new PolynomialRegression(xs, ys, 5);
};

export const theilSenRegression = (xs, ys) => {
  return new TheilSenRegression(xs, ys);
};

export const reverseTransform = (regressionName, val, minY) => {
  if (regressionName === regressionNames.EXPONENTIAL) {
    return val - Math.abs(minY) - 1;
  }
  return val;
};
