import mock from "src/utils/mock";

mock.onGet("/api/maintenance/list/sample").reply(200, {
  maintenance_list: [
    /* {
     *   id: "transmission_life",
     *   name: "Transmission Life",
     *   type: "replacement",
     *   cutoff_value: "20",
     *   recommended_date: "08/01/2019",
     *   instructions:
     *     "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
     *   burn_rates: [
     *     {
     *       system_time: "03/01/2019",
     *       value: 100,
     *     },
     *     {
     *       system_time: "04/01/2019",
     *       value: 88,
     *     },
     *     {
     *       system_time: "05/01/2019",
     *       value: 75,
     *     },
     *     {
     *       system_time: "06/01/2019",
     *       value: 60,
     *     },
     *     { system_time: "07/01/2019", value: 50 },
     *     { system_time: "08/01/2019", value: 40 },
     *     { system_time: "09/01/2019", value: 10 },
     *   ],
     *   history: [
     *     {
     *       system_time: "03/01/2019",
     *       status: "Just on time",
     *     },
     *     {
     *       system_time: "10/01/2018",
     *       status: "Too late",
     *     },
     *   ],
     * }, */
    /* {
     *   id: "wheel_tread",
     *   name: "Wheel Tread",
     *   type: "replacement",
     *   cutoff_value: "10",
     *   recommended_date: "12/15/2020",
     *   last_performed_at: "03/15/2020",
     *   instructions:
     *     "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
     *   burn_rates: [
     *     {
     *       system_time: "03/15/2020",
     *       value: 100,
     *     },
     *     {
     *       system_time: "04/01/2020",
     *       value: 94,
     *     },
     *     {
     *       system_time: "05/01/2020",
     *       value: 88,
     *     },
     *     {
     *       system_time: "06/01/2020",
     *       value: 70,
     *     },
     *     {
     *       system_time: "07/01/2020",
     *       value: 69,
     *     },
     *     {
     *       system_time: "08/01/2020",
     *       value: 53,
     *     },
     *     { system_time: "09/01/2020", value: 49 },
     *     { system_time: "10/01/2020", value: 36 },
     *   ],
     *   history: [
     *     {
     *       performed_at: "05/01/2019",
     *       prediction_accuracy: "early",
     *     },
     *     {
     *       performed_at: "03/15/2020",
     *       prediction_accuracy: "late",
     *     },
     *   ],
     * }, */
    /* {
     *   id: "ink_pump_p4",
     *   name: "Ink pump hard rebuild P4 ",
     *   type: "lube",
     *   cutoff_value: "100",
     *   recommended_date: "12/01/2021",
     *   instructions:
     *     "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
     *   burn_rates: [
     *     {
     *       system_time: "03/01/2019",
     *       value: 800,
     *     },
     *     {
     *       system_time: "04/01/2019",
     *       value: 700,
     *     },
     *     {
     *       system_time: "05/01/2019",
     *       value: 600,
     *     },
     *     {
     *       system_time: "06/01/2019",
     *       value: 500,
     *     },
     *     { system_time: "07/01/2019", value: 400 },
     *     { system_time: "08/01/2019", value: 300 },
     *     { system_time: "09/01/2019", value: 200 },
     *   ],
     *   history: [
     *     {
     *       system_time: "03/01/2019",
     *       status: "Just on time",
     *     },
     *     {
     *       system_time: "10/01/2018",
     *       status: "Too late",
     *     },
     *   ],
     * }, */
    {
      id: "transmission_oil",
      name: "Transmission Oil",
      type: "replacement",
      cutoff_value: "15%",
      recommended_date: "",
      instructions:
        "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
      burn_rates: [],
      history: [],
    },
  ],
});
