import _ from "lodash";
import { getDeviceTelemetryDefinitions } from "src/repos/devices";

export function getDeviceMachineKeyToHumanKeys(
  deviceID,
  excludeEmptyHumanKeys
) {
  const filters = excludeEmptyHumanKeys
    ? [{ op: "ne", fd: "human_key", va: null }]
    : [];
  return new Promise((resolve) => {
    getDeviceTelemetryDefinitions(deviceID, {
      filters,
      aggregation: "machine_key:human_key",
    })
      .then((r) => r.json())
      .then(({ data }) => resolve(data));
  });
}

export const doesDeviceHaveHumanKey = (deviceID, humanKey) => {
  return new Promise((resolve) => {
    getDeviceTelemetryDefinitions(deviceID, {
      limit: 1,
      filters: [{ op: "eq", fd: "human_key", va: humanKey }],
    })
      .then((r) => r.json())
      .then(({ paging }) => resolve(paging.count > 0));
  });
};

export const getDeviceHumanKeysAndCalculatedFields = (deviceID) => {
  return new Promise((resolve) => {
    getDeviceTelemetryDefinitions(deviceID, {
      aggregation: "human_keys,calc_fields",
    })
      .then((r) => r.json())
      .then(({ data }) => resolve(data));
  });
};

export const getDeviceMetrics = (deviceID) => {
  return new Promise((resolve) => {
    getDeviceMachineKeyToHumanKeys(deviceID).then((data) => {
      const humanKeys = [];
      const machineKeys = [];
      _.each(data, (humanKey, machineKey) => {
        if (humanKey) {
          humanKeys.push(humanKey);
        } else {
          machineKeys.push(machineKey);
        }
      });
      resolve([...humanKeys.sort(), ...machineKeys.sort()]);
    });
  });
};

export const getDeviceHumanKeys = (deviceID) => {
  return new Promise((resolve) => {
    getDeviceMachineKeyToHumanKeys(deviceID, true).then((data) => {
      const humanKeys = _.values(data).sort();
      resolve(humanKeys);
    });
  });
};
