import React from "react";
import { Grid } from "@material-ui/core";
import EmailCC from "./EmailCC";

function Notifications() {
  return (
    <Grid container style={{ width: "100%" }}>
      <Grid item xs={12} md={6}>
        <EmailCC />
      </Grid>
    </Grid>
  );
}

export default Notifications;
