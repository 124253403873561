/* eslint-disable react/no-multi-comp */
import {
  Button,
  colors,
  Divider,
  Drawer,
  List,
  ListItem,
  ListSubheader,
} from "@material-ui/core";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { matchPath, useLocation } from "react-router";
import NavItem from "src/components/NavItem";
import { logout } from "src/utils/session";
import useMobileNavigationItems from "./mobileNavConfig";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  listItem: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  navButton: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    color: theme.palette.gray.main,
    fontWeight: 700,
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: theme.palette.status.error.main,
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: "auto",
    color: colors.blueGrey[200],
  },
  listHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.contrastText,
    padding: 20,
    fontFamily: "Lato",
    fontWeight: "700",
  },
  logoutIcon: {
    color: theme.palette.gray.main,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
}));

function renderNavItems(
  {
    // eslint-disable-next-line react/prop-types
    items,
    subheader,
    key,
    ...rest
  },
  notifications
) {
  return (
    <List key={key}>
      {subheader && (
        <ListSubheader color="primary" disableSticky>
          {subheader}
        </ListSubheader>
      )}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }, notifications),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }, notifications) {
  const labelComponent = item.label && item.label(notifications);

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems(
          {
            depth: depth + 1,
            pathname,
            items: item.items,
          },
          notifications
        )}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        external={item?.external}
        icon={item.icon}
        key={item.href}
        label={labelComponent}
        title={item.title}
      />
    );
  }

  return acc;
}

function MobileMenu({ openMobile, onMobileClose, className, ...rest }) {
  const classes = useStyles();
  const location = useLocation();
  const notifications = undefined;
  const mobileNavConfig = useMobileNavigationItems();
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.listHeader}>{t("menu")}</div>
      <nav className={classes.navigation}>
        {mobileNavConfig.map((list, index) =>
          renderNavItems(
            {
              items: list.items,
              subheader: list.subheader,
              pathname: location.pathname,
              key: list.items,
            },
            notifications
          )
        )}
        <ListItem disableGutters key={"logout"} className={classes.listItem}>
          <Button
            color="primary"
            className={classes.navButton}
            onClick={logout}
          >
            <ExitToAppOutlinedIcon className={classes.logoutIcon} />
            {t("log_out")}
          </Button>
        </ListItem>
      </nav>
      <Divider />
    </div>
  );

  return (
    <>
      <Drawer
        anchor="right"
        classes={{
          paper: classes.mobileDrawer,
        }}
        onClose={onMobileClose}
        open={openMobile}
      >
        {content}
      </Drawer>
    </>
  );
}

MobileMenu.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default MobileMenu;
