// @ts-nocheck
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import HelpIcon from "@material-ui/icons/Help";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import FeatureNotAvailable from "src/components/FeatureNotAvailable";
import DateRangePicker from "src/components/TimePicker/DateRangePicker";
import { getDevices } from "src/repos/devices";
import { getDeviceMetrics } from "src/repos/devices/views";
import { getTimeseries } from "src/repos/timeseries";
import palette from "src/theme/palette";
import { calcGranularity } from "src/utils/analytics";
import { download } from "src/utils/download";
import { segmentsMappedWithTelemetry } from "src/utils/machineTelemetryMapping";
import { isAllowed } from "src/utils/tier_permissions";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  button: { marginTop: theme.spacing(3), marginBottom: theme.spacing(2) },
  checkboxWrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "stretch",
  },
  checkbox: {
    width: "250px",
  },
  flexCol: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    paddingBottom: 0,
    paddingTop: 0,
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    minWidth: "300px",
    width: "50%",
  },
  picker: { marginTop: theme.spacing(2) },
  link: {
    color: palette.text.link,
    fontSize: "14px",
    textTransform: "none",
    fontWeight: 400,
    cursor: "pointer",
  },
  buttonContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  captionContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },
  timer: {
    color: palette.secondary.dark,
  },
}));

function Measures() {
  const classes = useStyles();
  const initialDatePast = moment().subtract(20, "minutes");
  const [selectedMachineId, setSelectedMachineId] = useState<string>("");
  const [selectedTimeframe, setSelectedTimeframe] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(initialDatePast);
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [unlimited, setUnlimited] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState([] as string[]);
  const [selectedAdditionalMetrics, setSelectedAdditionalMetrics] = useState(
    []
  );
  const [additionalMetrics, setAdditionalMetrics] = useState([]);
  const [reportAvailableAt, setReportAvailableAt] = useState<Moment>();
  const [machineList, setMachineList] = useState<any>([]);
  const metricMultiSelect = segmentsMappedWithTelemetry("");
  const [reportData, setReportData] = useState<any>();
  const { t } = useTranslation(["glossary", "common"]);

  const tagLimit = 25;

  const basicTimeRanges = [
    { key: "24-hours", label: t("last_24_hours") },
    { key: "7-days", label: t("last_7_days") },
    { key: "30-days", label: t("last_30_days") },
  ];
  const extraTimeRanges = [
    { key: "3-months", label: t("last_3_months") },
    { key: "1-Year", label: t("last_year") },
    { key: "custom", label: t("custom_range") },
  ];

  useEffect(() => {
    getDevices()
      .then((r) => r.json())
      .then(({ data }) => setMachineList(data));
  }, []);

  useEffect(() => {
    setSelectedMetrics([]);
    setSelectedTimeframe("");

    if (!_.isNil(selectedMachineId)) {
      const selectedDevice = _.find(machineList, ["id", selectedMachineId]);

      setAllowed(isAllowed("generateReport", selectedDevice?.service_tier));
      setUnlimited(isAllowed("unlimitedReport", selectedDevice?.service_tier));

      setReportAvailableAt(
        selectedDevice?.report_available_at
          ? moment(selectedDevice?.report_available_at)
          : undefined
      );

      if (selectedDevice) {
        getDeviceMetrics(selectedDevice.id).then((data) => {
          setAdditionalMetrics(data);
        });
      }
    }
  }, [selectedMachineId, machineList]);

  useEffect(() => {
    if (_.isNil(selectedTimeframe) || selectedTimeframe === "custom") return;

    const [amount, unit] = selectedTimeframe.split("-");

    setSelectedStartDate(moment().add(-1 * Number(amount), unit as any));
  }, [selectedTimeframe]);

  const handleMetricsChange = (e: any) => {
    if (e.target.checked) {
      setSelectedMetrics([...selectedMetrics, e.target.value] as []);
    }
    if (!e.target.checked) {
      setSelectedMetrics(
        selectedMetrics.filter(
          (selectedMetrics) => selectedMetrics !== e.target.value
        )
      );
    }
  };

  const handleRangeChange = (startDate: Moment, endDate: Moment) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      getTimeseries({
        metrics: [...selectedMetrics, ...selectedAdditionalMetrics],
        start_time: selectedStartDate.toISOString(),
        end_time: selectedEndDate.toISOString(),
        device_id: selectedMachineId,
        format: "csv",
        granularity: calcGranularity(
          selectedStartDate.toISOString(),
          selectedEndDate.toISOString()
        ),
      })
        .then((r) => r.text())
        .then((csv) => {
          getDevices()
            .then((r) => r.json())
            .then(({ data }) => {
              setLoading(false);
              setMachineList(data);
              setReportData(csv);
              if (csv !== "") download("helios-report.csv", csv);
            });
        });
    }
  };

  const getTotalSelectedMetrics = () => {
    return selectedMetrics.length + selectedAdditionalMetrics.length;
  };

  const isFormValid = () => {
    return (
      !_.isEmpty(selectedMachineId) &&
      !_.isEmpty(selectedTimeframe) &&
      getTotalSelectedMetrics() > 0
    );
  };

  const isMetricMax = () => {
    return unlimited ? false : getTotalSelectedMetrics() >= tagLimit;
  };

  const upsellText = t("upsell_message");
  return (
    <Card>
      <CardContent>
        <form
          onSubmit={(e) => {
            onSubmitHandler(e);
          }}
        >
          {selectedMachineId && !allowed && (
            <FeatureNotAvailable upsellText={upsellText} />
          )}
          <div className={classes.flexCol}>
            <FormControl required className={classes.formControl}>
              <Typography gutterBottom variant="h5">
                {t("which_machine_message")}
              </Typography>
              <Select
                variant="outlined"
                color="primary"
                className={classes.input}
                value={selectedMachineId}
                onChange={(e: React.FormEvent<EventTarget>) =>
                  setSelectedMachineId((e.target as HTMLInputElement).value)
                }
              >
                {machineList.map((machine) => (
                  <MenuItem value={machine.id}>
                    {machine.name ? machine.name : machine.serial_number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              required
              className={classes.formControl}
              disabled={!allowed}
            >
              <Typography gutterBottom variant="h5">
                {t("choose_the_timeframe")}
              </Typography>

              <Select
                variant="outlined"
                color="primary"
                value={selectedTimeframe}
                onChange={(e: React.FormEvent<EventTarget>) =>
                  setSelectedTimeframe((e.target as HTMLInputElement).value)
                }
                className={classes.input}
              >
                {basicTimeRanges.map(({ key, label }) => (
                  <MenuItem value={key}>{label}</MenuItem>
                ))}
                {extraTimeRanges.map(({ key, label }) => (
                  <MenuItem value={key} disabled={!unlimited}>
                    {label} {!unlimited && " - [Tier 2]"}
                  </MenuItem>
                ))}
                {t("unlimited")}
              </Select>

              {selectedTimeframe === "custom" && (
                <div className={classes.picker}>
                  <DateRangePicker
                    onApply={(
                      _event: any,
                      picker: { startDate: Moment; endDate: Moment }
                    ) => handleRangeChange(picker.startDate, picker.endDate)}
                    open="left"
                    drops="auto"
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    inputStartDate={selectedStartDate}
                    inputEndDate={selectedEndDate}
                    maxDate={new Date()}
                  ></DateRangePicker>
                </div>
              )}
            </FormControl>
            <FormControl
              required
              component="fieldset"
              className={classes.formControl}
            >
              <Typography gutterBottom variant="h5">
                {t("what_report_contents_message")}
              </Typography>

              {selectedMachineId && !unlimited && (
                <Typography gutterBottom>
                  {`You can select up to ${tagLimit} tags at a time. (` +
                    getTotalSelectedMetrics() +
                    `/${tagLimit})`}{" "}
                  <Tooltip
                    style={{ fontSize: 14 }}
                    title="To remove this restriction, please go to gohelios.us to upgrade"
                  >
                    <HelpIcon />
                  </Tooltip>
                </Typography>
              )}
              <FormGroup className={classes.checkboxWrapper}>
                {metricMultiSelect.map(
                  ({ tag, label }: { tag: string; label: string }) => (
                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e) => handleMetricsChange(e)}
                          name={label}
                          value={tag}
                          checked={selectedMetrics.includes(tag)}
                          disabled={
                            (isMetricMax() && !selectedMetrics.includes(tag)) ||
                            !allowed
                          }
                        />
                      }
                      label={label}
                    />
                  )
                )}
              </FormGroup>
            </FormControl>
            <FormControl required className={classes.formControl}>
              <Typography gutterBottom variant="h6">
                {t("additional_metrics")}
              </Typography>
              <Autocomplete
                disabled={!allowed}
                multiple
                disableCloseOnSelect
                id="checkboxes-additional-metrics"
                className={classes.input}
                options={additionalMetrics}
                onChange={(_event, value) =>
                  setSelectedAdditionalMetrics(value)
                }
                getOptionDisabled={(_option) => isMetricMax()}
                noOptionsText={t("no_options")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t("select_metrics")}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      color="primary"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>

            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                color={loading ? "primary" : "secondary"}
                variant="contained"
                className={classes.button}
                disabled={
                  !isFormValid() ||
                  !allowed ||
                  (!unlimited && reportAvailableAt?.isAfter(moment()))
                }
              >
                {loading ? t("generating") + "..." : t("generate_report")}
              </Button>
              {!unlimited && reportAvailableAt?.isAfter(moment()) && (
                <div className={classes.captionContainer}>
                  <Typography className={classes.timer}>
                    {t("remove_restriction_message", {
                      time: reportAvailableAt.format("lll"),
                    })}
                  </Typography>
                  <Typography variant="caption">
                    <Trans
                      i18nKey="glossary:remove_restriction_message"
                      components={{
                        1: (
                          <a href="https://gohelios.u" target="blank">
                            gohelios.us
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                </div>
              )}
            </div>
            {reportData === "" && (
              <Typography>{t("data_not_found")}</Typography>
            )}
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

export default Measures;
