import { AppBar, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import ReportEventButton from "src/components/ReportEvent";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: theme.palette.text.primary,
    boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.10)",
    minHeight: "0",
    ".MuiToolbar-regular": {
      height: 400,
    },
    top: 65,
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10,
    marginRight: 30,
    flexGrow: 1,
  },
  tab: {
    height: 64,
    minWidth: 0,
    marginRight: 0,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

function DowntimeReportBar({
  onOpenMobileMenu,
  className,
  staticContext,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <Hidden smDown>
      <AppBar {...rest} className={clsx(classes.root)}>
        <div className={classes.tabs}>
          <div className={classes.tab}>
            <Typography
              id="simple-modal-title"
              variant="h5"
              gutterBottom
              style={{ paddingTop: "10px", margin: 0 }}
            >
              {t("experienced_downtime")}
            </Typography>
            <Typography
              id="simple-modal-title"
              variant="body1"
              gutterBottom
              style={{ margin: 0, color: "#999999" }}
            >
              {t("record_downtime_event")}
            </Typography>
          </div>
          <div style={{ margin: "10px" }}>
            <ReportEventButton
              machine={null}
              buttonText={t("report_unplanned_downtime")}
              preloaded={false}
            />
          </div>
        </div>
      </AppBar>
    </Hidden>
  );
}

export default DowntimeReportBar;
