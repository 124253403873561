import {
  ButtonGroup,
  colors,
  Container,
  Divider,
  Tab,
  Tabs,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import FeatureNotAvailable from "src/components/FeatureNotAvailable";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import { getDevice, getDeviceTelemDispCfgs } from "src/repos/devices";
import { isAllowed } from "src/utils/tier_permissions";
import { isOEMSuperUser, isSuperUser } from "../../../../utils/session";
import Analytics from "./Analytics";
import Anomalies from "./Anomalies";
import Configuration from "./Configuration";
import CurrentState from "./CurrentState";
import Events from "./Events";
import Maintenance from "./Maintenance";
import Overview from "./Overview";
import Samplers from "./Samplers";
import Alarms from "./Alarms";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    backgroundColor: colors.grey[300],
  },
  tabsWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexFlow: "wrap",
      alignItems: "flex-start",
    },
  },
}));

function MachineDetails() {
  const history = useHistory();
  const classes = useStyles();
  const { id, tab } = useParams();
  const [device, setDevice] = useState({});
  const [createdEvent] = useState({});
  const isUnconfigured = device.facility_id === null;
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    getDevice(id)
      .then((r) => r.json())
      .then((d) => {
        setDevice(d.data);
        getDeviceTelemDispCfgs(d.data.id);
      });
  }, [id]);

  const handleTabsChange = (event, value) => {
    history.replace(`/machines/${id}/${value}`);
  };

  const isSU = isSuperUser();
  const superUserOnlyTabs = isSU
    ? [
        { value: "configuration", label: t("configuration"), disabled: false },
        { value: "samplers", label: t("samplers"), disabled: isUnconfigured },
      ]
    : [];

  const isOEMSU = isOEMSuperUser();
  const OEMSuperUserOnlyTabs = isOEMSU
    ? [{ value: "samplers", label: "Samplers", disabled: isUnconfigured }]
    : [];

  const tabs = [
    { value: "overview", label: t("overview"), disabled: isUnconfigured },
    { value: "maintenance", label: t("maintenance"), disabled: isUnconfigured },
    { value: "analytics", label: t("analytics"), disabled: isUnconfigured },
    {
      value: "anomalies",
      label: t("anomaly_explorer"),
      disabled: isUnconfigured,
    },
    { value: "events", label: t("events"), disabled: isUnconfigured },
    {
      value: "machine_health",
      label: t("machine_health"),
      disabled: isUnconfigured,
    },
    { value: "alarms", label: t("alarms"), disabled: isUnconfigured },
    ...superUserOnlyTabs,
    ...OEMSuperUserOnlyTabs,
  ];

  if (isUnconfigured && tab !== "configuration")
    return <Redirect to={`/machines/${id}/configuration`} />;
  else if (!tab) return <Redirect to={`/machines/${id}/overview`} />;

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  const anomalyUpsellText = t("anomaly_upsell_text");
  const analyticsUpsellText = t("analytics_upsell_text");
  const maintenanceUpsellText = t("maintenance_upsell_text");

  return (
    <Page className={classes.root} title={tab.label}>
      <Container maxWidth="xl">
        <PageHeader
          pageTitle={t("machine_details")}
          machine={device}
          loading={_.isEmpty(device)}
        />
        <div className={classes.tabsWrapper}>
          <ButtonGroup></ButtonGroup>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={tab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                disabled={tab.disabled}
              />
            ))}
          </Tabs>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === "overview" && <Overview machine={device} />}
          {tab === "machine_health" && <CurrentState machine={device} />}
          {tab === "maintenance" &&
            (isAllowed("accessMaintenances", device?.service_tier) ? (
              <Maintenance machine={device} />
            ) : (
              <FeatureNotAvailable upsellText={maintenanceUpsellText} />
            ))}
          {tab === "anomalies" &&
            (isAllowed("accessAnomalyExplorer", device?.service_tier) ? (
              <Anomalies
                referrer={`/machines/${id}/anomalies`}
                device={device}
              />
            ) : (
              <FeatureNotAvailable upsellText={anomalyUpsellText} />
            ))}
          {tab === "events" &&
            (isAllowed("accessAnomalyExplorer", device?.service_tier) ? (
              <Events createdEvent={createdEvent} device={device} />
            ) : (
              <FeatureNotAvailable />
            ))}
          {tab === "analytics" &&
            (isAllowed("generateReport", device?.service_tier) ? (
              <Analytics machine={device} />
            ) : (
              <FeatureNotAvailable upsellText={analyticsUpsellText} />
            ))}
          {tab === "configuration" && (
            <Configuration machine={device} setMachine={setDevice} />
          )}
          {tab === "samplers" && (
            <Samplers machine={device} setMachine={setDevice} />
          )}
          {tab === "alarms" && (
            <Alarms machine={device} setMachine={setDevice} />
          )}
        </div>
      </Container>
    </Page>
  );
}

MachineDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default MachineDetails;
