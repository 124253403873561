import ServiceTier from "../types/ServiceTier";
import { isOEMSuperUser, isSuperUser } from "src/utils/session";

interface Permissions {
  [key: string]: Array<keyof ServiceTier>;
}

const permissions: Permissions = {
  generateReport: ["reports", "reports_unlimited"],
  unlimitedReport: ["reports_unlimited"],
  accessAnomalyExplorer: ["ad", "ad_adv"],
  accessEvents: ["events"],
  accessMaintenances: ["maintenance", "maintenance_unlimited"],
  unlimitedMaintenances: ["maintenance_unlimited"],
};

export function isAllowed(
  feature: string,
  serviceTier: ServiceTier | undefined
): boolean {
  const keys = permissions[feature];

  if (isSuperUser() || isOEMSuperUser()) {
    return true;
  } else {
    return (
      serviceTier?.ui === true &&
      keys.some(
        (key: keyof ServiceTier) => serviceTier && serviceTier[key] === true
      )
    );
  }
}
