import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import AddOutlined from "@material-ui/icons/AddOutlined";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import palette from "src/theme/palette";
import { demoValue } from "src/utils/demoMode";
import { gridTheme } from "../../../theme";
import { useTranslation } from "react-i18next";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddOutlined {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <SortIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  AssignmentTurnedInOutlined: forwardRef((props, ref) => (
    <AssignmentTurnedInOutlined {...props} ref={ref} />
  )),
};

function Results({
  className,
  pageSizeOptions,
  eventList,
  initialPage,
  onRowClicked,
  showFilters,
  showHeaderActions,
  showToolbar,
  pageSize,
  showEmptyRows,
  isLoading,
}) {
  const { t } = useTranslation(["glossary", "common"]);

  const author = (evt) => {
    if (evt.user) return evt.user;
    if (evt.sampler) return evt.sampler.name;
    return t("no_author");
  };

  const defaultColumns = [
    { title: t("name"), field: "name" },
    {
      title: t("start_date"),
      field: "start_at",
      render: ({ start_at }) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(start_at).isValid()
              ? moment(start_at).format("lll")
              : "Invalid"}
          </div>
        );
      },
    },
    {
      title: t("end_date"),
      field: "end_at",
      render: ({ end_at }) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(end_at).isValid()
              ? moment(end_at).format("lll")
              : "Invalid"}
          </div>
        );
      },
    },
    {
      title: t("downtime_duration"),
      customSort: (a, b) => {
        const endA = moment(a.downtime_end_at);
        const endB = moment(b.downtime_end_at);
        if (!endA.isValid()) {
          return 1;
        }
        if (!endB.isValid()) {
          return -1;
        }

        return (
          endA.diff(moment(a.downtime_start_at)) -
          endB.diff(moment(b.downtime_start_at))
        );
      },
      render: ({ downtime_start_at, downtime_end_at }) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(downtime_start_at).isValid() &&
            moment(downtime_start_at).isValid()
              ? moment
                  .duration(
                    moment(downtime_start_at).diff(moment(downtime_end_at))
                  )
                  .humanize()
              : t("no_downtime")}
          </div>
        );
      },
    },
    {
      title: t("authored_by"),
      field: "author",
      render: (evt) => {
        return <span>{demoValue(author(evt))}</span>;
      },
    },
  ];

  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={gridTheme}>
        <MaterialTable
          icons={tableIcons}
          localization={{
            body: {
              emptyDataSourceMessage: t("no_records_to_display"),
            },
            toolbar: {
              exportTitle: t("export"),
              exportAriaLabel: t("export"),
              exportName: t("export"),
              showColumnsTitle: t("show_columns"),
              searchPlaceholder: t("search"),
              addRemoveColumns: t("add_or_remove_columns"),
            },
            grouping: {
              placeholder: t("drag_headers_message"),
            },
            pagination: {
              labelDisplayedRows:
                eventList.length === 0
                  ? "0-0 " + t("of") + " 0"
                  : "{from}-{to} " + t("of") + " {count}",
              labelRowsSelect: t("rows"),
              firstTooltip: t("first_page"),
              previousTooltip: t("previous_page"),
              nextTooltip: t("next_page"),
              lastTooltip: t("last_page"),
            },
          }}
          components={{
            Toolbar: (props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <MTableToolbar {...props} />
                </div>
              </div>
            ),
            Container: (props) => (
              <div style={{ background: palette.gray.white }}>
                {props.children}
              </div>
            ),
          }}
          options={{
            selection: false,
            grouping: false,
            pageSize,
            initialPage,
            filtering: showFilters,
            columnsButton: showHeaderActions,
            exportButton: false,
            exportAllData: true,
            filterCellStyle: { backgroundColor: palette.gray.lightest },
            actionsColumnIndex: -1,
            toolbar: showToolbar,
            showTitle: false,
            pageSizeOptions,
            emptyRowsWhenPaging: showEmptyRows,
          }}
          onRowClick={onRowClicked}
          columns={defaultColumns}
          data={eventList}
        />
      </MuiThemeProvider>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  eventList: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  onRowClicked: PropTypes.func,
  showFilters: PropTypes.bool,
  showHeaderActions: PropTypes.bool,
  showToolbar: PropTypes.bool,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  columns: PropTypes.array,
  showEmptyRows: PropTypes.bool,
  // This prop is not being used for conditional rendering. See comment at the top of
  // ./EventList.jsx for more context.
  isLoading: PropTypes.bool,
};

Results.defaultProps = {
  eventList: [],
  pageSizeOptions: [5, 10, 20, 50],
  onRowClicked: () => {},
  showFilters: true,
  showHeaderActions: true,
  showToolbar: true,
  initialPage: 0,
  pageSize: 20,
  showEmptyRows: false,
  isLoading: true,
};

export default Results;
