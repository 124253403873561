import _ from "lodash";
import { getAuthToken } from "src/utils/session";

const [API_URL, ENVIRONMENT] = ((hostname) => {
  if (hostname === "www.helios-app.com" || hostname === "helios-app.com") {
    return ["https://api.helios-app.com/", "production"];
  } else if (
    hostname === "www.staging.helios-app.com" ||
    hostname === "staging.helios-app.com"
  ) {
    return ["https://api.staging.helios-app.com/", "staging"];
  } else {
    return ["http://localhost:4000/", "development"];
  }
})(window.location.hostname);

const BASE_URI = `${API_URL}v1`;
const REQUIRED = Symbol();

function makeHeaders(headers) {
  return {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: getAuthToken(),
    ...(headers || {}),
  };
}

const makeQueryJSON = (query, defaults) => {
  query = { ...(defaults || {}), ...(query || {}) };
  const parts = _.reduce(
    query,
    (acc, value, key) => {
      acc.push(`${key}=${JSON.stringify(value)}`);
      return acc;
    },
    []
  );
  if (parts) {
    return `?${parts.join("&")}`;
  } else {
    return "";
  }
};

function makeQuery(query, defaults) {
  query = { ...(defaults || {}), ...(query || {}) };
  const missingRequired = _.reduce(
    query,
    (acc, value, key) => {
      if (value === REQUIRED) acc.push(key);
      return acc;
    },
    []
  );
  if (!_.isEmpty(missingRequired))
    throw TypeError(
      `Missing required query params: ${JSON.stringify(missingRequired)}.`
    );
  query = _.pickBy(query, (value) => value !== undefined);
  query = _.map(query, (value, key) => {
    if (_.isArray(value))
      return _.map(value, (element) => `${key}[]=${element}`).join("&");
    else if (_.isObject(value))
      return _.map(value, (element, ikey) => `${key}[${ikey}]=${element}`).join(
        "&"
      );
    else return `${key}=${value}`;
  }).join("&");
  return query !== "" ? `?${query}` : "";
}

function request(method, path, body, headers) {
  return fetch(`${BASE_URI}/${path}`, {
    method: method,
    body: body && JSON.stringify(body),
    headers: makeHeaders(headers),
    credentials: "include",
  });
}

function get(path, headers) {
  return request("GET", path, undefined, headers);
}

function post(path, body, headers) {
  return request("POST", path, body, {
    "Content-Type": "application/json",
    ...headers,
  });
}

function put(path, body, headers) {
  return request("PUT", path, body, {
    "Content-Type": "application/json",
    ...headers,
  });
}

function del(path, headers) {
  return request("DELETE", path, undefined, headers);
}

function manage(req) {
  const handlers = {};
  let data;
  let ok;
  let status;
  let handleAny;
  let handled = false;
  const m = {
    on: (type, handler) => {
      if (handler) handlers[type] = handler;
      // type is handler
      else handleAny = type;
      handle();
      return m;
    },
  };
  const handle = () => {
    if (!handled && status !== undefined) {
      const okHandler = handlers["ok"];
      const statusHandler = handlers[status];
      const errorHandler = handlers["error"];
      handled = true;
      if (ok && okHandler) okHandler(data, status);
      else if (statusHandler) statusHandler(data, status);
      else if (errorHandler) errorHandler(data, status);
      else if (handleAny) handleAny(data);
      else handled = false;
    }
  };
  req.then((r) => {
    r.text().then((data_) => {
      data = data_ ? JSON.parse(data_) : null;
      status = r.status;
      ok = r.ok;
      handle();
    });
  });
  return m;
}

export {
  get,
  del,
  put,
  post,
  manage,
  BASE_URI,
  API_URL,
  makeQuery,
  makeQueryJSON,
  REQUIRED,
  ENVIRONMENT,
};
