import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useMemo } from "react";
import { MachineCard } from "src/components/MachineSnapshot";
import _ from "lodash";
import Carousel, { consts } from "react-elastic-carousel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(() => ({
  arrowButton: {
    minWidth: "40px",
    padding: "0px 0px",
    "&:hover": {
      backgroundColor: "#F3F6F8",
    },
  },
  gridItem: {
    paddingTop: "0",
  },
}));

const MachineSnapshot = ({
  facilityList,
  machines,
  metricAverages,
  downtime,
  downtimeLoading,
}) => {
  const classes = useStyles();

  const machine = (machineId) => {
    return machines.find((machine) => machine.id === machineId);
  };

  const arrowButton = ({ type, onClick, isEdge }) => {
    return (
      <Button
        onClick={onClick}
        disabled={isEdge}
        className={classes.arrowButton}
      >
        {type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
      </Button>
    );
  };

  const sortedMetrics = useMemo(() => {
    const averagesList = Object.entries(metricAverages).map((e) => ({
      id: e[0],
      ...e[1],
    }));

    return averagesList.sort(function (a, b) {
      var aw = ("machine_running" in a) + ("operating_productivity" in a);
      var bw = ("machine_running" in b) + ("operating_productivity" in b);
      return bw - aw;
    });
  }, [metricAverages]);

  const allDevices = sortedMetrics.length > 0 ? sortedMetrics : machines;

  return (
    <>
      <Carousel
        itemsToShow={1}
        pagination={false}
        renderArrow={arrowButton}
        itemPadding={[0, 10]}
      >
        {_.chunk(allDevices, 2).map((devices) => (
          <Grid
            container
            spacing={3}
            key={`${devices[0].id}-${devices[1]?.id}`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={classes.gridItem}
            >
              <MachineCard
                machine={machine(devices[0].id)}
                facilityList={facilityList}
                metricAverages={metricAverages}
                downtime={downtime[devices[0].id]}
                downtimeLoading={downtimeLoading}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={classes.gridItem}
            >
              <MachineCard
                machine={machine(devices[1]?.id)}
                facilityList={facilityList}
                metricAverages={metricAverages}
                downtime={downtime[devices[1]?.id]}
                downtimeLoading={downtimeLoading}
              />
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </>
  );
};

export default MachineSnapshot;
