export function getQuery() {
  const query = {};
  for (let [key, value] of new URLSearchParams(window.location.search)) {
    query[key] = value;
  }
  return query;
}

export function getQueryParam(param) {
  return getQuery()[param];
}
