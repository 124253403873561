import Cookies from "js-cookie";
import _ from "lodash";

export const currentUser = () => {
  return Cookies.get("currentUser")
    ? JSON.parse(Cookies.get("currentUser"))
    : null;
};

export const updateCurrentUser = (user) =>
  Cookies.set("currentUser", JSON.stringify(user));

export const getAuthToken = () => {
  return Cookies.get("sessionId");
};

export const isAdmin = () => {
  const user = currentUser();
  return user?.role === "admin" || user?.role === "superuser";
};

export const isCustomerAdmin = () => {
  const user = currentUser();
  return user?.role === "admin";
};

export const isSuperUser = () => {
  const user = currentUser();
  return user?.role === "superuser";
};

export const isOEMSuperUser = () => {
  const user = currentUser();
  return user?.role === "oem_superuser";
};

export const isUnprivilegedUser = () => {
  const user = currentUser();
  return user?.role === "user";
};

export const currentUserRole = () => {
  const user = currentUser();
  return user?.role;
};

export const termsAccepted = () =>
  !_.isNil(currentUser()?.["terms_accepted_at"]);

export const shouldResetPassword = () =>
  currentUser()?.["reset_password"] === true;

export const logout = () => {
  Cookies.remove("sessionId");
  Cookies.remove("currentUser");
  window.location = "/login";
};
