import { TableCell, TableRow } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Skeleton } from "@material-ui/lab";
import _ from "lodash";
import MaterialTable, { MTableBody } from "material-table";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import MachineConnectionStatus, {
  tagValueToHuman,
} from "src/components/MachineConnectionStatus";
import { getDevices } from "src/repos/devices";
import { getFacilities } from "src/repos/facilities";
import telemetries from "src/repos/telemetries";
import palette from "src/theme/palette";
import { demoValue, isDemoModeEnabled } from "src/utils/demoMode";
import { gridTheme } from "../../../theme";
import { useSocketContext } from "src/context/SocketContext";
import { useTranslation } from "react-i18next";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <SortIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Results({ className, pageSizeOptions, ...rest }) {
  const history = useHistory();
  const { socket } = useSocketContext();
  const table = useRef(null);
  const machineState = useRef({});

  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [facilities, setFacilities] = useState([]);
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    getDevices()
      .then((r) => r.json())
      .then(({ data }) => {
        setMachines(
          isDemoModeEnabled()
            ? data.filter(
                (d) => d.configured && d.serial_number.slice(-4) !== "_SIM"
              )
            : data
        );
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getFacilities()
      .then((r) => r.json())
      .then((d) => {
        setFacilities(
          _.reduce(
            d.data,
            (acc, facility) => {
              acc[facility.id] = facility;
              return acc;
            },
            {}
          )
        );
      });
  }, []);

  useEffect(() => {
    if (machines && machines.length)
      machineState.current = _.reduce(
        machines,
        (acc, machine) => {
          acc[machine.id] = "Unknown";
          return acc;
        },
        {}
      );
    return telemetries.subscribe(
      _.map(machines, (machine) => machine.id),
      ["machine_state"],
      (id, state) => {
        machineState.current[id] = state.machine_state;
        if (table.current) {
          table.current.dataManager.setData(machines);
          table.current.setState(table.current.dataManager.getRenderState());
        }
      },
      socket
    );
  }, [machines, socket]);

  const onRowClicked = (event, { serial_number, id }) => {
    history.push("/machines/" + id);
  };

  const facilityName = (facility_id) => {
    const facility = facilities[facility_id];

    if (facility) return demoValue(facility.name);
    else return "Unconfigured";
  };

  const facilityLocation = (facility_id) => {
    const facility = facilities[facility_id];

    if (facility) return demoValue(`${facility.city}, ${facility.state}`);
    else return "";
  };

  const machineConnectionStatus = (machine) => {
    return <MachineConnectionStatus machineId={machine?.id} />;
  };

  const getMachineState = (serialNumber) => {
    return machineState.current[serialNumber];
  };

  const getMachineStateHuman = (serialNumber) => {
    const value = getMachineState(serialNumber);
    return tagValueToHuman[value] || "";
  };

  const filterAndSearch = (term, value) => {
    term = term.toLowerCase();
    value = value.toLowerCase();
    const subs = [value, ...value.split(" ")];
    return _.some(subs, (s) => s.indexOf(term) === 0);
  };

  const sort = (a, b) => {
    return a < b ? -1 : a > b ? 1 : 0;
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={gridTheme}>
        <MaterialTable
          tableRef={table}
          icons={tableIcons}
          components={{
            Container: (props) => (
              <div style={{ background: palette.gray.white }}>
                {props.children}
              </div>
            ),
            Body: (props) =>
              isLoading ? (
                <tbody>
                  {Array.apply(null, { length: 5 }).map((e, i) => (
                    <TableRow width="100%" key={i}>
                      {Array.apply(null, { length: 8 }).map((e, i) => (
                        <TableCell scope="row" key={`cell-${i}`}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </tbody>
              ) : (
                <MTableBody {...props} />
              ),
          }}
          onRowClick={onRowClicked}
          options={{
            selection: false,
            pageSize: 20,
            filtering: true,
            columnsButton: true,
            exportButton: true,
            exportAllData: true,
            filterCellStyle: { backgroundColor: palette.gray.lightest },
            grouping: true,
            actionsColumnIndex: -1,
            toolbar: true,
            pageSizeOptions,
            emptyRowsWhenPaging: false,
          }}
          columns={[
            { title: t("serial_number"), field: "serial_number" },
            { title: t("machine_s/n"), field: "manufacturer_serial_number" },
            { title: t("device_name"), field: "name" },
            {
              title: t("machine_status"),
              render: (rowData) => machineConnectionStatus(rowData),
              customFilterAndSearch: (term, rowData) => {
                if (!_.isEmpty(machineState.current)) {
                  return filterAndSearch(
                    term,
                    getMachineStateHuman(rowData?.id)
                  );
                }
              },
              customSort: (rowA, rowB) => {
                return sort(
                  getMachineStateHuman(rowA?.id),
                  getMachineStateHuman(rowB?.id)
                );
              },
              defaultFilter: isDemoModeEnabled() ? "r" : "",
            },
            { title: t("make"), field: "make" },
            { title: t("model"), field: "model" },
            {
              title: t("facility"),
              field: "facility",
              render: (rowData) => {
                return facilityName(rowData.facility_id);
              },
              customFilterAndSearch: (term, rowData) => {
                if (!_.isEmpty(machineState.current)) {
                  return filterAndSearch(
                    term,
                    facilityName(rowData.facility_id)
                  );
                }
              },
              customSort: (rowA, rowB) => {
                return sort(
                  facilityName(rowA.facility_id),
                  facilityName(rowB.facility_id)
                );
              },
            },
            // Location Includes City, State
            {
              title: t("location"),
              field: "location",
              render: (rowData) => {
                return facilityLocation(rowData.facility_id);
              },
              customFilterAndSearch: (term, rowData) => {
                if (!_.isEmpty(machineState.current)) {
                  return filterAndSearch(
                    term,
                    facilityLocation(rowData.facility_id)
                  );
                }
              },
              customSort: (rowA, rowB) => {
                return sort(
                  facilityLocation(rowA.facility_id),
                  facilityLocation(rowB.facility_id)
                );
              },
            },
          ]}
          data={machines}
          title={t("machine_list")}
          localization={{
            body: {
              emptyDataSourceMessage: t("no_records_to_display"),
            },
            toolbar: {
              exportTitle: t("export"),
              exportAriaLabel: t("export"),
              exportName: t("export"),
              showColumnsTitle: t("show_columns"),
              searchPlaceholder: t("search"),
              addRemoveColumns: t("add_or_remove_columns"),
            },
            grouping: {
              placeholder: t("drag_headers_message"),
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
              labelRowsSelect: t("rows"),
              firstTooltip: t("first_page"),
              previousTooltip: t("previous_page"),
              nextTooltip: t("next_page"),
              lastTooltip: t("last_page"),
            },
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  machines: PropTypes.array,
  pageSizeOptions: PropTypes.array,
};

Results.defaultProps = {
  machines: [],
  pageSizeOptions: [10, 20, 50],
};

export default Results;
