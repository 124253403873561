import uuid from "uuid/v1";
import mock from "src/utils/mock";
mock.onGet("/api/machine/config").reply(200, {
  machineConfig: {
    id: uuid(),
    serial_number: "",
    firmware_version: "",
    frequency: "",
    tags: [],
  },
});
mock.onGet("/api/machine/config/sample").reply(200, {
  machineConfig: {
    id: uuid(),
    serial_number: "4g3t84-dfjd553-21456",
    firmware_version: "v.4.32",
    frequency: "1 Hz",
    tags: ["North Region", "Maine", "Box"],
  },
});
