import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { oneOfType, PropTypes } from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  dateTimeWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  dateTimeSeparator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  description: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  timeWrapper: {
    paddingBottom: "0 !important",
  },
  descriptionWrapper: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "5px !important",
    },
  },
}));

function DateTimeHeader({
  startDate,
  startTime,
  endDate,
  endTime,
  description,
  ...rest
}) {
  const classes = useStyles();

  const formatDate = (t) => moment(t).format("MM/DD/YYYY");
  const formatTime = (t) => moment(t).format("hh:mm A");

  return (
    <Grid container spacing={4} className={classes.wrapper}>
      <Grid item xs={12} sm={6} className={classes.timeWrapper}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={classes.dateTimeWrapper}>
            <Typography variant="body2">{formatDate(startDate)}</Typography>
            <Typography variant="h5">{formatTime(startDate)}</Typography>
          </div>
          <Typography variant="h5" className={classes.dateTimeSeparator}>
            -
          </Typography>
          <div className={classes.dateTimeWrapper}>
            <Typography variant="body2">{formatDate(endDate)}</Typography>
            <Typography variant="h5">{formatTime(endDate)}</Typography>
          </div>
        </div>
      </Grid>
      {description ? (
        <Grid item xs={12} sm={6} className={classes.descriptionWrapper}>
          <Typography variant="h5" className={classes.description}>
            {description}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

DateTimeHeader.propTypes = {
  startDate: oneOfType([PropTypes.string, PropTypes.object]),
  startTime: PropTypes.string,
  endDate: oneOfType([PropTypes.string, PropTypes.object]),
  endTime: PropTypes.string,
  description: PropTypes.string,
};

DateTimeHeader.defaultProps = {
  startDate: "0/0/00",
  startTime: "0/0/00",
  endDate: "00:00PM",
  endTime: "00:00PM",
  description: null,
};

export default DateTimeHeader;
