import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import TileCard from "./TileCard";
import machineSubscribe from "src/utils/machineSubscribe";
import { tileAvg } from "src/utils/number";
import { useTranslation } from "react-i18next";

const MachineSpeed = ({ machines, socket, chartData }) => {
  const [machinesSpeed, setMachinesSpeed] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const speedAvg = useMemo(() => {
    if (!machines?.length) return "--";
    return tileAvg(machinesSpeed, Object.keys(machinesSpeed).length);
  }, [machinesSpeed, machines]);
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    return machineSubscribe(
      machines,
      "machine_speed",
      socket,
      setMachinesSpeed,
      setIsLoading
    );
  }, [machines, socket]);

  if (isLoading) {
    return <TileCard isSkeleton />;
  }

  return (
    <TileCard
      label={t("machine_speed")}
      units={t("rpm")}
      value={speedAvg}
      chartData={chartData}
    />
  );
};

export default MachineSpeed;
