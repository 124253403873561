import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { updateUser } from "src/repos/users";
import palette from "src/theme/palette";
import {
  currentUser,
  logout,
  termsAccepted,
  updateCurrentUser,
} from "src/utils/session";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  subTitle: {
    margin: "30px 0 10px",
  },
}));

export function Terms() {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(!termsAccepted());

  const handleDisagreement = () => logout();
  const handleAgreement = () => {
    updateUser({
      ...currentUser(),
      terms_accepted_at: new Date().toISOString(),
    })
      .then((r) => r.json())
      .then(({ data }) => {
        updateCurrentUser(data);
        setOpen(false);
      });
  };
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <Dialog open={open} maxWidth={"md"} fullScreen={fullScreen}>
      <DialogTitle>
        <Typography variant="h4" className={classes.title}>
          {t("glossary:terms_of_service:terms_of_access_and_use")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {t("glossary:terms_of_service:terms_of_access_and_use_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("modifications")}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:modifications_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:trademarks_and_copyrights")}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:trademarks_and_copyrights_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:limited_license")}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:limited_license_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:links_to_third-party_websites")}
          </Typography>
          <Typography variant="body1">
            {t(
              "glossary:terms_of_service:links_to_third-party_websites_message"
            )}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:use_restrictions")}{" "}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:use_restrictions_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:termination")}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:termination_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:disclaimer_of_warranty")}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:disclaimer_of_warranty_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:limitation_of_liability")}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:limitation_of_liability_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:complete_agreement")}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:complete_agreement_message")}
          </Typography>

          <Typography variant="h5" className={classes.subTitle}>
            {t("glossary:terms_of_service:applicable_laws")}
          </Typography>
          <Typography variant="body1">
            {t("glossary:terms_of_service:applicable_laws_message")}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisagreement} color="secondary">
          {t("disagree")}
        </Button>
        <Button
          onClick={handleAgreement}
          color="secondary"
          autoFocus
          style={{ color: palette.status.error.main }}
        >
          {t("agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
