import { colors, Container, Divider, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import Events from "src/views/SunScreens/Reports/Events";
import Maintenances from "src/views/SunScreens/Reports/Maintenances";
import Measures from "src/views/SunScreens/Reports/Measures";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    backgroundColor: colors.grey[300],
    marginBottom: theme.spacing(3),
  },
  tabsWrapper: {},
}));

function Reports() {
  const classes = useStyles();
  const history = useHistory();
  let { tab } = useParams();
  const { t } = useTranslation(["glossary", "common"]);

  if (!tab) return <Redirect to={`/reports/measures`} />;

  const handleTabsChange = (event, value) => {
    history.replace(`/reports/${value}`);
  };

  const tabs = [
    { value: "measures", label: t("measures") },
    { value: "maintenances", label: t("maintenances") },
    { value: "events", label: t("events") },
  ];

  return (
    <Page className={classes.root} title={"Reports"}>
      <Container maxWidth="xl">
        <PageHeader pageTitle={t("reports")} />
        <div className={classes.tabsWrapper}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={tab}
            variant="scrollable"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === "measures" && <Measures />}
          {tab === "maintenances" && <Maintenances />}
          {tab === "events" && <Events />}
        </div>
      </Container>
    </Page>
  );
}

export default Reports;
