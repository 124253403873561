import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import HelpIcon from "@material-ui/icons/Help";
import { getFacility } from "src/repos/facilities";

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: 0,
  },
  keywords: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(2),
  },
  chips: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  select: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: theme.spacing(1),
  },
  tagLabel: {
    marginLeft: "10px",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "15px",
  },
  formControl: {
    width: "100%",
  },
  button: {
    backgroundColor: colors.red[700],
    "&:hover": {
      backgroundColor: colors.red[900],
    },
  },
  colorGray: {
    color: "gray",
  },
  notificationFormControl: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function AlarmForm({
  machine,
  alarm,
  availableMetrics,
  machineStates,
  emailList,
  onCreateAlarm,
  onUpdateAlarm,
  onDeleteAlarm,
  className,
  ...rest
}) {
  const { t } = useTranslation(["glossary", "common"]);

  const SECONDS_IN_WEEK = 604800;
  const SECONDS_IN_DAY = 86400;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_MINUTE = 60;

  const THRESHOLD_ALARM = "value_threshold_for_time_period_in_state";
  const ANOMALY_ALARM = "anomalous_for_time_period";

  const classes = useStyles();
  const [availableEmails, setAvailableEmails] = useState([]);
  const [currentAlarm, setCurrentAlarm] = useState({
    name: alarm?.name || "",
    function: alarm?.function || THRESHOLD_ALARM,
    extra_emails: alarm?.extra_emails || [],
    sap_notification: alarm?.sap_notification,
    sap_last_notified: null,
    sap_rate_in_seconds: alarm?.sap_rate_in_seconds || SECONDS_IN_WEEK,
  });
  //default values
  const [alarmArgs, setAlarmArgs] = useState({
    machine_states: alarm?.arguments?.machine_states || [],
    comp: alarm?.arguments?.comp || ">=",
    tag_name: alarm?.arguments?.tag_name || "",
    period: alarm?.arguments?.period / 1000 || 0,
    threshold: alarm?.arguments?.threshold || 0,
    uts_ad_enabled: alarm.arguments?.uts_ad_enabled || false,
    threshold_ad_enabled: alarm.arguments?.threshold_ad_enabled || false,
  });
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const isNew = alarm?.id === undefined;
  const title = isNew ? t("create_alarm") : t("alarm_configuration");

  const closeDeleteConfirm = () => setOpenDeleteConfirm(false);

  const [enableSapNotification, setEnableSapNotification] = useState(false);
  const [sapNotificationFields, setSapNotificationFields] = useState({});
  const [notificationRateUnit, setNotificationRateUnit] = useState("days");
  const [notificationRateError, setNotificationRateError] = useState("");

  const [SAPEnabled, setSAPEnabled] = useState(false);

  useEffect(() => {
    const SAPEnabledCheck = async () => {
      try {
        const response = await getFacility(machine.facility_id);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const facility = await response.json();
        const SAPProtocol = facility?.data?.sap_protocol;
        setSAPEnabled(SAPProtocol !== undefined && SAPProtocol !== null);
      } catch (error) {
        console.error("Failed to fetch facility:", error);
        setSAPEnabled(false);
      }
    };

    SAPEnabledCheck();
  }, [machine.facility_id]);

  useEffect(() => {
    //convert notification rate to most readable unit possible
    let converted_alarm_rate = alarm?.sap_rate_in_seconds || SECONDS_IN_WEEK;
    if (converted_alarm_rate % SECONDS_IN_DAY === 0) {
      setNotificationRateUnit("days");
      converted_alarm_rate /= SECONDS_IN_DAY;
    } else if (converted_alarm_rate % SECONDS_IN_HOUR === 0) {
      setNotificationRateUnit("hours");
      converted_alarm_rate /= SECONDS_IN_HOUR;
    } else {
      setNotificationRateUnit("minutes");
      converted_alarm_rate /= SECONDS_IN_MINUTE;
    }

    setCurrentAlarm({
      name: alarm?.name || "",
      function: alarm?.function || THRESHOLD_ALARM,
      extra_emails: alarm?.extra_emails || [],
      sap_notification: alarm?.sap_notification || null,
      sap_last_notified: null,
      sap_rate_in_seconds: converted_alarm_rate,
    });

    setAlarmArgs({
      machine_states: alarm?.arguments?.machine_states || [],
      comp: alarm?.arguments?.comp || ">=",
      tag_name: alarm?.arguments?.tag_name || "",
      period: alarm?.arguments?.period / 1000 || 0,
      threshold: alarm.arguments?.threshold || 0,
      uts_ad_enabled: alarm.arguments?.uts_ad_enabled || false,
      threshold_ad_enabled: alarm.arguments?.threshold_ad_enabled || false,
    });

    //default check or uncheck the checkbox based on whether you have a JSON message defined for the alarm
    if (
      alarm.sap_notification === null ||
      alarm.sap_notification === undefined
    ) {
      setEnableSapNotification(false);
    } else {
      setEnableSapNotification(true);
    }

    setSapNotificationFields({
      NotifType: "M4",
      ShortText: alarm.sap_notification?.ShortText || "",
      Priority: "2",
      FunctLoc: alarm.sap_notification?.FunctLoc || "",
      Reportedby: "Helios",
      CodeGroup: "IP3",
      Coding: "0160",
      Breakdown: false,
      Status: "E0010",
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarm]);

  useEffect(() => {
    setAvailableEmails(emailList);
  }, [emailList]);

  const handleFormChange = (event) => {
    event.persist();
    setCurrentAlarm({
      ...currentAlarm,
      [event.target.name]: event.target.value,
    });
  };

  const handleNotificationRateInput = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    if (value === "0") {
      setNotificationRateError(t("notification_rate_error"));
    } else {
      setNotificationRateError("");
    }
    setCurrentAlarm({
      ...currentAlarm,
      [event.target.name]: value,
    });
  };

  const handleArgChange = (event) => {
    event.persist();
    setAlarmArgs({
      ...alarmArgs,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (notificationRateError) {
      return;
    }

    if (
      currentAlarm.function === ANOMALY_ALARM &&
      !alarmArgs.uts_ad_enabled &&
      !alarmArgs.threshold_ad_enabled
    ) {
      alert("Please enable at least one Anomaly Detection Type");
      return;
    }

    //convert seconds back to ms
    const args = {
      ...alarmArgs,
      period: alarmArgs.period * 1000,
      threshold: parseFloat(alarmArgs.threshold),
    };

    if (!enableSapNotification) {
      currentAlarm.sap_notification = null;
    } else {
      try {
        if (sapNotificationFields.ShortText === "") {
          sapNotificationFields.ShortText = currentAlarm.name;
        }
        currentAlarm.sap_notification = { ...sapNotificationFields };
      } catch (error) {
        return;
      }
    }

    //convert notification rate to seconds
    switch (notificationRateUnit) {
      case "minutes":
        currentAlarm.sap_rate_in_seconds *= SECONDS_IN_MINUTE;
        break;
      case "hours":
        currentAlarm.sap_rate_in_seconds *= SECONDS_IN_HOUR;
        break;
      case "days":
        currentAlarm.sap_rate_in_seconds *= SECONDS_IN_DAY;
        break;
      default:
      //do nothing
    }

    isNew
      ? onCreateAlarm({
          ...currentAlarm,
          arguments: args,
          device_id: machine.id,
        })
      : onUpdateAlarm({
          ...currentAlarm,
          id: alarm.id,
          arguments: args,
        });
  };

  const handleSapCheckboxChange = (event) => {
    setEnableSapNotification(event.target.checked);
  };

  const handleUtsAnomalyCheckboxChange = (event) => {
    setAlarmArgs({
      ...alarmArgs,
      uts_ad_enabled: event.target.checked,
    });
  };

  const handleThresholdAnomalyCheckboxChange = (event) => {
    setAlarmArgs({
      ...alarmArgs,
      threshold_ad_enabled: event.target.checked,
    });
  };

  const handleFieldChange = (fieldName, value) => {
    setSapNotificationFields({
      ...sapNotificationFields,
      [fieldName]: value,
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={title}
        action={
          !isNew ? (
            <IconButton
              aria-label="delete"
              onClick={() => setOpenDeleteConfirm(true)}
              style={{
                marginTop: -5,
                marginBottom: -15,
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : null
        }
      />
      <Divider />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  label={t("alarm_name")}
                  variant="outlined"
                  id="name"
                  name="name"
                  required
                  value={currentAlarm?.name}
                  onChange={handleFormChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <InputLabel required id="alarm_type">
                  {t("alarm_type")}
                </InputLabel>
                <Tooltip
                  PopperProps={{ style: { zIndex: 1 } }}
                  title={t("anomaly_alarm_tooltip")}
                >
                  <Select
                    variant="outlined"
                    color="primary"
                    labelId="alarm-label"
                    id="function"
                    name="function"
                    required
                    style={{ width: "100%" }}
                    value={currentAlarm?.function}
                    onChange={handleFormChange}
                  >
                    <MenuItem value={THRESHOLD_ALARM}>
                      {t("threshold")}
                    </MenuItem>
                    <MenuItem value={ANOMALY_ALARM}>{t("anomaly")}</MenuItem>
                  </Select>
                </Tooltip>
              </FormControl>
            </Grid>
            {currentAlarm.function === ANOMALY_ALARM && (
              <>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="uts_ad_enabled"
                        checked={alarmArgs?.uts_ad_enabled}
                        onChange={handleUtsAnomalyCheckboxChange}
                        value={alarmArgs?.uts_ad_enabled}
                      />
                    }
                    label={
                      <span>
                        {t("enable_uts_anomaly_detection")}
                        <Tooltip
                          title={t("enable_uts_anomaly_detection_tooltip")}
                        >
                          <HelpIcon
                            style={{
                              display: "inline",
                              fontSize: 16,
                              marginLeft: 3,
                            }}
                          />
                        </Tooltip>
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={alarmArgs?.threshold_ad_enabled}
                        onChange={handleThresholdAnomalyCheckboxChange}
                      />
                    }
                    label={
                      <span>
                        {t("enable_threshold_anomaly_detection")}
                        <Tooltip
                          title={t(
                            "enable_threshold_anomaly_detection_tooltip"
                          )}
                        >
                          <HelpIcon
                            style={{
                              display: "inline",
                              fontSize: 16,
                              marginLeft: 3,
                            }}
                          />
                        </Tooltip>
                      </span>
                    }
                  />
                </Grid>
              </>
            )}
            {currentAlarm?.function === THRESHOLD_ALARM && (
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel required id="machine_state">
                    {t("when_machine_state_is")}
                  </InputLabel>
                  <Tooltip
                    PopperProps={{ style: { zIndex: 1 } }}
                    title={t("when_machine_state_is_tooltip")}
                  >
                    <Select
                      variant="outlined"
                      color="primary"
                      labelId="alarm-label"
                      id="machine_state"
                      name="machine_states"
                      multiple
                      required
                      style={{ width: "100%" }}
                      value={alarmArgs?.machine_states}
                      onChange={handleArgChange}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {_.map(selected, (value) => (
                            <Chip
                              key={value}
                              label={t(value.toString()).toLowerCase()}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {_.map(machineStates, (value) => (
                        <MenuItem key={value} value={value}>
                          {t(value.toString()).toLowerCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <Tooltip title={t("and_tag_tooltip")}>
                  <Autocomplete
                    id="tag_name"
                    name="tag_name"
                    required
                    limitTags={2}
                    options={availableMetrics}
                    onChange={(event, newValue) => {
                      //custom function since autocomplete decided to act differently
                      setAlarmArgs({
                        ...alarmArgs,
                        tag_name: newValue,
                      });
                    }}
                    value={alarmArgs?.tag_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="outlined"
                        label={
                          currentAlarm?.function === THRESHOLD_ALARM
                            ? t("and_tag") + " "
                            : t("tag")
                        }
                      />
                    )}
                  />
                </Tooltip>
              </FormControl>
            </Grid>
            {currentAlarm?.function === THRESHOLD_ALARM && (
              <>
                <Grid item xs={12} sm={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel required id="compare">
                      {t("is")}
                    </InputLabel>
                    <Tooltip
                      PopperProps={{ style: { zIndex: 1 } }}
                      title={t("is_tooltip")}
                    >
                      <Select
                        variant="outlined"
                        color="primary"
                        labelId="alarm-label"
                        id="compare"
                        name="comp"
                        required
                        style={{ width: "100%" }}
                        value={alarmArgs?.comp}
                        onChange={handleArgChange}
                      >
                        <MenuItem key=">" value=">">
                          {t("greater_than")}
                        </MenuItem>
                        <MenuItem key=">=" value=">=">
                          {t("greater_than/equal_to")}
                        </MenuItem>
                        <MenuItem key="<" value="<">
                          {t("less_than")}
                        </MenuItem>
                        <MenuItem key="<=" value="<=">
                          {t("less than/equal to")}
                        </MenuItem>
                        <MenuItem key="==" value="==">
                          {t("equal_to")}
                        </MenuItem>
                        <MenuItem key="!=" value="!=">
                          {t("not_equal_to")}
                        </MenuItem>
                      </Select>
                    </Tooltip>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl className={classes.formControl}>
                    <Tooltip title={t("value_tooltip")}>
                      <TextField
                        label={t("value")}
                        id="threshold"
                        name="threshold"
                        type="number"
                        variant="outlined"
                        required
                        value={alarmArgs?.threshold}
                        onChange={handleArgChange}
                      />
                    </Tooltip>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <Tooltip title={t("for_at_least_tooltip")}>
                  <TextField
                    label={
                      currentAlarm?.function === THRESHOLD_ALARM
                        ? t("for_at_least") + " "
                        : t("anomalous_for_at_least") + " "
                    }
                    variant="outlined"
                    id="period"
                    name="period"
                    type="number"
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {t("sec")}
                        </InputAdornment>
                      ),
                    }}
                    value={alarmArgs?.period}
                    onChange={handleArgChange}
                  />
                </Tooltip>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Tooltip title={t("also_notify_tooltip")}>
                  <Autocomplete
                    id="extra_emails"
                    name="extra_emails"
                    multiple
                    limitTags={2}
                    options={availableEmails}
                    onChange={(event, newValue) => {
                      //custom function since autocomplete decided to act differently
                      setCurrentAlarm({
                        ...currentAlarm,
                        extra_emails: newValue,
                      });
                    }}
                    //Allow a freeform option to appear in the dropdown
                    onInputChange={(event, newValue) => {
                      setAvailableEmails([...emailList, newValue]);
                    }}
                    value={currentAlarm?.extra_emails}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t("also_notify")}
                      />
                    )}
                  />
                </Tooltip>
              </FormControl>
            </Grid>
            {SAPEnabled && (
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableSapNotification}
                      onChange={handleSapCheckboxChange}
                    />
                  }
                  label={
                    <span>
                      {t("sap_notification")}
                      <Tooltip title={t("sap_notification_tooltip")}>
                        <HelpIcon
                          style={{
                            display: "inline",
                            fontSize: 16,
                            marginLeft: 3,
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                />
              </Grid>
            )}
            {enableSapNotification && (
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.notificationFormControl}>
                  <Tooltip title={t("notification_rate_tooltip")}>
                    <TextField
                      label={t("notification_rate")}
                      variant="outlined"
                      id="sap_rate_in_seconds"
                      name="sap_rate_in_seconds"
                      type="text"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Select
                              value={notificationRateUnit}
                              onChange={(event) =>
                                setNotificationRateUnit(event.target.value)
                              }
                              name="notificationRateUnit"
                            >
                              <MenuItem value="minutes">{t("min")}</MenuItem>
                              <MenuItem value="hours">{t("hrs")}</MenuItem>
                              <MenuItem value="days">{t("days")}</MenuItem>
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 3,
                      }}
                      value={currentAlarm?.sap_rate_in_seconds}
                      onChange={handleNotificationRateInput}
                      error={!!notificationRateError}
                      helperText={notificationRateError}
                      style={{
                        maxWidth: "200px",
                      }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl className={classes.notificationFormControl}>
                  <Tooltip title={t("notification_description_tooltip")}>
                    <TextField
                      label={t("notification_description")}
                      variant="outlined"
                      id="Notification Description"
                      name="Notification Description"
                      value={sapNotificationFields.ShortText}
                      onChange={(event) =>
                        handleFieldChange("ShortText", event.target.value)
                      }
                      inputProps={{ maxLength: 40 }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl className={classes.notificationFormControl}>
                  <Tooltip title={t("functional_location_tooltip")}>
                    <TextField
                      label={t("functional_location")}
                      variant="outlined"
                      id="Functional Location"
                      name="Functional Location"
                      required
                      value={sapNotificationFields.FunctLoc}
                      onChange={(event) =>
                        handleFieldChange("FunctLoc", event.target.value)
                      }
                      inputProps={{ maxLength: 40 }}
                    />
                  </Tooltip>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
      <Dialog
        open={openDeleteConfirm}
        onClose={closeDeleteConfirm}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <WarningIcon color="secondary"></WarningIcon> {t("delete_alarm")}{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography component="div">
              <Box fontStyle="normal" m={1}>
                {t("delete_alarm_warning")}
              </Box>
              <Box fontWeight="fontWeightBold" m={1}>
                {t("delete")} {alarm.name}?
              </Box>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirm} className={classes.colorGray}>
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              closeDeleteConfirm();
              onDeleteAlarm(alarm);
            }}
            color="secondary"
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

AlarmForm.propTypes = {
  className: PropTypes.string,
  machine: PropTypes.object.isRequired,
};

export default AlarmForm;
