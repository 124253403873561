import { get, makeQuery, REQUIRED } from "./base";

export function getJobs(query) {
  const params = makeQuery(query, {
    device_id: REQUIRED,
    start_time: REQUIRED,
    end_time: REQUIRED,
  });
  return get(`jobs${params}`);
}
