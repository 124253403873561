import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  colors,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number-input";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import TopSnackbar from "src/components/TopSnackbar";
import { getCustomers } from "src/repos/customers";
import { createUser, getUser, updateUser } from "src/repos/users";

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: 0,
  },
  keywords: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: 0,
  },
  searchIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(2),
  },
  chips: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  selects: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    backgroundColor: colors.grey[50],
    padding: theme.spacing(1),
  },
  tagLabel: {
    marginLeft: "10px",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "15px",
  },
  formControl: {
    width: "100%",
  },
}));

const humanize = (str) => {
  return _.capitalize(
    _.trim(_.snakeCase(str).replace(/_id$/, "").replace(/_/g, " "))
  );
};

function ConfigurationForm({ action, ...rest }) {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation(["common", "glossary"]);
  const { user_id } = useParams();
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    error: false,
    message: "",
  });
  const isUpdate = action === "update";
  const tempUserData = {
    id: 1,
    first_name: "",
    last_name: "",
    customer_id: "",
    email: "",
    phone: "",
    role: "user",
    status: "active",
    notifications_email: true,
    make: null,
  };
  const [values, setValues] = useState({
    first_name: tempUserData.first_name,
    last_name: tempUserData.last_name,
    customer_id: tempUserData.customer_id,
    email: tempUserData.email,
    phone: tempUserData.phone,
    role: tempUserData.role,
    status: tempUserData.status,
    notifications_email: tempUserData.notifications_email,
    make: tempUserData.make,
  });
  const title = isUpdate ? t("user.edit") : t("user.add");

  const errorMessages = errors
    ? Object.keys(errors).map((key) =>
        errors[key].map((errorMessage, messageIndex) => (
          <li key={`${key}${messageIndex}`}>{`${humanize(
            key
          )} ${errorMessage}`}</li>
        ))
      )
    : null;

  const errorDisplay = (
    <>
      {errors && (
        <Typography variant="body2" className={classes.error}>
          {errorMessages}
        </Typography>
      )}
    </>
  );

  useEffect(() => {
    getCustomers()
      .then((r) => r.json())
      .then((d) => setCustomerList(d.data));
  }, []);

  useEffect(() => {
    if (user_id && isUpdate)
      getUser(user_id)
        .then((r) => r.json())
        .then((d) => setUser(d.data));
  }, [user_id, isUpdate]);

  useEffect(() => {
    if (customerList.length && user && isUpdate) setValues(user);
  }, [customerList, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    if (!["+", "+1"].includes(value)) {
      setValues({
        ...values,
        phone: value,
      });
    } else {
      setValues({
        ...values,
        phone: "",
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    (isUpdate ? updateUser : createUser)(values)
      .then((r) => r.json())
      .then((r = { data: user }) => {
        if (r.data && !r.errors) {
          setUser(user);
          setSnackbar({
            open: true,
            error: false,
            message: `${
              isUpdate
                ? t("glossary:update_successful")
                : t("glossary:user_created")
            }`,
          });
          setTimeout(() => {
            history.push(`/admin/users`);
          }, 2000);
        } else {
          setErrors(r.errors);
        }
      })
      .catch((e) => {
        setSnackbar({
          open: true,
          error: true,
          message: t("glossary:request_failed_500"),
        });
        setTimeout(() => {
          setSnackbar((snackbar) => ({
            ...snackbar,
            open: false,
          }));
        }, 5000);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbar((snackbar_) => {
      setSnackbar({
        ...snackbar_,
        open: false,
      });
    });
  };

  return (
    <Page
      className={classes.root}
      title={`${tempUserData.first_name} ${tempUserData.last_name}`}
    >
      <Container maxWidth="md">
        <PageHeader pageTitle={title} user={user} />
        <Card {...rest} className={clsx(classes.root)}>
          <form onSubmit={handleSubmit}>
            <CardHeader title={title} />
            <Divider />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    color="primary"
                    fullWidth
                    label={t("glossary:email_address")}
                    name="email"
                    onChange={handleChange}
                    required
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="primary"
                    fullWidth
                    label={t("glossary:name_first")}
                    name="first_name"
                    onChange={handleChange}
                    required
                    value={values.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="primary"
                    fullWidth
                    label={t("glossary:name_last")}
                    name="last_name"
                    onChange={handleChange}
                    required
                    value={values.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiPhoneNumber
                    color="primary"
                    defaultCountry={"us"}
                    fullWidth
                    label={t("glossary:phone_number")}
                    name="phone"
                    onChange={handlePhoneChange}
                    onlyCountries={["us"]}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="customer-label">
                      {t("glossary:customer")}
                    </InputLabel>
                    <Select
                      color="primary"
                      variant="outlined"
                      labelId="customer-label"
                      id="customer_id"
                      value={values.customer_id}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      name="customer_id"
                      defaultValue=""
                    >
                      {customerList &&
                        customerList.length &&
                        customerList.map((customer) => {
                          return (
                            <MenuItem value={customer.id}>
                              {customer.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="role-label">
                      {t("glossary:role")}
                    </InputLabel>
                    <Select
                      color="primary"
                      variant="outlined"
                      labelId="role-label"
                      id="role"
                      value={values.role}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      name="role"
                    >
                      <MenuItem value="user">{t("glossary:user")}</MenuItem>
                      <MenuItem value="admin">{t("glossary:admin")}</MenuItem>
                      <MenuItem value="superuser">
                        {t("glossary:super_user")}
                      </MenuItem>
                      <MenuItem value="oem_superuser">
                        {t("glossary:oem_super_user")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {values.role === "oem_superuser" && (
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="make-label">
                        {t("glossary:make")}
                      </InputLabel>
                      <Select
                        color="primary"
                        variant="outlined"
                        labelId="make-label"
                        id="make"
                        value={values.make}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        name="make"
                      >
                        <MenuItem value="Sun">Sun</MenuItem>
                        <MenuItem value="LMC">LMC</MenuItem>
                        <MenuItem value="Fosber">Fosber</MenuItem>
                        <MenuItem value="Ward">Ward</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="status-label">
                      {t("glossary:status")}
                    </InputLabel>
                    <Select
                      color="primary"
                      variant="outlined"
                      labelId="status-label"
                      id="status"
                      value={values.status}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      name="status"
                    >
                      <MenuItem value="active">{t("glossary:active")}</MenuItem>
                      <MenuItem value="deactivated">
                        {t("glossary:disabled")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="notifications_email"
                        onChange={handleChange}
                        checked={values.notifications_email}
                        color="primary"
                      />
                    }
                    label={t("glossary:email_notifications_enabled")}
                  />
                </Grid>
                <Grid item xs={12}>
                  {errorDisplay}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t("glossary:save_changes")}
              </Button>
            </CardActions>
          </form>
          <TopSnackbar
            onClose={handleSnackbarClose}
            open={snackbar.open}
            message={snackbar.message}
            error={snackbar.error}
          />
        </Card>
      </Container>
    </Page>
  );
}

ConfigurationForm.propTypes = {
  className: PropTypes.string,
};

export default ConfigurationForm;
