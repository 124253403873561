/* eslint-disable no-unused-vars */
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "src/components/Page";
import { manage } from "src/repos/base";
import { confirm_sms_code, resend_sms_code } from "src/repos/users";
import { currentUser } from "src/utils/session";
import { useStyles } from "src/views/Login/LoginStyles";

function SmsVerify() {
  const classes = useStyles();
  const user = currentUser();
  const [code, setCode] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [response, setResponse] = useState({ msg: "", error: false });

  const { t } = useTranslation(["common", "glossary"]);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return ["(***) ***-" + match[4]].join("");
    }
    return null;
  }

  const toastMessage = (message, error) => {
    setResponse({
      error: error || false,
      msg: message,
    });
    setTimeout(
      () =>
        setResponse((toast_) => ({
          ...toast_,
          msg: "",
        })),
      5000
    );
  };

  const handleSubmit = () => {
    manage(confirm_sms_code(code))
      .on("ok", (data) => {
        toastMessage(t("phone_number_confirmation"));
      })
      .on("error", (data) => {
        toastMessage(data.msg + ", " + t("please_try_again"), true);
      });
  };

  return (
    <Page className={classes.root} title="Verify Phone Number">
      <Card>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h3">
            {t("verify_phone_number")}
          </Typography>
          <Typography variant="subtitle1">
            {t("phone_number_message", {
              phoneNumber: formatPhoneNumber(user.phone),
            })}
          </Typography>
          <form className={classes.root}>
            <Grid container>
              <Grid item>
                <TextField
                  color="primary"
                  label="Sms Code"
                  name="sms_code"
                  value={code}
                  disabled={!codeSent}
                  onChange={(event) => setCode(event.target.value)}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item style={{ display: "flex" }}>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <Button
                    color="primary"
                    disabled={!codeSent}
                    onClick={handleSubmit}
                  >
                    {t("confirm_sms")}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      resend_sms_code();
                      setCodeSent(true);
                    }}
                  >
                    {codeSent ? t("resend_code") : t("send_code")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Typography variant="body2" className={classes.error}>
              {response.msg}
            </Typography>
          </form>
        </CardContent>
      </Card>
    </Page>
  );
}

export default SmsVerify;
