import React, { Fragment, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  Slider,
  Typography,
  TextField,
  Tooltip,
  FormControlLabel,
  Tab,
  Collapse,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import HelpIcon from "@material-ui/icons/Help";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Autocomplete,
  TabContext,
  TabList,
  TabPanel,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import DateRangePicker from "src/components/TimePicker/DateRangePicker";
import _ from "lodash";
import ButtonSwitch from "src/components/ButtonSwitch";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 20px",
    marginBottom: "20px",
  },
  container: {
    padding: 0,
  },
  heading: {
    textAlign: "center",
  },
  formLabel: {
    fontWeight: 500,
    color: "#292929",
  },
  resetLabel: {
    textAlign: "right",
    color: "#E0572A",
    fontSize: "12px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  measures: {
    marginTop: 20,
  },
  submit: {
    padding: "20px",
    marginBottom: 0,
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: "#000000",
    opacity: "100%",
  },
  tabPanel: {
    padding: "20px 0 0 0",
  },
  tab: {
    borderBottom: "2px solid #F8F8F8",
  },
  sections: {
    alignItems: "center",
  },
  sectionLabel: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 5,
  },
  switchControl: {
    marginRight: 0,
  },
  collapse: {
    width: "100%",
  },
}));

const ResetButton = ({ handleReset }) => {
  const classes = useStyles();
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <Typography className={classes.resetLabel} onClick={handleReset}>
      {t("reset")}
    </Typography>
  );
};

const SearchCard = ({
  availableMetrics,
  selectedStartDate,
  selectedEndDate,
  handleRangeChange,
  anomalousTime,
  changeAnomalousTime,
  selectedMetrics,
  setSelectedMetrics,
  humanKeyToCfg,
  changeTimezone,
  timezone,
  resetControl,
  resetSectionControl,
  handleSubmit,
  handleTabChange,
  formType,
  selectedSections,
  handleSectionToogle,
  deviceSections,
  handleSelectSectionMetric,
  handleChangeSectionAnomalousTime,
}) => {
  const classes = useStyles();
  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  const metricLimit = 15;
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    handleSubmit("0");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const valuetext = (value) => {
    return `${value}%`;
  };

  const displaySection = (sectionName) => {
    return !!selectedSections[sectionName];
  };

  const TimeForm = () => {
    return (
      <>
        <Grid container spacing={1} className={clsx(classes.root)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography className={classes.formLabel}>
              {t("time_range")}
            </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography
                variant="body1"
                style={{
                  fontSize: 12,
                  color: "#333",
                  fontWeight: 600,
                }}
              >
                {t("timezone_colon")}
              </Typography>
              <ToggleButtonGroup
                style={{ marginLeft: 5 }}
                exclusive
                color="primary"
                value={timezone}
                onChange={changeTimezone}
                aria-label={t("machine_uptime_date_range")}
              >
                <ToggleButton
                  classes={{ selected: classes.toggleSelected }}
                  value="local"
                  aria-label="bold"
                  size="small"
                  style={{ height: 25, marginBottom: 0 }}
                >
                  <Typography>{t("local")}</Typography>
                </ToggleButton>
                <ToggleButton
                  classes={{ selected: classes.toggleSelected }}
                  value="facility"
                  aria-label="bold"
                  size="small"
                  style={{ height: 25, marginBottom: 0 }}
                >
                  <Typography>{t("facility")}</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <Grid item xs={12} sm={12} lg={12}>
            <DateRangePicker
              onApply={(event, picker) =>
                handleRangeChange(picker.startDate, picker.endDate)
              }
              open="left"
              drops="auto"
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              isRequired={true}
              inputStartDate={selectedStartDate}
              inputEndDate={selectedEndDate}
            ></DateRangePicker>
          </Grid>
        </Grid>
      </>
    );
  };

  const SubmitButton = ({ form }) => {
    return (
      <Grid
        container
        spacing={1}
        className={clsx(classes.root, classes.submit)}
      >
        <Button
          aria-label="Search anomalies"
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => handleSubmit(form)}
        >
          {t("search_for_anomalies")}
        </Button>
      </Grid>
    );
  };

  return (
    <Card>
      <CardHeader
        title={t("search_for_anomalies")}
        className={classes.heading}
      />
      <TabContext value={formType} color="secondary">
        <TabList
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
        >
          <Tab label={t("basic")} value="0" className={classes.tab} />
          <Tab label={t("advanced")} value="1" className={classes.tab} />
        </TabList>
        <TabPanel value="0" classes={{ root: classes.tabPanel }}>
          <TimeForm />
          <Divider />
          <Grid
            container
            spacing={0}
            className={clsx(classes.root, classes.measures)}
          >
            <Grid item xs={10} sm={10} lg={10}>
              <Typography className={classes.formLabel}>
                {t("measures")}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} lg={2}>
              <ResetButton handleReset={() => resetControl("measures")} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <List>
                {!_.isEmpty(availableMetrics) && (
                  <>
                    <Autocomplete
                      multiple
                      getOptionDisabled={() =>
                        selectedMetrics.length >= metricLimit
                      }
                      limitTags={3}
                      id="multiple-limit-tags"
                      options={availableMetrics}
                      onChange={(evt, metrics) => setSelectedMetrics(metrics)}
                      value={selectedMetrics}
                      getOptionLabel={(option) =>
                        humanKeyToCfg[option]
                          ? humanKeyToCfg[option].title
                          : option
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={t("select_measures")}
                          placeholder={t("select_measures")}
                          fullWidth
                        />
                      )}
                    />
                  </>
                )}
              </List>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={clsx(classes.root)}>
            <Grid item xs={10} sm={10} lg={10}>
              <Typography className={classes.formLabel}>
                {t("anomalous_time")}
                <Tooltip
                  color="disabled"
                  placement="right-end"
                  title={t("anomalous_time_message")}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <HelpIcon
                    style={{
                      display: "inline",
                      fontSize: 15,
                      marginLeft: 3,
                    }}
                  />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Slider
                value={anomalousTime}
                defaultValue={[0, 100]}
                aria-label={t("default")}
                valueLabelDisplay="auto"
                onChange={changeAnomalousTime}
                marks={marks}
                getAriaValueText={valuetext}
              />
            </Grid>
          </Grid>
          <Divider />
          <SubmitButton form="0" />
        </TabPanel>
        <TabPanel value="1" classes={{ root: classes.tabPanel }}>
          <TimeForm />
          <Divider />
          {_.map(deviceSections, (tags, section) => {
            return (
              <Fragment key={section}>
                <Grid
                  container
                  spacing={0}
                  className={clsx(
                    classes.root,
                    classes.measures,
                    classes.sections
                  )}
                >
                  <Grid item xs={6} sm={6} lg={6}>
                    <Typography className={classes.sectionLabel}>
                      {section}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} style={{ textAlign: "end" }}>
                    <FormControlLabel
                      className={classes.switchControl}
                      control={
                        <ButtonSwitch
                          checked={displaySection(section)}
                          onChange={handleSectionToogle}
                          name={section}
                        />
                      }
                    />
                  </Grid>

                  <Collapse
                    in={displaySection(section)}
                    className={classes.collapse}
                  >
                    <Grid container style={{ marginTop: 15 }}>
                      <Grid item xs={10} sm={10} lg={10}>
                        <Typography className={classes.formLabel}>
                          {t("measures")}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} lg={2}>
                        <ResetButton
                          handleReset={() =>
                            resetSectionControl("measures", section)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12}>
                        <List>
                          {!_.isEmpty(availableMetrics) && (
                            <>
                              <Autocomplete
                                multiple
                                getOptionDisabled={() =>
                                  selectedMetrics.length >= metricLimit
                                }
                                limitTags={3}
                                id={`multiple-limit-tags-${section}`}
                                options={tags}
                                onChange={(evt, metrics) =>
                                  handleSelectSectionMetric(section, metrics)
                                }
                                value={
                                  selectedSections[section]?.selectedMeasures ||
                                  []
                                }
                                getOptionLabel={(option) =>
                                  humanKeyToCfg[option]
                                    ? humanKeyToCfg[option].title
                                    : option
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={t("select_measures")}
                                    placeholder={t("select_measures")}
                                    fullWidth
                                  />
                                )}
                              />
                            </>
                          )}
                        </List>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 18 }}>
                      <Grid item xs={10} sm={10} lg={10}>
                        <Typography className={classes.formLabel}>
                          {t("anomalous_time")}
                          <Tooltip
                            color="disabled"
                            placement="right-end"
                            title={t("anomalous_time_message")}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <HelpIcon
                              style={{
                                display: "inline",
                                fontSize: 15,
                                marginLeft: 3,
                              }}
                            />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} lg={2}>
                        <ResetButton
                          handleReset={() =>
                            resetSectionControl("anomalous_time", section)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12}>
                        <Slider
                          value={
                            selectedSections[section]?.anomalousTime || [0, 100]
                          }
                          defaultValue={[0, 100]}
                          aria-label={t("default")}
                          valueLabelDisplay="auto"
                          onChange={(evt, val) => {
                            handleChangeSectionAnomalousTime(section, val);
                          }}
                          marks={marks}
                          getAriaValueText={valuetext}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <Divider />
              </Fragment>
            );
          })}
          <SubmitButton form="1" />
        </TabPanel>
      </TabContext>
    </Card>
  );
};

export default SearchCard;
