import {
  get,
  put,
  post,
  del,
  makeQuery,
  makeQueryJSON,
  REQUIRED,
} from "src/repos/base";

function getDevicesURI() {
  return `devices`;
}

function getDeviceURI(serial) {
  return `${getDevicesURI()}/${serial}`;
}

function getCustomerDevicesURI(customerId) {
  return `customers/${customerId}/devices`;
}

function getDeviceMakeModelsURI() {
  return `${getDevicesURI()}/make_models`;
}

export function getDevice(serial) {
  return get(getDeviceURI(serial));
}

export function getDevices() {
  return get("devices");
}

export function getCustomerDevices(customerId) {
  return get(getCustomerDevicesURI(customerId));
}

export function getFacilityDevices(facilityId) {
  return get(`facilities/${facilityId}/devices`);
}

export function updateDevice(serial, device) {
  return put(getDeviceURI(serial), device);
}

export function forceShadowUpdate(device_id) {
  return get(`${getDeviceURI(device_id)}/force_shadow_update`);
}

export function getDeviceMakeModels() {
  return get(getDeviceMakeModelsURI());
}

export function getDeviceTelemDispCfgs(deviceID) {
  return get(`devices/${deviceID}/telem_disp_cfg`);
}

export function createDeviceTelemDispCfg(telemDispCfg) {
  return post(`devices/${telemDispCfg.device_id}/telem_disp_cfg`, telemDispCfg);
}

export function updateDeviceTelemDispCfg(telemDispCfg) {
  return put(
    `devices/${telemDispCfg.device_id}/telem_disp_cfg/${telemDispCfg.id}`,
    telemDispCfg
  );
}

export function deleteDeviceTelemDispCfg(telemDispCfg) {
  return del(
    `devices/${telemDispCfg.device_id}/telem_disp_cfg/${telemDispCfg.id}`
  );
}

export function resetDeviceTelemetryDisplayConfigs(deviceId) {
  return put(`devices/${deviceId}/telem_disp_cfg/reset_defaults`);
}

export function createDeviceReplacement(device_new, device_existing) {
  return post(`devices/${device_existing.id}/replace`, {
    device_id: device_new.id,
  });
}

export function getSingleMetricAverageOverTimeRange(
  deviceID,
  metric,
  start_time,
  end_time
) {
  const params = {
    device_id: deviceID,
    metric: metric,
    start_time: start_time,
    end_time: end_time,
  };
  return get(`single_metric_average${makeQuery(params)}`);
}

export function getMetricAverages(deviceIds, metrics, start_time, end_time) {
  return post(`metric_averages`, {
    device_ids: deviceIds,
    metrics: metrics,
    start_time: start_time,
    end_time: end_time,
  });
}

export function getDeviceTimeseries(query) {
  const params = makeQuery(query, {
    devices: REQUIRED,
    metrics: REQUIRED,
    start_date: REQUIRED,
    end_date: REQUIRED,
    datapoints: REQUIRED,
  });

  return get(`devices_timeseries${params}`);
}

export function getDeviceSamplers(deviceID) {
  return get(`devices/${deviceID}/samplers`);
}

export function getDeviceAlarms(deviceID) {
  return get(`devices/${deviceID}/alarms`);
}

export function getDeviceSections(deviceID) {
  return get(`devices/${deviceID}/sections`);
}

export function getDeviceTelemetryDefinitions(deviceID, query) {
  query = makeQueryJSON(query, {
    page: 1,
    limit: 30,
    // filters: [{"op": "<eq|ne|il>", "va": "<value>", "fd": "<field>"}],
    // aggregation: "human_key:machine_key",  // If set, ignores paging
  });
  return get(`devices/${deviceID}/telemetry_definitions${query}`);
}

export function getDeviceDowntimes(deviceIds) {
  const query = makeQuery({
    device_ids: deviceIds,
  });
  return get(`devices/downtime_predictions${query}`);
}
