import { Card, Grid, Typography } from "@material-ui/core";
import React from "react";

const EmptyResults = ({ headerText, secondaryText }) => {
  return (
    <Grid container spacing={4} style={{ alignItems: "flex-start" }}>
      <Grid
        item
        xs={12}
        md={12}
        xl={12}
        style={{ maxHeight: "100vh", overflow: "auto" }}
      >
        <Card
          style={{
            minHeight: 619,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "150px",
            }}
            alt="Not Found"
            src="/images/not_found.png"
          />
          <Typography variant="h4">{headerText}</Typography>
          <Typography variant="body1">{secondaryText}</Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EmptyResults;
