/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";
import Cookies from "js-cookie";
import Login from "./views/Login";
import EmailUnsubscribe from "src/views/Singletons/EmailUnsubscribe";
import ResetPassword from "./views/Login/ResetPassword";
import ResetPasswordRequest from "./views/Login/ResetPasswordRequest";
import Dashboard from "./views/SunScreens/Dashboard";
import Machines from "./views/SunScreens/Machines";
import MachinesDetails from "./views/SunScreens/Machines/Details";
import Reports from "./views/SunScreens/Reports";
import Customers from "./views/SunScreens/Customers";
import CustomersDetails from "./views/SunScreens/Customers/Details";
import CustomersDetailsFacilitiesDetail from "./views/SunScreens/Customers/Details/Facilities/Detail";
import PartsQuoteRequest from "./views/SunScreens/Machines/Details/PartsQuoteRequest";
import Account from "./views/SunScreens/Account";
import SmsVerify from "./views/SunScreens/Account/SmsVerify";
import Admin from "./views/SunScreens/Admin";
import AdminEditUser from "./views/SunScreens/Admin/Users/EditUser";
import CustomerDetailsEditUser from "./views/SunScreens/Customers/Details/EditUser";
import {
  termsAccepted,
  shouldResetPassword,
  isSuperUser,
} from "src/utils/session";
import { initializePendo } from "src/utils/pendo";

let pendoInitialized = false;

const isLoggedIn = (component, goToDashboard, allowed = true) => {
  const sessionToken = Cookies.get("sessionId") || undefined;
  const path = encodeURIComponent(
    `${window.location.pathname}${window.location.search}`
  );

  if (sessionToken && shouldResetPassword()) {
    if (component === ResetPassword) return ResetPassword;
    else
      return () => (
        <Redirect to={`/login/reset-password-authed?redirect=${path}`} />
      );
  }

  if (sessionToken && termsAccepted() && !pendoInitialized) {
    pendoInitialized = true;
    initializePendo();
  }

  if (sessionToken && !termsAccepted())
    return () =>
      component === Dashboard ? <Dashboard /> : <Redirect to="/dashboard" />;

  if (goToDashboard)
    return sessionToken ? () => <Redirect to="/dashboard" /> : component;

  if (!allowed) return () => <Redirect to="/dashboard" />;

  return sessionToken
    ? component
    : () => <Redirect to={`/login?redirect=${path}`} />;
};

export default [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/login",
    component: AuthLayout,
    routes: [
      {
        path: "/login",
        exact: true,
        component: () => isLoggedIn(Login, true)(),
      },
      {
        path: "/login/reset-password-request",
        exact: true,
        component: () => isLoggedIn(ResetPasswordRequest, true)(),
      },
      {
        path: "/login/setup-account/:token",
        exact: true,
        component: () => {
          const component = isLoggedIn(null, true);
          if (component) return component();
          else return <ResetPassword initial={true} />;
        },
      },
      {
        path: "/login/reset-password-authed",
        exact: true,
        component: () => isLoggedIn(ResetPassword, true)(),
      },
      {
        path: "/login/reset-password/:token",
        exact: true,
        component: () => isLoggedIn(ResetPassword, true)(),
      },
    ],
  },
  {
    path: "/s",
    component: AuthLayout,
    routes: [
      {
        path: "/s/notifications/unsubscribe/email",
        exact: true,
        component: () => isLoggedIn(EmailUnsubscribe)(),
      },
    ],
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: lazy(() => import("src/views/Error401")),
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: lazy(() => import("src/views/Error404")),
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: lazy(() => import("src/views/Error500")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: "/admin",
        exact: true,
        component: () => isLoggedIn(Admin, false, isSuperUser())(),
      },
      {
        path: "/admin/:tab",
        exact: true,
        component: () => isLoggedIn(Admin, false, isSuperUser())(),
      },
      {
        path: "/admin/customers/:id",
        exact: true,
        component: () => isLoggedIn(CustomersDetails, false, isSuperUser())(),
      },
      {
        path: "/admin/customers/:id/:tab",
        exact: true,
        component: () => isLoggedIn(CustomersDetails, false, isSuperUser())(),
      },
      {
        path: "/admin/users/new",
        exact: true,
        component: () => isLoggedIn(() => <AdminEditUser action="new" />)(),
      },
      {
        path: "/admin/users/:user_id",
        exact: true,
        component: () => isLoggedIn(() => <AdminEditUser action="update" />)(),
      },
      {
        path: "/dashboard",
        exact: true,
        component: () => isLoggedIn(Dashboard)(),
      },
      {
        path: "/machines",
        exact: true,
        component: () => isLoggedIn(Machines)(),
      },
      {
        path: "/machines/:id",
        exact: true,
        component: () => isLoggedIn(MachinesDetails)(),
      },
      {
        path: "/machines/:id/:tab",
        exact: true,
        component: () => isLoggedIn(MachinesDetails)(),
      },
      {
        path: "/machines/:id/:tab/measure/:measure",
        exact: true,
        component: () => isLoggedIn(MachinesDetails)(),
      },
      {
        path: "/machines/:id/:tab/measure",
        exact: true,
        component: () => isLoggedIn(MachinesDetails)(),
      },
      {
        path: "/maintenances/:id/request_parts_quote",
        exact: true,
        component: () => isLoggedIn(PartsQuoteRequest)(),
      },
      {
        path: "/reports",
        exact: true,
        component: () => isLoggedIn(Reports)(),
      },
      {
        path: "/reports/:tab",
        exact: true,
        component: () => isLoggedIn(Reports)(),
      },
      {
        path: "/customers",
        exact: true,
        component: () => isLoggedIn(Customers)(),
      },
      {
        path: "/customers/:id",
        exact: true,
        component: () => isLoggedIn(CustomersDetails)(),
      },
      {
        path: "/customers/:id/:tab",
        exact: true,
        component: () => isLoggedIn(CustomersDetails)(),
      },
      {
        path: "/customers/:id/facilities/new",
        exact: true,
        component: () =>
          isLoggedIn(() => <CustomersDetailsFacilitiesDetail action="new" />)(),
      },
      {
        path: "/customers/:id/facilities/:facility_id",
        exact: true,
        component: () =>
          isLoggedIn(() => (
            <CustomersDetailsFacilitiesDetail action="update" />
          ))(),
      },
      {
        path: "/customers/:customer_id/users/new",
        exact: true,
        component: () =>
          isLoggedIn(() => <CustomerDetailsEditUser action="new" />)(),
      },
      {
        path: "/customers/:customer_id/users/:user_id",
        exact: true,
        component: () =>
          isLoggedIn(() => <CustomerDetailsEditUser action="update" />)(),
      },
      {
        path: "/account",
        exact: true,
        component: () => isLoggedIn(Account)(),
      },
      {
        path: "/account/sms/verify",
        exact: true,
        component: () => isLoggedIn(SmsVerify)(),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];
