import {
  Card,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useCallback } from "react";
import EmptyResults from "./EmptyResults";
import palette from "src/theme/palette";
import clsx from "clsx";
import ResultsList from "../../../Lists/AnomalySectionList";
import { formatTimeRange } from "src/utils/timeRange";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import HelpIcon from "@material-ui/icons/Help";
import SectionResults from "./SectionResults";
import ButtonSwitch from "src/components/ButtonSwitch";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  row: {
    padding: "15px",
  },
  date: {
    color: palette.text.caption,
    fontSize: "14px",
  },
  box: {
    textAlign: "center",
  },
  stats: {
    borderTop: "1px solid #ECEFF1",
    borderBottom: "1px solid #ECEFF1",
  },
  statLabel: {
    fontSize: "12px",
    color: "#000000",
  },
  statValue: {
    fontSize: 24,
  },
  boxBorder: {
    "&, &:after": {
      borderRight: "1px solid #ECEFF1",
    },
  },
  skeleton: {
    display: "inline-flex",
  },
  loadingSkeleton: {
    marginLeft: "45%",
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: "#000000",
    opacity: "100%",
  },
  sectionToggle: {
    fontSize: 13,
    fontWeight: 600,
  },
  switchControl: {
    marginRight: 0,
  },
}));

const Results = ({
  result,
  loading = false,
  columns,
  page,
  setPage,
  pageSize,
  setPageSize,
  referrer,
  handlerOrderChange,
  handleRowClick,
  wasLastSearchBasic,
  selectedSections,
  toggleGroupBySection,
  groupBySection,
  resultGroupedBySection,
  selectedStartDate,
  selectedEndDate,
}) => {
  const classes = useStyles();

  const selectedTime = useCallback((date) => {
    if (!date) return "";
    return moment(date);
  }, []);
  const { t } = useTranslation(["glossary", "common"]);

  if (!loading && _.isEmpty(result)) {
    return (
      <EmptyResults
        headerText={t("search_for_anomalous_behavior")}
        secondaryText={t("filter_search_message")}
      />
    );
  }

  if (!loading && _.isEmpty(result.data)) {
    return (
      <EmptyResults
        headerText={t("no_results")}
        secondaryText={t("update_search_message")}
      />
    );
  }

  const filterResultsByDeviceRange = () => {
    return _.reduce(
      result.data,
      (acc, tagData) => {
        const { anomalousTime = [0, 100] } =
          selectedSections[tagData.section || "others"] || {};
        const roundedVal = Math.round(tagData.value * 100);
        return roundedVal >= anomalousTime[0] && roundedVal <= anomalousTime[1]
          ? [...acc, tagData]
          : acc;
      },
      []
    );
  };

  const filteredResults = wasLastSearchBasic
    ? result.data
    : filterResultsByDeviceRange();

  return (
    <>
      <Card>
        <Grid container className={classes.row}>
          <Grid item xs={12} md={8} xl={8}>
            <Typography variant="h5">
              {t("results")} (
              {loading ? (
                <Skeleton
                  className={classes.skeleton}
                  variant="text"
                  width={30}
                  animation="wave"
                />
              ) : (
                result.count
              )}
              )
            </Typography>
            <Typography variant="body1" className={classes.date}>
              {loading ? (
                <Skeleton variant="text" width={100} animation="wave" />
              ) : (
                formatTimeRange(
                  selectedTime(selectedStartDate),
                  selectedTime(selectedEndDate)
                )
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} style={{ textAlign: "end" }}>
            <FormControlLabel
              className={classes.switchControl}
              label={
                <Typography variant="body1" className={classes.sectionToggle}>
                  {t("group_by_machine_section")}
                </Typography>
              }
              labelPlacement="start"
              control={
                <ButtonSwitch
                  checked={groupBySection}
                  onChange={toggleGroupBySection}
                  name="group_by_section"
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container className={clsx(classes.row, classes.stats)}>
          <Grid
            item
            xs={4}
            md={4}
            xl={4}
            className={clsx(classes.box, classes.boxBorder)}
          >
            <Typography variant="body1" className={classes.statLabel}>
              {t("machine_uptime")}
            </Typography>
            <Typography variant="h4" className={classes.statValue}>
              {loading ? (
                <Skeleton
                  variant="text"
                  width={30}
                  animation="wave"
                  className={classes.loadingSkeleton}
                />
              ) : (
                result.uptime && `${_.round(result.uptime * 100, 0)}%`
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            xl={4}
            className={clsx(classes.box, classes.boxBorder)}
          >
            <Typography variant="body1" className={classes.statLabel}>
              {t("msf")}
            </Typography>
            <Typography variant="h4" className={classes.statValue}>
              {loading ? (
                <Skeleton
                  variant="text"
                  width={30}
                  animation="wave"
                  className={classes.loadingSkeleton}
                />
              ) : (
                result.msf && _.round(result.msf, 0)
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} xl={4} className={classes.box}>
            <Typography variant="body1" className={classes.statLabel}>
              {t("most_anomalous_section")}
              <Tooltip
                color="disabled"
                placement="bottom-start"
                title={t("highest_percentage_message")}
                classes={{ tooltip: classes.tooltip }}
              >
                <HelpIcon
                  style={{
                    display: "inline",
                    fontSize: 14,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            <Typography variant="h4" className={classes.statValue}>
              {loading ? (
                <Skeleton
                  variant="text"
                  width={50}
                  animation="wave"
                  className={classes.loadingSkeleton}
                />
              ) : (
                result.most_anomalous_section
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          {!groupBySection && (
            <ResultsList
              referrer={referrer}
              result={filteredResults}
              onOrderChange={handlerOrderChange}
              loading={loading}
              page={page}
              totalCount={result.count}
              rowsPerPage={pageSize}
              onChangePage={(_event, page) => setPage(page)}
              onChangeRowsPerPage={(_event, args) => {
                setPageSize(args.props.value);
                setPage(0);
              }}
              remotePagination={wasLastSearchBasic}
              display_columns={columns}
              handleRowClick={handleRowClick}
            />
          )}
        </Grid>
      </Card>
      {groupBySection &&
        _.map(resultGroupedBySection, (sectionResults, section) => {
          return (
            <SectionResults
              referrer={referrer}
              results={sectionResults}
              columns={columns}
              handleRowClick={handleRowClick}
              sectionName={section}
            />
          );
        })}
    </>
  );
};

export default Results;
