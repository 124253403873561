import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";
import WarningIcon from "@material-ui/icons/Warning";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TopSnackbar from "src/components/TopSnackbar";
import { manage } from "src/repos/base";
import {
  getSamplerTelemetryDefinitions,
  getSamplerTelemetryTypes,
} from "src/repos/samplers";
import {
  createTelemetryDefinition,
  deleteTelemetryDefinition,
  updateTelemetryDefinition,
} from "src/repos/telemetryDefinitions";
import FilterMappings from "./FilterMappings";
import MappedTelemetry from "./MappedTelemetry";
import TelemetryDefinitionForm from "./TelemetryDefinitionForm";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: 0,
  },
  keywords: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(2),
  },
  chips: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  select: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: theme.spacing(1),
  },
  tagLabel: {
    marginLeft: "10px",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "15px",
  },
  formControl: {
    width: "100%",
  },
  button: {
    backgroundColor: colors.red[700],
    "&:hover": {
      backgroundColor: colors.red[900],
    },
  },
  colorGray: {
    color: "gray",
  },
}));

function SamplerForm({
  machine,
  sampler,
  samplerTypes,
  onCreateSampler,
  onUpdateSampler,
  onDeleteSampler,
  className,
  defaultHumanTags,
  ...rest
}) {
  const classes = useStyles();
  const [isBasic, setIsBasic] = useState(true);
  const [telemetryDefinitions, setTelemetryDefinitions] = useState([]);
  const [telemetryDefinition, setTelemetryDefinition] = useState({});
  const [telemetryTypes, setTelemetryTypes] = useState([]);
  const [
    openTelemetryDefinitionForm,
    setOpenTelemetryDefinitionForm,
  ] = useState(false);
  const [values, setValues] = useState({
    plc_port: sampler?.plc_port || "",
    plc_address: sampler?.plc_address || "",
    spec: sampler?.spec || "",
    name: sampler?.name || "",
    dataset_name: sampler?.dataset_name || "",
    machine_number: sampler?.machine_number || "",
    only_mapped_tags: sampler?.only_mapped_tags || false,
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  //const [paging, setPaging] = useState(null);
  const [pages, setPages] = useState(0);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [utsAdEnabledCount, setUtsAdEnabledCount] = useState(0);
  const [toast, setToast] = useState({
    error: false,
    visible: false,
    message: "",
  });
  const isNew = sampler?.id === undefined;
  const { t } = useTranslation(["glossary", "common"]);
  const title = isNew ? t("create_sampler") : t("sampler_configuration");

  const closeDeleteConfirm = () => setOpenDeleteConfirm(false);

  useEffect(() => {
    setValues({
      plc_port: sampler?.plc_port || "",
      plc_address: sampler?.plc_address || "",
      spec: sampler?.spec || "",
      name: sampler?.name || "",
      dataset_name: sampler?.dataset_name || "",
      machine_number: sampler?.machine_number || "",
      only_mapped_tags: sampler?.only_mapped_tags || false,
    });

    setIsBasic(sampler?.spec === "InternationalPaperOpsDB");
  }, [sampler]);

  useEffect(() => {
    // search is debounced by FilterMappings
    if (sampler.id) {
      let filters = [];
      filters = search
        ? [
            ...filters,
            { op: "ilike", fd: ["human_key", "machine_key"], va: search },
          ]
        : filters;
      getSamplerTelemetryDefinitions(sampler.id, {
        filters,
        page,
      })
        .then((r) => r.json())
        .then(({ data, paging }) => {
          setTelemetryDefinitions(data);
          setUtsAdEnabledCount(paging.uts_ad_enabled_count);
          const pages_ = Math.floor(paging.count / paging.limit);
          setPages(
            paging.count > paging.limit
              ? paging.count % paging.limit
                ? pages_ + 1
                : pages_
              : paging.count > 0
              ? 1
              : 0
          );
        });
    }
  }, [search, page, sampler]);

  const toastMessage = (message, error) => {
    setToast({
      error: error || false,
      visible: true,
      message: message,
    });
    setTimeout(
      () =>
        setToast((toast_) => ({
          ...toast_,
          visible: false,
        })),
      3000
    );
  };

  const handleFormChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
    if (event.target.name === "spec") {
      setIsBasic(event.target.value === "InternationalPaperOpsDB");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    isNew
      ? onCreateSampler({
          ...values,
          device_id: machine.id,
          spec: samplerTypes.find((st) => st.name === values.spec).value,
        })
      : onUpdateSampler({
          id: sampler.id,
          ...values,
          spec: samplerTypes.find((st) => st.name === values.spec).value,
        });
  };

  const handleUpdateTelemetryDefinition = (td) => {
    manage(updateTelemetryDefinition(td))
      .on("ok", () => {
        setTelemetryDefinitions((defs) => {
          return defs.map((def) => {
            if (def.id === td.id) {
              if (
                def.uts_anomaly_detection_enabled !==
                td.uts_anomaly_detection_enabled
              ) {
                setUtsAdEnabledCount(
                  utsAdEnabledCount +
                    td.uts_anomaly_detection_enabled -
                    def.uts_anomaly_detection_enabled
                );
              }

              return td;
            } else {
              return def;
            }
          });
        });
        toastMessage(t("successfully_updated_telemetry_mapping"));
      })
      .on("error", () =>
        toastMessage(t("failed_to_update_telemetry_mapping"), true)
      );
  };

  const handleCreateTelemetryDefinition = (td) => {
    manage(createTelemetryDefinition(td))
      .on("ok", (data) => {
        if (data.human_key) setTelemetryDefinitions((defs) => [data, ...defs]);
        setUtsAdEnabledCount(
          utsAdEnabledCount + td.uts_anomaly_detection_enabled
        );
        toastMessage(t("successfully_updated_telemetry_mapping"));
      })
      .on("error", () =>
        toastMessage(t("failed_to_update_telemetry_mapping"), true)
      );
  };

  const handleDeleteTelemetryDefinition = (td) => {
    manage(deleteTelemetryDefinition(td))
      .on("ok", () => {
        setTelemetryDefinitions((defs) => {
          return defs.filter((def) => def.id !== td.id);
        });
        setUtsAdEnabledCount(
          utsAdEnabledCount - td.uts_anomaly_detection_enabled
        );
        toastMessage(t("successfully_updated_telemetry_mapping"));
      })
      .on("error", () =>
        toastMessage(t("failed_to_update_telemetry_mapping"), true)
      );
  };

  useEffect(() => {
    if (sampler?.id) {
      getSamplerTelemetryTypes(sampler.id)
        .then((r) => r.json())
        .then((data) => setTelemetryTypes(data));
      setSearch("");
    }
  }, [sampler]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={title}
        action={
          !isNew ? (
            <IconButton
              aria-label={t("delete_2")}
              onClick={() => setOpenDeleteConfirm(true)}
              style={{
                marginTop: -5,
                marginBottom: -15,
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : null
        }
      />
      <Divider />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  label={t("sampler_name")}
                  variant="outlined"
                  id="name"
                  name="name"
                  required
                  value={values.name}
                  onChange={handleFormChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink id="spec">
                  {t("spec")}
                </InputLabel>
                <Select
                  variant="outlined"
                  color="primary"
                  labelId="sampler-label"
                  id="spec"
                  name="spec"
                  required
                  style={{ width: "100%" }}
                  value={values.spec}
                  onChange={handleFormChange}
                >
                  {_.map(samplerTypes, (samplerType) => {
                    return (
                      <MenuItem key={samplerType.name} value={samplerType.name}>
                        {samplerType.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {!isBasic && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label={t("hostname/ip_address")}
                      variant="outlined"
                      id="plc_address"
                      name="plc_address"
                      value={values.plc_address}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label={t("port")}
                      id="plc_port"
                      name="plc_port"
                      variant="outlined"
                      type="number"
                      value={values.plc_port}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {isBasic && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label={t("dataset_name")}
                      id="dataset_name"
                      name="dataset_name"
                      variant="outlined"
                      value={values.dataset_name}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label={t("machine_number")}
                      variant="outlined"
                      id="machine_number"
                      name="machine_number"
                      value={values.machine_number}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              {!isBasic && (
                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        className={classes.checkbox}
                        checked={values.only_mapped_tags}
                        onChange={handleFormChange}
                        name="only_mapped_tags"
                        value={values.only_mapped_tags}
                        color="primary"
                      />
                    </>
                  }
                  label={
                    <span>
                      {t("only_collect_mapped_tags")}
                      <Tooltip title={t("mapped_tags_tooltip")}>
                        <HelpIcon
                          style={{
                            display: "inline",
                            fontSize: 16,
                            marginLeft: 3,
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        {!isNew && !isBasic && (
          <>
            <CardHeader
              title={
                <>
                  <span>{t("telemetry_mappings")}</span>
                  <IconButton
                    style={{
                      marginTop: -16,
                      marginBottom: -15,
                      marginLeft: 2,
                    }}
                    onClick={() => {
                      setTelemetryDefinition({});
                      setOpenTelemetryDefinitionForm(true);
                    }}
                  >
                    <ControlPointIcon />
                  </IconButton>
                </>
              }
              action={<FilterMappings setter={setSearch} />}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                {telemetryDefinitions.map((td) => {
                  return (
                    <Grid item xs={12} sm={4} key={td.id}>
                      <FormControl className={classes.formControl}>
                        <MappedTelemetry
                          telemetryDefinition={td}
                          onEditClick={() => {
                            setTelemetryDefinition(td);
                            setOpenTelemetryDefinitionForm(true);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  );
                })}
                {pages > 0 && (
                  <Grid item xs={12}>
                    <Pagination
                      count={pages}
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      onChange={(_, page) => {
                        setPage(page);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </>
        )}
      </form>
      <TopSnackbar
        open={toast.visible}
        message={toast.message}
        error={toast.error}
      />
      <Dialog
        open={openDeleteConfirm}
        onClose={closeDeleteConfirm}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <WarningIcon color="secondary"></WarningIcon> {t("delete_sampler")}{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography component="div">
              <Box fontStyle="normal" m={1}>
                {t("delete_sampler_warning")}
              </Box>
              <Box fontWeight="fontWeightBold" m={1}>
                {t("delete")} {sampler.name}?
              </Box>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirm} className={classes.colorGray}>
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              closeDeleteConfirm();
              onDeleteSampler(sampler);
            }}
            color="secondary"
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      {openTelemetryDefinitionForm && (
        <TelemetryDefinitionForm
          machine={machine}
          sampler={sampler}
          telemetryDefinition={telemetryDefinition}
          telemetryTypes={telemetryTypes}
          open={openTelemetryDefinitionForm}
          onClose={() => {
            setOpenTelemetryDefinitionForm(false);
          }}
          utsAdEnabledCount={utsAdEnabledCount}
          onUpdate={handleUpdateTelemetryDefinition}
          onCreate={handleCreateTelemetryDefinition}
          onDelete={handleDeleteTelemetryDefinition}
          defaultHumanTags={defaultHumanTags}
        />
      )}
    </Card>
  );
}

SamplerForm.propTypes = {
  className: PropTypes.string,
  machine: PropTypes.object.isRequired,
};

export default SamplerForm;
