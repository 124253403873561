import React from "react";
import { Card, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import MetricsChart from "./MetricsChart";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    height: 167,
    justifyContent: "center",
    borderRadius: "10px",
    flexDirection: "column",
  },
  value: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  valueLabel: {
    marginRight: 0,
    color: theme.palette.gray.main,
  },
  textBold: {
    fontWeight: "600",
    color: theme.palette.text.primary,
  },
  valueWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  skeletonLabel: {
    minWidth: "110px",
  },
  skeletonContent: {
    minWidth: "20px",
  },
  chart: {
    marginTop: "10px",
  },
  unitLabel: {
    color: theme.palette.gray.main,
    marginLeft: 5,
  },
}));

const TileCard = ({ label, value, isSkeleton, chartData, units }) => {
  const classes = useStyles();

  if (isSkeleton) {
    return (
      <Card className={classes.root}>
        <div className={classes.skeletonLabel}>
          <Skeleton animation="wave" width="100%" height={20} />
        </div>
        <div className={classes.skeletonContent}>
          <Skeleton animation="wave" width="100%" height={30} />
        </div>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <div>
        <Typography variant="subtitle2" className={classes.textBold}>
          {label}
        </Typography>
      </div>
      <div className={classes.valueWrapper}>
        <div className={classes.value}>
          <Typography variant="h3" className={classes.value}>
            {value}
          </Typography>
          {units && (
            <Typography variant="body2" className={classes.unitLabel}>
              {units}
            </Typography>
          )}
        </div>
      </div>
      {chartData && (
        <MetricsChart
          data={chartData}
          width={240}
          height={40}
          margin={{ top: 10, right: 0, bottom: 0, left: 0 }}
        />
      )}
    </Card>
  );
};

export default TileCard;
