export const machineKeyToParent = (machine_key) => {
  return (machine_key || "").split(".")[0].split("[")[0].split("|")[0];
};

export const machineKeyToChild = (machine_key) => {
  machine_key = machine_key || "";
  const dotIndex = machine_key.indexOf(".");
  return dotIndex !== -1
    ? machine_key
        .slice(dotIndex + 1)
        .split("[")[0]
        .split("|")[0]
    : "";
};

export const machineKeyToArrayIndex = (machine_key) => {
  machine_key = machine_key || "";
  const leftBracket = machine_key.indexOf("[");
  const rightBracket = machine_key.indexOf("]");
  if (leftBracket !== -1)
    return machine_key.slice(leftBracket + 1, rightBracket);
  else return "";
};

export const machineKeyToBit = (machine_key) => {
  machine_key = machine_key || "";
  const bit = machine_key.split("|")[1];
  return bit || "";
};

export const parseMachineKey = (machine_key) =>
  [
    machineKeyToParent,
    machineKeyToChild,
    machineKeyToArrayIndex,
    machineKeyToBit,
  ].map((fn) => fn(machine_key));

export const createMachineKey = (parent, child, index, bit) => {
  let tag = parent;
  if (child) tag = `${tag}.${child}`;
  if (index === 0 || index) tag = `${tag}[${index}]`;
  if (bit) tag = `${tag}|${bit}`;
  return tag;
};
