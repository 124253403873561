import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 2),
    flexDirection: "column",
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    background: theme.palette.secondary.extraLight,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -16,
    left: theme.spacing(3),
    height: 32,
    width: 32,
    fontSize: 16,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  loginLogo: {
    textTransform: "uppercase",
    fontSize: 30,
    fontWeight: 700,
    letterSpacing: -2,
    textAlign: "center",
    color: theme.palette.gray.dark,
  },
  logo: {
    maxWidth: 100,
  },
  banner: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 2),
  },
}));
