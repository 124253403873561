import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TopSnackbar from "src/components/TopSnackbar";
import { createPasswordReset } from "src/repos/users";
import { useStyles } from "./LoginFormStyles";

const ResetPasswordRequestForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    values: {
      email: null,
    },
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation(["common", "glossary"]);
  const confirmationMessage = t("auth.password_reset_sent_");

  const onSubmit = (event) => {
    event.preventDefault();
    createPasswordReset(formState.values.email).then((r) => {
      if (r.status < 300) setShowConfirmation(true);
      return r.text();
    });
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  return (
    <form onSubmit={onSubmit} {...rest}>
      <div className={classes.fields}>
        <TextField
          color="primary"
          fullWidth
          label={t("glossary:email_address")}
          name="email"
          type="email"
          required
          onChange={handleChange}
          value={formState.values.email || ""}
          variant="outlined"
        />
      </div>
      <Button
        className={classes.submitButton}
        color="primary"
        size="large"
        type="submit"
        variant="contained"
      >
        {t("auth.password_reset_request")}
      </Button>
      <TopSnackbar open={showConfirmation} message={confirmationMessage} />
    </form>
  );
};

export default ResetPasswordRequestForm;
