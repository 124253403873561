import {
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useState } from "react";
import FeatureNotAvailable from "src/components/FeatureNotAvailable";
import { ShowEvent } from "src/components/ShowEvent";
import EventResults from "../../../Lists/EventList";
import EmptyResults from "src/views/SunScreens/Lists/EmptyEventList";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  grid: {},
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
  },
  notAllowedOverlay: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "10",
  },
  noBlur: { maxWidth: "100%" },
  blur: { maxWidth: "100%", filter: "blur(3px)", pointerEvents: "none" },
}));

function Events({ isAllowed, events, machine, isLoading, ...rest }) {
  const classes = useStyles();
  const eventList = isAllowed
    ? events
    : [
        { name: "MOTOR Event 1", start_at: moment() },
        { name: "Fake Event 2", start_at: moment() },
        { name: "Another Fake Event", start_at: moment() },
        { name: "Event 4", start_at: moment() },
        { name: "ANOMALOUS Event 5", start_at: moment() },
      ];

  const [selectedEvent, setSelectedEvent] = useState();
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <Card {...rest}>
      <CardHeader title={t("recent_events")} />
      <Divider />
      <CardContent style={{ maxWidth: "100%", position: "relative" }}>
        {!isAllowed && (
          <Card className={classes.notAllowedOverlay}>
            <FeatureNotAvailable />
          </Card>
        )}
        <div className={isAllowed ? classes.noBlur : classes.blur}>
          {isLoading ? (
            <EmptyResults pageSize={5} pageSizeOptions={[5, 10]} />
          ) : (
            <EventResults
              isLoading={isLoading}
              referrer="/dashboard"
              className={classes.results}
              eventList={eventList}
              machine={machine}
              pageSize={5}
              showFilters={false}
              showHeaderActions={false}
              showToolbar={false}
              showEmptyRows={true}
              onRowClicked={(sytheticEvent, event) => setSelectedEvent(event)}
              columns={[
                {
                  title: t("name"),
                  field: "name",
                  render: ({ name }) => {
                    return (
                      <div style={{ minWidth: "100px", maxWidth: "200px" }}>
                        <Tooltip title={name}>
                          <Typography noWrap={true} variant="subtitle2">
                            {name}
                          </Typography>
                        </Tooltip>
                      </div>
                    );
                  },
                },
                {
                  title: t("start_date"),
                  field: "start_at",
                  render: ({ start_at }) => {
                    return (
                      <div style={{ whiteSpace: "nowrap" }}>
                        {moment(start_at).isValid()
                          ? moment(start_at).format("lll")
                          : "Invalid"}
                      </div>
                    );
                  },
                },
                {
                  title: t("downtime_duration"),
                  render: ({ downtime_start_at, downtime_end_at }) => {
                    return (
                      <div style={{ whiteSpace: "nowrap" }}>
                        {moment(downtime_start_at).isValid() &&
                        moment(downtime_end_at).isValid()
                          ? moment
                              .duration(
                                moment(downtime_start_at).diff(
                                  moment(downtime_end_at)
                                )
                              )
                              .humanize()
                          : t("no_downtime")}
                      </div>
                    );
                  },
                  customSort: (a, b) => {
                    const endA = moment(a.downtime_end_at);
                    const endB = moment(b.downtime_end_at);
                    if (!endA.isValid()) {
                      return 1;
                    }
                    if (!endB.isValid()) {
                      return -1;
                    }

                    return (
                      endA.diff(moment(a.downtime_start_at)) -
                      endB.diff(moment(b.downtime_start_at))
                    );
                  },
                },
              ]}
            />
          )}
          <>
            {selectedEvent && (
              <ShowEvent
                event={selectedEvent}
                device={machine}
                onClose={() => setSelectedEvent(null)}
              />
            )}
          </>
        </div>
      </CardContent>
    </Card>
  );
}

export default Events;
