/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, Container } from "@material-ui/core";
import StateSection from "./StateSection";
import Page from "src/components/Page";
import TopSnackbar from "src/components/TopSnackbar";
import { isDemoModeEnabled } from "src/utils/demoMode";
import {
  getDeviceTelemDispCfgs,
  createDeviceTelemDispCfg,
  updateDeviceTelemDispCfg,
  deleteDeviceTelemDispCfg,
  resetDeviceTelemetryDisplayConfigs,
} from "src/repos/devices";
import { getDeviceHumanKeys } from "src/repos/devices/views";
import { ConfirmationDialog } from "src/components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  container: {
    padding: 0,
  },
}));

const kiwiplan_telemDispCfgs = [
  {
    human_key: "kiwiplan_order_number",
    title: i18next.t("glossary:order_number"),
    type: "passthrough",
    static: true,
    units: "",
    category: "kiwiplan",
  },
  {
    human_key: "kiwiplan_board_thickness",
    title: i18next.t("glossary:board_thickness"),
    type: "float",
    static: false,
    units: "in",
    category: "kiwiplan",
  },
  {
    human_key: "kiwiplan_die_number",
    title: i18next.t("glossary:die_number"),
    type: "passthrough",
    static: false,
    units: "",
    category: "kiwiplan",
  },
  {
    human_key: "kiwiplan_corrugator_slit_width",
    title: i18next.t("glossary:corrugator_slit_width"),
    type: "float",
    static: false,
    units: "in",
    category: "kiwiplan",
  },
  {
    human_key: "kiwiplan_lead_corrugator_cut_length",
    title: i18next.t("glossary:lead_corrugator_cut_length"),
    type: "float",
    static: false,
    units: "in",
    category: "kiwiplan",
  },
  {
    human_key: "kiwiplan_flute_code",
    title: i18next.t("glossary:flute_code"),
    type: "passthrough",
    static: false,
    units: "",
    category: "kiwiplan",
  },
];

function CurrentState({ machine }) {
  const [toast, setToast] = useState({
    error: false,
    visible: false,
    message: "",
  });
  const toastMessage = (message, error) => {
    setToast({
      error: error || false,
      visible: true,
      message: message,
    });
    setTimeout(
      () =>
        setToast((toast_) => ({
          ...toast_,
          visible: false,
        })),
      3000
    );
  };
  const classes = useStyles();
  const [telemDispCfgs, setTelemDispCfgs] = useState([]);
  const [tags, setTags] = useState([]);
  const [resetDialogOpen, setResetDialogOpen] = React.useState(false);
  const { t } = useTranslation(["glossary", "common"]);

  const submitTelemDispCfg = (action, telemDispCfg_old, telemDispCfg_new) => {
    if (action === "create")
      createDeviceTelemDispCfg(telemDispCfg_new).then((r) => {
        r.json()
          .then((data) => {
            if (r.status < 300) {
              toastMessage(t("measure_created_successfully"));
              setTelemDispCfgs((telemDispCfgs) => {
                telemDispCfgs = _.cloneDeep(telemDispCfgs);
                telemDispCfgs[data.data.category].push(data.data);
                return telemDispCfgs;
              });
            } else {
              const field = Object.keys(data.errors)[0];
              toastMessage(
                t("measure_creation_error") + ` ${field} ${data.errors[field]}`,
                true
              );
            }
          })
          .catch(() => toastMessage(t("measure_creation_error"), true));
      });
    else if (action === "update")
      updateDeviceTelemDispCfg(telemDispCfg_new).then((r) => {
        r.json()
          .then((data) => {
            if (r.status < 300) {
              toastMessage(t("measure_updated_successfully"));
              setTelemDispCfgs((telemDispCfgs) => {
                telemDispCfgs = _.cloneDeep(telemDispCfgs);
                if (telemDispCfg_old.category !== data.data.category) {
                  telemDispCfgs[telemDispCfg_old.category] = _.filter(
                    telemDispCfgs[telemDispCfg_old.category],
                    (m) => m.id !== data.data.id
                  );
                  telemDispCfgs[data.data.category].push(data.data);
                } else {
                  const index = _.findIndex(
                    telemDispCfgs[data.data.category],
                    (m) => m.id === data.data.id
                  );
                  telemDispCfgs[data.data.category][index] = data.data;
                }
                return telemDispCfgs;
              });
            } else {
              const field = Object.keys(data.errors)[0];
              toastMessage(
                t("measure_update_error") + ` ${field} ${data.errors[field]}`,
                true
              );
            }
          })
          .catch(() => toastMessage(t("measure_update_error"), true));
      });
    else if (action === "delete")
      deleteDeviceTelemDispCfg(telemDispCfg_old).then((r) => {
        // TODO: Surface this rare case to the client.
        if (!r.ok)
          console.error(
            `Failed to delete telemDispCfg: ${telemDispCfg_old.id}`
          );
        else
          setTelemDispCfgs((telemDispCfgs) => {
            telemDispCfgs = _.cloneDeep(telemDispCfgs);
            telemDispCfgs[telemDispCfg_old.category] = _.filter(
              telemDispCfgs[telemDispCfg_old.category],
              (m) => m.id !== telemDispCfg_old.id
            );
            return telemDispCfgs;
          });
      });
  };

  const resetTelemDispCfgs = (deviceId) => {
    resetDeviceTelemetryDisplayConfigs(deviceId)
      .then((r) => r.json())
      .then(({ data }) =>
        setTelemDispCfgs(
          _.groupBy(data, (telemDispCfg) => telemDispCfg.category)
        )
      );
  };

  useEffect(() => {
    if (machine && !_.isEmpty(machine)) {
      getDeviceHumanKeys(machine.id).then(setTags);
      getDeviceTelemDispCfgs(machine.id)
        .then((r) => r.json())
        .then(({ data }) =>
          setTelemDispCfgs(
            _.groupBy(data, (telemDispCfg) => telemDispCfg.category)
          )
        );
    }
  }, [machine]);

  return (
    <Page className={classes.root} title="Current State">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <StateSection
            name={t("machine_overview")}
            category="machine_overview"
            telemDispCfgs={telemDispCfgs.machine_overview}
            machine={machine}
            tags={tags}
            onSubmitTelemDispCfg={submitTelemDispCfg}
          ></StateSection>

          <StateSection
            name={t("productivity")}
            category="productivity"
            telemDispCfgs={telemDispCfgs.productivity}
            machine={machine}
            tags={tags}
            onSubmitTelemDispCfg={submitTelemDispCfg}
          ></StateSection>

          <StateSection
            name={t("printing")}
            category="printing"
            telemDispCfgs={telemDispCfgs.printing}
            machine={machine}
            tags={tags}
            onSubmitTelemDispCfg={submitTelemDispCfg}
          ></StateSection>

          <StateSection
            name={t("converting")}
            category="converting"
            telemDispCfgs={telemDispCfgs.converting}
            machine={machine}
            tags={tags}
            onSubmitTelemDispCfg={submitTelemDispCfg}
          ></StateSection>

          {isDemoModeEnabled() && (
            <StateSection
              name="KiwiPlan"
              category="kiwiplan"
              telemDispCfgs={kiwiplan_telemDispCfgs}
              machine={machine}
              tags={tags}
              onSubmitTelemDispCfg={submitTelemDispCfg}
            ></StateSection>
          )}
        </Grid>
        <ConfirmationDialog
          open={resetDialogOpen}
          onClose={() => setResetDialogOpen(false)}
          title={t("reset_telemetry_message")}
          onConfirm={() => {
            resetTelemDispCfgs(machine.id);
            setResetDialogOpen(false);
          }}
          confirmText={t("confirm")}
          body={t("revert_changes_message")}
        />
        <Button
          aria-label={t("reset_to_default")}
          color="primary"
          variant="contained"
          style={{ marginTop: "10px", float: "right" }}
          onClick={() => setResetDialogOpen(true)}
        >
          {t("reset_to_default")}
        </Button>
      </Container>
      <TopSnackbar
        open={toast.visible}
        message={toast.message}
        error={toast.error}
      />
    </Page>
  );
}

CurrentState.propTypes = {
  className: PropTypes.string,
};

export default CurrentState;
