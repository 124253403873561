import { get, post } from "./base";

import moment from "moment";

export function getEvents(deviceID, startAt, endAt) {
  let params = `?device_id=${deviceID}`;
  if (startAt) {
    endAt = endAt || moment();
    params = `${params}&start_time=${startAt.toISOString()}&end_time=${endAt.toISOString()}`;
  }
  return get(`events${params}`);
}

export function createEvent(event) {
  return post("events", { event });
}
