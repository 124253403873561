import { Box, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import AddTelemDispCfg from "src/components/AddTelemDispCfg";
import palette from "src/theme/palette.js";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: theme.spacing(3, 3),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: palette.primary.ultraLight,
    },
    height: "100%",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  cardContent: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.primary,
    textAlign: "left",
    fontWeight: 600,
    fontSize: "0.9rem",
    justifyConotent: "left",
    alignItems: "left",
    lineHeight: 1,
  },
}));

function StateCardAction({ action, category, tags, machine, onSubmit }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box className={classes.cardContainer} onClick={() => setOpen(true)}>
        <AddCircleIcon style={{ color: palette.primary.main }} />
        <Typography className={classes.cardContent} variant="h5">
          {action}
        </Typography>
      </Box>
      {open && (
        <AddTelemDispCfg
          open={open}
          selectedCategory={category}
          tags={tags}
          machine={machine}
          onClose={() => setOpen(false)}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}

StateCardAction.propTypes = {
  category: PropTypes.string,
  tags: PropTypes.array.isRequired,
  machine: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default StateCardAction;
