// @ts-nocheck
import moment from "moment";

const secondsCuttoff = 300; // 5 minutes
const minutesCutoffInSeconds = 200 * 60;
const hoursCutoffInSeconds = 200 * 60 * 60;

export const calcGranularity = (startTime: string, endTime: string) => {
  const diff = moment(endTime).diff(moment(startTime), "seconds");

  if (diff <= secondsCuttoff) {
    return "second";
  } else if (diff < minutesCutoffInSeconds) {
    return "minute";
  } else if (diff < hoursCutoffInSeconds) {
    return "hour";
  } else {
    return "day";
  }
};
