import { useLocation, useHistory } from "react-router-dom";
import _ from "lodash";

function setQuery(history, query) {
  const search = _.reduce(
    query,
    (acc, value, key) => {
      if (value !== undefined) acc.set(key, JSON.stringify(value));
      return acc;
    },
    new URLSearchParams()
  );
  history.push({ search: `?${search.toString()}` });
}

function _useQuery() {
  const query = {};
  for (let [key, value] of new URLSearchParams(useLocation().search)) {
    query[key] = JSON.parse(value);
  }
  return query;
}

export function useQuery() {
  const history = useHistory();
  const query = _useQuery();
  return [
    query,
    (query_, update = false) =>
      setQuery(history, update ? _.assign(query, query_) : query_),
  ];
}

export function useQueryParam(
  param,
  value,
  getter = _.identity,
  setter = _.identity
) {
  const [query, setQuery] = useQuery();
  return [
    getter(query[param] || setter(value)),
    (value) => setQuery(_.assign(query, { [param]: setter(value) })),
  ];
}
