import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Popover,
  MenuItem,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

function AccountMenuButton(props, history) {
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { t } = useTranslation(["glossary", "common"]);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleGoToMyAccount = () => {
    setOpenMenu(false);
    props.history.push("/account");
  };

  return (
    <>
      <Tooltip title={t("my_account")}>
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <PersonIcon />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
      >
        <MenuItem
          onClick={() => {
            handleGoToMyAccount();
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={t("my_account")} />
        </MenuItem>
        <MenuItem onClick={props.logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t("log_out")} />
        </MenuItem>
      </Popover>
    </>
  );
}

AccountMenuButton.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  logout: PropTypes.func,
};

export default withRouter(AccountMenuButton);
