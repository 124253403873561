import { Button, TableCell, TableRow } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import AddOutlined from "@material-ui/icons/AddOutlined";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Skeleton } from "@material-ui/lab";
import MaterialTable, { MTableBody, MTableToolbar } from "material-table";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCustomers } from "src/repos/customers";
import palette from "src/theme/palette";
import { gridTheme } from "../../../theme";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddOutlined {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <SortIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  AssignmentTurnedInOutlined: forwardRef((props, ref) => (
    <AssignmentTurnedInOutlined {...props} ref={ref} />
  )),
};

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingRight: 15,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& div[class|='MuiToolbar-root']": {
        flexDirection: "column",
      },
    },
    "& div[class|='MTableToolbar-title'] h6": {
      whiteSpace: "normal !important",
    },
  },
  newButton: {
    [theme.breakpoints.down("xs")]: {
      width: 200,
      marginBottom: 15,
    },
  },
}));

function Results({ className, pageSizeOptions, linkPrefix }) {
  const history = useHistory();
  const classes = useStyles();
  const [customerList, setCustomerList] = useState([]);
  const { t } = useTranslation(["glossary", "common"]);

  linkPrefix = linkPrefix || "/customers";

  console.log(linkPrefix);

  useEffect(() => {
    getCustomers()
      .then((r) => r.json())
      .then((d) => setCustomerList(d.data));
  }, []);

  const onAddNew = (event, value) => {
    history.push(`${linkPrefix}/New`);
  };

  const onRowClicked = (event, { id }) => {
    history.push(`${linkPrefix}/${id}`);
  };

  const customerColumns = [
    { title: t("id"), field: "id" },
    { title: t("name"), field: "name" },
    { title: t("contact_name"), field: "contact_name" },
    { title: t("contact_email"), field: "contact_email" },
    { title: t("contact_phone"), field: "contact_phone" },
    { title: t("city"), field: "city" },
    { title: t("state_province"), field: "state" },
    { title: t("country"), field: "country" },
    { title: t("external_id"), field: "external_id" },
  ];
  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={gridTheme}>
        <MaterialTable
          icons={tableIcons}
          components={{
            Toolbar: (props) => (
              <div>
                <div className={classes.toolbarContainer}>
                  <MTableToolbar {...props} />
                  <div>
                    <Button
                      startIcon={<AddOutlined />}
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={onAddNew}
                      className={classes.newButton}
                    >
                      {t("add_new")}
                    </Button>
                  </div>
                </div>
              </div>
            ),
            Container: (props) => (
              <div style={{ background: palette.gray.white }}>
                {props.children}
              </div>
            ),
            Body: (props) =>
              customerList.length === 0 ? (
                Array.apply(null, { length: 20 }).map((e, i) => (
                  <TableRow width="100%" key={i}>
                    {customerColumns.map((item, index) => (
                      <TableCell scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <MTableBody {...props} />
              ),
          }}
          options={{
            selection: false,
            pageSize: 20,
            filtering: true,
            columnsButton: true,
            exportButton: true,
            exportAllData: true,
            filterCellStyle: { backgroundColor: palette.gray.lightest },
            grouping: true,
            actionsColumnIndex: -1,
            toolbar: true,
            pageSizeOptions,
            emptyRowsWhenPaging: false,
          }}
          onRowClick={onRowClicked}
          columns={customerColumns}
          data={customerList}
          title={t("customer_list")}
          localization={{
            body: {
              emptyDataSourceMessage: t("no_records_to_display"),
            },
            toolbar: {
              exportTitle: t("export"),
              exportAriaLabel: t("export"),
              exportName: t("export"),
              showColumnsTitle: t("show_columns"),
              searchPlaceholder: t("search"),
              addRemoveColumns: t("add_or_remove_columns"),
            },
            grouping: {
              placeholder: t("drag_headers_message"),
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
              labelRowsSelect: t("rows"),
              firstTooltip: t("first_page"),
              previousTooltip: t("previous_page"),
              nextTooltip: t("next_page"),
              lastTooltip: t("last_page"),
            },
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customerList: PropTypes.array,
  pageSizeOptions: PropTypes.array,
};

Results.defaultProps = {
  customerList: [],
  pageSizeOptions: [10, 20, 50],
};

export default Results;
