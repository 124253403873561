import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useRef, useState, useEffect } from "react";
import SlideshowMachineCard from "src/components/MachineSnapshot/SlideshowMachineCard";
import _ from "lodash";
import Carousel from "react-elastic-carousel";

const useStyles = makeStyles(() => ({
  gridItem: {
    paddingTop: "0",
  },
}));

const SlideshowMachineSnapshot = ({
  facilityList,
  machines,
  metricAverages,
}) => {
  const classes = useStyles();
  const [lastIndex, setLastIndex] = useState(null);
  const carouselRef = useRef(null);

  const machine = (machineId) => {
    return machines.find((machine) => machine.id === machineId);
  };

  useEffect(() => {
    let timer;
    if (lastIndex !== null) {
      timer = setTimeout(() => {
        carouselRef.current.goTo(0);
      }, 30500);
    }
    return () => clearTimeout(timer);
  }, [lastIndex]);

  const onLoop = (currentItem) => {
    setLastIndex(currentItem.index);
  };

  return (
    <>
      <Carousel
        verticalMode
        showArrows={false}
        onChange={onLoop}
        ref={carouselRef}
        enableAutoPlay
        autoPlaySpeed={30000}
        itemsToShow={3}
        pagination={false}
        itemPadding={[0, 10]}
        style={{ marginTop: "100px" }}
      >
        {_.chunk(machines, 2).map((devices) => (
          <Grid
            container
            spacing={3}
            key={`${devices[0].id}-${devices[1]?.id}`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={classes.gridItem}
            >
              <SlideshowMachineCard
                machine={machine(devices[0].id)}
                facilityList={facilityList}
                metricAverages={metricAverages}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={classes.gridItem}
            >
              <SlideshowMachineCard
                machine={machine(devices[1]?.id)}
                facilityList={facilityList}
                metricAverages={metricAverages}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={classes.gridItem}
            >
              <SlideshowMachineCard
                machine={machine(devices[2]?.id)}
                facilityList={facilityList}
                metricAverages={metricAverages}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={classes.gridItem}
            >
              <SlideshowMachineCard
                machine={machine(devices[3]?.id)}
                facilityList={facilityList}
                metricAverages={metricAverages}
              />
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </>
  );
};

export default SlideshowMachineSnapshot;
