import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// eslint-disable-next-line
import i18n from "src/i18n";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ENVIRONMENT } from "src/repos/base";

if (ENVIRONMENT === "staging" || ENVIRONMENT === "production") {
  Sentry.init({
    environment: ENVIRONMENT,
    dsn:
      "https://7a90d20b755f4710bb58c59c6c03deda@o439346.ingest.sentry.io/5416655",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: ENVIRONMENT === "production" ? 0.3 : 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
