import { get, makeQuery, REQUIRED } from "./base";

export function getAnomalies(query) {
  query = makeQuery(query, {
    device_id: REQUIRED,
    start_at: REQUIRED,
    end_at: REQUIRED,
  });
  return get(`anomalies${query}`);
}

export function getAnomalySections(query) {
  query = makeQuery(query, {
    sort_by: "desc:value",
    device_id: undefined,
  });
  return get(`anomalies/summary${query}`);
}
