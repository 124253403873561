/* eslint-disable no-unused-vars */
import {
  AppBar,
  Button,
  Hidden,
  IconButton,
  Modal,
  Paper,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import FeedbackIcon from "@material-ui/icons/Feedback";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { matchPath, useHistory, useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { isDemoModeEnabled, setDemoMode } from "src/utils/demoMode";
import {
  currentUser,
  currentUserRole,
  isSuperUser,
  logout,
} from "src/utils/session";
import AccountMenuButton from "../../components/AccountMenuButton";
import { getCustomers } from "src/repos/customers";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: theme.palette.text.primary,
    boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.10)",
    minHeight: "0",
    ".MuiToolbar-regular": {
      height: 400,
    },
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(0.5, 2),
  },
  flexGrow: {
    flexGrow: 1,
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 30,
    flexGrow: 1,
  },
  tab: {
    height: 64,
    minWidth: 0,
    marginRight: 0,
    paddingLeft: 30,
    paddingRight: 30,
  },
  navIcons: {},
  menuButton: {
    marginRight: theme.spacing(1),
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    lineHeight: 0,
    paddingTop: 4,
    paddingBottom: 4,
    width: 100,
  },
  appBar: {
    backgroundColor: "white",
  },
}));

function TopBar({ onOpenMobileMenu, className, staticContext, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const [logoutModalOpen, setLogoutModalState] = useState(false);
  const [demoModeChecked, setDemoModeChecked] = useState(isDemoModeEnabled());
  const user = currentUser();
  const { t } = useTranslation(["glossary", "common"]);

  const location = useLocation();

  const modalStyle = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    outline: "none",
  };

  const customerAdminTabs = [
    { value: "/dashboard", label: t("dashboard") },
    { value: "/machines", label: t("machines") },
    {
      value: `/customers/${user?.customer_id}`,
      label: "Organization",
    },
    { value: "/reports", label: t("reports") },
  ];
  const unprivilegedUserTabs = [
    { value: "/dashboard", label: t("dashboard") },
    { value: "/machines", label: t("machines") },
    { value: "/reports", label: t("reports") },
  ];
  const superUserTabs = [
    { value: "/dashboard", label: t("dashboard") },
    { value: "/machines", label: t("machines") },
    { value: "/reports", label: t("reports") },
    { value: "/admin", label: t("admin") },
  ];

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const [logo, setLogo] = useState("/images/logos/helios-logo-fullColor.png");

  useEffect(() => {
    if (
      currentUserRole() !== "superuser" &&
      currentUserRole() !== "oem_superuser"
    ) {
      fetchAndSetLogo();
    }
  }, []);

  function fetchAndSetLogo() {
    //getCustomers returns only the customer the user belongs to when not superuser or oem_superuser
    getCustomers()
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && data.data.length > 0) {
          const customers = data.data;
          const currentCustomer = customers[0];
          console.log(currentCustomer.logo);
          const logo_file =
            currentCustomer?.logo || "helios-logo-fullColor.png";
          setLogo("/images/logos/" + logo_file);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }

  let tabs;
  function getCurrentTab() {
    let currentPage;
    const role = currentUserRole();
    switch (role) {
      case "superuser":
        tabs = superUserTabs;
        break;
      case "admin":
        tabs = customerAdminTabs;
        break;
      default:
        tabs = unprivilegedUserTabs;
        break;
    }
    tabs.map((tab) => {
      let isPage = matchPath(location.pathname, {
        path: tab.value,
        exact: false,
      });
      if (isPage) {
        currentPage = tab.value;
      }
      return currentPage;
    });
    return currentPage ? currentPage : false;
  }

  const openLogoutModal = () => {
    setLogoutModalState(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalState(false);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root)}>
      <Toolbar>
        <RouterLink to="/dashboard">
          <img className={classes.logo} alt="Logo" src={logo} />
        </RouterLink>
        <div className={classes.tabs}>
          <Hidden smDown>
            <Tabs
              indicatorColor="primary"
              value={getCurrentTab()}
              onChange={handleTabsChange}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  disableFocusRipple={true}
                  classes={{ root: classes.tab }}
                />
              ))}
            </Tabs>
          </Hidden>
        </div>

        <Hidden smDown>
          <Tooltip title={t("help_contact")}>
            <IconButton
              onClick={() =>
                window.open(
                  "https://sunautomation.atlassian.net/servicedesk/customer/portal/3"
                )
              }
            >
              <FeedbackIcon />
            </IconButton>
          </Tooltip>
          <AccountMenuButton logout={openLogoutModal} />
        </Hidden>
        {isSuperUser() && (
          <Switch
            checked={demoModeChecked}
            onChange={(event) => {
              setDemoMode(event.target.checked);
              setDemoModeChecked(event.target.checked);
              window.location.reload();
            }}
            value="flag"
            color="primary"
          />
        )}
        <Hidden mdUp>
          <IconButton onClick={onOpenMobileMenu}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Modal
        open={logoutModalOpen}
        onClose={closeLogoutModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle}>
          <Paper elevation={1} style={{ padding: "15px" }}>
            <Typography
              id="simple-modal-title"
              variant="h4"
              gutterBottom
              style={{ padding: "5px" }}
            >
              {t("log_out")}
            </Typography>
            <Typography
              id="simple-modal-description"
              variant="body2"
              style={{ padding: "5px" }}
            >
              {t("log_out_confirmation")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={clsx(classes.textDanger, classes.button)}
              onClick={logout}
              style={{ marginTop: "10px", marginLeft: "5px" }}
            >
              {t("confirm_log_out")}
            </Button>
          </Paper>
        </div>
      </Modal>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
