import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";
import palette from "src/theme/palette.js";
import { useTranslation } from "react-i18next";

function FilterMappings({ setter }) {
  const [jobID, setJobID] = useState(null);
  const { t } = useTranslation(["glossary", "common"]);

  return (
    <TextField
      placeholder={t("filter")}
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <SearchIcon style={{ color: palette.gray.main }} />
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        const value = e.target.value;
        clearTimeout(jobID);
        setJobID(setTimeout(() => setter(value), 1000));
      }}
    />
  );
}

export default FilterMappings;
