import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import RotateLeftRoundedIcon from "@material-ui/icons/RotateLeftRounded";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TopSnackbar from "src/components/TopSnackbar";
import {
  createPasswordReset,
  deleteUser,
  getUsers,
  updateUser,
} from "src/repos/users";
import palette from "src/theme/palette";
import UsersList from "../../Lists/GenericList";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    padding: 0,
  },
}));

const tableIcons = {
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  ResetPassword: forwardRef((props, ref) => (
    <RotateLeftRoundedIcon {...props} ref={ref} />
  )),
};

function Users({ confirmation, facilityDetails, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common", "glossary"]);
  const getSnackbarState = () => {
    return !!history.location?.state?.confirmation;
  };
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeUser, setActiveUser] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(getSnackbarState);
  const [clickHandler, setClickHandler] = useState();
  const handleClickOpen = (handler, data) => {
    setClickHandler(handler);
    setActiveUser(data);
    setOpen(true);
  };
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getUsers()
      .then((r) => r.json())
      .then(({ data }) => setUserList(data))
      .then(() => setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleEdit = (user) => {
    history.push(`/admin/users/${user.id}`);
  };

  const handleDelete = (user) => {
    deleteUser(user.id)
      .then((r) => {
        if (r.status >= 300) console.error(`Failed to delete user ${user.id}.`);
        else
          getUsers()
            .then((r) => r.json())
            .then(({ data }) => setUserList(data));
      })
      .finally(() => handleClose());
  };

  const handlePasswordReset = (user) => {
    createPasswordReset(user.email).then((r) => {
      if (r.status < 300) {
        updateUser({
          ...user,
          reset_password: true,
          login_attempts: 0,
          login_attempt_last_at: null,
        })
          .then((r) => {
            if (r.status >= 300)
              console.error(`Failed to reset password of user ${user.id}.`);
            else
              getUsers()
                .then((r) => r.json())
                .then(({ data }) => setUserList(data));
          })
          .finally(() => handleClose());
      }
    });
  };

  const handle = (user) => {
    if (clickHandler === "delete") handleDelete(user);
    else handlePasswordReset(user);
  };

  const onAddNew = (event, value) => {
    history.push(`/admin/users/new`);
  };

  if (!userList) {
    return null;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UsersList
              isLoading={isLoading}
              list_data={userList}
              display_columns={[
                {
                  title: t("glossary:name"),
                  grouping: false,
                  field: "first_name",
                  render: (rowData) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      {rowData.first_name} {rowData.last_name}
                    </div>
                  ),
                },
                { title: t("glossary:email"), field: "email", grouping: false },
                { title: t("glossary:phone"), grouping: false, field: "phone" },
                { title: t("glossary:role"), field: "role" },
                { title: t("glossary:status"), field: "status" },
              ]}
              actions={[
                {
                  icon: tableIcons["Delete"],
                  tooltip: t("user.delete"),
                  onClick: (event, rowData) => {
                    handleClickOpen("delete", rowData);
                  },
                },
                (rowData) =>
                  rowData.reset_password
                    ? null
                    : {
                        icon: tableIcons["ResetPassword"],
                        tooltip: t("auth.password_reset"),
                        onClick: (event, rowData) => {
                          handleClickOpen("reset_password", rowData);
                        },
                      },
              ]}
              title={t("user.management")}
              onRowClicked={(event, rowData) => handleEdit(rowData)}
              show_grouping
              onAddNew={onAddNew}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Dialog
        open={open && activeUser}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {clickHandler === "delete"
            ? t("user.delete?")
            : t("user.password_reset?")}
        </DialogTitle>
        <DialogContent>
          {clickHandler === "delete" ? (
            <DialogContentText id="alert-dialog-description">
              <Trans
                i18nKey="user.delete?_"
                values={{ user: activeUser }}
                components={{ strong: <strong /> }}
              />
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <Trans
                i18nKey="user.password_reset?_"
                values={{ user: activeUser }}
                components={{ strong: <strong /> }}
              />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("glossary:cancel")}
          </Button>
          <Button
            onClick={() => handle(activeUser)}
            color="secondary"
            autoFocus
            style={{ color: palette.status.error.main }}
          >
            {clickHandler === "delete"
              ? t("glossary:delete")
              : t("auth.password_reset")}
          </Button>
        </DialogActions>
      </Dialog>
      <TopSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
        message={t("glossary:form_saved_successfully")}
      />
    </Card>
  );
}

Users.propTypes = {
  className: PropTypes.string,
};

export default Users;
