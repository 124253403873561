import { del, get, post, put, makeQueryJSON } from "../base";

export function getSamplerSpecs() {
  return get("samplers/specs");
}

export function deleteSampler(samplerID) {
  return del(`samplers/${samplerID}`);
}

export function createSampler(sampler) {
  return post("samplers", sampler);
}

export function updateSampler(sampler) {
  return put(`samplers/${sampler.id}`, sampler);
}

export function getSamplerTelemetryTypes(samplerID) {
  return get(`samplers/${samplerID}/telemetry_types`);
}

export function getSamplerTelemetryDefinitions(samplerID, query) {
  query = makeQueryJSON(query, {
    page: 1,
    limit: 30,
    // filters: [{"op": "<eq|ne|il>", "va": "<value>", "fd": "<field>"}],
  });
  return get(`samplers/${samplerID}/telemetry_definitions${query}`);
}
