import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Card, CardContent } from "@material-ui/core";
import Results from "../Lists/CustomerList";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function Customers(props) {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <PageHeader pageTitle="Customers" />
        <Card>
          <CardContent className={classes.content}>
            <Results className={classes.results} />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default Customers;
