export function download(filename, data, mimetype = "text/csv") {
  const a = document.createElement("a");
  a.href = `data:${mimetype};base64,${btoa(data)}`;
  a.type = "text/csv";
  a.download = filename;
  a.style.display = "none";
  a.style.visibility = "hidden";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
