import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Modal,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modalBody: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    outline: "none",
    width: "20%",
    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
    [theme.breakpoints.down("md")]: {
      width: "55%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

function AddEmailCC({
  open,
  cc,
  emails,
  categories,
  onClose,
  onCreate,
  onUpdate,
  onDelete,
}) {
  const classes = useStyles();
  const newCC = cc === undefined || _.isEmpty(cc);
  const { t } = useTranslation(["glossary", "common"]);

  cc = newCC ? {} : cc;
  emails = emails || [];
  categories = categories || [];

  const [name, setName] = useState(cc.name || "");
  const [email, setEmail] = useState(cc.email || "");
  const [team, setTeam] = useState(cc.team || "");

  const submit = () => {
    const action = newCC ? onCreate : onUpdate;
    action({
      ...cc,
      name,
      team,
      email,
    });
    onClose();
  };

  const submitEnabled = () => name && team && email;

  const submitDelete = () => {
    onDelete(cc);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} style={{ overflow: "scroll" }}>
      <div className={classes.modalBody}>
        <Card elevation={1}>
          <CardHeader
            title={
              <span style={{ fontSize: "20px" }}>
                {newCC ? t("add") : t("update")} {t("notification_email_cc")}
              </span>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  type="text"
                  fullWidth
                  label={t("name")}
                  name="name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  id="team"
                  options={categories}
                  name="team"
                  value={team}
                  onChange={(_, value) => {
                    setTeam(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("team")}
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        setTeam(e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  id="email"
                  options={emails}
                  name="tag"
                  onChange={(_, value) => {
                    setEmail(value);
                  }}
                  value={email}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("email")}
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions
            style={{
              justifyContent: "space-between",
            }}
          >
            <span>
              <Button
                disabled={!submitEnabled()}
                type="submit"
                variant="contained"
                color="secondary"
                onClick={submit}
              >
                {t("submit")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={onClose}
                style={{ marginLeft: "5px" }}
              >
                {t("close")}
              </Button>
            </span>
            <span>
              {!newCC && (
                <Button
                  type="submit"
                  variant="contained"
                  color="red"
                  onClick={submitDelete}
                >
                  {t("delete")}
                </Button>
              )}
            </span>
          </CardActions>
        </Card>
      </div>
    </Modal>
  );
}

export default AddEmailCC;
