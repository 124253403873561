import React, { Suspense, useState } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  LinearProgress,
  ThemeProvider,
  StylesProvider,
} from "@material-ui/core";
import TopBar from "./TopBar";
import DowntimeReportBar from "./DowntimeReportBar";
import MobileMenu from "./MobileMenu";
import { theme } from "../../theme";
import { Terms } from "src/components/Terms";
import { isSuperUser } from "src/utils/session";

const showMenu = false;
const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: isSuperUser() ? 128 : 64,
    paddingBottom: 32,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: showMenu ? 256 : 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
}));

function Dashboard({ route }) {
  const classes = useStyles();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <TopBar onOpenMobileMenu={() => setOpenMobileMenu(true)} />
        {isSuperUser() && (
          <DowntimeReportBar onOpenMobileMenu={() => setOpenMobileMenu(true)} />
        )}
        <MobileMenu
          onMobileClose={() => setOpenMobileMenu(false)}
          openMobile={openMobileMenu}
        />

        <div className={classes.container}>
          <div className={classes.content}>
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </div>
        </div>
        <Terms />
      </StylesProvider>
    </ThemeProvider>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
