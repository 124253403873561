export default {
  root: {
    height: 5,
  },
  thumb: {
    color: "#FFFFFF",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
  },
  track: {
    color: "#1851E4",
    height: 5,
  },
  rail: {
    color: "black",
    height: 5,
  },
  mark: {
    backgroundColor: "none",
  },
};
