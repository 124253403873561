const VALID_CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_".split(
  ""
);

const VALID_CHAR_MAP = VALID_CHARS.reduce((acc, c) => {
  acc[c] = true;
  return acc;
}, {});

const isValidChar = (c) => VALID_CHAR_MAP[c] || false;

export const isValidHumanKey = (humanKey) =>
  humanKey && humanKey.length > 0 && humanKey.split("").every(isValidChar);
