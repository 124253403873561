import React from "react";
import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-perfect-scrollbar/dist/css/styles.css";
import { theme } from "./theme";
import routes from "./routes";
import GoogleAnalytics from "./components/GoogleAnalytics";
import CookiesNotification from "./components/CookiesNotification";
import ScrollReset from "./components/ScrollReset";
import StylesProvider from "./components/StylesProvider";
import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./mock";
import "./assets/scss/main.scss";
import { history } from "src/utils/history";
import SocketProvider from "./context/SocketContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SocketProvider>
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              <CookiesNotification />
              {renderRoutes(routes)}
            </Router>
          </SocketProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
