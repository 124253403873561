import uuid from "uuid/v1";
import mock from "src/utils/mock";

mock.onGet("/api/machine/state/sample").reply(200, {
  machineStates: [],
});

mock.onGet("/api/machine/state").reply(200, {
  machineStates: [
    {
      id: uuid(),
      title: "SPH",
      value: "120 SPH",
      status: "error",
      type: "value",
    },
    {
      id: uuid(),
      title: "UPTIME",
      value: "75%",
      status: "error",
      type: "value",
    },
    {
      id: uuid(),
      title: "MSF",
      value: "12",
      status: "above_average",
      type: "value",
    },
    {
      id: uuid(),
      title: "TRANSMISSION HEALTH",
      value: "23/25",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "ANVIL BLANKET THICKNESS",
      value: "14.3",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "ANVIL IMPRESSION SETTINGS",
      value: "Normal",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "MACHINE RUNNING & FEEDING",
      value: "Yes",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "PRINT",
      value: "Running",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "MACHINE RUNNING",
      value: "Yes",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "TEMPERATURE",
      value: "84F",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "JAM DETECTED",
      value: "No",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "TIME ONLINE",
      value: "12:33:23",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "SHEET COUNT",
      value: "1,294",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "MACHINE FEEDING",
      value: "Yes",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "WASH REQUESTED",
      value: "No",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "E-STOP PRESSED",
      value: "No",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "GRINDER STATUS",
      value: "Running",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "HOME COUNTER ALL AXIS",
      value: "Yes",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "COMPENSATOR CURRENT",
      value: "14A",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "FIND COVERS",
      value: "Yes",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "COMPENSATION OVERRIDE VALUE",
      value: "158",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "PUMP STROKES",
      value: "14",
      status: "normal",
      type: "value",
    },
    {
      id: uuid(),
      title: "NUMBER OF SINGLE SHEET FEEDS",
      value: "12",
      status: "normal",
      type: "boolean",
    },
    {
      id: uuid(),
      title: "SINGLE FEED ON",
      value: "Yes",
      status: "normal",
      type: "value",
    },
  ],
});
