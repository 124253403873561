import React, { useMemo } from "react";
import { PieChart, Pie, Cell, Label } from "recharts";
import palette from "src/theme/palette.js";

type CustomLabelProps = {
  viewBox?: {
    cx: number;
    cy: number;
  };
  label: string;
  caption?: string;
  value: string;
  variant: GaugeProps["variant"];
};

type Option = {
  valueSize: string;
  labelSize: string;
  valueMargin: number;
  labelMargin: number;
  captionSize: string;
  captionMargin: number;
};

type LabelOptions = {
  small: Option;
  large: Option;
};

function CustomLabel(props: CustomLabelProps) {
  const cx = props.viewBox?.cx;
  const cy = props.viewBox?.cy || 0;
  const variant = props.variant || "large";
  const options: LabelOptions = {
    small: {
      valueSize: "20px",
      labelSize: "12px",
      valueMargin: 0,
      labelMargin: 55,
      captionSize: "7px",
      captionMargin: 10,
    },
    large: {
      valueSize: "25px",
      labelSize: "13px",
      valueMargin: 10,
      labelMargin: 15,
      captionSize: "10px",
      captionMargin: 32,
    },
  };

  return (
    <>
      <text
        x={cx}
        y={cy - options[variant].valueMargin}
        fill="#4A4A4A"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan
          alignmentBaseline="middle"
          fontSize={options[variant].valueSize}
          fontWeight="700"
        >
          {props.value}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + options[variant].labelMargin}
        fill="rgba(0, 0, 0, 0.87)"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize={options[variant].labelSize} fontWeight="600">
          {props.label}
        </tspan>
      </text>
      {!!props.caption && (
        <text
          x={cx}
          y={cy + options[variant].captionMargin}
          fill="#666666"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan fontSize={options[variant].captionSize} fontWeight="600">
            {props.caption}
          </tspan>
        </text>
      )}
    </>
  );
}

type GraphData = {
  value: number;
};

type GaugeProps = {
  data: GraphData[];
  label: string;
  caption?: string;
  value: string;
  rawValue?: number;
  width: number;
  height: number;
  colorRules: "op-uptime" | "downtime";
  variant?: "small" | "large";
};

const ColorRules = {
  "op-uptime": [
    {
      range: [0, 20],
      color: palette.gauge.danger,
    },
    {
      range: [21, 50],
      color: palette.gauge.warning,
    },
    {
      range: [51, 100],
      color: palette.gauge.success,
    },
  ],
  downtime: [
    {
      range: [0, 50],
      color: palette.gauge.success,
    },
    {
      range: [51, 90],
      color: palette.gauge.warning,
    },
    {
      range: [91, 100],
      color: palette.gauge.danger,
    },
  ],
};

const Gauge = ({
  data,
  value,
  rawValue,
  label,
  caption,
  width,
  height,
  colorRules,
  variant = "large",
}: GaugeProps) => {
  const gaugeColor = useMemo(() => {
    const defaultColor = palette.gauge.background;
    if (!rawValue) return defaultColor;
    if (rawValue > 100) return palette.gauge.success;
    const color = ColorRules[colorRules].find(
      (rule) => rule.range[0] <= rawValue && rawValue <= rule.range[1]
    );
    return color?.color || defaultColor;
  }, [rawValue, colorRules]);
  const radius = variant === "small" ? "62%" : "65%";

  return (
    <PieChart
      height={height}
      width={width}
      margin={{ top: 0, right: 0, bottom: -25, left: 0 }}
    >
      <Pie
        startAngle={230}
        endAngle={-50}
        innerRadius={radius}
        data={data}
        dataKey="value"
        labelLine={false}
        isAnimationActive
      >
        <Cell fill={gaugeColor} />
        <Cell fill={palette.gauge.background} />
        <Label
          content={
            <CustomLabel
              value={value}
              label={label}
              variant={variant}
              caption={caption}
            />
          }
          position="center"
        />
      </Pie>
    </PieChart>
  );
};

export default Gauge;
