import { Button, Link, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: "none",
    padding: 10,
    zIndex: 2000,
  },
  content: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

function CookiesNotification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["glossary", "common"]);

  const handleClose = () => {
    Cookies.set("consent", "true");
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get("consent");

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Paper className={classes.root} elevation={3}>
      <div className={classes.content}>
        <Typography variant="body1">
          {t("cookies_message")}{" "}
          <Link
            className={classes.link}
            component="a"
            href="https://www.sunautomation.com/wp-content/uploads/2018/12/Sun-Automation-Group-Privacy-Policy-20181213.pdf"
            target="_blank"
          >
            {t("privacy_policy")}
          </Link>
          .
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          className={classes.agreeButton}
          color="secondary"
          onClick={handleClose}
          variant="contained"
        >
          {t("i_agree")}
        </Button>
      </div>
    </Paper>
  );
}

export default CookiesNotification;
