import { Button, TextField, Typography } from "@material-ui/core";
import Cookies from "js-cookie";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import TopSnackbar from "src/components/TopSnackbar";
import { useSocketContext } from "src/context/SocketContext";
import { updateUserPassword } from "src/repos/users";
import { getQueryParam } from "src/utils/query";
import { useStyles } from "./LoginFormStyles";

const humanize = (str) => {
  return _.capitalize(
    _.trim(_.snakeCase(str).replace(/_id$/, "").replace(/_/g, " "))
  );
};

const ResetPasswordForm = ({ className, initial, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const { setToken } = useSocketContext();
  const { token } = useParams();
  const { t } = useTranslation(["common", "glossary"]);
  const [formState, setFormState] = useState({
    submitted: false,
    values: {
      password: null,
      password_confirmation: null,
    },
  });
  const { password, password_confirmation } = formState.values;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errors, setErrors] = useState({});
  const errorMessages = errors
    ? Object.keys(errors).map((key) =>
        errors[key].map((errorMessage, messageIndex) => (
          <li key={`${key}${messageIndex}`}>{`${humanize(
            key
          )} ${errorMessage}`}</li>
        ))
      )
    : null;
  const confirmationMessage = t("auth.password_reset_success_");

  const onSubmit = (e) => {
    e.preventDefault();
    updateUserPassword(password, password_confirmation, token).then((r) => {
      if (r.status < 300)
        r.json().then(({ data }) => {
          setShowConfirmation(true);
          setTimeout(() => {
            Cookies.set("sessionId", data.token);
            Cookies.set("currentUser", data.user);
            setToken(data.token);
            const redirect = getQueryParam("redirect");
            history.push(redirect || "/dashboard");
          }, 3000);
        });
      else r.json().then(({ errors }) => setErrors(errors));
    });
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  return (
    <form onSubmit={onSubmit} {...rest}>
      <div className={classes.fields}>
        <TextField
          color="primary"
          fullWidth
          label={t(initial ? "auth.password" : "auth.password_new")}
          name="password"
          type="password"
          required
          onChange={handleChange}
          value={formState.values.password || ""}
          variant="outlined"
        />
      </div>
      <div className={classes.fields}>
        <TextField
          color="primary"
          fullWidth
          label={t(
            initial
              ? "auth.password_confirmation"
              : "auth.password_new_confirmation"
          )}
          name="password_confirmation"
          type="password"
          required
          onChange={handleChange}
          value={formState.values.password_confirmation || ""}
          variant="outlined"
        />
      </div>
      <div className={classes.fields}>
        {errors && (
          <Typography variant="body2" className={classes.errors}>
            {errorMessages}
          </Typography>
        )}
      </div>
      <Button
        className={classes.submitButton}
        color="primary"
        size="large"
        type="submit"
        variant="contained"
      >
        {initial ? t("glossary:submit") : t("auth.password_reset")}
      </Button>
      <TopSnackbar open={showConfirmation} message={confirmationMessage} />
    </form>
  );
};

export default ResetPasswordForm;
