import _ from "lodash";
import { isSuperUser } from "src/utils/session";

const GREEK_LETTERS = [
  "Alpha",
  "Beta",
  "Gamma",
  "Delta",
  "Epsilon",
  "Zeta",
  "Eta",
  "Theta",
  "Iota",
  "Kappa",
  "Lambda",
  "Mu",
  "Nu",
  "Xi",
  "Omikron",
  "Pi",
  "Rho",
  "Sigma",
  "Tau",
  "Upsilon",
  "Phi",
  "Chi",
  "Psi",
  "Omega",
];

export function setDemoMode(value) {
  localStorage.setItem("demoMode", value);
}

export function isDemoModeEnabled() {
  return localStorage.getItem("demoMode") === "true" && isSuperUser();
}

function demoize(value) {
  if (value.length < 4) {
    const code = value.charCodeAt(0);
    value =
      value +
      _.map(_.range(1, 5 - value.length), (i) => {
        return String.fromCharCode(code + i);
      }).join("");
  }
  value = String(
    _.reduce(
      _.map(value, (c) => c.charCodeAt(0)),
      (a, v) => {
        return Math.log(a * v);
      }
    )
  ).slice(-7, -1);
  return _.map(_.chunk(value, 2), ([a, b]) => {
    const i = parseInt(`${a}${b}`) % 24;
    return GREEK_LETTERS[i];
  }).join(" ");
}

export function demoValue(value, template) {
  if (isDemoModeEnabled()) {
    value = demoize(value);
    if (typeof template === "string") value = template + value;
    else if (typeof template === "function") value = template(value);
  }
  return value;
}

const demoData = [
  {
    kiwiplan_order_number: 10,
    kiwiplan_board_thickness: 0.1875,
    kiwiplan_die_number: 2,
    kiwiplan_corrugator_slit_width: 12,
    kiwiplan_lead_corrugator_cut_length: 24,
    kiwiplan_flute_code: "A",
  },
  {
    kiwiplan_order_number: 11,
    kiwiplan_board_thickness: 0.125,
    kiwiplan_die_number: 4,
    kiwiplan_corrugator_slit_width: 30,
    kiwiplan_lead_corrugator_cut_length: 62,
    kiwiplan_flute_code: "B",
  },
  {
    kiwiplan_order_number: 12,
    kiwiplan_board_thickness: 0.15625,
    kiwiplan_die_number: 6,
    kiwiplan_corrugator_slit_width: 60,
    kiwiplan_lead_corrugator_cut_length: 138,
    kiwiplan_flute_code: "C",
  },
];

// On initial get, value will be null, but Number(null) === 0
const dataIndex =
  (Number(localStorage.getItem("kiwiplan_demo_data_index")) + 1) % 3;
localStorage.setItem("kiwiplan_demo_data_index", dataIndex);

function getDemoDataByTag(tag) {
  return demoData[dataIndex][tag];
}

export function demoChannel(name) {
  const tag = name.split(":").slice(-1)[0];
  const channel = {
    join: () => channel,
    receive: (status, callback) => {
      if (status === "ok") callback(getDemoDataByTag(tag));
      return channel;
    },
    on: (_, callback) => {
      callback({ data: getDemoDataByTag(tag) });
      return channel;
    },
    leave: () => channel,
  };
  return channel;
}
